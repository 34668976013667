import {
    GET_PROXY_ADUNITS_SUCCESS,
    GET_PROXY_ADUNITS_FAIL,
    GET_PROXY_ADUNITS_LOADING,
    ADD_PROXY_ADUNIT_SUCCESS,
    ADD_PROXY_ADUNIT_ERROR,
    UPDATE_PROXY_ADUNIT_SUCCESS,
    UPDATE_PROXY_ADUNIT_ERROR,
    READ_PROXY_ADUNIT_SUCCESS,
    STATUS_PROXY_ADUNIT_SUCCESS,
    ARCHIVE_PROXY_ADUNIT_SUCCESS,
    GET_PROXY_ADUNITS_REPORTS_FAIL,
    GET_PROXY_ADUNITS_REPORTS_SUCCESS,

    GET_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_ANALYTICS_DISPLAY_REPORTS_FAIL,
    GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS,

    GET_PAGE_URL_21_REPORTS_FAIL,
    GET_PAGE_URL_21_REPORTS_SUCCESS,

    LOAD_REPORTS
} from "./actionTypes"

const INIT_STATE = {
    proxyAdU: [],
    proxyAdURpt: [],
    loading:false,
    revenueDisplayRpt: [],
    publisherRevenueDisplayRpt: [],
    agencyRevenueDisplayRpt: [],
    pAgencyRevenueDisplayRpt: [],
    analyticsDisplayRpt: [],
    pageUrl21Rpt: [],
    proxyLoading: {},
    error: {},
    successMsg: null,
    errorMsg: null,
    proxyAdUnit: null,
    response: null,
    archiveMsg: null,
}

const proxyAdUnit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_REPORTS:
            return {
                ...state,
                loading: true,
            }
        case GET_PROXY_ADUNITS_SUCCESS:
            return {
                ...state,
                loading: false,
                proxyAdU: action.payload,
            }
        case GET_PROXY_ADUNITS_LOADING:
            return {
                ...state,
                loading: false,
                proxyLoading: action.payload,
            }
        case GET_PROXY_ADUNITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_PROXY_ADUNIT_SUCCESS:

            return {
                ...state,
                loading: false,
                successMsg: action.payload,
            }
        case ADD_PROXY_ADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PROXY_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PROXY_ADUNIT_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PROXY_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PROXY_ADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PROXY_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case READ_PROXY_ADUNIT_SUCCESS:
            return {
                ...state,
                proxyAdUnit: action.payload,
            }
        case STATUS_PROXY_ADUNIT_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PROXY_ADUNIT_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        case GET_PROXY_ADUNITS_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                proxyAdURpt: action.payload,
            }

        case GET_PROXY_ADUNITS_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_REVENUE_DISPLAY_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                revenueDisplayRpt: action.payload,
            }

        case GET_REVENUE_DISPLAY_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
            case GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    publisherRevenueDisplayRpt: action.payload,
                }
    
            case GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                }
                case GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        agencyRevenueDisplayRpt: action.payload,
                    }
        
                case GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    }

                    case GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS:
                        return {
                            ...state,
                            loading: false,
                            pAgencyRevenueDisplayRpt: action.payload,
                        }
            
                    case GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL:
                        return {
                            ...state,
                            loading: false,
                            error: action.payload,
                        }
        case GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                analyticsDisplayRpt: action.payload,
            }

        case GET_ANALYTICS_DISPLAY_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case GET_PAGE_URL_21_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                pageUrl21Rpt: action.payload,
            }

        case GET_PAGE_URL_21_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default proxyAdUnit