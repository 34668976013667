import {
    GET_SIZES_SUCCESS,
    GET_SIZES_FAIL,
    GET_DEVICE_SUCCESS,
    GET_DEVICE_FAIL,
    GET_OS_SUCCESS,
    GET_OS_FAIL,
    GET_BROWSER_SUCCESS,
    GET_BROWSER_FAIL,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAIL,
    GET_PAGE_URL_SUCCESS,
    GET_PAGE_URL_FAIL,
    GET_INTEREST_SUCCESS,
    GET_INTEREST_FAIL,
    GET_KEYWORD_SUCCESS,
    GET_KEYWORD_FAIL,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_FAIL,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_BRAND_AFFINITY_SUCCESS,
    GET_BRAND_AFFINITY_FAIL,
    GET_REGION_SUCCESS,
    GET_REGION_FAIL,
    GET_CITY_SUCCESS,
    GET_CITY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    sizeList: [],
    contextualAllData: {},
    oSList: [],
    browserList: [],
    domainList: [],
    pageUrlList: [],
    interestList: [],
    keywordsList: [],
    languageList: [],
    categoryList: [],
    brandAffinityList: [],
    regionList: [],
    citiesList: [],
    error: {},
    successMsg: null,
    errorMsg: null,
}

const commonEvents = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_SIZES_SUCCESS:
            return {
                ...state,
                sizeList: action.payload,
            }

        case GET_SIZES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_DEVICE_SUCCESS:
            return {
                ...state,
                contextualAllData: action.payload,
            }

        case GET_DEVICE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_OS_SUCCESS:
            return {
                ...state,
                oSList: action.payload,
            }

        case GET_OS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_BROWSER_SUCCESS:
            return {
                ...state,
                browserList: action.payload,
            }

        case GET_BROWSER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_DOMAIN_SUCCESS:
            return {
                ...state,
                domainList: action.payload,
            }

        case GET_DOMAIN_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_PAGE_URL_SUCCESS:
            return {
                ...state,
                pageUrlList: action.payload,
            }

        case GET_PAGE_URL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_INTEREST_SUCCESS:
            return {
                ...state,
                interestList: action.payload,
            }

        case GET_INTEREST_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_KEYWORD_SUCCESS:
            return {
                ...state,
                keywordsList: action.payload,
            }

        case GET_KEYWORD_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                languageList: action.payload,
            }

        case GET_LANGUAGE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
            }

        case GET_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_BRAND_AFFINITY_SUCCESS:
            return {
                ...state,
                brandAffinityList: action.payload,
            }

        case GET_BRAND_AFFINITY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_REGION_SUCCESS:
            return {
                ...state,
                regionList: action.payload,
            }

        case GET_REGION_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_CITY_SUCCESS:
            return {
                ...state,
                citiesList: action.payload,
            }

        case GET_CITY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default commonEvents