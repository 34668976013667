import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  getPublisher as onGetPublishers,
  statusPublisher as onStatusPublisher,
  archivePublisher as onArchivePublisher,
  statusPublisherSuccess,
  archivePublisherSuccess,
  addPublisherFail,
  updatePublisherFail,
} from "store/Supply/Publisher/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Publisher from "./Create_Publisher";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const Publisher = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {

      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: 'Active', type: '' }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: 'Active', type: '' }));
    }
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetPublishers(null));
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.publisherID)
        selectedItem.push(row.publisherID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.publisherID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.publisherID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.publisherID)
          selectedItem.push(item.publisherID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { publishers } = useSelector(state => ({
    publishers: state.publishers.publisherList,
  }));

  const { loading } = useSelector(state => ({
    loading: state.publishers.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.publishers.noData,
  }));

  const { publishersResponse } = useSelector(state => ({
    publishersResponse: state.publishers.response,
  }));

  const { archivePublishersResponse } = useSelector(state => ({
    archivePublishersResponse: state.publishers.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.publishers.errorMsg,
  //   successMsg: state.publishers.successMsg,
  // }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(publishersResponse)
  useEffect(() => {
    if (publishersResponse !== null) {
      if (publishersResponse.success) {
        toastr.success('Publisher Status Changed Successfully')
        if (parsed.partnerId) {
          dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
        } else {
          dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusPublisherSuccess(null));
      }
    }
  }, [dispatch, publishersResponse]);

  useEffect(() => {
    if (archivePublishersResponse !== null) {
      if (archivePublishersResponse.success) {
        toastr.success('Publisher Archived Successfully')
        if (parsed.partnerId) {
          dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
        } else {
          dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archivePublisherSuccess(null));
      }
    }
  }, [dispatch, archivePublishersResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    }
    dispatch(addPublisherFail(""));
    dispatch(updatePublisherFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, publisher) => (event) => {
    console.log(event)
    console.log(publisher)
    if (event === 'Create') {
      toastr.success('Publisher Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Publisher Updated Successfully!...')
    }
    selectedData = publisher
    setIsArchived(publisher.isPublisherArchived)
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    }
    dispatch(addPublisherFail(""));
    dispatch(updatePublisherFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    if (parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: parsed.partnerId, userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    }
  }, []);

  useEffect(() => {
    if (publishers !== null && !parsed.partnerId) {
      dispatch(onGetPublishers({ partnerID: '', userRole:userInfo.userRole,userID:userInfo.userID,filter: statusSearch, type: '' }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const publisherListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      dataField: "publisherID",
      sort: true,
    },
    {
      dataField: "publisherCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "Publisher Name",
      dataField: "publisherName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, publisher) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/AdUnitGroup?publisherId=${publisher.publisherID}&publisherName=${publisher.publisherName}&status=AdUnitGroupCreateByParent`} className="color-dark-blue ">
              {publisher.publisherName}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: "publisherSite",
      text: "Publisher Site",
      sort: true,
    },
    {
      dataField: "publisherStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, publisher) => (
        <>
          <span className={publisher.newStatus === 'Active' ? "active-state" : publisher.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{publisher.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers) && (!UserRolePrivileges.Supply.Publishers.create),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, publisher) => (
        <div className="d-flex gap-3" >
          <span className="Email-state" onClick={toggleDrawer(true, publisher)}>
            {publisher.newStatus === 'Archived' &&
             'View'
            }
            {publisher.newStatus !== 'Archived' &&
              'Edit'
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archivePublisher = async () => {
    dispatch(onArchivePublisher({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem }));
  }

  const statusPublisher = (state) => (event) => {
    console.log(state)
    dispatch(onStatusPublisher({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem, publisherStatus: state }));
  }

  console.log(publishers)

  if (publishers.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers) {
      if (!UserRolePrivileges.Supply.Publishers.archive && !UserRolePrivileges.Supply.Publishers.status) {
        publishers.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        publishers.map((item, index) => {
          if (item.isPublisherArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
        <div className="page-content">
          <MetaTags>
            <title>Publisher | Bidsxchange</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}

            <div className=" d-sm-flex  justify-content-between">
              {parsed.partnerName ? (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="Publisher" partnerName={parsed.partnerName} />
              ) : (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="Publisher" breadList="All Publishers" />
              )}
              {UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.create &&
                <div className="text-sm-end">
                         <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleRightCanvas}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    New Publisher
                  </Button>
                </div>
              }
            </div>
            {switchLoader && 
              <LoadingTable loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                            <ToolkitProvider
                              keyField={keyField}
                              data={publishers}
                              columns={publisherListColumns}
                              // bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      {selectedRowsStatus && selectedIndex.length > 0 &&
                                        <div className="row m-0 React-Table-Selection">
                                          {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                          <div style={{ padding: '0.5% 2%' }}>

                                            {/* <button color="primary" className="btn btn-primary " onClick={statusPublisher(true)}>Activate</button> */}
                                            {UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.status &&
                                              <button color="primary" className="btn btn-primary " onClick={statusPublisher(true)}>
                                                Activate
                                              </button>
                                            }
                                            &nbsp;  &nbsp;
                                            {UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.status &&
                                              <button color="primary" className="btn btn-primary " onClick={statusPublisher(false)}>
                                                DeActivate
                                              </button>
                                            }
                                            &nbsp;  &nbsp;
                                            {UserRolePrivileges.Supply && UserRolePrivileges.Supply.Publishers && UserRolePrivileges.Supply.Publishers.archive &&
                                              <Button color="primary" className="btn btn-primary " onClick={archivePublisher} >
                                                Archive
                                              </Button>
                                            }
                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                              className="mdi mdi-close-thick font-size-18"
                                            ></i></Button>
                                          </div>
                                        </div>
                                      }
                                    </Col>
                                    <Col xl="12" className="table-responsive">
                                      <div >
                                        <BootstrapTable
                                          keyField={keyField}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          pagination={paginationFactory(pageOptions)}
                                          noDataIndication={<h5 className="text-center">No Data</h5>}
                                        // ref={node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Publisher' : 'New Publisher'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Publisher closeCanvas={toggleDrawer(false, '')} selectedPublisher={selectedData} selectedArchived={isArchived} selectedPartner={(parsed.status === 'PublisherCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default Publisher