/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Row, Col, Label, Form,
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";

//redux
import { useSelector, useDispatch } from "react-redux";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const GeneralSettings = props => {
  const [generalData, setGeneralData] = useState({
    devicesIn: [],
    devicesEx: [],
    urlIn: [],
    urlEx: [],
  })

  const [deviceData, setDeviceData] = useState([])
  const [pageUrlData, setPageUrlData] = useState([])

  const [selectedDevice, setSelectedDevice] = useState([]);

  const [deviceState, setDeviceState] = useState(true)
  const [pageUrlState, setPageUrlState] = useState(true)

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
  })

  useEffect(() => {
    if(props.tabId !== 2) {
      setIsOptionsOpen(false);
    }
  },[props.tabId])

  const toggleOptions = () => {
    console.log(treeSelect)
    setIsOptionsOpen(!isOptionsOpen);
  };


  useEffect(() => {
    if (props.selectedHighImpact !== '') {
      console.log(props.selectedHighImpact)
      setGeneralData(props.selectedHighImpact)
      setSelectedDevice(props.selectedHighImpact.devicesIn.length > 0 ? objectModal(props.selectedHighImpact.devicesIn) : props.selectedHighImpact.devicesEx.length > 0 ? objectModal(props.selectedHighImpact.devicesEx) : [])
      
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.selectedHighImpact.urlIn.length > 0 ? props.selectedHighImpact.urlIn : props.selectedHighImpact.urlEx.length > 0 ? props.selectedHighImpact.urlEx : [] }))
      setDeviceState(props.selectedHighImpact.devicesIn.length > 0 ? true : props.selectedHighImpact.devicesEx.length  > 0 ? false : true)
      setPageUrlState(props.selectedHighImpact.urlIn.length > 0 ? true : props.selectedHighImpact.urlEx.length > 0 ? false : true)

    }
  }, []);


  useEffect(() => {
    if (props.contextualData.devices && props.contextualData.devices.length > 0) {
      setDeviceData([{value:"tablet",label:'Tablet'},{value:"phone",label:'Phone'},{value:"tv",label:'TV'},
      {value:"desktop",label:'Desktop'},{value:"stb",label:'Set Top Box'},{value:"gc",label:'Game Console'}])
    }
    if (props.contextualData.pageUrlGroups && props.contextualData.pageUrlGroups.length > 0) {
      setPageUrlData(objectPageURLModal(props.contextualData.pageUrlGroups))
    }
  }, [props.contextualData]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const objectPageURLModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.pageURLGroupName
      obj.label = item.pageURLGroupName
      obj.children = childModal(item.pageURLGroupValues)
      dataList.push(obj)
    })
    return dataList
  }

  const childModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const handleSelectDevice = device => {
    var devicesList = []
    setSelectedDevice(device);
    device.map((item, index) => {
      devicesList.push(item.value)
    })
    deviceState ? setGeneralData(generalData => ({ ...generalData, devicesIn: devicesList })) : setGeneralData(generalData => ({ ...generalData, devicesEx: devicesList }))
    deviceState ? props.selectedItems({ devicesIn: devicesList }) : props.selectedItems({ devicesEx: devicesList })
  }

  const deviceStateChange = (e) => {
    setDeviceState(e.target.checked)
    var devicess = []
    if (generalData.devicesIn.length > 0) {
      devicess = generalData.devicesIn
    } else {
      devicess = generalData.devicesEx
    }
    if (e.target.checked) {
      setGeneralData(generalData => ({ ...generalData, devicesIn: devicess, devicesEx: [] }));
      props.selectedItems({ devicesIn: devicess, devicesEx: [] })
    } else {
      setGeneralData(generalData => ({ ...generalData, devicesEx: devicess, devicesIn: [] }));
      props.selectedItems({ devicesEx: devicess, devicesIn: [] })
    }
  }

  const pageUrlStateChange = e => {
    setPageUrlState(e.target.checked)
    var pageUrls = []
    if (generalData.urlIn.length > 0) {
      pageUrls = generalData.urlIn
    } else {
      pageUrls = generalData.urlEx
    }
    if (e.target.checked) {
      setGeneralData(generalData => ({ ...generalData, urlIn: pageUrls, urlEx: [] }));
      props.selectedItems({ urlIn: pageUrls, urlEx: [] })
    } else {
      setGeneralData(generalData => ({ ...generalData, urlEx: pageUrls, urlIn: [] }));
      props.selectedItems({ urlEx: pageUrls, urlIn: [] })
    }
  }

  return (
      <Row>
        <Col lg="8">
          <div className="mb-3">
            <Label>Device Targeting</Label>
            <div className='row'>
              <MultiSelect
                options={deviceData}
                value={selectedDevice}
                onChange={handleSelectDevice}
                overrideStrings={{ "selectSomeItems": "Please Select Devices" }}
                className="col-8"
                disabled={props.selectedArchived}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={deviceState}
                  onChange={deviceStateChange}
                  disabled={props.selectedArchived}
                />
                <label className="form-check-label">
                  {deviceState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Label>URL Targeting</Label>
            <div className='row checkbox-tree rmsc'>
              <div className='col-8'>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                  onClick={toggleOptions}
                >
                  {treeSelect.checked.length > 0 ? <span style={{color:'black'}}>{treeSelect.checked.length + " URLs are Selected"}</span>  : "Please Select URLs" }
                </button>
                <ul className={`options ${isOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={pageUrlData}
                    checked={treeSelect.checked}
                    expanded={treeSelect.expanded}
                    onCheck={(checked,targetNode) => {setTreeSelect(treeSelect => ({ ...treeSelect, checked: checked }));
                    console.log(targetNode)
                    pageUrlState ? setGeneralData(generalData => ({ ...generalData, urlIn: checked })) : setGeneralData(generalData => ({ ...generalData, urlEx: checked }))
                    pageUrlState ? props.selectedItems({ urlIn: checked }) : props.selectedItems({ urlEx: checked })

                    // if(targetNode.isParent){
                    //   pageUrlState ? setGeneralData(generalData => ({ ...generalData, urlIn:[...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] })) : setGeneralData(generalData => ({ ...generalData, urlEx: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] }))
                    //   pageUrlState ? props.selectedItems({ urlIn: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ urlEx: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] })
                    // }else{
                    //   pageUrlState ? setGeneralData(generalData => ({ ...generalData, urlIn:[...generalData.urlIn,{group_name:targetNode.parent.value,group_values:checked}] })) : setGeneralData(generalData => ({ ...generalData, urlEx: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] }))
                    //   pageUrlState ? props.selectedItems({ urlIn: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ urlEx: [...generalData.urlIn,{group_name:targetNode.value,group_values:checked}] })
                    // }
                  }}
                    onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={pageUrlState}
                  onChange={pageUrlStateChange}
                  disabled={props.selectedArchived}
                />
                <label className="form-check-label">
                  {pageUrlState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
  )
}

GeneralSettings.propTypes = {}

export default GeneralSettings