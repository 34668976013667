import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, Redirect } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, loginUsers, apiError, socialLogin } from "../../store/actions";

// import images
import logodark from "../../assets/images/favicons.png";

//Import config
import { facebook, google } from "../../config";
import CarouselPage from "pages/Authentication/CarouselPage";
import Footer from "components/VerticalLayout/Footer";



const Login = props => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const windowStop = () => {
  }

  useEffect(() => {

    localStorage.removeItem('authUser');
    localStorage.removeItem('UserRole');
    localStorage.removeItem('ParentRole');
    localStorage.removeItem('Terms');
    localStorage.removeItem('NotificationStatus');
    localStorage.removeItem('UserRolePrivileges');
    localStorage.removeItem('AdminRolePrivileges');
    localStorage.removeItem('previousNavigation');
    localStorage.removeItem('UserSwitching');
    localStorage.removeItem('AdminDetails');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('InventoryType');
    localStorage.removeItem('userSessionState');
    localStorage.removeItem('darkMode');
    localStorage.setItem("loginStatus", 'LoggedOut');
    localStorage.setItem("alertStatus", 'closed');
    dispatch(apiError(null));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUsers(values, props.history, 'login'));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // const signIn = (res, type) => {
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       username: res.profileObj.username,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.history, type));
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       username: res.username,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.history, type));
  //   }
  // };

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google");
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook");
  // };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Bidsxchange</title>
      </MetaTags>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          {/* <Redirect to="/page-maintenance" /> */}
          <Col md={7} style={{ backgroundColor: 'white' }}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-50" style={{ margin: "auto" }}>
                <div className="d-flex flex-column h-100">
              
                  {/* <div class="alert alert-danger" role="alert">
                    We&rsquo;ll be back soon! 
                    We are very sorry for the inconvenience. Please check back soon..
                  </div> */}
                  <div className="mb-1 mb-md-2">
                    <h4>
                      <img
                        src={logodark}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />
                      {/* &nbsp;&nbsp; BIDSXCHANGE */}
                    </h4>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">Sign In</h5>
                      <p className="text-muted ">
                        Sign in and start managing your AdServer!
                      </p>
                    </div>

                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-1">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {!(validation.touched.password && validation.errors.password) &&
                            <span className="passwordIcon" >
                              <   i onClick={togglePasswordVisiblity} id="passwordToggle" className={passwordShown ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span>
                          }

                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}
                        <div className="mt-1 text-left">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot password?
                          </Link>
                        </div>

                        <div className="mt-3 d-flex flex-row justify-content-end">
                          <button
                            className="btn btn-primary btn-block login-btn"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          {/*<h5 className="font-size-14 mb-3">Sign in with</h5>*/}

                          <ul className="list-inline">
                            {/* <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li> */}
                            {/*<li className="list-inline-item">*/}
                            {/*  <TwitterLogin*/}
                            {/*    loginUrl={*/}
                            {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                            {/*    }*/}
                            {/*    onSuccess={this.twitterResponse}*/}
                            {/*    onFailure={this.onFailure}*/}
                            {/*    requestTokenUrl={*/}
                            {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                            {/*    }*/}
                            {/*    showIcon={false}*/}
                            {/*    tag={"div"}*/}
                            {/*  >*/}
                            {/*    <a*/}
                            {/*      href=""*/}
                            {/*      className="social-list-item bg-info text-white border-info"*/}
                            {/*    >*/}
                            {/*      <i className="mdi mdi-twitter"/>*/}
                            {/*    </a>*/}
                            {/*  </TwitterLogin>*/}
                            {/*</li>*/}
                            {/* <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li> */}
                          </ul>
                        </div>


                      </Form>
                      {/* <div className="mt-2 text-center">
                        <p>
                          Don&apos;t have an account ?
                          <Link
                            to="register"
                            className="fw-medium text-primary"
                          >&nbsp;
                            Signup now
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  </div>

                  {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Bidsxchange
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
            <footer className="footer" style={{ textAlign: 'end' }}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>
                    <div className="text-sm-start d-flex">
                      <Col className="d-flex">
                        <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Cookie Policy</a>
                        &nbsp;|&nbsp;
                        <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Privacy Policy</a>
                      </Col>
                    </div>
                  </Col>
                  <Col md={6}> 2015 - {new Date().getFullYear()} © Bidsxchange.</Col>
                </Row>
              </Container>
            </footer>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
