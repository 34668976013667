import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga";

import advertisersSaga from "./Demand/Advertiser/saga";
import partnersSaga from "./PartnerManagement/saga"
import rolesSaga from "./RoleManagement/saga"
import iOGroupSaga from "./Demand/IOGroup/saga"
import iOsSaga from "./Demand/IOs/saga"
import lineItemSaga from "./Demand/LineItem/saga"
import tcsSaga from "./Demand/TagController/saga";
import creativeSaga from "./Demand/Creative/saga"

import publisherSaga from "./Supply/Publisher/saga"
import adUnitGroupSaga from "./Supply/AdUnitGroup/saga"
import adUnitSaga from "./Supply/AdUnit/saga"
import childAdUnitSaga from "./Supply/ChildAdUnit/saga"
import highImpactSettingSaga from "./Supply/HighImpactSettings/saga"
import StbrSettingSaga from "./Supply/StbrSettings/saga"
import playerSettingSaga from "./Supply/PlayerSetting/saga"
import commonEventsSaga from "./CommonEvents/saga"
import brandAffinitySaga from "./Contextual_Settings/Brand_Affinity/saga"
import interestSaga from "./Contextual_Settings/Interests/saga"
import keywordSaga from "./Contextual_Settings/Keywords/saga"
import iPMappingSaga from "./Contextual_Settings/IPMap/saga"
import pageUrlSaga from "./Contextual_Settings/PageUrls/saga"
import websiteCategorizationSaga from "./Contextual_Settings/WebsiteCategorization/saga"
import netBlockingsSaga from "./Contextual_Settings/NetBlocking/saga"
import contextualDataSaga from "./Contextual_Settings/ContextualData/saga"

import videoContentsSaga from "./Media/VideoContent/saga";
import MarketContentsSaga from "./Media/MarketPlace/saga"
import playlistSaga from "./Media/Playlist/saga";
import pubNotificationSaga from "./Notifications/Publisher_Notification/saga"

import categoriesSaga from "./customTracking/Categories/saga"
import adPublishersSaga from "./customTracking/HouseAdPub/saga"
import creativesSaga from "./customTracking/Creatives/saga"
import adUnitsSaga from "./customTracking/AdUnits/saga"

import proxyAdUnitsSaga from "./Proxy/ProxyAdUnits/saga"

import scheduleReportsSaga from "./PublisherConsole/ScheduleReport/saga"
import agencyScheduleReportsSaga from "./AgencyConsole/ScheduleReport/saga"
import supportTicketsSaga from "./Support_Ticket/Publisher_Ticket/Publisher_Support/saga"
import supportTicketAdminsSaga from "./Support_Ticket/Admin_Ticket/Admin_Support/saga"

import adminReportsSaga from "./Reports/AdminReports/saga"

import billsSaga from "./Billing/saga"

import userLogsSaga from "./Logs/saga"
import NetworkSettingSaga from "./Supply/NetworkSettings/saga"

import pubVideoContentsSaga from "./Media_Publisher/VideoContent/saga"
import pubPlaylistSaga from "./Media_Publisher/Playlist/saga"

import headerBidSaga from "./HeaderBidding/HeaderBid/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    
    fork(advertisersSaga),
    fork(partnersSaga),
    fork(rolesSaga),
    fork(iOGroupSaga),
    fork(iOsSaga),
    fork(lineItemSaga),
    fork(tcsSaga),
    fork(creativeSaga),
    fork(publisherSaga),
    fork(adUnitGroupSaga),
    fork(adUnitSaga),
    fork(childAdUnitSaga),
    fork(highImpactSettingSaga),
    fork(StbrSettingSaga),
    fork(playerSettingSaga),
    fork(commonEventsSaga),
    fork(brandAffinitySaga),
    fork(interestSaga),
    fork(keywordSaga),
    fork(iPMappingSaga),
    fork(pageUrlSaga),
    fork(websiteCategorizationSaga),
    fork(videoContentsSaga),
    fork(playlistSaga),
    fork(MarketContentsSaga),
    fork(pubNotificationSaga),
    fork(categoriesSaga),
    fork(adPublishersSaga),
    fork(creativesSaga),
    fork(adUnitsSaga),
    fork(proxyAdUnitsSaga),
    fork(scheduleReportsSaga),
    fork(agencyScheduleReportsSaga),
    fork(supportTicketsSaga),
    fork(supportTicketAdminsSaga),
    fork(adminReportsSaga),
    fork(billsSaga),
    fork(userLogsSaga),
    fork(NetworkSettingSaga),
    fork(netBlockingsSaga),
    fork(contextualDataSaga),
    fork(pubVideoContentsSaga),
    fork(pubPlaylistSaga),
    fork(headerBidSaga)
  ])
}
