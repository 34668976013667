/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import axios from "axios";
var path = require('path');
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from 'react-select';


// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewVideoContent as onADDVideoContent,
  addVideoContentSuccess,
  addVideoContentFail,
} from "store/Media/VideoContent/actions";
import {
  getCommonLanguages as onGetLanguages,
  getCommonCategories as onGetCategories,
} from "store/CommonEvents/actions";


var videoContentFileName = ''
var videoThumbnailFileName = ''

const filePath = path.join(__dirname, 'Tools');

const Create_VideoContent = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [videoContent, setVideoContent] = useState({
    videoContentName: '',
    videoContentClickThroughURL: '',
    videoContentCategory: '',
    videoContentLanguage: '',
    videoContentPath: '',
    videoContentThumbnailPath: '',
    videoContentDuration: '',
  });
  const [languageData, setLanguageData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [language, setLanguage] = useState('');
  const [category, setCategory] = useState('');
  console.log(props)

  const { videoContentLanguage, videoContentCategory } = useSelector(state => ({
    videoContentLanguage: state.commonEvents.languageList,
    videoContentCategory: state.commonEvents.categoryList,
  }));

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const objectModal = (response = []) => {
    return response.map((item, index) => {
      return {
        value: item,
        label: item
      }
    })
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.videoContents.errorMsg,
    successMsg: state.videoContents.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.Success) {
        dispatch(addVideoContentSuccess(""));
        dispatch(addVideoContentFail(""));
        setLoader(false)
        props.closeCanvas('Create')
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
    }
  }, [errorMsg]);

  useEffect(() => {
    if (videoContentLanguage && videoContentLanguage.length > 0) {
      setLanguageData(objectModal(videoContentLanguage))
      console.log(videoContentLanguage)
    }
  }, [videoContentLanguage]);

  useEffect(() => {
    if (videoContentCategory && videoContentCategory.length > 0) {
      setCategoryData(objectModal(videoContentCategory))
      console.log(videoContentCategory)
    }
  }, [videoContentCategory]);

  useEffect(() => {
    if (videoContentLanguage && !videoContentLanguage.length, videoContentCategory && !videoContentCategory.length) {
      dispatch(onGetLanguages());
      dispatch(onGetCategories());
    }
  }, [dispatch, videoContentLanguage, videoContentCategory]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setVideoContent(videoContent => ({ ...videoContent, [name]: value }));
  }

  const fileChange = (file) => {
    setLoader(true)
    const fileName = file.target.files[0].name
    const fileType = file.target.files[0].type
    console.log("file=====================", file)
    videoContentFileName = file.target.files[0].name
    console.log(videoContentFileName)
    const formData = new FormData();
    let newFile = file.target.files[0];
    formData.append("data", newFile);

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var media = new Audio(reader.result);
        media.onloadedmetadata = function () {
          media.duration; // this would give duration of the video/audio file
          setVideoContent(videoContent => ({ ...videoContent, videoContentDuration: media.duration }));
        };
        resolve(reader.result)
      };
      reader.onerror = error => reject(error);
      console.log(reader.result)
    });

    var newBaseData = toBase64(newFile);
    const newBaseContent = {};
    (async () => {
      newBaseContent.data = await newBaseData;
      newBaseContent.fileName = fileName;
      newBaseContent.type = fileType;
      newBaseContent.userID = userInfo.userID;
      newBaseContent.userRole = userInfo.userRole;
      newBaseContent.companyID = userInfo.companyID;
      console.log(newBaseContent)
      const body = {
        "url": "http://10.0.3.41:3022/upload/uploadFile",
        "requestBody": newBaseContent
      }
      axios.post("/v1.0/connectOP", body, {
        headers: headers
      })
        .then((res) => {
          console.log("Video Upload ==>", res)
          setLoader(false)
          setVideoContent(videoContent => ({ ...videoContent, videoContentPath: "/home/ubuntu/videoContentData/" + videoContentFileName }));
        })
        .catch((err) => {
          setLoader(false)
          console.log("e", err)
        });
    })();
  };

  const fileChangeThumbnail = (file) => {
    setLoader(true)
    console.log(file.target.files[0])
    const fileName = file.target.files[0].name
    const fileType = file.target.files[0].type
    console.log("fileName=====================", fileName, file)
    videoThumbnailFileName = file.target.files[0].name

    const formData = new FormData();
    let newFile = file.target.files[0];
    formData.append("data", newFile);

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);

    });

    var newBaseData = toBase64(newFile);
    const newBaseContent = {};
    (async () => {
      newBaseContent.data = await newBaseData;
      newBaseContent.fileName = fileName;
      newBaseContent.type = fileType;
      newBaseContent.userID = userInfo.userID;
      newBaseContent.userRole = userInfo.userRole;
      newBaseContent.companyID = userInfo.companyID;
      console.log(newBaseContent)
      const body = {
        "url": "http://10.0.3.41:3022/upload/uploadFile",
        "requestBody": newBaseContent
      }
      axios.post("/v1.0/connectOP", body, {
        headers: headers
      })
        .then((res) => {
          console.log("Thumbnail Upload ==>", res)
          setLoader(false)
          setVideoContent(videoContent => ({ ...videoContent, videoContentThumbnailPath: "/home/ubuntu/videoThumbnailData/" + fileName }));
        })
        .catch((err) => {
          setLoader(false)
          console.log("e", err)
        });
    })();
  };

  const handleSubmit = async (e) => {
    console.log(videoContent)
    e.preventDefault();
    setSubmitted(true);
    if (videoContent.videoContentName && videoContent.videoContentCategory && videoContent.videoContentLanguage && videoContent.videoContentPath && videoContent.videoContentThumbnailPath) {
      setLoader(true)
      dispatch(onADDVideoContent({ ...videoContent }));
    }
  }

  const handleInputChange = (inputValue, actionMeta) => {
    console.log(inputValue);
    setLanguage(inputValue)
  };

  const handleInputChangeCategory = (inputValue, actionMeta) => {
    console.log(inputValue);
    setCategory(inputValue)
  };

  const handleLanguageChange = (e, actionMeta) => {
    let event = JSON.stringify(e)
    if (actionMeta.action === 'create-option' && language !== '') {
      createData(language, 'Language')
    }
    setSelectedLanguage(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentLanguage: e.value }));
    console.log(videoContent)
  }

  const handleCategoryChange = (e, actionMeta) => {
    console.log(e)
    if (actionMeta.action === 'create-option' && category !== '') {
      createData(category, 'Category')
    }
    setSelectedCategory(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentCategory: e.value }));
  }


  const createData = (data, type) => {
    console.log(data, type)
    console.log('create Data')
    let newData = []
    newData.push(data)
    const body = {
      "url": "http://127.0.0.1:2002/v1.0/Miscs/create",
      "requestBody": {
        key: type,
        value: newData,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      }
    }

    let tempBody = {
      "vidpath": "/home/ubuntu/videoContentData/10secondsTimer_NeonDesign_10SekundenCountdown_Alarm_10sec.mp4",
      "thumbpath": "/home/ubuntu/videoThumbnailData/10secondsTimer_NeonDesign_10SekundenCountdown_Alarm_10sec.png",
      "category": "Arts",
      "language": "English"

    }
    axios.post("/cms/convert", tempBody, {
      headers: headers
    })
      .then((res) => {
        console.log("Data Created", res)
        if (type === 'Language') {
          setLanguageData([])
          dispatch(onGetLanguages());
          setLanguageData(objectModal(videoContentLanguage))
        }
        else {
          setCategoryData([])
          dispatch(onGetCategories());
          setCategoryData(objectModal(videoContentCategory))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <Row style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">VideoContent Name</Label>
                <Input
                  name="videoContentName"
                  type="text"
                  onChange={handleChange}
                  value={videoContent.videoContentName}
                  className={'form-control' + (submitted && !videoContent.videoContentName ? ' is-invalid' : '')}
                />
                {submitted && !videoContent.videoContentName ? (
                  <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label>Language</Label>
                <CreatableSelect
                  onChange={(event, actionMeta) => {
                    handleLanguageChange(event, actionMeta)
                  }}
                  onInputChange={handleInputChange}
                  options={languageData}
                  value={selectedLanguage}
                />
                {submitted && !videoContent.videoContentLanguage &&
                  <div className="invalid-feedback">Language is required</div>}
              </div>
              <div className="mb-3">
                <Label>Category</Label>
                <div className='row'>
                  <CreatableSelect
                    onChange={(event, actionMeta) => {
                      handleCategoryChange(event, actionMeta)
                    }}
                    onInputChange={handleInputChangeCategory}
                    options={categoryData}
                    value={selectedCategory}
                  />
                  {submitted && !videoContent.videoContentCategory ? (
                    <FormFeedback type="invalid">{'Category is required'}</FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">Click Through URL</Label>
                <Input
                  name="videoContentClickThroughURL"
                  type="text"
                  onChange={handleChange}
                  value={videoContent.videoContentClickThroughURL}
                  className={'form-control' + (submitted && !videoContent.videoContentClickThroughURL ? ' is-invalid' : '')}
                />
                {submitted && !videoContent.videoContentClickThroughURL ? (
                  <FormFeedback type="invalid">{'Click Through URL is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Browse Video File</Label>
                <div>
                  {/* <Input id="uploadFile" value={videoContentFileName} className="f-input" readOnly />
                  <div className="fileUpload  btn--browse">
                    <span>Browse</span>
                    <Input id="uploadBtn" type="file" accept="video/mp4" className="upload" onChange={fileChange} />
                  </div> */}
                  <Input type="file" accept="video/mp4" onChange={fileChange} />
                  <span className="notes">Note: Allowed file types: MP4</span>
                </div>
                {submitted && !videoContent.videoContentPath ? (
                  <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Browse Thumbnail Image File</Label>
                <div>
                  {/* <Input id="uploadFile" value={videoThumbnailFileName} className="f-input" readOnly />
                  <div className="fileUpload  btn--browse">
                    <span>Browse</span>
                    <Input id="uploadBtn" type="file" accept="image/x-png,image/jpg" className="upload" onChange={fileChangeThumbnail} />
                  </div> */}
                  <Input type="file" accept="image/x-png,image/jpg" onChange={fileChangeThumbnail} />
                  <span className="notes">Note: Allowed file types: jpg, png</span>
                </div>
                {submitted && !videoContent.videoContentThumbnailPath ? (
                  <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
                ) : null}
              </div>

            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Save
                </button>

              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Create_VideoContent);

Create_VideoContent.propTypes = {
  history: PropTypes.object,
};