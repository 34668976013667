/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";
import { WithContext as ReactTags } from 'react-tag-input';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewWebsiteCategorization as onADDWebsiteCategorization,
  updateWebsiteCategorization as onUpdateWebsiteCategorization,
  readWebsiteCategorization as onReadWebsiteCategorization,
  addWebsiteCategorizationSuccess,
  addWebsiteCategorizationFail,
  updateWebsiteCategorizationSuccess,
  updateWebsiteCategorizationFail,
} from "store/Contextual_Settings/WebsiteCategorization/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_Web_Categorization = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [websiteCategorization, setWebsiteCategorization] = useState({
    website: '',
    language: '',
    category: ''
  });
  console.log(props)

  useEffect(() => {
    if (props.selectedWebsiteCategorization !== '') {
      console.log(props.selectedWebsiteCategorization)
      setIsEdit(true)
      setWebsiteCategorization(props.selectedWebsiteCategorization)
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWebsiteCategorization(websiteCategorization => ({ ...websiteCategorization, [name]: value }));
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.websiteCategorizations.errorMsg,
    successMsg: state.websiteCategorizations.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addWebsiteCategorizationSuccess(""));
        dispatch(addWebsiteCategorizationFail(""));
        dispatch(updateWebsiteCategorizationSuccess(""));
        dispatch(updateWebsiteCategorizationFail(""));
        if (props.selectedWebsiteCategorization !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addWebsiteCategorizationSuccess(""));
        dispatch(addWebsiteCategorizationFail(""));
        dispatch(updateWebsiteCategorizationSuccess(""));
        dispatch(updateWebsiteCategorizationFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addWebsiteCategorizationFail(""));
    dispatch(updateWebsiteCategorizationFail(""));
    props.closeCanvas('')
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (websiteCategorization.website && websiteCategorization.language &&  websiteCategorization.category) {
      if (props.selectedWebsiteCategorization !== '') {
        dispatch(onUpdateWebsiteCategorization({ ...websiteCategorization }));
      } else {
        dispatch(onADDWebsiteCategorization(websiteCategorization));
      }
    }
  }



  return (
    <React.Fragment>
      <div className="col-6">
        <Form onSubmit={handleSubmit}>
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Website Name</Label>
                <Input
                  type="text" name="website" value={websiteCategorization.website} onChange={handleChange} className={'form-control' + (submitted && !websiteCategorization.website ? ' is-invalid' : '')}
                />
                {submitted && !websiteCategorization.website &&
                  <div className="invalid-feedback">Website Name is required</div>
                }
              </div>
              <div className="mb-3">
                <Label className="form-label">Langauage</Label>
                <Input
                  type="text" name="language" value={websiteCategorization.language} onChange={handleChange} className={'form-control' + (submitted && !websiteCategorization.language ? ' is-invalid' : '')}
                />
                {submitted && !websiteCategorization.language &&
                  <div className="invalid-feedback">Langauage is required</div>
                }
              </div>
              <div className="mb-3">
                <Label className="form-label">Category</Label>
                <Input
                  type="text" name="category" value={websiteCategorization.category} onChange={handleChange} className={'form-control' + (submitted && !websiteCategorization.category ? ' is-invalid' : '')}
                />
                {submitted && !websiteCategorization.category &&
                  <div className="invalid-feedback">Category is required</div>
                }
              </div>
              <h6>or</h6>
              <div className="mt-3">
                <Label htmlFor="formFile" className="form-label">Upload Web Category List</Label>
                <Input className="form-control" type="file" id="formFile" />
                <br></br>
                <span href="#">Download sample file <i className="bx bx-cloud-download"></i></span>
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save Web Category
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_Web_Categorization);

Create_Web_Categorization.propTypes = {
  history: PropTypes.object,
};