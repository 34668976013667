import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_ADUNITGROUPS_SUCCESS,
    GET_ADUNITGROUPS_FAIL,
    ADD_ADUNITGROUP_SUCCESS,
    ADD_ADUNITGROUP_ERROR,
    UPDATE_ADUNITGROUP_SUCCESS,
    UPDATE_ADUNITGROUP_ERROR,
    READ_ADUNITGROUP_SUCCESS,
    STATUS_ADUNITGROUP_SUCCESS,
    ARCHIVE_ADUNITGROUP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    adUnitGroupsList: [],
    error: {},
    loading: false,
    noData: false,
    successMsg: null,
    errorMsg: null,
    adUnitGroup: null,
    response: null,
    archiveMsg: null,
}

const adUnitGroups = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
            return {
                ...state,
                noData: true,
            }
        case GET_ADUNITGROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                adUnitGroupsList: action.payload,
            }

        case GET_ADUNITGROUPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_ADUNITGROUP_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_ADUNITGROUP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_ADUNITGROUPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_ADUNITGROUP_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_ADUNITGROUPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_ADUNITGROUP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_ADUNITGROUPS_SUCCESS' ? null : action.payload,
            }
        case READ_ADUNITGROUP_SUCCESS:
            return {
                ...state,
                adUnitGroup: action.payload,
            }
        case STATUS_ADUNITGROUP_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_ADUNITGROUP_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default adUnitGroups