import {
    LOAD_NET_BLOCKINGS,
    NO_DATA,
    GET_NET_BLOCKINGS,
    GET_NET_BLOCKINGS_FAIL,
    GET_NET_BLOCKINGS_SUCCESS,
    ADD_NET_BLOCKING,
    ADD_NET_BLOCKING_SUCCESS,
    ADD_NET_BLOCKING_ERROR,
    UPDATE_NET_BLOCKING,
    UPDATE_NET_BLOCKING_SUCCESS,
    UPDATE_NET_BLOCKING_ERROR,
    READ_NET_BLOCKING,
    READ_NET_BLOCKING_SUCCESS,
    STATUS_NET_BLOCKING,
    STATUS_NET_BLOCKING_SUCCESS,
    ARCHIVE_NET_BLOCKING ,
    ARCHIVE_NET_BLOCKING_SUCCESS,

    CALL_API,
    CALL_API_SUCCESS

  } from "./actionTypes"

  export const loadNetBlockings = Params => ({
    type: LOAD_NET_BLOCKINGS,
    payload:Params
  })

  export const noDataNetBlockings = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getNetBlockings = Params => ({
    type: GET_NET_BLOCKINGS,
    payload:Params
  })
  
  export const getNetBlockingsSuccess = NetBlockings => ({
    type: GET_NET_BLOCKINGS_SUCCESS,
    payload: NetBlockings,
  })

  export const getNetBlockingsFail = error => ({
    type: GET_NET_BLOCKINGS_FAIL,
    payload: error,
  })

  export const addNewNetBlocking = NetBlocking => ({
    type: ADD_NET_BLOCKING,
    payload: NetBlocking,
  })
  
  export const addNetBlockingSuccess = netBlocking => ({
    type: ADD_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })
  
  export const addNetBlockingFail = netBlocking => ({
    type: ADD_NET_BLOCKING_ERROR,
    payload: netBlocking,
  })

  export const updateNetBlocking = netBlocking => ({
    type: UPDATE_NET_BLOCKING,
    payload: netBlocking,
  })
  
  export const updateNetBlockingSuccess = netBlocking => ({
    type: UPDATE_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })
  
  export const updateNetBlockingFail = netBlocking => ({
    type: UPDATE_NET_BLOCKING_ERROR,
    payload: netBlocking,
  })

  export const readNetBlocking = netBlocking => ({
    type: READ_NET_BLOCKING,
    payload: netBlocking,
  })

  export const readNetBlockingSuccess = netBlocking => ({
    type: READ_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })

  export const statusNetBlocking = netBlocking => ({
    type: STATUS_NET_BLOCKING,
    payload: netBlocking,
  })

  export const statusNetBlockingSuccess = netBlocking => ({
    type: STATUS_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })

  export const archiveNetBlocking = netBlocking => ({
    type: ARCHIVE_NET_BLOCKING,
    payload: netBlocking,
  })

  export const archiveNetBlockingSuccess = netBlocking => ({
    type: ARCHIVE_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })

  export const callAPIChange = netBlocking => ({
    type: CALL_API,
    payload: netBlocking,
  })

  export const apiCallResponseSuccess = netBlocking => ({
    type: CALL_API_SUCCESS,
    payload: netBlocking,
  })