import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

import morning from "assets/images/bidsxchange/morning.png"
import afternoon from "assets/images/bidsxchange/afternoon.png"
import evening from "assets/images/bidsxchange/evening.png"

import DigitalClock from "pages/Dashboard/clock";
import Greeting from "pages/Dashboard/Greeting";

const WelcomeComp = () => {
  const userRole = localStorage.getItem('UserRole');
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const currentHour = new Date().getHours();
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft" style={{ background: '#ffffff' }}>
          <Row>
            <Col xs="8">
              <div className="text-primary py-3 px-2">
                <Greeting />
                {/*<h5 className="text-primary">Welcome Back !</h5>*/}
                <h6 className="text-center" style={{ marginBottom: '4%', color: '#524848ad',fontSize:'1pc',fontWeight:'600' }}>{userInfo.name}</h6>
                <DigitalClock />
                {/* {userRole === 'Admin' && 
                <p>Administrator</p> 
                }
                {userRole === 'Publisher' && 
                <p>Publisher</p> 
                }
                {userRole === 'AdOps' && 
                <p>AdOps</p> 
                } */}
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
              {/* <img src={(currentHour >= 0 && currentHour < 12) ? morning : (currentHour >= 12 && currentHour < 17) ? afternoon : evening} alt="" className="img-fluid" /> */}
            </Col>
          </Row>
        </div>
        {/* <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15"></h5>
                    <p className="text-muted mb-0"></p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15"></h5>
                    <p className="text-muted mb-0"></p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody> */}
      </Card>
    </React.Fragment >
  )
}
export default WelcomeComp
