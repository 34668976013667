import axios from "axios";
import { del, get, post, put } from "./api_helper";
import { authPost } from "./Auth_API_Helper";
import { demandPost,reportPost,contextualSettingsPost,supportTicketPost,partnerManagementPost,partnerManagementCreatePost,userManagementPost } from "./Demand_API_Helper";
import { APICallRequest } from "./Demand_API_Helper";
import { toolsPost } from "./Tools_API_Helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// Login Method
const postLogin = data => authPost(url.POST_LOGIN, data);

// Register Method
const postRegister = data => authPost(url.POST_REGISTER, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// postForgetPwd Method
const postForgetPwd = data => authPost(url.POST_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// // get Categories
// export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};


//Demand : Advertiser

export const getAPICalls = data => APICallRequest();

// get Advertisers
export const getAdvertisers = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/read' :`http://127.0.0.1:2002/v1.0/Advertisers/read`,data,"Advertisers");
// get Advertisers on Type
export const getAdvertisersType = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/advertiser/listOnType' : "http://127.0.0.1:2002/v1.0/advertiser/listOnType",data,"Advertisers");
// Create Advertiser 
export const postAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/create' : "http://127.0.0.1:2002/v1.0/Advertisers/create", data);
// Update Advertiser 
export const updateAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/update/'+ data.advertiserID : "http://127.0.0.1:2002/v1.0/Advertisers/update/" + data.advertiserID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Advertiser 
export const readAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/read' : "http://127.0.0.1:2002/v1.0/Advertisers/readByID/" + data.advertiserID);
// StatusChange of Advertiser 
export const statusChangeAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/status' : "http://127.0.0.1:2002/v1.0/Advertisers/status",data);
// Archive Advertiser 
export const archiveAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/archive' : "http://127.0.0.1:2002/v1.0/Advertisers/archive" , data);

//Partner Management

// get Partners
export const getPartners = data => partnerManagementPost("/v1.0/partnerListFull",data,"PartnerProfiles");
// get Prtners on Type
export const getPartnersOnType = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/partner/listOnType",data,"PartnerProfiles");
// Create Partners 
export const createPartner = data => partnerManagementCreatePost('/v1.0/createPartner', data);
// Update Partners 
export const updatePartner = data => partnerManagementCreatePost("/v1.0/updatePartner/" + data.partnerID, data);
// Read By Id Partners 
export const readPartner = data => partnerManagementPost("/v1.0/partner/" + data.partnerID);
// StatusChange of Partners 
export const statusChangePartner = data => partnerManagementCreatePost("/v1.0/updateUserStatus" ,data);
// Archive Partners 
export const archivePartner = data => partnerManagementCreatePost("/v1.0/usersArchive" , data);
// Get Users
export const getUserList = data => userManagementPost("/v1.0/usersList",data,"Users");
// Create Users 
export const createUsers = data => partnerManagementCreatePost('/v1.0/createUsers', data);
// Update Users 
export const updateUsers = data => partnerManagementCreatePost("/v1.0/updateUsers/" + data.userID, data);

//Roles Management

// get Roles
export const getRoles = data => userManagementPost("/v1.0/AMRole/list",data,"Roles");
// Create Roles 
export const createRole = data => partnerManagementCreatePost('/v1.0/AMRole/create', data);
// Update Roles 
export const updateRole = data => partnerManagementCreatePost("/v1.0/AMRole/update/" + data.roleID, data);
// Read By Id Roles 
export const readRole = data => userManagementPost("/v1.0/AMRole/read/" + data.roleID);
// StatusChange of Roles 
export const statusChangeRole = data => partnerManagementCreatePost("/v1.0/AMRole/status" ,data);
// Archive Roles 
export const archiveRole = data => partnerManagementCreatePost("/v1.0/AMRole/archive" , data);

//Demand : IOGroup

// get IOGroup
export const getIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/read' : `http://127.0.0.1:2002/v1.0/IOGroups/read`,data,"IOGroups");
// get IOGroup by Advertiser
export const getIOGroupAdvertiser = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/read/advertiser/'+ data.advertiserID :'http://127.0.0.1:2002/v1.0/IOGroups/read/advertiser/'+ data.advertiserID,data,"IOGroups");
// get IOGroup by type
export const getIOGroupType = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/iOGroup/listOnType' :'http://127.0.0.1:2002/v1.0/iOGroup/listOnType',data,"IOGroups");
// Create IOGroup 
export const createIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/create' :'http://127.0.0.1:2002/v1.0/IOGroups/create', data);
// Update IOGroup 
export const updateIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/update/' + data.iOGroupID :"http://127.0.0.1:2002/v1.0/IOGroups/update/" + data.iOGroupID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id IOGroup 
export const readIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Advertisers/read' :"http://127.0.0.1:2002/v1.0/IOGroups/readByID/" + data.iOGroupID);
// StatusChange of IOGroup 
export const statusChangeIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/status' :"http://127.0.0.1:2002/v1.0/IOGroups/status",data);
// Archive IOGroup 
export const archiveIOGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/archive' :"http://127.0.0.1:2002/v1.0/IOGroups/archive" , data);

//Demand : IOs

// get IOs
export const getIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/read' : `http://127.0.0.1:2002/v1.0/IOs/read`, data,"IOs");
// get IOs by IOGroups
export const getIOsByIOG = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/IOs/read/ioGroup/' + data.iOGroupID : "http://127.0.0.1:2002/v1.0/IOs/read/ioGroup/" + data.iOGroupID,data,"IOs");
// get IOs by type
export const getIOsType = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/IOs/listOnType' : 'http://127.0.0.1:2002/v1.0/IOs/listOnType', data,"IOs");
// Create IOs 
export const createIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/create' : 'http://127.0.0.1:2002/v1.0/IOs/create', data);
// Update IOs 
export const updateIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/update/'+ data.iOID : "http://127.0.0.1:2002/v1.0/IOs/update/" + data.iOID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data );
// Read By Id IOs 
export const readIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/read' : "http://127.0.0.1:2002/v1.0/IOs/readByID/" + data.iOID);
// StatusChange of IOs 
export const statusChangeIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/status' : "http://127.0.0.1:2002/v1.0/IOs/status" ,data);
// Archive IOs 
export const archiveIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOs/archive' : "http://127.0.0.1:2002/v1.0/IOs/archive" ,data);

//Demand : LineItem
// get LineItem
export const getLineItem = data => demandPost(data.userRole === 'Partner' ? `http://127.0.0.1:3006/v1.0/Demand/LineItems/read` : `http://127.0.0.1:2002/v1.0/LineItems/read`,data,"LineItems");
// get LineItem by IOS
export const getLineItemByIOs = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/LineItems/read/IO/' +data.iOID: 'http://127.0.0.1:2002/v1.0/LineItems/read/IO/'+data.iOID,data,"LineItems");
// get LineItem by Type
export const getLineItemType = data => demandPost('http://127.0.0.1:2002/v1.0/LineItems/list/lineItemType',data,"LineItems");
// get LineItem by Display 
export const getLineItemSTDB = data => demandPost('http://127.0.0.1:2002/v1.0/LineItems/list/display');
// get LineItem by RichMedia
export const getLineItemRCM = data => demandPost('http://127.0.0.1:2002/v1.0/LineItems/list/richMedia');
// get LineItem by Video
export const getLineItemVideo = data => demandPost('http://127.0.0.1:2002/v1.0/LineItems/list/video');
// Create LineItem 
export const createLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/LineItems/create' : 'http://127.0.0.1:2002/v1.0/LineItems/create', data);
// Update LineItem 
export const updateLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/LineItems/update/'  + data.lineItemID : "http://127.0.0.1:2002/v1.0/LineItems/update/" + data.lineItemID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id LineItem 
export const readLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/IOGroups/read' : "http://127.0.0.1:2002/v1.0/LineItems/readByID/" + data.lineItemID);
// StatusChange of LineItem 
export const statusChangeLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/LineItems/status' : "http://127.0.0.1:2002/v1.0/LineItems/status" ,data);
// Archive LineItem 
export const archiveLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/LineItems/archive' : "http://127.0.0.1:2002/v1.0/LineItems/archive" , data);

//Demand : Creative

// get Creative
export const getCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/read/both' : `http://127.0.0.1:2002/v1.0/Creatives/read/both`,data,"Creatives");
// get Creative by Video line Item
export const getCreativeByVideoLineItem = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/lineitem/'+data.lineItemID : 'http://127.0.0.1:2002/v1.0/VideoCreative/lineitem/'+data.lineItemID,data,"Creatives");
// Create Creative 
export const createCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/create' : 'http://127.0.0.1:2002/v1.0/Creatives/create', data);
// Create Video Creative 
export const createVideoCreative = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/create' : 'http://127.0.0.1:2002/v1.0/VideoCreative/create', data);
// Update Creative 
export const updateCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/update/'+ data.creativeID : "http://127.0.0.1:2002/v1.0/Creatives/update/" + data.creativeID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Update Video Creative 
export const updateVideoCreative = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/update/'+ data.videoCreativeID : "http://127.0.0.1:2002/v1.0/VideoCreative/update/" + data.videoCreativeID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Creative 
export const readCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/readByID/' + data.creativeID: "http://127.0.0.1:2002/v1.0/Creatives/readByID/" + data.creativeID);
// Read By Id Video Creative 
export const readVideoCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/read/'+ data.creativeID : "http://127.0.0.1:2002//v1.0/VideoCreative/read/" + data.creativeID);
// StatusChange of Creative 
export const statusChangeCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/status' : "http://127.0.0.1:2002/v1.0/Creatives/status" ,data);
// StatusChange of VideoCreative 
export const statusChangeVideoCreative = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/status' : "http://127.0.0.1:2002/v1.0/VideoCreative/status" ,data);
// Archive Creative 
export const archiveCreative = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/Creatives/archive' : "http://127.0.0.1:2002/v1.0/Creatives/archive" , data);
// Video Archive Creative 
export const videoArchiveCreative = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/VideoCreative/archive' : "http://127.0.0.1:2002/v1.0/VideoCreative/archive" , data);

//Demand : Tag Controller

// get Tag Controller
export const getTcs = data => demandPost("http://127.0.0.1:2002/v1.0/TCs/read",data);
// Create Tag Controller 
export const postTc = data => demandPost("http://127.0.0.1:2002/v1.0/TCs/create", data);
// Update Tag Controller 
export const updateTc = data => demandPost("http://127.0.0.1:2002/v1.0/TCs/update/" + data.tcID, data);
// Read By Id Tag Controller 
export const readTc = data => demandPost("http://127.0.0.1:2002/v1.0/TCs/readByID/" + data);
// StatusChange of Tag Controller 
export const statusChangeTc = data => demandPost("http://127.0.0.1:2002/v1.0/TCs/status",data);
// Archive Tag Controller 
export const archiveTc = data => demandPost("http://127.0.0.1:4009/v1.0/TC/archive" , data);

//Demand : Hb Buyer

// get Hb Buyer
export const getHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/read' :  `http://127.0.0.1:2002/v1.0/HB_Buyers/read`,data);
// Create Hb Buyer 
export const createHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/create' :  "http://127.0.0.1:2002/v1.0/HB_Buyers/create", data);
// Update Hb Buyer
export const updateHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/update/'+ data.hB_BuyerID :  "http://127.0.0.1:2002/v1.0/HB_Buyers/update/" + data.hB_BuyerID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Update Hb Buyer
export const updateAdUnitBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/AssociateAdUnit/'+ data.hB_BuyerID :  "http://127.0.0.1:2002/v1.0/HB_Buyers/AssociateAdUnit/" + data.hB_BuyerID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Hb Buyer 
export const readHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:2002/v1.0/HB_Buyers/readByID' :  "http://127.0.0.1:2002/v1.0/HB_Buyers/readByID/" + data);
// StatusChange of Hb Buyer
export const statusChangeHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/status' :  "http://127.0.0.1:2002/v1.0/HB_Buyers/status",data);
// Archive Hb Buyer
export const archiveHeaderBid = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/archive' :  "http://127.0.0.1:2002/v1.0/HB_Buyers/archive" , data);

//Supply : Publisher

// get Publisher
export const getPublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/read/users/'+data.userID : `http://127.0.0.1:2002/v1.0/Publishers/read`,data,"Publishers");
// get Publisher by type
export const getPublisherByType = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/listOnType' : 'http://127.0.0.1:2002/v1.0/Publishers/listOnType',data,"Publishers");
// get Publishers by Partners
export const getPublishersByPartner = data => demandPost(data.userRole === 'Partner' ? "http://127.0.0.1:3006/v1.0/Supply/Publishers/read/users/" + data.partnerID : "http://127.0.0.1:2002/v1.0/Publishers/read/users/" + data.partnerID,data,"Publishers");
// Create Publisher 
export const createPublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/create' : 'http://127.0.0.1:2002/v1.0/Publishers/create', data);
// Update Publisher 
export const updatePublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/update/'+ data.publisherID : "http://127.0.0.1:2002/v1.0/Publishers/update/" + data.publisherID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Publisher 
export const readPublisher = data => demandPost("http://127.0.0.1:2002/v1.0/Publishers/readByID/" + data.publisherID);
// StatusChange of Publisher 
export const statusChangePublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/status' : "http://127.0.0.1:2002/v1.0/Publishers/status" ,data);
// Archive Publisher 
export const archivePublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/Publishers/archive' :"http://127.0.0.1:2002/v1.0/Publishers/archive" , data);

//Supply : AdUnitGroup
// get AdUnitGroup
export const getAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/read' : `http://127.0.0.1:2002/v1.0/AdUnitGroups/read`,data,"AdUnitGroups");
// get AdUnitGroups by Publisher
export const getAdUnitGroupsByPublisher = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/read/publisher/'+ data.publisherID :  "http://127.0.0.1:2002/v1.0/AdUnitGroups/read/publisher/" + data.publisherID,data,"AdUnitGroups");
// Create AdUnitGroup 
export const createAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/create' : 'http://127.0.0.1:2002/v1.0/AdUnitGroups/create', data);
// Update AdUnitGroup 
export const updateAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/update/'+ data.adUnitGroupID : "http://127.0.0.1:2002/v1.0/AdUnitGroups/update/" + data.adUnitGroupID,  data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id AdUnitGroupadUnitGroupID
export const readAdUnitGroup = data => demandPost("http://127.0.0.1:2002/v1.0/AdUnitGroups/readByID/" + data.adUnitGroupID);
// StatusChange of AdUnitGroup 
export const statusChangeAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/status' : "http://127.0.0.1:2002/v1.0/AdUnitGroups/status" ,data);
// Archive AdUnitGroup 
export const archiveAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnitGroups/archive' : "http://127.0.0.1:2002/v1.0/AdUnitGroups/archive" , data);

//Supply : AdUnit

// get AdUnit
export const getAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/read/byPartner' : `http://127.0.0.1:2002/v1.0/AdUnits/read`,data,"AdUnits");
// get AdUnits by AdUnitGroup
export const getAdUnitsByAdUnitGroup = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/read/byAdunitGroupID/'+ data.adUnitGroupID : "http://127.0.0.1:2002/v1.0/AdUnits/read/adunitGroup/" + data.adUnitGroupID,data,"AdUnits");
// Create AdUnit
export const createAdUnit = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/create' : 'http://127.0.0.1:2002/v1.0/AdUnits/create', data);
// Update AdUnitGroup 
export const updateAdUnit = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/update/' +data.adUnitID : "http://127.0.0.1:2002/v1.0/AdUnits/update/" + data.adUnitID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id AdUnit
export const readAdUnit = data => demandPost("http://127.0.0.1:2002/v1.0/AdUnits/readByID/" + data);
// StatusChange of AdUnit
export const statusChangeAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/status' : "http://127.0.0.1:2002/v1.0/AdUnits/status",data);
// Archive AdUnit
export const archiveAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/AdUnits/archive' : "http://127.0.0.1:2002/v1.0/AdUnits/archive" , data);
// get AdUnit only Rich Media
export const getAdUnitRichMedia = () => demandPost('http://127.0.0.1:2002/v1.0/ChildAdUnits/list/notArchived');
// get AdUnit only Standard Banner
export const getAdUnitStandardBanner = () => demandPost('http://127.0.0.1:2002/v1.0/AdUnits/read/display');
// get AdUnit only Video
export const getAdUnitVideo = () => demandPost('http://127.0.0.1:2002/v1.0/AdUnits/read/video');
// get AdUnit by TC
export const getAdUnitbyTC = data => demandPost('http://127.0.0.1:4009/v1.0/TC/getAdunit',data);

export const getAdUnitbyHIS = data => demandPost('http://127.0.0.1:2002/v1.0/LineItems/read/getAdunit',data);


//Supply : ChildAdUnit

// get ChildAdUnit
export const getChildAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/ChildAdUnits/read' : `http://127.0.0.1:2002/v1.0/ChildAdUnits/read`,data,"ChildAdUnits");
// get ChildAdUnits by AdUnit
export const getChildAdUnitsByAdUnit = data => demandPost("http://127.0.0.1:2002/v1.0/ChildAdUnits/adunit/" + data.adUnitID,data,"ChildAdUnits");
// Create ChildAdUnit 
export const createChildAdUnit = data => demandPost(data.userRole === 'Partner' ?  'http://127.0.0.1:3006/v1.0/Supply/ChildAdUnits/create' : 'http://127.0.0.1:2002/v1.0/ChildAdUnits/create', data);
// Update ChildAdUnit 
export const updateChildAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/ChildAdUnits/update/' + data.childAdUnitID :  "http://127.0.0.1:2002/v1.0/ChildAdUnits/update/" + data.childAdUnitID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id ChildAdUnit 
export const readChildAdUnit = data => demandPost("http://127.0.0.1:2002/v1.0/ChildAdUnits/readByID/" + data.childAdUnitID);
// StatusChange of ChildAdUnit 
export const statusChangeChildAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/ChildAdUnits/status' : "http://127.0.0.1:2002/v1.0/ChildAdUnits/status" ,data);
// Archive ChildAdUnit 
export const archiveChildAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/ChildAdUnits/archive' : "http://127.0.0.1:2002/v1.0/ChildAdUnits/archive" ,data);

//Supply : HighImpactSettings

// get HighImpactSettings
export const getHighImpactSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/read' : `http://127.0.0.1:2002/v1.0/HighImpactSettings/read`,data,"HighImpactSettings");
// get HighImpactSettings by AdUnit
export const getHighImpactSettingsByAdUnit = data => demandPost("http://127.0.0.1:2002/v1.0/HighImpactSettings/adunit/" + data.adUnitID,data,"HighImpactSettings");
// Create HighImpactSetting 
export const createHighImpactSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/create' :'http://127.0.0.1:2002/v1.0/HighImpactSettings/create', data);
// Update HighImpactSetting 
export const updateHighImpactSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/update/' + data.highImpactSettingID : "http://127.0.0.1:2002/v1.0/HighImpactSettings/update/" + data.highImpactSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id HighImpactSetting 
export const readHighImpactSetting = data => demandPost("http://127.0.0.1:2002/v1.0/HighImpactSettings/readByID/" + data.highImpactSettingID);
// StatusChange of HighImpactSetting 
export const statusChangeHighImpactSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/status' : "http://127.0.0.1:2002/v1.0/HighImpactSettings/status" ,data);
// Archive HighImpactSetting 
export const archiveHighImpactSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/archive' : "http://127.0.0.1:2002/v1.0/HighImpactSettings/archive" ,data);

//Supply : Stdb Settings

//get Stbb Settings
export const getStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/StandardBannerSettings/read' :`http://127.0.0.1:2002/v1.0/StandardBannerSettings/read`,data, "StandardBannerSetttings");
//get stbr settings by adunit id
export const readStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/read' :'http://127.0.0.1:2002/v1.0/StandardBannerSettings/readByID/', + data.standardBannerSettingID);
//Create StbrSettings
export const createStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/StandardBannerSettings/create' : 'http://127.0.0.1:2002/v1.0/StandardBannerSettings/create', data);
//update StbrSettings
export const updateStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/StandardBannerSettings/update/'+ data.standardBannerSettingID : 'http://127.0.0.1:2002/v1.0/StandardBannerSettings/update/' + data.standardBannerSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
//status stbrSettings
export const statusChangeStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/StandardBannerSettings/status' : 'http://127.0.0.1:2002/v1.0/StandardBannerSettings/status', data);
//get stbrSettings archive
export const archiveStbrSetting = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/StandardBannerSettings/archive' : 'http://127.0.0.1:2002/v1.0/StandardBannerSettings/archive', data);
//get stbrsettings by adunid id
export const getStbrSettingsByAdUnit = data => demandPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/read' : 'http://127.0.0.1:2002/v1.0/StandardBannerSettings/adunit/' + data.adUnitID,data,"StandardBannerSettings")
//Supply : PlayerSetting

// get PlayerSetting
export const getPlayerSetting = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/read' : `http://127.0.0.1:2002/v1.0/PlayerSettings/read`,data,"PlayerSettings");
// get PlayerSettings by AdUnit
export const getPlayerSettingsByAdUnit = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/getAdUnit' :"http://127.0.0.1:2002/v1.0/PlayerSettings/readbyAdunit" + data,"PlayerSettings");
// Create PlayerSetting 
export const createPlayerSetting = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/create' :'http://127.0.0.1:2002/v1.0/PlayerSettings/create', data);
// Update PlayerSetting 
export const updatePlayerSetting = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/update/' + data.playerSettingID : "http://127.0.0.1:2002/v1.0/PlayerSettings/update/" + data.playerSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id PlayerSetting 
export const readPlayerSetting = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayerSettings/read/" + data.playerSettingID);
// StatusChange of PlayerSetting 
export const statusChangePlayerSetting = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/status' : "http://127.0.0.1:2002/v1.0/PlayerSettings/status" ,data);
// Archive PlayerSetting 
export const archivePlayerSetting = data => contextualSettingsPost(data.userRole === 'Partner' ? 'http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/archive' : "http://127.0.0.1:2002/v1.0/PlayerSettings/archive" , data);


//Common : Size

// get Sizes
export const getSizes = () => demandPost('http://127.0.0.1:2002/v1.0/Creatives/size');

//Contextual Targeting / Global Settings

// get Brand Affinity
export const getBrandAffinity = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/brandAffinity/read','','brandAffinity');
// Create Brand Affinity 
export const createBrandAffinity = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/brandAffinity/create', data);
// Update Brand Affinity 
export const updateBrandAffinity = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/brandAffinity/update/" + data.brandAffinityID, data);
// Read By Id Brand Affinity 
export const readBrandAffinity = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/brandAffinity/read/" + data.brandAffinityID);
// StatusChange of Brand Affinity 
export const statusChangeBrandAffinity = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/brandAffinity/updateStatus",data);
// Archive Brand Affinity 
export const archiveBrandAffinity = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/brandAffinity/delete",data);

// get Website Categorization
export const getWebsiteCategorization = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/read','','websiteCatorization');
// Create Website Categorization 
export const createWebsiteCategorization = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/create', data);
// Update Website Categorization 
export const updateWebsiteCategorization = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/update/" + data.websiteID, data);
// Read By Id Website Categorization 
export const readWebsiteCategorization = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/read/" + data.websiteID);
// StatusChange of Website Categorization 
export const statusChangeWebsiteCategorization = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/updateStatus",data);
// Archive Website Categorization 
export const archiveWebsiteCategorization = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/websiteCatorization/delete",data);

// get Devices
export const getDevices = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/all');

// get Operating Systems
export const getOSs = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/NetworkBlock/read/all');

// get Browsers
export const getBrowsers = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/webBrowser/read');

// get Domains
export const getDomains = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/Domain/list');

// get PageUrls
export const getPageUrls = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/read','','PageURLGroups');
// Create PageUrls 
export const createPageUrl = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/create', data);
// Update PageUrls 
export const updatePageUrl = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/update/" + data.pageURLGroupID, data);
// Read By Id PageUrls 
export const readPageUrl = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/read/" + data.pageURLGroupID);
// StatusChange of PageUrls 
export const statusChangePageUrl = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/updateStatus",data);
// Archive PageUrls 
export const archivePageUrl = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/PageURLGroups/delete",data);

// get Keywords
export const getKeywords = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/keyword/read','','keyword');
// Create Keyword 
export const createKeyword = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/keyword/create', data);
// Update Keyword 
export const updateKeyword = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/keyword/update/" + data.keywordID, data);
// Read By Id Keyword 
export const readKeyword = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/keyword/read/" + data.keywordID);
// StatusChange of Keyword 
export const statusChangeKeyword = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/keyword/updateStatus",data);
// Archive Keyword 
export const archiveKeyword = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/keyword/delete",data);

// get IpMap
export const getIpMaps = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/iPMapping/read','','keyword');
// Create IpMap 
export const createIpMap = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/iPMapping/create', data);
// Update IpMap 
export const updateIpMap = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/iPMapping/update/" + data.iPMappingID, data);
// Read By Id IpMap 
export const readIpMap = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/iPMapping/read/" + data.iPMappingID);
// StatusChange of IpMap 
export const statusChangeIpMap = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/iPMapping/updateStatus",data);
// Archive IpMap 
export const archiveIpMap = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/iPMapping/delete",data);

// get Languages
export const getLanguages = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/Miscs/list');

// get Categories
export const getCategories = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/category/read');

// get Brand Affinities
export const getBrandAffinities = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/brandAffinity/read');

// get Interests
export const getInterests = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/interest/read','','interest');
// Create Interest
export const createInterest = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/interest/create', data);
// Update Interest 
export const updateInterest = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/interest/update/" + data.interestID, data);
// Read By Id Interest 
export const readInterest = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/interest/read/" + data.interestID);
// StatusChange of Interest 
export const statusChangeInterest = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/interest/updateStatus",data);
// Archive Interest 
export const archiveInterest = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/interest/delete",data);

// get Regions
export const getRegions = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/regions/list');

// get Cities
export const getCities = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/cities/list');


// get VideoContents
export const getVideoContents = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/read",data);
// Create VideoContents 
export const postVideoContent = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/create", data);
//delete videoContents
export const deleteVideoContent = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/delete/"+data.videoContentID, data);

// get VideoContents
export const getVideoContentsPartner = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/read",data);

// get Playlist
export const getPlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/read",data);
// get Playlistbyid
export const getPlaylistById = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/read/"+data.playlistID);
//create Playlist 
export const createPlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/create",data);
//update Playlist
export const updatePlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/update/"+data.playListID,data);
//status playlist
export const statusPlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/status", data);
//archive playlist
export const archivePlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/archive", data);

//market place
export const getMarketContents = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/read/readMarketPlace",data)
//move to vcs to pub
export const addMarketContent = data => toolsPost("http://127.0.0.1:3006/v1.0/VideoContentOther/create/MoveFromMarketPlace",data)
//remove from market place in admin
export const deleteMarketContent = data => toolsPost("http://127.0.0.1:2002/v1.0/VideoContent/update/ReMoveMarketPlace",data)



// get VideoContents
export const getPubVideoContents = data => toolsPost("http://127.0.0.1:3006/v1.0/VideoContentOther/read",data);
// Create VideoContents 
export const postPubVideoContent = data => toolsPost("http://127.0.0.1:3006/v1.0/VideoContentOther/create", data);
//delete videoContents
export const deletePubVideoContent = data => toolsPost("http://127.0.0.1:3006/v1.0/VideoContentOther/delete/"+data);


// get Playlist
export const getPubPlaylist = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/PlayListOther/read",data);
// get Playlistbyid
export const getPubPlaylistById = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/PlayListOther/read/"+data.playlistID);
//create Playlist 
export const createPubPlaylist = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/PlayListOther/create",data);
//update Playlist need url for pub
export const updatePubPlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/update/"+data.playListID,data);
//status playlist
export const statusPubPlaylist = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/PlayListOther/archive", data);
//archive playlist
export const archivePubPlaylist = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/PlayList/archive", data);



//Notifications

// get Notifications
export const getPub_Notifications = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/read/forAdmin",data,"Notifications");
// Create Pub_Notification 
export const postPub_Notification = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/create", data);
// Update updatePub_Notification 
export const updatePub_Notification = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/update/" + data.notificationID, data);
// Read By Id readPub_Notification 
export const readPub_Notification = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/read/" + data.notificationID);
// StatusChange of status Change Pub_Notification 
export const statusChangePub_Notification = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/status",data);
// Archive archive Pub_Notification 
export const archivePub_Notification = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/archive" , data);

// get Action Notifications
export const getAction_Notifications = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/read/partnerInteraction",data,"ActionNotifications");


//Custom Tracking : Category

// get Category
export const getCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/read",data,"HouseAdsCategories");
// Create Category 
export const postCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/create", data);
// Update Category 
export const updateCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/update/" + data.HouseAdsCategoryID, data);
// Read By Id Category 
export const readCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/readByID" + data.HouseAdsCategoryID);
// StatusChange of Category 
export const statusChangeCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/status",data);
// Archive Category 
export const archiveCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategories/archive" , data);
// get Category Types
export const getCategoryTypes = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategoryTypes/list",data);
// Create CategoryType 
export const postCategoryType = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCategoryTypes/create", data);

//Custom Tracking : Creatives

// get Creatives
export const getCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/read",data,"HouseAdsCreatives");
// get Creatives by Category
export const getCreativesByCategory = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/readOnCategory/" + data.categoryID);
// get Creatives by AdUnits
export const getCreativesByAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/getListAdunit", data);
// Create Creatives 
export const postCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/create", data);
// Update Creatives 
export const updateCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/update/" + data.houseAdsCreativeID, data);
// Read By Id Creatives 
export const readCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/readByID/" + data.houseAdsCreativeID);
// StatusChange of Creatives 
export const statusChangeCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/status",data);
// Archive Creatives 
export const archiveCreatives = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsCreatives/archive" , data);

//Custom Tracking : AdUnits

// get AdUnits
export const getAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/read",data,"HouseAdsAdUnits");
// Create AdUnits 
export const postAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/create", data);
// Update AdUnits 
export const updateAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/update/" + data.houseAdsAdUnitID, data);
// Read By Id AdUnits 
export const readAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/readByID/" + data.houseAdsAdUnitID);
// StatusChange of AdUnits 
export const statusChangeAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/status",data);
// Archive AdUnits 
export const archiveAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsAdUnits/archive" , data);

//Custom Tracking : Publishers

// get Publishers
export const getAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/read",data,"HouseAdsPublishers");
// Create Publishers 
export const postAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/create", data);
// Update Publishers 
export const updateAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/update/" + data.houseAdsPublisherID, data);
// Read By Id Publishers 
export const readAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/readByID/" + data.houseAdsPublisherID);
// StatusChange of Publishers 
export const statusChangeAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/status",data);
// Archive Publishers 
export const archiveAdPublisher = data => demandPost("http://127.0.0.1:4009/v1.0/HouseAdsPublishers/archive" , data);

//Proxy : AdUnits

// get ProxyAdUnits
export const getProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/read",data,"ProxyAdUnits");
// Create ProxyAdUnits 
export const postProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/create", data);
// Update ProxyAdUnits 
export const updateProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/update/" + data.proxyAdUnitID, data);
// Read By Id ProxyAdUnits 
export const readProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/readByID/" + data.proxyAdUnitID);
// StatusChange of ProxyAdUnits 
export const statusChangeProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/status",data);
// Archive ProxyAdUnits 
export const archiveProxyAdUnits = data => demandPost("http://127.0.0.1:4009/v1.0/ProxyAdUnits/archive" , data);

// get Proxy Reports
export const getProxyAdUnitsReports = data => demandPost("http://localhost:2003/v1.0/Query/Proxy/ReportData",data);
// get HouseAd Reports
export const getHouseAdUnitsReports = data => demandPost("http://localhost:2003/v1.0/Query/Native/ReportData",data);

// get Revenue Display Reports
export const getRevenueDisplayReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/Revenue/DisplayQuerys",data);

// get Analytics Display Reports
export const getAdminAnalyticsReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/Analytic/ReportData",data);

// get YM Analytics Display Reports
export const getYMAnalyticsReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/api/analytics/ym_team",data);

// get AS Analytics Display Reports
export const getASAnalyticsReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/api/analytics/as_team",data);

// get MIS Analytics Display Reports
export const getMISAnalyticsReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/api/analytics/mis_team",data);

// get Page URL Display Reports
export const getPageUrlReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/Pageurl/ReportData",data);

// get Team Page URL Display Reports
export const getTeamPageUrlReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/api/page_url/as_and_ym",data);

// get Team History Display Reports
export const getTeamHistoryReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/ReportsHistory/read",data);

// get Publisher Revenue Display Reports
export const getPublisherRevenueDisplayReports = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/DisplayQuerys",data);

export const getAgencyRevenueDisplayReports = data => demandPost("http://127.0.0.1:2003/v1.0/Query/Revenue/AgencyQuerys",data);

export const getPAgencyRevenueDisplayReports = data => demandPost("http://127.0.0.1:4011/Agency/Reports",data);

//Publisher Console : Schedule Report

// get ScheduleReports
export const getScheduleReports = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/read",data);
// Create ScheduleReport 
export const postScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/create", data);
// Update ScheduleReport 
export const updateScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/update/" + data.scheduleID, data);
// Read By Id ScheduleReport 
export const readScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/readByID/" + data.scheduleID);
// StatusChange of ScheduleReport 
export const statusChangeScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/status",data);
// Archive ScheduleReport 
export const archiveScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/archive" , data);

//Agency Console : Schedule Report

// get ScheduleReports
export const getAgencyScheduleReports = data => demandPost("http://127.0.0.1:4011/AgencyScheduleReport/Read",data);
// Create ScheduleReport 
export const postAgencyScheduleReport = data => demandPost("http://127.0.0.1:4011/Agency/ScheduleReport/Create", data);
// Update ScheduleReport 
export const updateAgencyScheduleReport = data => demandPost("http://127.0.0.1:4011/AgencyScheduleReport/Update/" + data.scheduleID, data);
// Read By Id ScheduleReport 
export const readAgencyScheduleReport = data => demandPost("http://127.0.0.1:3008/v1.0/Query/Revenue/ScheduleReports/readByID/" + data.scheduleID);
// StatusChange of ScheduleReport 
export const statusAgencyChangeScheduleReport = data => demandPost("http://127.0.0.1:4011/AgencyScheduleReport/Status",data);
// Archive ScheduleReport 
export const archiveAgencyScheduleReport = data => demandPost("http://127.0.0.1:4011/AgencyScheduleReport/Archive" , data);

// get NotRead Notifications for Publishers
export const getNotRead_Notifications = data => demandPost("http://127.0.0.1:4009/v1.0/Notifications/readByUserID",data);

// get Analytics Display Reports BSRV2021
export const getAnalyticsDisplayReports = data => reportPost("http://127.0.0.1:2006/bsrv/report",data);

// get Page URL BSRV2021 Reports
export const getPageUrl21Reports = data => reportPost("http://127.0.0.1:2006/bsrv/pageURLreport",data);

//Admin Support Ticket

// get Support Tickets
export const getSupportTicketforAdmin = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/SupportTickets/read/forAdmin" ,data,"SupportTicketsAdmin");
// Create Support Tickets 
export const changeStatusSupportTicket = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/SupportTickets/update/status", data);


// both Admin and Publisher Create Chat 
export const postChat = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/SupportTickets/create/chat", data);


//Publisher Support Ticket

// get Support Tickets
export const getSupportTicket = data => contextualSettingsPost("http://127.0.0.1:3006/v1.0/SupportTickets/read/partner/" + data.partnerID);
// Create Support Tickets 
export const postSupportTicket = data => supportTicketPost("http://10.0.3.41:3006/v1.0/SupportTickets/create", data);
 

// get Bill History
export const getBillHistoryList = data => demandPost("http://127.0.0.1:4013/v1.0/Invoices/History/list",data,"BillHistoryList");

// get Approved Bill History
export const getBillHistory = data => demandPost("http://127.0.0.1:4013/v1.0/Invoices/History/listApproved",data,"ApprovedBillHistoryList");

// get Bill History
export const getLogs = data => contextualSettingsPost("http://10.0.3.41:2007/partner/report",data,"PublisherLogs");

// get Approved Bill History
export const getLogsMaster = data => contextualSettingsPost("http://10.0.3.41:2007/master/report",data,"MasterLogs");

//Admin : Network Settings

//get Stbb Settings
export const getNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/read',data, "NetworkSettings");
//get Network settings by adunit id
export const readNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/read/', + data.networkSettingID);
//Create Network Settings
export const createNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/create', data);
//update Network Settings
export const updateNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/update/' + data.networkSettingID, data);
//status Network Settings
export const statusChangeNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/status', data);
//get Network Settings archive
export const archiveNetworkSetting = data => demandPost('http://127.0.0.1:2002/v1.0/NetworkSettings/archive', data);

export const getNetBlocking = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/NetworkBlock/read/' + data.type,data);
// Update NetBlocking 
export const updateNetBlocking = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/NetworkBlock/update/" + data.deviceControlID, data);
// StatusChange of NetBlocking 
export const statusChangeNetBlocking = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/NetworkBlock/status/" + data.type,data);
// Archive NetBlocking 
export const archiveNetBlocking = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/NetworkBlock/archive/" + data.type, data);


export const getContextualData = data => contextualSettingsPost('http://127.0.0.1:2002/v1.0/targeting/' + data.type + '/read' ,data);
// Update Advertiser 
export const updateContextualData = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/update/" + data.contextualDataID, data);
// StatusChange of Advertiser 
export const statusChangeContextualData = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/" + data.type +"/updateStatus" ,data);
// Archive Advertiser 
export const archiveContextualData = data => contextualSettingsPost("http://127.0.0.1:2002/v1.0/targeting/" + data.type +"/delete" ,data);

export {  
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
  postLogin,
  postRegister,
  postForgetPwd,
};


