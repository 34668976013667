import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Select from "react-select";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Input,
  Spinner,
  Label,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  BreadcrumbItem
} from "reactstrap"

import DateRangePickers from '../../helpers/DateRangePicker'
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import axios from "axios";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from "moment";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import { useSelector, useDispatch } from "react-redux";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().startOf('month')
var toDate = moment().endOf('month')
var filterCount = 0

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getLogs as onGetLogs,
} from "store/Logs/actions";
import AdminSidebar from "./Admin_Sidebar/AdminSidebar";

const Publisher_Log = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [publisherLogData, setPublisherLogData] = useState([])
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [partnerData, setPartnerData] = useState([])
  const [switchLoader, setSwitchLoader] = useState(true)
  // const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    start: moment().startOf('month'),
    end: moment().endOf('month'),
  });

  const { start, end } = state;

  const handleEvent = (startDate, endDate, label) => {
    console.log(startDate, endDate)
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    fetchMasterLogs(selectedPartner ? selectedPartner.label : '', startDate._d.toISOString(), endDate._d.toISOString())
  };

  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  // useEffect(() => {
  //   dispatch(onGetPartners(null));
  // }, [])

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const { logsPublisher } = useSelector(state => ({
    logsPublisher: state.userLogs.logs
  }));

  const { loading } = useSelector(state => ({
    loading: state.userLogs.loading,
  }));

  useEffect(() => {
    if (logsPublisher !== null) {
      console.log(logsPublisher)
      dispatch(onGetLogs({
        "startDate": moment(start).format("YYYY-MM-DD"),
        "endDate": moment(end).format("YYYY-MM-DD"),
        "partner": '',
        "action": ""
      }));
    }
  }, []);

  useEffect(() => {
    if (logsPublisher !== null && logsPublisher.length) {
      logsPublisher.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("MM/DD/YYYY");
        // setPublisherLogData(logsPublisher)
      })
    }
  }, [logsPublisher]);

  // useEffect(() => {
  //   fetchMasterLogs('',start, end)
  // }, []);

  const fetchMasterLogs = (partner, fromDates, toDates) => {
    dispatch(onGetLogs({
      "startDate": moment(fromDates).format("YYYY-MM-DD"),
      "endDate": moment(toDates).format("YYYY-MM-DD"),
      "partner": partner ? partner : '',
      "action": ""
    }));
    // setLoading(true)
    // const body = {
    //   "url": 'http://10.0.3.41:2007/partner/report',
    //   "requestBody": {
    //     userID: userInfo.userID,
    //     userRole: userInfo.userRole,
    //     companyID: userInfo.companyID,
    //     "startDate": moment(fromDates).format("YYYY-MM-DD"),
    //     "endDate": moment(toDates).format("YYYY-MM-DD"),
    //     "partner":partner ? partner : '',
    //     "action": ""
    //   }
    // }
    // axios.post("/connectReportAPI", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is ==>", res)
    //     setLoading(false)
    //     if (res.data.responseData.success) {
    //       res.data.responseData.list.map((item, index) => {
    //         item.id = index + 1
    //       })
    //       setPublisherLogData(res.data.responseData.list)
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false)
    //     console.log("e", err)
    //   });
  }

  const { partners } = useSelector(state => ({
    partners: state.partners.partners,
  }));

  console.log(partners)

  useEffect(() => {
    fromDate = moment().startOf('month')
    toDate = moment().endOf('month')
    if (partners !== null) {
      dispatch(onGetPartners({ filter: 'Active' }));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const [filterState, setFilterState] = useState(false)

  function handleSelectPartner(selectPartner) {
    console.log(selectPartner)
    setSelectedPartner(selectPartner);

  }

  function handleSelectPartner(selectPartner) {
    if (selectPartner.label === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (selectedPartner === null) {
      filterCount = filterCount + 1
    }
    setSelectedPartner(selectPartner);
    // fetchMasterLogs(selectPartner.label, selectedRole ? selectedRole.label : '', actionSearch, resourceTypeSearch, fromDate, toDate)
  }

  const applyFilter = (e) => {
    fetchMasterLogs(selectedPartner ? selectedPartner.label : '', fromDate, toDate)
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setSelectedPartner(null);
    filterCount = 0
    setFilterState(false)
    fetchMasterLogs('', fromDate, toDate)
  }



  console.log(publisherLogData)

  const userLogColoumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Date",
      dataField: "date",
      sort: true,
      formatter: (cellContent, userLogs) => (
        <>
          <span>{moment(userLogs.date).format("DD/MM/YYYY")}</span>
        </>
      ),
    },
    {
      text: "Partner",
      dataField: "partner",
      sort: true,
    },
    {
      text: "Action",
      dataField: "action",
      sort: false,
      formatter: (cellContent, userLogs) => (
        <span dangerouslySetInnerHTML={{ __html: boldLabels(userLogs.action) }} />
      ),
    },
  ];

  function boldLabels(text) {
    return text.replace(/(\b(Report Name|Frequency|Sent On):)/g, "<b>$1</b>");
  }
  


  const keyField = "id";

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
    // if (publisherLogData && publisherLogData.length) {
    //   setSwitchLoader(false)
    // }
  }, [loading]);

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {/* <AdminSidebar />
      <div className="main-content access-management"> */}
      <div className="page-content">
        <MetaTags>
          <title>Publisher Log | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Admin" breadcrumbItem="Publisher Log" />

            <div className="text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={toggleMenu}
                >
                  <DropdownToggle
                    tag="button"
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Partner</Label> <br />
                            <Select
                              value={selectedPartner}
                              onChange={s => {
                                handleSelectPartner(s)
                              }}
                              options={partnerData}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                          <ToolkitProvider
                            keyField={keyField}
                            data={logsPublisher}
                            columns={userLogColoumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePicker
                                        initialSettings={{
                                          opens: 'left',
                                          startDate: start.toDate(),
                                          endDate: end.toDate(),
                                          ranges: {
                                            // Today: [moment(), moment()],
                                            Yesterday: [
                                              moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                              moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                            ],
                                            'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                            'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [
                                              moment().subtract(1, 'month').startOf('month'),
                                              moment().subtract(1, 'month').endOf('month'),
                                            ],
                                          },
                                        }}
                                        onCallback={handleEvent}
                                      >
                                        <button className='datePicker' type="button">
                                          <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                        </button>
                                      </DateRangePicker>
                                    </div>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}
      {/* </div> */}
    </React.Fragment >
  )
}

Publisher_Log.propTypes = {}

export default Publisher_Log