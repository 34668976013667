import React, { } from 'react';
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types'
import {Col, Container, Row, Input, Label, Form,Button} from "reactstrap"

const Custom_Creative_Template = props => {
  return (
    <React.Fragment>
    {/* <Sidebar />
    <Header />
    <div className="main-content"> */}
        <div className="page-content">
            <MetaTags>
                <title>Custom Creative Template | Bidsxchange</title>
            </MetaTags>
            <Container fluid={true}>
                <Breadcrumbs title="Admin" breadcrumbItem="Custom Creative Template" />
            </Container>
        </div>
    {/* </div> */}
</React.Fragment >
  )
}

Custom_Creative_Template.propTypes = {}

export default Custom_Creative_Template