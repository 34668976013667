import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ButtonDropdown,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';
import { MultiSelect } from "react-multi-select-component";

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  getLineItem as onGetLineItem,
  statusLineItem as onStatusLineItem,
  archiveLineItem as onArchiveLineItem,
  statusLineItemSuccess,
  archiveLineItemSuccess,
  addLineItemFail,
  updateLineItemFail,
} from "store/Demand/LineItem/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Line_Item from "./Line_Items";
import Footer from "components/VerticalLayout/Footer";
import Create_LI from "./Create_LineItem/Create_LI";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import DetachLineItem from "./DetachLineItem";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var lineItemType = ''

var filterCount = 0

const Line_Items = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isRights, setIsRights] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [drp_primary1, setDrp_primary1] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [lineItemTypeSearch, setLineItemTypeSearch] = useState('')
  const [adUnitSearch, setAdUnitSearch] = useState([])
  const [prioritySearch, setPrioritySearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [switchLoader, setSwitchLoader] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
      setIsRights(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)
  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: e.target.value, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: e.target.value, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    // }
  }

  const handleSelectAdUnit = selectAdUnit => {
    if (selectAdUnit.length === 0) {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitSearch.length === 0) {
      filterCount = filterCount + 1
    }
    setSelectedAdUnit(selectAdUnit);
    let listData = []
    selectAdUnit.map((item, index) => {
      listData.push(item.value)
    })
    setAdUnitSearch(listData)
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: listData }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: listData }));
    // }
  }

  const lineItemTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (lineItemTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setLineItemTypeSearch(e.target.value)
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: e.target.value, priority: prioritySearch, AdUnitID: adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: e.target.value, priority: prioritySearch, AdUnitID: adUnitSearch }));
    // }
  }

  const priorityChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (prioritySearch === '') {
      filterCount = filterCount + 1
    }
    setPrioritySearch(e.target.value)
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: e.target.value, AdUnitID: adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: e.target.value, AdUnitID: adUnitSearch }));
    // }
  }

  const applyFilter = (e) => {
    if (parsed.iOID) {
      dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setSelectedAdUnit([])
    setAdUnitSearch([])
    setStatusSearch('Active')
    setLineItemTypeSearch('')
    setPrioritySearch('')
    filterCount = 0
    setFilterState(false)
    if (parsed.iOID) {
      dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: 'Active', lineItemType: '', priority: '', AdUnitID: [] }));
    } else {
      dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: 'Active', lineItemType: '', priority: '', AdUnitID: [] }));
    }
  }

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetLineItem(null));
    if (parsed.status === 'LineItemCreateByIO') {
      lineItemType = parsed.lineItemType
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.lineItemID)
        selectedItem.push(row.lineItemID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.lineItemID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.lineItemID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.lineItemID)
          selectedItem.push(item.lineItemID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { lineItems } = useSelector(state => ({
    lineItems: state.lineItems.lineItems,
  }));

  const { loading } = useSelector(state => ({
    loading: state.lineItems.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.lineItems.noData,
  }));

  const { lineItemsResponse } = useSelector(state => ({
    lineItemsResponse: state.lineItems.response,
  }));

  const { archiveLineItemResponse } = useSelector(state => ({
    archiveLineItemResponse: state.lineItems.archiveMsg,
  }));

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitList
  }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);

  useEffect(() => {
    if (adUnits !== null) {
      dispatch(onGetAdUnits({ adUnitGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: 'Active', adUnitType: "", adUnitSize: '' }));
    }
  }, []);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.adUnitName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (lineItemsResponse !== null) {
      if (lineItemsResponse.responseData.success) {
        toastr.success('LineItem Status Changed Successfully')
        if (parsed.iOID) {
          dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
        } else {
          dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusLineItemSuccess(null));
      }
    }
  }, [dispatch, lineItemsResponse]);

  useEffect(() => {
    if (archiveLineItemResponse !== null) {
      if (archiveLineItemResponse.success) {
        toastr.success('LineItem Archived Successfully')
        if (parsed.iOID) {
          dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
        } else {
          dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
        }
        dispatch(archiveLineItemSuccess(null));
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  }, [dispatch, archiveLineItemResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    APIforLineItem()
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch,AdUnitID:adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch,AdUnitID:adUnitSearch }));
    // }
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleRightCanvass = () => {
    selectedData = ''
    APIforLineItem()
    // if (parsed.iOID) {
    //   dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch,AdUnitID:adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch,AdUnitID:adUnitSearch }));
    // }
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRights(!isRights);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, lineItem, lineItemTypes) => (event) => {
    if (event === 'Create') {
      toastr.success('LineItem Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('LineItem Updated Successfully!...')
    }

    APIforLineItem()
    selectedData = lineItem
    lineItemType = lineItemTypes
    setIsArchived(lineItem.isLineItemArchived)

    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  const APIforLineItem = (e) => {
    if (parsed.iOID) {
      dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    }
  }

  const toggleDrawers = (close, highImpact) => (event) => {
    console.log(event)
    if (parsed.iOID) {
      dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    }
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRights(close);
  };

  useEffect(() => {
    if (parsed.iOID) {
      dispatch(onGetLineItem({ iOID: parsed.iOID, userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    }
  }, []);

  useEffect(() => {
    if (lineItems !== null && !parsed.iOID) {
      dispatch(onGetLineItem({ iOID: '', userRole: userInfo.userRole, userID: userInfo.userID,searchText:'',filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, AdUnitID: adUnitSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const lineItemListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      dataField: "lineItemID",
      sort: true,

      formatter: (cellContent, lineItem) => (
        <>
          <span>{lineItem.lineItemID}</span>
        </>
      ),
    },
    {
      dataField: "lineItemCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "LI Name",
      dataField: "lineItemName",
      sort: true,
      style: {
        minWidth: "30em"
      },
      formatter: (cellContent, lineItem) => (
        <>

          {(lineItem.lineItemType === 'video' && lineItem.count !== 0) ?
            <Link to={`/Creative?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByParent`} className="color-dark-blue ">
              {lineItem.lineItemName}
            </Link>
            :
            <span className="font-size-13 mb-1">
              <span className=" mb-1">{lineItem.lineItemName}</span><br />
              {lineItem.lineItemType !== 'video' && lineItem.count !== 0 &&
                <Link to={`/Creative_Weightage?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByParent`} className="color-dark-blue ">
                  Manage Creative Weightage
                </Link>
              }
              {(lineItem.count === 0 && lineItem.lineItemType !== 'video') ?
                <Link to={`/Creative_Weightage?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByLineItem`} className=" " style={{ color: 'red' }}>
                  * Need Creatives | <span style={{ color: 'blue' }}> + Add Creative </span>
                </Link> : ''}
              {(lineItem.count === 0 && lineItem.lineItemType === 'video') ?
                <Link to={`/Creative?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByLineItem`} className=" " style={{ color: 'red' }}>
                  * Need Creatives | <span style={{ color: 'blue' }}> + Add Creative </span>
                </Link> : ''}
            </span>
          }
        </>
      ),
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
      style: {
        minWidth: "10em"
      },
      formatter: (cellContent, lineItem) => (
        < >
          {lineItem.priority === 'high' &&
           <span className="inactive-state"> High</span>
          }
          {lineItem.priority === 'medium' &&
         <span className="medium-state"> Medium</span>
          }
          {lineItem.priority === 'low' &&
            <span className="active-state">Low</span>
          }
        </>
      ),
    },
    {
      text: "LI Type",
      dataField: "lineItemType",
      sort: false,
      style: {
        minWidth: "15em"
      },
      formatter: (cellContent, lineItem) => (
        <div className=' ' >
          {lineItem.lineItemType === 'video' &&
            <span>Video</span>
          }
          {lineItem.lineItemType === 'vast' &&
            <span>Vast</span>
          }
          {lineItem.lineItemType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {lineItem.lineItemType === 'highImpact' &&
            <span>High Impact</span>
          }
        </div>
      )
    },
    {
      dataField: "lineItemStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, lineItem) => (
        <>
          <span className={lineItem.newStatus === 'Active' ? "active-state" : lineItem.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{lineItem.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems) && (!UserRolePrivileges.Demand.LineItems.create),
      formatter: (cellContent, lineItem) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, lineItem, lineItem.lineItemType)}>
            {lineItem.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {lineItem.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveLineItem = async () => {
    dispatch(onArchiveLineItem({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem }));
  }

  const statusLineItem = (state) => (event) => {
    dispatch(onStatusLineItem({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem, lineItemStatus: state }));
  }

  if (lineItems.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems) {
      if (!UserRolePrivileges.Demand.LineItems.archive && !UserRolePrivileges.Demand.LineItems.status) {
        lineItems.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        lineItems.map((item, index) => {
          if (item.isLineItemArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }



  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Line Item | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumbs */}
          <div className='row'>
            <div className='col-6 col-xxl-8'>
              {parsed.iOID ? (
                <Breadcrumbs title="Demand" breadcrumbItem="Line Item" iOID={parsed.iOID} />
              ) : (
                <Breadcrumbs title="Demand" breadcrumbItem="Line Item" breadList="All Line Items" />
              )
              }
            </div>
            <div className='col-6 col-xxl-4'>
              <div className='row'>
                <div className="text-sm-end">

                  {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.create &&
                    <div>
                      <div className="btn-group">
                        <Dropdown
                          direction="left"
                          isOpen={isMenu}
                          toggle={toggleMenu}
                        >
                          <DropdownToggle
                            tag="button"
                            className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                          >
                            <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                            {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                              Filter Applied
                            </span> : <span className="d-none font-16 d-sm-inline-block">
                              Filter
                            </span>
                            }
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                            <div className="dropdown-item-text">
                              <div className="row">
                                <h5 className="mb-0">Filters</h5>
                              </div>
                            </div>

                            <DropdownItem divider />

                            <Card>
                              <CardBody aria-hidden="true">
                                <Row >
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">Ad Unit</Label>
                                    <MultiSelect
                                      options={adUnitData}
                                      value={selectedAdUnit}
                                      onChange={handleSelectAdUnit}
                                      overrideStrings={{ "selectSomeItems": "Please Select Ad Unit " }}
                                    />
                                  </Col>
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">Priority</Label>
                                    <div className=" text-sm-end">
                                      <Input type="select" className="form-select" value={prioritySearch} onChange={priorityChange}>
                                        <option value=''> All</option>
                                        <option value='high' >High</option>
                                        <option value='medium'> Medium</option>
                                        <option value='low'> Low</option>
                                      </Input>
                                    </div>
                                  </Col>
                                  {UserInventoryType !== 'video' &&
                                    <Col sm="12" className="mt-2">
                                      <Label className="form-label">Line Item Type</Label>
                                      <div className=" text-sm-end">
                                        <Input type="select" className="form-select" value={lineItemTypeSearch} onChange={lineItemTypeChange}>
                                          <option value=''> All</option>
                                          <option value='stdb' >Standard Banner</option>
                                          <option value='highImpact'> High Impact</option>
                                          {UserInventoryType === 'both' &&
                                            <option value='video'> Video</option>
                                          }
                                        </Input>
                                      </div>
                                    </Col>
                                  }
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">Status</Label>
                                    <div className=" text-sm-end">
                                      <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                        <option value=''> All</option>
                                        <option value='Active' > Active</option>
                                        <option value='Inactive'> InActive</option>
                                        <option value='Archived'> Archived</option>
                                      </Input>
                                    </div>
                                  </Col>
                                  {/* <Col sm="12" className="date-range mt-2">
                  <Label className="form-label">Date Range</Label> <br />
               
                </Col> */}
                                </Row>
                              </CardBody>
                            </Card>

                            <DropdownItem divider />
                            <Row>
                              <Col sm="6">
                                <DropdownItem
                                  className="text-primary text-center"
                                  onClick={clearAllFilter}
                                >
                                  Clear ALL
                                  {/* {filterCount > 0 &&
                  <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                } */}
                                </DropdownItem>
                              </Col>
                              <Col sm="6">
                                <DropdownItem
                                  className="text-primary text-center"
                                  onClick={applyFilter}
                                >
                                  Apply Filter
                                  {/* {filterCount > 0 &&
                  <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                } */}
                                </DropdownItem>
                              </Col>
                            </Row>

                          </DropdownMenu>
                        </Dropdown>
                      </div>&nbsp;&nbsp;
                      {/* <Button
      color="primary"
      className="font-16 btn-block btn btn-primary"
      onClick={toggleRightCanvass}
    >
      Detach Line Items
    </Button> &nbsp;&nbsp; */}
                      {(parsed.status === 'LineItemCreateByIO' || parsed.status === 'LineItemCreateByParent') &&
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={toggleDrawer(true, '', parsed.lineItemType)}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Line Item
                        </Button>
                      }
                      {(!parsed.status) &&
                        <div className="btn-group">
                          {UserInventoryType !== 'video' ?
                            <Dropdown
                              isOpen={btnprimary1}
                              toggle={() => setBtnprimary1(!btnprimary1)}
                            >
                              <DropdownToggle tag="button" className="btn btn-primary">
                                <i className="mdi mdi-plus-circle-outline me-1" />Add Line Item&nbsp; <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem header>Standard</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard Banner</DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact </DropdownItem>
                              <DropdownItem divider />
                              {UserInventoryType === 'both' &&
                                <>
                                  <DropdownItem header>Video</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'video')}>Player</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'vast')} disabled>Vast</DropdownItem>
                                </>
                              } */}
                                <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard Banner</DropdownItem>
                                <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact</DropdownItem>
                                {UserInventoryType === 'both' &&
                                  <DropdownItem onClick={toggleDrawer(true, '', 'video')}>Video</DropdownItem>
                                }
                              </DropdownMenu>
                            </Dropdown> : <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              onClick={toggleDrawer(true, '', 'video')}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Add Line Item
                            </Button>}
                          &nbsp;&nbsp;
                          <Button
                            color="primary"
                            className="btn btn-danger"
                            onClick={toggleRightCanvass}
                          >
                            <i className="bx bx-unlink me-1" />
                            Detach Line Item
                          </Button>
                        </div>
                      }
                      {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                  <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                      <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                  <DropdownMenu>
                                      <li><DropdownItem onClick={toggleRightCanvass}>Detach Line Items</DropdownItem></li>
                                  </DropdownMenu>
      </UncontrolledDropdown> */}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {/* <div className=" d-sm-flex  justify-content-between">
            {parsed.iOID ? (
              <Breadcrumbs title="Demand" breadcrumbItem="Line Item" iOID={parsed.iOID} />
            ) : (
              <Breadcrumbs title="Demand" breadcrumbItem="Line Item" breadList="All Line Items" />
            )
            }
            {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.create &&
              <div className="text-sm-end">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvass}
                >
                  Detach Line Items
                </Button> &nbsp;&nbsp;
                {(parsed.status === 'LineItemCreateByIO' || parsed.status === 'LineItemCreateByParent') &&
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleDrawer(true, '', parsed.lineItemType)}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Line Item
                  </Button>
                }
                {(!parsed.status) &&
                  <div className="btn-group">
                    {UserInventoryType !== 'video' ?
                      <Dropdown
                        isOpen={btnprimary1}
                        toggle={() => setBtnprimary1(!btnprimary1)}
                      >
                        <DropdownToggle tag="button" className="btn btn-primary">
                          <i className="mdi mdi-plus-circle-outline me-1" />Add Line Item&nbsp; <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem header>Standard</DropdownItem>
                          <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard Banner</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact Settings</DropdownItem>
                          <DropdownItem divider />
                          {UserInventoryType === 'both' &&
                                    <>
                                    <DropdownItem header>Video</DropdownItem>
                                    <DropdownItem onClick={toggleDrawer(true, '', 'video')}>Player</DropdownItem>
                                    <DropdownItem onClick={toggleDrawer(true, '', 'vast')} disabled>Vast</DropdownItem>
                                  </>
                          }
                        </DropdownMenu>
                      </Dropdown> : <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={toggleDrawer(true, '', 'video')}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add Line Item
                      </Button>}
                  </div>
                }
              </div>
            }

          </div>
          <Card>
            <CardBody aria-hidden="true">
              <Row >
                <Col sm="4" className="mt-2">
                  <Label className="form-label">Ad Unit</Label>
                  <MultiSelect
                    options={adUnitData}
                    value={selectedAdUnit}
                    onChange={handleSelectAdUnit}
                    overrideStrings={{ "selectSomeItems": "Please Select Ad Unit " }}
                  />
                </Col>
                <Col sm="4" className="mt-2">
                  <Label className="form-label">Priority</Label>
                  <div className=" text-sm-end">
                    <Input type="select" className="form-select" value={prioritySearch} onChange={priorityChange}>
                      <option value=''> Select Priority</option>
                      <option value=''> All</option>
                      <option value='high' >High</option>
                      <option value='medium'> Medium</option>
                      <option value='low'> Low</option>
                    </Input>
                  </div>
                </Col>
                {UserInventoryType !== 'video' &&
                  <Col sm="4" className="mt-2">
                    <Label className="form-label">Line Item Type</Label>
                    <div className=" text-sm-end">
                      <Input type="select" className="form-select" value={lineItemTypeSearch} onChange={lineItemTypeChange}>
                        <option value=''> Select Line Item Type</option>
                        <option value=''> All</option>
                        <option value='stdb' >Standard Banner</option>
                        <option value='highImpact'> High Impact</option>
                        {UserInventoryType === 'both' &&
                          <option value='video'> Video</option>
                        }
                      </Input>
                    </div>
                  </Col>
                }

              </Row>
              <Row className="mt-2">
                <Col sm="4" className="mt-2">
                  <Label className="form-label">Status</Label>
                  <div className=" text-sm-end">
                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                      <option value=''> Select Status</option>
                      <option value=''> All</option>
                      <option value='Active' > Active</option>
                      <option value='Inactive'> InActive</option>
                      <option value='Archived'> Archived</option>
                    </Input>
                  </div>
                </Col>
                <Col sm="4" className="date-range mt-2">
                  <Label className="form-label">Date Range</Label> <br />
                  <DateRangePickers datePick={changeDatePick} />
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField={keyField}
                      data={lineItems}
                      columns={lineItemListColumns}
                      // bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="4"></Col>
                            <Col sm="4" className="date-range ">
                              <div className="text-sm-end">
                                <DateRangePickers datePick={changeDatePick} />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              {selectedRowsStatus && selectedIndex.length > 0 &&
                                <div className="row m-0 React-Table-Selection">
                                  {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                  <div style={{ padding: '0.5% 2%' }}>

                                    {/* <button color="primary" className="btn btn-primary " onClick={statusLineItem(true)}>Activate</button> */}
                                    {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.status &&
                                      <button color="primary" className="btn btn-primary " onClick={statusLineItem(true)}>
                                        Activate
                                      </button>
                                    }
                                    &nbsp;  &nbsp;
                                    {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.status &&
                                      <button color="primary" className="btn btn-primary " onClick={statusLineItem(false)}>
                                        DeActivate
                                      </button>
                                    }
                                    &nbsp;  &nbsp;
                                    {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.archive &&
                                      <Button color="primary" className="btn btn-primary " onClick={archiveLineItem} >
                                        Archive
                                      </Button>
                                    }
                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                      className="mdi mdi-close-thick font-size-18"
                                    ></i></Button>
                                  </div>
                                </div>
                              }
                            </Col>
                            <Col xl="12 " className="table-responsive">
                              <BootstrapTable
                                keyField={keyField}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                pagination={paginationFactory(pageOptions)}
                                noDataIndication={<h5 className="text-center">No Data</h5>}
                              // ref={node}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Line Item' : 'Add ' + lineItemType + ' Line Item '}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_LI closeCanvas={toggleDrawer(false, '', '')} selectedArchived={isArchived} selectedLineItem={selectedData} selectedLineItemType={lineItemType} selectedIO={(parsed.status === 'LineItemCreateByIO' || parsed.status === 'LineItemCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={isRights} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvass}>
          {'Detach Line Items'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <DetachLineItem closeCanvas={toggleDrawers(false, '')} />
        </OffcanvasBody>
      </Offcanvas>

    </React.Fragment >
  )
}

export default Line_Items












