import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";

import { connect } from "react-redux";
import { Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

// Reactstrap
import {
  Dropdown, DropdownToggle, DropdownMenu, Toast,
  ToastHeader,
  ToastBody,
  Carousel, CarouselItem, CarouselControl
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";


import ProfileImage from "pages/Dashboard/ProfileImage";

//i18n
import { withTranslation } from "react-i18next";

import {
  getNotRead_Notifications as onGetNotificationLists,
} from "store/Notifications/Publisher_Notification/actions";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import SwitchMenu from 'components/CommonForBoth/TopbarDropdown/switchMenu';

const items = [
  {
    id: 1,
    msg: "Notification 1",
    caption: "Slide 1",
    type: 'info',
    color: '#556EE6'
  },
  {
    id: 2,
    msg: "Notification 2",
    caption: "Slide 2",
    type: 'error',
    color: '#F46A6A'
  },
  {
    id: 3,
    msg: "Notification 3",
    caption: "Slide 3",
    type: 'warning',
    color: '#F1B44C'
  },
]

const Header = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [search, setsearch] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [animating, setAnimating] = useState(false)
  const notiStatus = localStorage.getItem('NotificationStatus');
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const parentRole = localStorage.getItem('ParentRole');
  const [toast, setToast] = useState(notiStatus === 'open' ? true : false);
  sessionStorage.setItem("escBtn", false);
  const linkRef = useRef();
  const [adminState, setAdminState] = useState(false)

  const { notificationLists } = useSelector(state => ({
    notificationLists: state.pub_notifications.notReadNotList,
  }));

  useEffect(() => {
    if (notificationLists !== null) {
      dispatch(onGetNotificationLists({ isRead: 'notRead' }));
    }
  }, []);

  if (notificationLists.length > 0) {
    notificationLists.map((ele, index) => {
      ele.color = ele.NotificationInfo === 'info' ? '#556EE6' : ele.NotificationInfo === 'success' ? '#34c38f' : ele.NotificationInfo === 'error' ? '#F46A6A' : '#F1B44C'
    })
  }

  if (userInfo) {
  } else {
    localStorage.removeItem('authUser');
    history.push('/login');
    // history.push('/page-maintenance');

  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      sessionStorage.setItem("escBtn", true);
    } return () => {
      sessionStorage.setItem('escBtn', false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  function toggleFullscreen() {
    // linkRef.current.click()
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  var body = document.body;
  function tToggle() {
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  useEffect(() => {
    if (notiStatus === 'open') {
      let className = document.querySelector(".page-content")
      if (className !== null) {
        document.querySelector(".page-content").classList.add('notification-Content');
        setToast(true);
      }
    }
  }, []);

  const toggleToast = () => {
    setToast(false);
    document.querySelector(".page-content").classList.remove('notification-Content');
    localStorage.setItem("NotificationStatus", 'close');
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex =
      activeIndex === notificationLists.length - 1
        ? 0
        : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0
        ? notificationLists.length - 1
        : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  useEffect(() => {
    if (activeIndex >= notificationLists.length) {
      setActiveIndex(0)
    }
  }, [activeIndex]);


  const goToIndex = (newIndex) => () => {
    if (animating) return
    this.setState({ activeIndex: newIndex })
  }

  function add3Dots(string, limit) {
    var dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  // console.log(activeIndex,notificationLists.length )

  const slides = notificationLists.map(item => {
    return (
      <CarouselItem
        onExiting={onExiting}
        onExited={onExited}
        key={item.id}
      >
        <div className='cNot-item text-white d-flex' > <span>{add3Dots(item.notificationName, 30)}</span> &nbsp; : &nbsp; <span dangerouslySetInnerHTML={{ __html: add3Dots(item.message, 30) }}></span></div>
      </CarouselItem>
    )
  })

  const toggleNotificationPanel = () => {
    setIsNotificationOpen(!isNotificationOpen)
    console.log("called")
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            {/* <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div> */}
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}


          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon align-middle me-1"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen align-middle me-1" />
              </button>
            </div>
            {

              /*<div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={() => {
                    toggleNotificationPanel();
                  }}
                  className="btn header-item noti-icon align-middle me-1"
                >
                  <i className={notificationLists.length > 0 ? 'bx bx-bell bx-tada' : 'bx bx-bell'} />
                  {notificationLists.length > 0 &&
                      <span className="badge bg-danger rounded-pill">{notificationLists.length}</span>
                  }
                </button>
              </div> */

            }
            <NotificationDropdown />
            {/* <NotificationDropdown /> */}
            {/* commented */}
            {userInfo && (userInfo.userRole === 'Root' || parentRole === 'Root' || userInfo.userRole === 'SuperAdmin' || (UserRolePrivileges && UserRolePrivileges.isSwitchManagementAccess)) &&
              <SwitchMenu />
            }
            &nbsp;&nbsp;
            <ProfileMenu />
            {/* {!adminState && (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') && (UserRolePrivileges && UserRolePrivileges.isAdminAccess) &&
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon align-middle me-1"
                >
                  <Link to="/Admin" className="btn header-item noti-icon align-middle me-1 mt-2">  <i className="bx bx-cog bx-spin" /></Link>
                </button>
              </div>
            } */}

            {/* &nbsp;&nbsp;
            <Dropdown
              className="dropdown-mega d-none d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            >
              <DropdownToggle
               className="btn header-item noti-icon right-bar-toggle "
                caret
                tag="button"
              >
                  <i className="bx bx-cog bx-spin" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  <Col sm={8}>
                    <Row>
                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Admin")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="/Network_Settings">{props.t("Network Settings")}</Link>
                          </li>
                          <li>
                            <Link to="/Access_Management">{props.t("Access management")}</Link>
                          </li>
                          <li>
                            <Link to="/Publisher_Access_Management">{props.t("Publisher Access management")}</Link>
                          </li>
                          <li>
                            <Link to="/Master_Log">{props.t("Master Log")}</Link>
                          </li>
                          <li>
                            <Link to="/Publisher_Log">{props.t("Publisher Log")}</Link>
                          </li>
                          <li>
                            <Link to="/Publisher_Approval">{props.t("Publisher Approval")}</Link>
                          </li>
                          <li>
                            <Link to="/Dashboard">{props.t("Dashboard")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Ads.txt Management")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="/Ads_Txt_Queries">{props.t("Ads.txt Queries")}</Link>
                          </li>
                          <li>
                            <Link to="/Ads_Txt_Entries">{props.t("Ads.txt Entries")}</Link>
                          </li>
                          <li>
                            <Link to="/Publisher_Ads_Txt_Entries">{props.t("Publisher Ads.txt Entries")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Extra Pages")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Light Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Compact Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Horizontal layout")}</Link>
                          </li>
                          <li>
                            <Link to="#"> {props.t("Maintenance")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Coming Soon")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Timeline")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("FAQs")}</Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={6}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Admin")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <img
                            src={megamenuImg}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown> */}


            {/* <div
               onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}



          </div>
        </div>
        {(userInfo.userRole === 'Partner' || userInfo.userRole === 'Root') && notificationLists.length > 0 && notiStatus === 'open' &&
             <Toast isOpen={toast} className=" mb-3" style={{ position: 'absolute', backgroundColor: (notificationLists[activeIndex] && notificationLists[activeIndex].color) ? notificationLists[activeIndex].color : 'inherit', width: '100%' }}>
             <ToastBody className="p-0" >
               <div className="row">
                 <div className="col-10">
                   <div className="notification" style={{ padding: '0.3pc 2pc' }}>
                     <Carousel
                       activeIndex={activeIndex}
                       next={next}
                       previous={previous}
                     >
                       <span style={{ margin: '0 0 0 0.5pc', padding: '1px 0.2%', color: 'white', width: '4pc', display: 'inline-block', textAlign: 'center' }}>{(activeIndex + 1) + "/" + notificationLists.length}</span>
                       {slides}
 
                       <CarouselControl
                         direction="prev"
                         directionText="Previous"
                         onClickHandler={previous}
                       />
 
                       <CarouselControl
                         direction="next"
                         directionText="Next"
                         onClickHandler={next}
                       />
 
 
                     </Carousel>
                   </div>
                 </div>
                 <div className="col-2">
                   <div className="text-sm-end">
                     <button
                       type="button"
                       onClick={toggleToast}
                       className="close"
                       data-dismiss="modal"
                       aria-label="Close"
                       style={{ position: 'relative', opacity: '1',margin:'-5px' }}
                     >
                       <span aria-hidden="true">&times;</span>
                     </button>
                   </div>
                 </div>
               </div>
             </ToastBody>
           </Toast>
        }
      </header>
      {/*<Offcanvas isOpen={isNotificationOpen} direction='start' scrollable >
          <OffcanvasHeader toggle={toggleNotificationPanel}>
                { 'Notifications'}
              </OffcanvasHeader>
              <OffcanvasBody className="p-0">
                <h1>checking</h1>
          </OffcanvasBody>
    </Offcanvas> */}

      <Link ref={linkRef} to="/Dashboard"></Link>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
