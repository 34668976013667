/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import moment from "moment";
import {
  Row, Col, Label, Form, FormGroup, Input, InputGroup, Button, FormFeedback
} from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { findIndex, indexOf } from 'lodash';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Flight_Selection = (props) => {

  var currentTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
  })

  console.log(currentTime, new Date())
  const [impressionState, setImpressionState] = useState(false)
  const [frequencyState, setFrequencyState] = useState(false)
  const [requestState, setRequestState] = useState(false)
  const [dayTimeState, setDayTimeState] = useState(false)
  const [timeList, setTimeList] = useState([])
  const [lineItem, setLineItem] = useState({
    startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
    priority: 'medium',
    requestCapping: {
      per: 'hour',
      capping: "0"
    },
    impressionCapping: {
      per: 'day',
      capping: "0",
    },
    frequencyCapping: {
      per: 'hour',
      capping: "0",
    },
    dayPartingsIn: [],
    dayPartingsEx: [],
    deliveryOption: "evenly",
    data: {
      isImmediate: false,
      isUnlimited: false
    },
  })
  const [frequencyData, setFrequencyData] = useState({
    capping: "0",
    per: 'hour'
  })
  const [impressionData, setImpressionData] = useState({
    capping: "0",
    per: 'day'
  })
  const [requestData, setRequestData] = useState({
    capping: "0",
    per: 'hour'
  })

  const [timeData, setTimeData] = useState({
    isImmediate: false,
    isUnlimited: false
  })

  const [periodState, setPeriodState] = useState(true)

  const convertDateZone = (date) => {
    const [datePart, timePart] = date.split(", ");
    const [month, day, year] = datePart.split("/");
    const [time, ampm] = timePart.split(" ");
    const [hours, minutes, seconds] = time.split(":");

    // Convert the time to 24-hour format if it's in AM/PM format
    let hour24 = parseInt(hours, 10);
    if (ampm === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (ampm === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    // Create a new Date object to extract individual components
    return new Date(year, month - 1, day, hour24, parseInt(minutes, 10), parseInt(seconds, 10));

  }

  useEffect(() => {
    if (props.lineItemData !== '') {
      if (props.lineItemData.dayPartingsIn.length > 0 || props.lineItemData.dayPartingsEx.length > 0) {
        setDayTimeState(true)
        props.cappingSelectedItems({ partition: true })
        if (props.lineItemData.dayPartingsIn.length > 0) {
          setPeriodState(true)
          props.lineItemData.dayPartingsIn.map((item, index) => {
            let excludeDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            item.weekSelected = (excludeDays.filter(e => !(item.weekDaysSelected).includes(e)))
            item.weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            item.excludedWeekDays = (excludeDays.filter(e => !(item.weekDaysSelected).includes(e)))
          });
          setTimeList(props.lineItemData.dayPartingsIn)
        } else {
          setPeriodState(false)
          props.lineItemData.dayPartingsEx.map((item, index) => {
            let excludeDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            item.weekSelected = (excludeDays.filter(e => !(item.weekDaysSelected).includes(e)))
            item.weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            item.excludedWeekDays = (excludeDays.filter(e => !(item.weekDaysSelected).includes(e)))
          });
          setTimeList(props.lineItemData.dayPartingsEx)
        }

      }
    } else {
      let newArr = [{
        startTime: '',
        endTime: '',
        weekDaysSelected: [],
        weekSelected: [],
        weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        excludedWeekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      }]
      setTimeList(newArr)
    }

  }, []);

  // console.log(props)

  const excludeDays = (day, index) => (e) => {
    let newArr2 = [...timeList];
    if(newArr2[index].weekDaysSelected.length < 7){
      newArr2[index].weekDaysSelected.push(day)
      let findIndex = newArr2[index].excludedWeekDays.findIndex(obj => obj === day)
      newArr2[index].excludedWeekDays.splice(findIndex, 1)
      let newArr = [...newArr2[index].excludedWeekDays]
      newArr2[index].weekSelected = newArr;
      setTimeList(newArr2)
      let newArr3 = newArr2.map(({ weekDays, excludedWeekDays, weekSelected, ...rest }) => ({ ...rest }));
      periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: newArr3 })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: newArr3 }))
      periodState ? props.selectedItems({ dayPartingsIn: newArr3 }) : props.selectedItems({ dayPartingsEx: newArr3 })
    }
  }

  const includeDays = (day, index) => (e) => {
    let newArr2 = [...timeList];
    newArr2[index].excludedWeekDays.push(day)
    let findIndex = newArr2[index].weekDaysSelected.findIndex(obj => obj === day)
    newArr2[index].weekDaysSelected.splice(findIndex, 1)
    let newArr = [...newArr2[index].excludedWeekDays]
    newArr2[index].weekSelected = newArr;
    setTimeList(newArr2)
    let newArr3 = newArr2.map(({ weekDays, excludedWeekDays, weekSelected, ...rest }) => ({ ...rest }));
    periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: newArr3 })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: newArr3 }))
    periodState ? props.selectedItems({ dayPartingsIn: newArr3 }) : props.selectedItems({ dayPartingsEx: newArr3 })
  }

  const changeStartTime = (index) => (e) => {
    let newArr2 = [...timeList];
    newArr2[index].startTime = moment(e[0]).format('HH:mm')
    setTimeList(newArr2)
    let newArr3 = newArr2.map(({ weekDays, excludedWeekDays, weekSelected, ...rest }) => ({ ...rest }));
    periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: newArr3 })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: newArr3 }))
    periodState ? props.selectedItems({ dayPartingsIn: newArr3 }) : props.selectedItems({ dayPartingsEx: newArr3 })
  }

  const changeEndTime = (index) => (e) => {
    let newArr2 = [...timeList];
    newArr2[index].endTime = moment(e[0]).format('HH:mm')
    setTimeList(newArr2)
    let newArr3 = newArr2.map(({ weekDays, excludedWeekDays, weekSelected, ...rest }) => ({ ...rest }));
    periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: newArr3 })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: newArr3 }))
    periodState ? props.selectedItems({ dayPartingsIn: newArr3 }) : props.selectedItems({ dayPartingsEx: newArr3 })
  }

  const addTimePeriodSlots = (e) => {
    let newArr = [...timeList];
    newArr.push({
      startTime: '',
      endTime: '',
      weekDaysSelected: [],
      weekSelected: [],
      weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      excludedWeekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    })
    setTimeList(newArr)
  }

  const removeTimePeriodSlots = (index) => (e) => {
    let newArr = [...timeList];
    newArr.splice(index, 1)
    setTimeList(newArr)
    periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: newArr })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: newArr }))
    periodState ? props.selectedItems({ dayPartingsIn: newArr }) : props.selectedItems({ dayPartingsEx: newArr })
  }

  const periodStateChange = (e) => {
    setPeriodState(e.target.checked)
    var periods = []
    if (lineItem.dayPartingsIn.length > 0) {
      periods = lineItem.dayPartingsIn
    } else {
      periods = lineItem.dayPartingsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: periods, dayPartingsEx: [] }));
      props.selectedItems({ dayPartingsIn: periods, dayPartingsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: periods, dayPartingsIn: [] }));
      props.selectedItems({ dayPartingsEx: periods, dayPartingsIn: [] })
    }
  }

  useEffect(() => {
    if (props.lineItemData !== '') {
      setDate(date => ({ ...date, startDate: new Date(moment(props.lineItemData.startDate).format('YYYY-MM-DD HH:mm:ss')), endDate: new Date(moment(props.lineItemData.endDate).format('YYYY-MM-DD HH:mm:ss')) }));
      setLineItem(props.lineItemData)
      //vasu perNHour --> per
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: props.lineItemData.frequencyCapping.capping, per: props.lineItemData.frequencyCapping.per }));
      setImpressionData(impressionData => ({ ...impressionData, capping: props.lineItemData.impressionCapping.capping, per: props.lineItemData.impressionCapping.per }));
      if (props.lineItemData.data.isImmediate) {
        setTimeData(timeData => ({ ...timeData, isImmediate: props.lineItemData.data.isImmediate, isUnlimited: props.lineItemData.data.isUnlimited }));
      }
      setRequestData(requestData => ({ ...requestData, capping: props.lineItemData.requestCapping.capping, per: props.lineItemData.requestCapping.per }));
      if (props.lineItemData.priority === 'high' || props.lineItemData.impressionCapping.capping > "0") {
        setImpressionState(true)
        props.cappingSelectedItems({ impression: true })
      } else {
        setImpressionState(false)
      }
      if (props.lineItemData.frequencyCapping.capping > "0") {
        setFrequencyState(true)
        props.cappingSelectedItems({ frequency: true })
      } else {
        setFrequencyState(false)
      }
    }
  }, []);

  const [date, setDate] = useState({
    startDate: convertDateZone(currentTime),
    endDate: convertDateZone(currentTime),
  })

  const changePriority = e => {
    const { name, value } = e.target;
    if (value === 'high') {
      setImpressionState(true)
      props.cappingSelectedItems({ impression: true })
    } else {
      if (impressionData.capping > '0') {
        setImpressionState(true)
        props.cappingSelectedItems({ impression: true })
      } else {
        setImpressionState(false)
        props.cappingSelectedItems({ impression: false })
      }
    }
    setLineItem(lineItem => ({ ...lineItem, priority: e.target.value }))
    props.selectedItems({ priority: e.target.value })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLineItem(lineItem => ({ ...lineItem, [name]: value }));
    props.selectedItems({ [name]: value })
  }

  const impressionStateChange = e => {
    setImpressionState(e.target.checked)
    props.cappingSelectedItems({ impression: e.target.checked })
    if (e.target.checked === false) {
      setImpressionData(impressionData => ({ ...impressionData, capping: "0" }));
      impressionValue = {
        ...impressionData, capping: "0"
      }
      setLineItem(lineItem => ({ ...lineItem, impressionCapping: impressionValue }));
      props.selectedItems({ impressionCapping: impressionValue })
    } else {
      setImpressionData(impressionData => ({ ...impressionData, capping: "" }));
      impressionValue = {
        ...impressionData, capping: ""
      }
    }
  }

  const frequencyStateChange = e => {
    setFrequencyState(e.target.checked)
    props.cappingSelectedItems({ frequency: e.target.checked })
    if (e.target.checked === false) {
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: "0" }));
      frequencyValue = {
        ...frequencyData, capping: "0"
      }
      setLineItem(lineItem => ({ ...lineItem, frequencyCapping: frequencyValue }));
      props.selectedItems({ frequencyCapping: frequencyValue })
    } else {
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: "" }));
      frequencyValue = {
        ...frequencyData, capping: ""
      }
    }
  }

  const requestStateChange = e => {
    setRequestState(e.target.checked)
    props.cappingSelectedItems({ request: e.target.checked })
    if (e.target.checked === false) {
      setRequestData(requestData => ({ ...requestData, capping: "0" }));
      requestValue = {
        ...requestData, capping: "0"
      }
      setLineItem(lineItem => ({ ...lineItem, requestCapping: requestValue }));
      props.selectedItems({ requestCapping: requestValue })
    } else {
      setRequestData(requestData => ({ ...requestData, capping: "" }));
      requestValue = {
        ...requestData, capping: ""
      }
    }
  }

  const blurChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'capping') {
      if (e.target.value) {
        if (e.target.value < "0") {
          setFrequencyData(frequencyData => ({ ...frequencyData, [name]: "0" }));
          frequencyValue = {
            ...frequencyData, [name]: "0"
          }
        } else {
          setFrequencyData(frequencyData => ({ ...frequencyData, [name]: value }));
          frequencyValue = {
            ...frequencyData, [name]: value
          }
        }
      } else {
        setFrequencyData(frequencyData => ({ ...frequencyData, [name]: "0" }));
        frequencyValue = {
          ...frequencyData, [name]: "0"
        }
      }

      setLineItem(lineItem => ({ ...lineItem, frequencyCapping: frequencyValue }));
      props.selectedItems({ frequencyCapping: frequencyValue })
    }
  }

  const blurChangeImpression = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'capping') {
      if (e.target.value) {
        if (e.target.value < "0") {
          setImpressionData(impressionData => ({ ...impressionData, [name]: "0" }));
          impressionValue = {
            ...impressionData, [name]: "0"
          }
        } else {
          setImpressionData(impressionData => ({ ...impressionData, [name]: value }));
          impressionValue = {
            ...impressionData, [name]: value
          }
        }
      } else {
        setImpressionData(impressionData => ({ ...impressionData, [name]: "0" }));
        impressionValue = {
          ...impressionData, [name]: "0"
        }
      }
      setLineItem(lineItem => ({ ...lineItem, impressionCapping: impressionValue }));
      props.selectedItems({ impressionCapping: impressionValue })
    }
  }


  const blurChangeRequest = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'capping' || e.target.name === 'per') {
      if (e.target.value) {
        if (e.target.value < "0") {
          setRequestData(requestData => ({ ...requestData, [name]: "0" }));
          requestValue = {
            ...requestData, [name]: "0"
          }
        } else {
          setRequestData(requestData => ({ ...requestData, [name]: value }));
          requestValue = {
            ...requestData, [name]: value
          }
        }
      } else {
        setRequestData(requestData => ({ ...requestData, [name]: "0" }));
        requestValue = {
          ...requestData, [name]: "0"
        }
      }
      setLineItem(lineItem => ({ ...lineItem, requestCapping: requestValue }));
      props.selectedItems({ requestCapping: requestValue })
    }
  }


  var frequencyValue = {}

  const changeFrequencyCapping = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFrequencyData(frequencyData => ({ ...frequencyData, [name]: value }));
      frequencyValue = {
        ...frequencyData, [name]: value
      }
      setLineItem(lineItem => ({ ...lineItem, frequencyCapping: frequencyValue }));
      props.selectedItems({ frequencyCapping: frequencyValue })
    } else {
      setFrequencyData(frequencyData => ({ ...frequencyData, [name]: '' }));
    }
  }

  var impressionValue = {}

  const changeImpressionCapping = (e) => {
    const { name, value } = e.target;
    console.log("----------------", name, value)
    if (value) {
      setImpressionData(impressionData => ({ ...impressionData, [name]: value }));
      impressionValue = {
        ...impressionData, [name]: value
      }
      setLineItem(lineItem => ({ ...lineItem, impressionCapping: impressionValue }));
      props.selectedItems({ impressionCapping: impressionValue })
    }
    else {
      setImpressionData(impressionData => ({ ...impressionData, [name]: '' }));
    }
  }

  const changeImpressionPer = (e) => {
    setImpressionData(impressionData => ({ ...impressionData, per: e.target.value }));
    impressionValue = {
      ...impressionData, per: e.target.value
    }
    setLineItem(lineItem => ({ ...lineItem, impressionCapping: impressionValue }));
    props.selectedItems({ impressionCapping: impressionValue })
  }

  const changeFrequencyPer = (e) => {
    setFrequencyData(frequencyData => ({ ...frequencyData, per: e.target.value }));
    frequencyValue = {
      ...frequencyData, per: e.target.value
    }
    setLineItem(lineItem => ({ ...lineItem, frequencyCapping: frequencyValue }));
    props.selectedItems({ frequencyCapping: frequencyValue })
  }

  const changeDelivery = (e) => {
    setLineItem(lineItem => ({ ...lineItem, deliveryOption: e.target.value }));
    props.selectedItems({ deliveryOption: e.target.value })
  }

  var requestValue = {}

  const changeRequestCapping = (e) => {
    const { name, value } = e.target;
    if (value) {
      setRequestData(requestData => ({ ...requestData, [name]: value }));
      requestValue = {
        ...requestData, [name]: value
      }
      setLineItem(lineItem => ({ ...lineItem, requestCapping: requestValue }));
      props.selectedItems({ requestCapping: requestValue })
    }
    else {
      setRequestData(requestData => ({ ...requestData, [name]: '' }));
    }
  }

  const startDateChange = (e) => {
    console.log(e)
    setDate(date => ({
      ...date, startDate: e
    }));
    setLineItem(lineItem => ({
      ...lineItem, startDate: moment(e).format('YYYY-MM-DD HH:mm:ss')
    }));
    props.selectedItems({
      startDate: moment(e).format('YYYY-MM-DD HH:mm:ss')
    })
  }

  var timeDataValue = {}

  const handleCheck = (event) => {
    if (event.target.name === 'isImmediate' && event.target.checked === true) {
      setLineItem(lineItem => ({ ...lineItem, startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') }));
      props.selectedItems({ startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') })
    } else if (event.target.name === 'isUnlimited' && event.target.checked === true) {
      setLineItem(lineItem => ({ ...lineItem, endDate: moment(currentTime).add(100, 'years').format('YYYY-MM-DD HH:mm:ss') }));
      setDate(date => ({ ...date, endDate: new Date(moment(currentTime).add(100, 'years').format('YYYY-MM-DD HH:mm'))}));
      props.selectedItems({ endDate: moment(currentTime).add(100, 'years').format('YYYY-MM-DD HH:mm:ss') })
    } else if (event.target.name === 'isUnlimited' && event.target.checked === false) {
      setLineItem(lineItem => ({ ...lineItem, endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') }));
      setDate(date => ({ ...date, endDate: convertDateZone(currentTime)}));
      props.selectedItems({ endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') })
    } else {
      setLineItem(lineItem => ({ ...lineItem, startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') }));
      setLineItem(lineItem => ({ ...lineItem, endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') }));
      props.selectedItems({ startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') })
      props.selectedItems({ endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss') })
    }
    setTimeData(timeData => ({ ...timeData, [event.target.name]: event.target.checked }));
    timeDataValue = {
      ...timeData, [event.target.name]: event.target.checked
    }
    setLineItem(lineItem => ({ ...lineItem, data: timeDataValue }));
    props.selectedItems({ data: timeDataValue })
  };

  const dateCheck = (e) => {
    setDayTimeState(e.target.checked)
    if (!e.target.checked) {
      periodState ? setLineItem(lineItem => ({ ...lineItem, dayPartingsIn: [] })) : setLineItem(lineItem => ({ ...lineItem, dayPartingsEx: [] }))
      periodState ? props.selectedItems({ dayPartingsIn: [] }) : props.selectedItems({ dayPartingsEx: [] })
      setTimeList([])
      props.cappingSelectedItems({ partition: false })
    } else {
      props.cappingSelectedItems({ partition: true })
    }
  }

  const endDateChange = (e) => {
    setDate(date => ({ ...date, endDate: e }));
    setLineItem(lineItem => ({
      ...lineItem, endDate: moment(e).format('YYYY-MM-DD HH:mm:ss')
    }));
    props.selectedItems({
      endDate: moment(e).format('YYYY-MM-DD HH:mm:ss')
    })
  }
  // onClick={weekSelected.includes(item) ? includeDays(item) : excludeDays(item) }
  const createUI = () => {
    // return weekDays.map((item, i) => (
    //   <li className="" key={i} onClick={(weekSelected.length > 0 ? weekSelected.includes(item) ? excludeDays(item) : includeDays(item) : excludeDays(item))}
    //     style={{
    //       listStyle: 'none', padding: '1.5% 1%', backgroundColor: (weekSelected.length > 0 ? weekSelected.includes(item) ? '#8ebaf866' : '#556ee6' : '#8ebaf866'), borderRadius: '40%',
    //       margin: '2px 5px', width: '4pc', textAlign: 'center', cursor: 'pointer', color: (weekSelected.length > 0 ? weekSelected.includes(item) ? '' : 'white' : ''),
    //     }}>{item}</li>
    // ))
  }

  const createTimeDayUI = () => {
    return timeList.map((item, index) =>
      <div key={index}>
        {/* <div style={{ disvideo: 'flex' }}>
          {item.weekSelected.length > 0 &&
            <span style={{ fontWeight: '500' }}>{item.weekSelected.join(",") + ' : '}</span>
          }
          {item.weekSelected.length > 0 &&
            'Running all Days'
          }
        </div> */}
        <div className='dayTime mt-2' style={{ padding: '2% 4%', backgroundColor: '#f1f8ff', width: '42.5pc' }}>
          <Row>
            <Col lg="5">
              <FormGroup className="mb-3">
                <Label>Start Time</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    value={item.startTime}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true
                    }}
                    onChange={changeStartTime(index)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="mdi mdi-clock-outline" />
                    </span>
                  </div>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="5">
              <FormGroup className="mb-3">
                <Label>End Time</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    value={item.endTime}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true
                    }}
                    onChange={changeEndTime(index)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="mdi mdi-clock-outline" />
                    </span>
                  </div>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="2">
              <div className=" text-sm-end">
                <button type="button" className="btn-close text-sm-end" aria-label="Close" onClick={removeTimePeriodSlots(index)}></button>
              </div>
            </Col>
          </Row>
          <Row>
            <Label>Repeating on</Label>
            <ul style={{ display: 'flex' }}>
            {(item.weekDaysSelected.length > 0 && item.weekDaysSelected.length === 7) &&
                  
                    item.weekDays.map((elem, i) => (
                      <li className="" key={i} onClick={(item.weekDaysSelected.length > 0 ? item.weekDaysSelected.includes(elem) ? includeDays(elem, index) : excludeDays(elem, index) : includeDays(elem, index) )}
                        style={{
                          listStyle: 'none', padding: '1.5% 1%', backgroundColor: (item.weekDaysSelected.length > 0 ? item.weekDaysSelected.includes(elem) ? '#556ee6' : '#8ebaf866' : '#556ee6'), borderRadius: '40%',
                          margin: '2px 5px', width: '4pc', textAlign: 'center', cursor: 'pointer', color: (item.weekDaysSelected.length > 0 ? item.weekDaysSelected.includes(elem) ? 'white' : '' : ''),
                        }}>{elem}</li>
                    ))
                  }
              {item.weekDaysSelected.length !== 7 &&
                item.weekDays.map((elem, i) => (
                  <li className="" key={i} onClick={(item.weekSelected.length > 0 ? item.weekSelected.includes(elem) ? excludeDays(elem, index) : includeDays(elem, index) : excludeDays(elem, index))}
                    style={{
                      listStyle: 'none', padding: '1.5% 1%', backgroundColor: (item.weekSelected.length > 0 ? item.weekSelected.includes(elem) ? '#8ebaf866' : '#556ee6' : '#8ebaf866'), borderRadius: '40%',
                      margin: '2px 5px', width: '4pc', textAlign: 'center', cursor: 'pointer', color: (item.weekSelected.length > 0 ? item.weekSelected.includes(elem) ? '' : 'white' : ''),
                    }}>{elem}</li>
                ))
              }
            </ul>
          </Row>
        </div>
      </div>
    )
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const [startDate, setStartDate] = useState(new Date());

  return (

    <div>
      <Row>
        <Col lg="6">
          <div className="mb-1">
            <Label >
              Start Date &nbsp;&nbsp;&nbsp; <i className='bx bxs-info-circle font-size-12' id="time-title" ></i>
            </Label>

            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input p-0 mt-2 mb-2" checked={lineItem.data.isImmediate} disabled={props.selectedArchived} onChange={handleCheck} name="isImmediate" />
              <span className="col-9  m-2">Immediately</span>
            </div>
            {/* <input
              className="form-control"
              type="datetime-local"
              // min={moment(currentTime).format('YYYY-MM-DD HH:mm')}
              value={date.startDate}
              onChange={startDateChange}
              disabled={lineItem.data.isImmediate || props.selectedArchived}
              readOnly={props.selectedArchived}
            /> */}
            <DatePicker
              selected={date.startDate}
              onChange={startDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="time"
              dateFormat="MMMM d, yyyy HH:mm"
              // value={moment(date.startDate.toLocaleString('en-US', {
              //   timeZone: 'America/New_York',
              // }),).format('YYYY-MM-DD HH:mm')}
              minDate={convertDateZone(currentTime)}
              className="form-control"
              disabled={lineItem.data.isImmediate || props.selectedArchived}
              readOnly={props.selectedArchived}
            />
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-1">
            <Label >
              End Date &nbsp;&nbsp;&nbsp; <i className='bx bxs-info-circle font-size-12' id="time-title2" ></i>
            </Label>
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-2 mb-2" checked={lineItem.data.isUnlimited} disabled={props.selectedArchived} onChange={handleCheck} name="isUnlimited" />
              <span className="col-9 m-2">Unlimited</span>
            </div>
            {/* <input
              className="form-control"
              type="datetime-local"
              // min={moment(currentTime).format('YYYY-MM-DD HH:mm')}
              value={date.endDate}
              onChange={endDateChange}
              disabled={lineItem.data.isUnlimited || props.selectedArchived}
              readOnly={props.selectedArchived}
            /> */}

            <DatePicker
              selected={date.endDate}
              onChange={endDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="time"
              dateFormat="MMMM d, yyyy HH:mm"
              // value={moment(date.endDate.toLocaleString('en-US', {
              //   timeZone: 'America/New_York',
              // }),).format('YYYY-MM-DD HH:mm')}
              minDate={convertDateZone(currentTime)}
              className="form-control"
              disabled={lineItem.data.isUnlimited || props.selectedArchived}
              readOnly={props.selectedArchived}
            />
          </div>
        </Col>
      </Row>
      <ReactTooltip
        anchorId="time-title"
        place="right"
        variant="info"
        content="All Dates and Times are in EST TimeZone"
      />
      <ReactTooltip
        anchorId="time-title2"
        place="right"
        variant="info"
        content="All Dates and Times are in EST TimeZone"
      />
      <div className="form-group d-flex mt-1">
        <input type="checkbox" id="setDaysTimes" className="form-check-input  p-0 mt-2 mb-2" checked={dayTimeState} disabled={props.selectedArchived} onChange={dateCheck} name="dayTime" />
        <Label className="col-9 m-2"    htmlFor="setDaysTimes">
                  Set Days and Times
            </Label>
      </div>
      {dayTimeState &&
        <div className="row">
          <div className="col-10">
            {createTimeDayUI()}
            <Button color="link" className="btn  mt-2" onClick={addTimePeriodSlots} disabled={props.selectedArchived}>
              ADD TIME PERIOD
            </Button>
          </div>
          <div className="col-2">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={periodState}
                onChange={periodStateChange}
                disabled={props.selectedArchived}
              />
              <label className="form-check-label">
                {periodState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
              </label>
            </div>
          </div>
        </div>
      }

      <Row>
        <Col lg="6">
          <div className="mb-3 mt-3">
            <Label className="d-block mb-3">
              Priority Level
            </Label>
            <div className="form-check form-check-inline">
              <Input
                type="radio"
                id="customRadioInline1"
                name="customRadioInline1"
                className="form-check-input"
                value="high"
                checked={lineItem.priority === "high"}
                onChange={changePriority}
                disabled={props.selectedArchived}
              />
              <Label
                className="form-check-label"
                htmlFor="customRadioInline1"
              >
                High
              </Label>
            </div>
            &nbsp;
            <div className="form-check form-check-inline">
              <Input
                type="radio"
                id="customRadioInline2"
                name="customRadioInline1"
                className="form-check-input"
                value="medium"
                checked={lineItem.priority === "medium"}
                onChange={changePriority}
                disabled={props.selectedArchived}
              />
              <Label
                className="form-check-label"
                htmlFor="customRadioInline2"
              >
                Medium
              </Label>
            </div>
            &nbsp;
            <div className="form-check form-check-inline">
              <Input
                type="radio"
                id="customRadioInline3"
                name="customRadioInline1"
                className="form-check-input"
                value="low"
                checked={lineItem.priority === "low"}
                onChange={changePriority}
                disabled={props.selectedArchived}
              />
              <Label
                className="form-check-label"
                htmlFor="customRadioInline3"
              >
                Low
              </Label>
            </div>
          </div>
          <div className='mb-3'>
            <Label >Delivery Option</Label>
            {lineItem.priority === "medium" || lineItem.priority === "low" ? <Input type="select" className="form-select" value={lineItem.deliveryOption} disabled>
              <option value='evenly'> Evenly</option>
            </Input> : <Input type="select" className="form-select" value={lineItem.deliveryOption} onChange={changeDelivery} disabled={props.selectedArchived}>
              <option value='evenly'> Evenly</option>
              <option value='asap'> ASAP</option>
            </Input>}
          </div>
        </Col>
      </Row>
      <Row>
        <div className="mb-3">
          <Label >Impression Capping : </Label>
          <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
              checked={impressionState}
              onChange={impressionStateChange}
              disabled={props.selectedArchived}
            />
            <label className="form-check-label">
              {impressionState ? <span color='text-success' style={{ color: '#34c38f' }}>On</span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
            </label>
          </div>
          <div className='row'>
            <div className='col-8'>
              <Label >Capping </Label>
              <Input
                type="text" name="capping"
                value={impressionData.capping} onBlur={blurChangeImpression} onKeyPress={allowOnlyNumbers} onChange={changeImpressionCapping}
                placeholder="Enter Impression Capping"
                disabled={!impressionState}
                readOnly={props.selectedArchived}
                className={'form-control' + (props.submitted && lineItem.priority === 'high' && impressionData.capping < "0" ? ' is-invalid' : '')}
              />
              {props.submitted && lineItem.priority === 'high' && impressionData.capping < "0" ? (
                <FormFeedback type="invalid">{'Capping is required'}</FormFeedback>
              ) : null}
            </div>
            <div className='col-4'>
              <Label >Per</Label>
              <Input type="select" className="form-select" value={impressionData.per} onChange={changeImpressionPer} readOnly={props.selectedArchived} disabled={!impressionState}>
                <option value='day'> Day</option>
                <option value='hour'> Hour</option>
                <option value='total'> Total</option>
              </Input>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label >Frequency Capping : </Label>
          <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
              checked={frequencyState}
              onChange={frequencyStateChange}
              disabled={props.selectedArchived}
            />
            <label className="form-check-label">
              {frequencyState ? <span color='text-success' style={{ color: '#34c38f' }}>On</span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
            </label>
          </div>
          <div className='row'>
            <div className='col-8'>
              <Label >Capping </Label>
              <Input
                type="text" name="capping"
                value={frequencyData.capping} onBlur={blurChange} onKeyPress={allowOnlyNumbers} onChange={changeFrequencyCapping}
                placeholder="Enter Frequency Capping"
                className="form-control"
                disabled={!frequencyState}
                readOnly={props.selectedArchived}
              />
            </div>
            <div className='col-4'>
              <Label >Per</Label>
              <Input type="select" className="form-select" value={frequencyData.per} onChange={changeFrequencyPer} readOnly={props.selectedArchived} disabled={!frequencyState}>
                <option value='hour'> Hour</option>
                <option value='minute'> Minute</option>
                <option value='day'> Day</option>
              </Input>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        {(props.lineItemType === 'video' || props.lineItemData.lineItemType === 'video') &&
          <div className="mb-3">
            <Label >Request Capping : </Label>
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
              checked={requestState}
              onChange={requestStateChange}
              disabled={props.selectedArchived}
            />
            <label className="form-check-label">
              {requestState ? <span color='text-success' style={{ color: '#34c38f' }}>On</span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
            </label>
          </div>
            <div className='row'>
              <div className='col-8'>
                <Label >Capping </Label>
                <Input
                  type="text" name="capping"
                  value={requestData.capping} onBlur={blurChangeRequest} onKeyPress={allowOnlyNumbers} onChange={changeRequestCapping}
                  placeholder="Enter Request Capping"
                  className="form-control"
                  disabled={!requestState}
                  readOnly={props.selectedArchived}
                />
              </div>
              <div className='col-4'>
                <Label >Per </Label>
                <Input type="select" className="form-select" value={requestData.per} onChange={changeRequestCapping} readOnly={props.selectedArchived} disabled={!requestState}>
                <option value='hour'> Hour</option>
                <option value='minute'> Minute</option>
                <option value='day'> Day</option>
              </Input>
              </div>
            </div>
          </div>
        }
      </Row>
    </div>
  )
}

export default Flight_Selection