import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

var Headers =  {
  "Content-Type": "application/json",
  Accept: "application/json",
}

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);


export async function authPost(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, {headers: Headers,data: {}})
    .then(response => response.data);
}

// export async function authPost(url, data, config = {}) {
//     const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json',  Accept: "application/json" },
//       body: JSON.stringify({...data})
//   };
//   fetch('http://127.0.0.1:2005/test', requestOptions)
//       .then(response => response.json())
//       .then(data => console.log(data));
// }
