import {
    LOAD_AGENCY_SCHEDULE_REPORTS,
    NO_DATA_AGENCY_SCHEDULE_REPORTS,
    GET_AGENCY_SCHEDULE_REPORTS,
    GET_AGENCY_SCHEDULE_REPORTS_FAIL,
    GET_AGENCY_SCHEDULE_REPORTS_SUCCESS,
    ADD_AGENCY_SCHEDULE_REPORT,
    ADD_AGENCY_SCHEDULE_REPORT_SUCCESS,
    ADD_AGENCY_SCHEDULE_REPORT_ERROR,
    UPDATE_AGENCY_SCHEDULE_REPORT,
    UPDATE_AGENCY_SCHEDULE_REPORT_SUCCESS,
    UPDATE_AGENCY_SCHEDULE_REPORT_ERROR,
    READ_AGENCY_SCHEDULE_REPORT,
    READ_AGENCY_SCHEDULE_REPORT_SUCCESS,
    STATUS_AGENCY_SCHEDULE_REPORTS,
    STATUS_AGENCY_SCHEDULE_REPORT_SUCCESS,
    ARCHIVE_AGENCY_SCHEDULE_REPORT ,
    ARCHIVE_AGENCY_SCHEDULE_REPORT_SUCCESS

  } from "./actionTypes"

  export const loadAgencyScheduleReports = Params => ({
    type: LOAD_AGENCY_SCHEDULE_REPORTS,
    payload:Params
  })

  export const noAgencyScheduleReports = Params => ({
    type: NO_DATA_AGENCY_SCHEDULE_REPORTS,
    payload:Params
  })

  export const getAgencyScheduleReports = Params => ({
    type: GET_AGENCY_SCHEDULE_REPORTS,
    payload:Params
  })
  
  export const getAgencyScheduleReportsSuccess = AgencyScheduleReports => ({
    type: GET_AGENCY_SCHEDULE_REPORTS_SUCCESS,
    payload: AgencyScheduleReports,
  })

  export const getAgencyScheduleReportsFail = error => ({
    type: GET_AGENCY_SCHEDULE_REPORTS_FAIL,
    payload: error,
  })

  export const addNewAgencyScheduleReport = AgencyScheduleReport => ({
    type: ADD_AGENCY_SCHEDULE_REPORT,
    payload: AgencyScheduleReport,
  })
  
  export const addAgencyScheduleReportSuccess = scheduleReport => ({
    type: ADD_AGENCY_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })
  
  export const addAgencyScheduleReportFail = scheduleReport => ({
    type: ADD_AGENCY_SCHEDULE_REPORT_ERROR,
    payload: scheduleReport,
  })

  export const updateAgencyScheduleReport = scheduleReport => ({
    type: UPDATE_AGENCY_SCHEDULE_REPORT,
    payload: scheduleReport,
  })
  
  export const updateAgencyScheduleReportSuccess = scheduleReport => ({
    type: UPDATE_AGENCY_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })
  
  export const updateAgencyScheduleReportFail = scheduleReport => ({
    type: UPDATE_AGENCY_SCHEDULE_REPORT_ERROR,
    payload: scheduleReport,
  })

  export const readAgencyScheduleReport = scheduleReport => ({
    type: READ_AGENCY_SCHEDULE_REPORT,
    payload: scheduleReport,
  })

  export const readAgencyScheduleReportSuccess = scheduleReport => ({
    type: READ_AGENCY_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })

  export const statusAgencyScheduleReports = scheduleReport => ({
    type: STATUS_AGENCY_SCHEDULE_REPORTS,
    payload: scheduleReport,
  })

  export const statusAgencyScheduleReportSuccess = scheduleReport => ({
    type: STATUS_AGENCY_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })

  export const archiveAgencyScheduleReport = scheduleReport => ({
    type: ARCHIVE_AGENCY_SCHEDULE_REPORT,
    payload: scheduleReport,
  })

  export const archiveAgencyScheduleReportSuccess = scheduleReport => ({
    type: ARCHIVE_AGENCY_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })