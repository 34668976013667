/* eslint-disable react/prop-types */
import React, { useState, useEffect,useRef } from 'react';
import {
  Row, Col, Label, Form,
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

//redux
import { useSelector, useDispatch } from "react-redux";

const Contextual_Targeting = (props) => {

  const [lineItem, setLineItem] = useState({
    domainsIn: [],
    domainsEx: [],
    languagesIn: [],
    languagesEx: [],
    categoriesIn: [],
    categoriesEx: [],
    pageURLsIn: [],
    pageURLsEx: [],
    interestsIn: [],
    interestsEx: [],
    keywordsIn: [],
    keywordsEx: [],
    brandAffinitiesIn: [],
    brandAffinitiesEx: [],
  })

  const [targetStates, setTargetStates] = useState({
    domains: false,
    languages: false,
    categories: false,
    pageURLs: false,
    interests: false,
    keywords: false,
    brandAffinities: false,
  })

  const [domainData, setDomainData] = useState([])
  const [languageData, seLanguageData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [pageUrlData, setPageUrlData] = useState([])
  const [interestData, setInterestData] = useState([])
  const [keywordData, setKeywordData] = useState([])
  const [brandAffinityData, setBrandAffinityData] = useState([])
  const [selectedPageURL, setSelectedPageURL] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [selectedBrandAffinity, setSelectedBrandAffinity] = useState([]);

  const [domainState, setDomainState] = useState(true)
  const [languagesState, setLanguagesState] = useState(true)
  const [categoryState, setCategoryState] = useState(true)
  const [pageUrlState, setPageUrlState] = useState(true)
  const [interestsState, setInterestsState] = useState(true)
  const [keywordsState, setKeywordsState] = useState(true)
  const [brandAffinityState, setBrandAffinityState] = useState(true)

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [interestTreeSelect, setInterestTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [keywordTreeSelect, setKeywordTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [brandTreeSelect, setBrandTreeSelect] = useState({
    checked: [],
    expanded: [],
  })

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isInterestOptionsOpen, setIsInterestOptionsOpen] = useState(false);
  const [isKeywordOptionsOpen, setIsKeywordOptionsOpen] = useState(false);
  const [isBrandOptionsOpen, setIsBrandOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const interestToggleOptions = () => {
    setIsInterestOptionsOpen(!isInterestOptionsOpen);
  };

  const keywordToggleOptions = () => {
    setIsKeywordOptionsOpen(!isKeywordOptionsOpen);
  };

  const brandToggleOptions = () => {
    setIsBrandOptionsOpen(!isBrandOptionsOpen);
  };

  useEffect(() => {
    if (props.tabId !== 4) {
      setIsOptionsOpen(false);
      setIsInterestOptionsOpen(false);
      setIsKeywordOptionsOpen(false);
      setIsBrandOptionsOpen(false);
    }
  }, [props.tabId])

  useEffect(() => {
    if (props.lineItemData !== '') {
      setLineItem(props.lineItemData)
      setSelectedDomain(props.lineItemData.domainsIn.length > 0 ? objectModals(props.lineItemData.domainsIn) : props.lineItemData.domainsEx.length > 0 ? objectModals(props.lineItemData.domainsEx) : [])
      setSelectedLanguage(props.lineItemData.languagesIn.length > 0 ? objectModals(props.lineItemData.languagesIn) : props.lineItemData.languagesEx.length > 0 ? objectModals(props.lineItemData.languagesEx) : [])
      setSelectedCategory(props.lineItemData.categoriesIn.length > 0 ? objectModals(props.lineItemData.categoriesIn) : props.lineItemData.categoriesEx.length > 0 ? objectModals(props.lineItemData.categoriesEx) : [])
      setSelectedPageURL(props.lineItemData.pageURLsIn.length > 0 ? objectModals(props.lineItemData.pageURLsIn) : props.lineItemData.pageURLsEx.length > 0 ? objectModals(props.lineItemData.pageURLsEx) : [])
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.lineItemData.pageURLsIn.length > 0 ? props.lineItemData.pageURLsIn : props.lineItemData.pageURLsEx.length > 0 ? props.lineItemData.pageURLsEx : [] }))
      setInterestTreeSelect(interestTreeSelect => ({ ...interestTreeSelect, checked: props.lineItemData.interestsIn.length > 0 ? props.lineItemData.interestsIn : props.lineItemData.interestsEx.length > 0 ? props.lineItemData.interestsEx : [] }))
      setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: props.lineItemData.keywordsIn.length > 0 ? props.lineItemData.keywordsIn : props.lineItemData.keywordsEx.length > 0 ? props.lineItemData.keywordsEx : [] }))
      setBrandTreeSelect(brandTreeSelect => ({ ...brandTreeSelect, checked: props.lineItemData.brandAffinitiesIn.length > 0 ? props.lineItemData.brandAffinitiesIn : props.lineItemData.brandAffinitiesEx.length > 0 ? props.lineItemData.brandAffinitiesEx : [] }));
      setSelectedInterest(props.lineItemData.interestsIn.length > 0 ? objectModals(props.lineItemData.interestsIn) : props.lineItemData.interestsEx.length > 0 ? objectModals(props.lineItemData.interestsEx) : [])
      setSelectedKeyword(props.lineItemData.keywordsIn.length > 0 ? objectModals(props.lineItemData.keywordsIn) : props.lineItemData.keywordsEx.length > 0 ? objectModals(props.lineItemData.keywordsEx) : [])
      setSelectedBrandAffinity(props.lineItemData.brandAffinitiesIn.length > 0 ? objectModals(props.lineItemData.brandAffinitiesIn) : props.lineItemData.brandAffinitiesEx.length > 0 ? objectModals(props.lineItemData.brandAffinitiesEx) : [])

      setDomainState(props.lineItemData.domainsIn.length > 0 ? true : props.lineItemData.domainsEx.length > 0 ? false : true)
      setLanguagesState(props.lineItemData.languagesIn.length > 0 ? true : props.lineItemData.languagesEx.length > 0 ? false : true)
      setCategoryState(props.lineItemData.categoriesIn.length > 0 ? true : props.lineItemData.categoriesEx.length > 0 ? false : true)
      setPageUrlState(props.lineItemData.pageURLsIn.length > 0 ? true : props.lineItemData.pageURLsEx.length > 0 ? false : true)
      setInterestsState(props.lineItemData.interestsIn.length > 0 ? true : props.lineItemData.interestsEx.length > 0 ? false : true)
      setKeywordsState(props.lineItemData.keywordsIn.length > 0 ? true : props.lineItemData.keywordsEx.length > 0 ? false : true)
      setBrandAffinityState(props.lineItemData.brandAffinitiesIn.length > 0 ? true : props.lineItemData.brandAffinitiesEx.length > 0 ? false : true)


      setTargetStates(targetStates => ({ ...targetStates, 
        domains: props.lineItemData.domainsIn.length > 0 || props.lineItemData.domainsEx.length > 0  ? true : false,
        languages: props.lineItemData.languagesIn.length > 0 || props.lineItemData.languagesEx.length > 0  ? true : false,
        categories: props.lineItemData.categoriesIn.length > 0 || props.lineItemData.categoriesEx.length > 0  ? true : false,
        pageURLs: props.lineItemData.pageURLsIn.length > 0 || props.lineItemData.pageURLsEx.length > 0  ? true : false,
        interests: props.lineItemData.interestsIn.length > 0 || props.lineItemData.interestsEx.length > 0  ? true : false,
        keywords: props.lineItemData.keywordsIn.length > 0 || props.lineItemData.keywordsEx.length > 0  ? true : false,
        brandAffinities: props.lineItemData.brandAffinitiesIn.length > 0 || props.lineItemData.brandAffinitiesEx.length > 0  ? true : false
      }));

      props.targetedSelectedItems({   domains: props.lineItemData.domainsIn.length > 0 || props.lineItemData.domainsEx.length > 0  ? true : false,
        languages: props.lineItemData.languagesIn.length > 0 || props.lineItemData.languagesEx.length > 0  ? true : false,
        categories: props.lineItemData.categoriesIn.length > 0 || props.lineItemData.categoriesEx.length > 0  ? true : false,
        pageURLs: props.lineItemData.pageURLsIn.length > 0 || props.lineItemData.pageURLsEx.length > 0  ? true : false,
        interests: props.lineItemData.interestsIn.length > 0 || props.lineItemData.interestsEx.length > 0  ? true : false,
        keywords: props.lineItemData.keywordsIn.length > 0 || props.lineItemData.keywordsEx.length > 0  ? true : false,
        brandAffinities: props.lineItemData.brandAffinitiesIn.length > 0 || props.lineItemData.brandAffinitiesEx.length > 0  ? true : false})
    }
  }, []);

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (props.contextualData.website && props.contextualData.website.length > 0) {
      setDomainData(objectModal(props.contextualData.website))
    }
    if (props.contextualData.language && props.contextualData.language.length > 0) {
      seLanguageData(objectModal(props.contextualData.language))
    }
    if (props.contextualData.category && props.contextualData.category.length > 0) {
      setCategoryData(objectModal(props.contextualData.category))
    }
    if (props.contextualData.pageUrlGroups && props.contextualData.pageUrlGroups.length > 0) {
      setPageUrlData(objectPageURLModal(props.contextualData.pageUrlGroups))
    }
    if (props.contextualData.interest && props.contextualData.interest.length > 0) {
      setInterestData(objectInterestModal(props.contextualData.interest))
    }
    if (props.contextualData.Keyword && props.contextualData.Keyword.length > 0) {
      setKeywordData(objectKeywordModal(props.contextualData.Keyword))
    }
    if (props.contextualData.brandAffinity && props.contextualData.brandAffinity.length > 0) {
      setBrandAffinityData(objectBrandAffinityModal(props.contextualData.brandAffinity))
    }
  }, [props.contextualData]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const objectPageURLModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.pageURLGroupName
      obj.label = item.pageURLGroupName
      obj.children = childModal(item.pageURLGroupName,item.pageURLGroupValues)
      dataList.push(obj)
    })
    return dataList
  }

  const objectInterestModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.interestGroup
      obj.label = item.interestGroup
      obj.children = childModal(item.interestGroup,item.interestList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectKeywordModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.keywordGroup
      obj.label = item.keywordGroup
      obj.children = childModal(item.keywordGroup,item.keywordList)
      dataList.push(obj)
    })
    return dataList
  }

  const childModal = (groupName,response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = groupName +"--"+ item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const objectBrandAffinityModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.brandAffinityGroup
      obj.label = item.brandAffinityGroup
      obj.children = childModal(item.brandAffinityGroup,item.brandAffinityList)
      dataList.push(obj)
    })
    return dataList
  }

  const handleSelectDomain = domain => {
    var domainsList = []
    setSelectedDomain(domain);
    domain.map((item, index) => {
      domainsList.push(item.value)
    })
    domainState ? setLineItem(lineItem => ({ ...lineItem, domainsIn: domainsList })) : setLineItem(lineItem => ({ ...lineItem, domainsEx: domainsList }))
    domainState ? props.selectedItems({ domainsIn: domainsList }) : props.selectedItems({ domainsEx: domainsList })
  }

  const handleSelectLanguage = language => {
    setSelectedLanguage(language);
    var languagesList = []
    language.map((item, index) => {
      languagesList.push(item.value)
    })
    languagesState ? setLineItem(lineItem => ({ ...lineItem, languagesIn: languagesList })) : setLineItem(lineItem => ({ ...lineItem, languagesEx: languagesList }))
    languagesState ? props.selectedItems({ languagesIn: languagesList }) : props.selectedItems({ languagesEx: languagesList })
  }

  const handleSelectCategory = category => {
    setSelectedCategory(category)
    var categoryList = []
    category.map((item, index) => {
      categoryList.push(item.value)
    })
    categoryState ? setLineItem(lineItem => ({ ...lineItem, categoriesIn: categoryList })) : setLineItem(lineItem => ({ ...lineItem, categoriesEx: categoryList }))
    categoryState ? props.selectedItems({ categoriesIn: categoryList }) : props.selectedItems({ categoriesEx: categoryList })
  }

  const handleSelectPageUrl = pageUrl => {
    setSelectedPageURL(pageUrl);
    var pageUrlsList = []
    pageUrl.map((item, index) => {
      pageUrlsList.push(item.value)
    })
    pageUrlState ? setLineItem(lineItem => ({ ...lineItem, pageURLsIn: pageUrlsList })) : setLineItem(lineItem => ({ ...lineItem, pageURLsEx: pageUrlsList }))
    pageUrlState ? props.selectedItems({ pageURLsIn: pageUrlsList }) : props.selectedItems({ pageURLsEx: pageUrlsList })
  }

  const handleSelectInterest = interest => {
    setSelectedInterest(interest);
    var interestsList = []
    interest.map((item, index) => {
      interestsList.push(item.value)
    })
    interestsState ? setLineItem(lineItem => ({ ...lineItem, interestsIn: interestsList })) : setLineItem(lineItem => ({ ...lineItem, interestsEx: interestsList }))
    interestsState ? props.selectedItems({ interestsIn: interestsList }) : props.selectedItems({ interestsEx: interestsList })
  }

  const handleSelectKeyword = keyword => {
    setSelectedKeyword(keyword);
    var keywordsList = []
    keyword.map((item, index) => {
      keywordsList.push(item.value)
    })
    keywordsState ? setLineItem(lineItem => ({ ...lineItem, keywordsIn: keywordsList })) : setLineItem(lineItem => ({ ...lineItem, keywordsEx: keywordsList }))
    keywordsState ? props.selectedItems({ keywordsIn: keywordsList }) : props.selectedItems({ keywordsEx: keywordsList })
  }

  const handleSelectBrandAffinity = brandAffinity => {
    setSelectedBrandAffinity(brandAffinity);
    var affinityList = []
    brandAffinity.map((item, index) => {
      affinityList.push(item.value)
    })
    brandAffinityState ? setLineItem(lineItem => ({ ...lineItem, brandAffinitiesIn: affinityList })) : setLineItem(lineItem => ({ ...lineItem, brandAffinitiesEx: affinityList }))
    brandAffinityState ? props.selectedItems({ brandAffinitiesIn: affinityList }) : props.selectedItems({ brandAffinitiesEx: affinityList })
  }

  const domainStateChange = e => {
    setDomainState(e.target.checked)
    var domains = []
    if (lineItem.domainsIn.length > 0) {
      domains = lineItem.domainsIn
    } else {
      domains = lineItem.domainsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, domainsIn: domains, domainsEx: [] }));
      props.selectedItems({ domainsIn: domains, domainsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, domainsEx: domains, domainsIn: [] }));
      props.selectedItems({ domainsEx: domains, domainsIn: [] })
    }
  }

  const languageStateChange = e => {
    setLanguagesState(e.target.checked)
    var languagess = []
    if (lineItem.languagesIn.length > 0) {
      languagess = lineItem.languagesIn
    } else {
      languagess = lineItem.languagesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, languagesIn: languagess, languagesEx: [] }));
      props.selectedItems({ languagesIn: languagess, languagesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, languagesEx: languagess, languagesIn: [] }));
      props.selectedItems({ languagesEx: languagess, languagesIn: [] })
    }
  }

  const categoryStateChange = e => {
    setCategoryState(e.target.checked)
    var categoriess = []
    if (lineItem.categoriesIn.length > 0) {
      categoriess = lineItem.categoriesIn
    } else {
      categoriess = lineItem.categoriesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, categoriesIn: categoriess, categoriesEx: [] }));
      props.selectedItems({ categoriesIn: categoriess, categoriesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, categoriesEx: categoriess, categoriesIn: [] }));
      props.selectedItems({ categoriesEx: categoriess, categoriesIn: [] })
    }
  }

  const pageUrlStateChange = e => {
    setPageUrlState(e.target.checked)
    var pageUrls = []
    if (lineItem.pageURLsIn.length > 0) {
      pageUrls = lineItem.pageURLsIn
    } else {
      pageUrls = lineItem.pageURLsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, pageURLsIn: pageUrls, pageURLsEx: [] }));
      props.selectedItems({ pageURLsIn: pageUrls, pageURLsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, pageURLsEx: pageUrls, pageURLsIn: [] }));
      props.selectedItems({ pageURLsEx: pageUrls, pageURLsIn: [] })
    }
  }

  const interestStateChange = e => {
    setInterestsState(e.target.checked)
    var interestss = []
    if (lineItem.interestsIn.length > 0) {
      interestss = lineItem.interestsIn
    } else {
      interestss = lineItem.interestsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, interestsIn: interestss, interestsEx: [] }));
      props.selectedItems({ interestsIn: interestss, interestsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, interestsEx: interestss, interestsIn: [] }));
      props.selectedItems({ interestsEx: interestss, interestsIn: [] })
    }
  }

  const keywordStateChange = e => {
    setKeywordsState(e.target.checked)
    var keywordss = []
    if (lineItem.keywordsIn.length > 0) {
      keywordss = lineItem.keywordsIn
    } else {
      keywordss = lineItem.keywordsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, keywordsIn: keywordss, keywordsEx: [] }));
      props.selectedItems({ keywordsIn: keywordss, keywordsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, keywordsEx: keywordss, keywordsIn: [] }));
      props.selectedItems({ keywordsEx: keywordss, keywordsIn: [] })
    }
  }

  const brandAffinityStateChange = e => {
    setBrandAffinityState(e.target.checked)
    var affinitiess = []
    if (lineItem.brandAffinitiesIn.length > 0) {
      affinitiess = lineItem.brandAffinitiesIn
    } else {
      affinitiess = lineItem.brandAffinitiesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, brandAffinitiesIn: affinitiess, brandAffinitiesEx: [] }));
      props.selectedItems({ brandAffinitiesIn: affinitiess, brandAffinitiesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, brandAffinitiesEx: affinitiess, brandAffinitiesIn: [] }));
      props.selectedItems({ brandAffinitiesEx: affinitiess, brandAffinitiesIn: [] })
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
      setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked  }));
      props.targetedSelectedItems({ [name]: e.target.checked})
      if(name === 'domains'){
        setSelectedDomain([])
        setLineItem(lineItem => ({ ...lineItem, domainsIn: [], domainsEx: [] }));
        props.selectedItems({ domainsIn: [], domainsEx: [] })
      }
      if(name === 'languages'){
        setSelectedLanguage([])
        setLineItem(lineItem => ({ ...lineItem, languagesEx: [], languagesIn: [] }));
        props.selectedItems({ languagesEx: [], languagesIn: [] })
      }
      if(name === 'categories'){
        setSelectedCategory([])
        setLineItem(lineItem => ({ ...lineItem, categoriesEx: [], categoriesIn: [] }));
        props.selectedItems({ categoriesEx: [], categoriesIn: [] })
      }
      if(name === 'pageURLs'){
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
        setLineItem(lineItem => ({ ...lineItem, pageURLsEx: [], pageURLsIn: [] }));
        props.selectedItems({ pageURLsEx: [], pageURLsIn: [] })
      }
      if(name === 'interests'){
        setInterestTreeSelect(interestTreeSelect => ({ ...interestTreeSelect, checked: [] }));
        setLineItem(lineItem => ({ ...lineItem, interestsEx: [], interestsIn: [] }));
        props.selectedItems({ interestsEx: [], interestsIn: [] })
      }
      if(name === 'keywords'){
        setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: [] }));
        setLineItem(lineItem => ({ ...lineItem, keywordsEx: [], keywordsIn: [] }));
        props.selectedItems({ keywordsEx: [], keywordsIn: [] })
      }
      if(name === 'brandAffinities'){
        setBrandTreeSelect(brandTreeSelect => ({ ...brandTreeSelect, checked: [] }));
        setLineItem(lineItem => ({ ...lineItem, brandAffinitiesEx: [], brandAffinitiesIn: [] }));
        props.selectedItems({ brandAffinitiesEx: [], brandAffinitiesIn: [] })
      }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });

  const myRef = useRef();
  const cityRef = useRef();
  const keyRef = useRef();
  const brandRef = useRef();

  const handleClickCityOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOptionsOpen(false)
    }
    if (!cityRef.current.contains(e.target)) {
      setIsInterestOptionsOpen(false)
    }
    if (!keyRef.current.contains(e.target)) {
      setIsKeywordOptionsOpen(false)
    }
    if (!brandRef.current.contains(e.target)) {
      setIsBrandOptionsOpen(false)
    }
  };

  return (
    <Form>
      <Row>
        <Col lg="6">
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="domains" id='domains' checked={targetStates.domains}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='domains'>Domain Targeting</Label>
            </div>

            <div className='row'>
              <MultiSelect
                options={domainData}
                value={selectedDomain}
                onChange={handleSelectDomain}
                overrideStrings={{ "selectSomeItems": "Please Select Domain" }}
                className="col-8"
                disabled={props.selectedArchived || !targetStates.domains}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={domainState}
                  onChange={domainStateChange}
                  disabled={props.selectedArchived || !targetStates.domains}
                />
                <label className="form-check-label">
                  {domainState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="languages" id='languages' checked={targetStates.languages}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='languages'>Language Targeting</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={languageData}
                value={selectedLanguage}
                onChange={handleSelectLanguage}
                overrideStrings={{ "selectSomeItems": "Please Select Languages" }}
                className="col-8"
                disabled={props.selectedArchived || !targetStates.languages}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={languagesState}
                  onChange={languageStateChange}
                  disabled={props.selectedArchived || !targetStates.languages}
                />
                <label className="form-check-label">
                  {languagesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="categories" id='categories' checked={targetStates.categories}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='categories'>Category Targeting</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={categoryData}
                value={selectedCategory}
                onChange={handleSelectCategory}
                overrideStrings={{ "selectSomeItems": "Please Select Categories " }}
                className="col-8"
                disabled={props.selectedArchived || !targetStates.categories}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={categoryState}
                  onChange={categoryStateChange}
                  disabled={props.selectedArchived || !targetStates.categories}
                />
                <label className="form-check-label">
                  {categoryState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="pageURLs" id='pageURLs' checked={targetStates.pageURLs}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='pageURLs'>Page URL Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-8' ref={myRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                  onClick={toggleOptions}
                  disabled={props.selectedArchived || !targetStates.pageURLs}
                >
                  {treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Page URLs are Selected"}</span> : "Please Select Page URLs"}
                </button>
                <ul className={`options ${isOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={pageUrlData}
                    checked={treeSelect.checked}
                    expanded={treeSelect.expanded}
                    onCheck={(checked, targetNode) => {
                      setTreeSelect(treeSelect => ({ ...treeSelect, checked: checked }));
                      pageUrlState ? setLineItem(lineItem => ({ ...lineItem, pageURLsIn: checked })) : setLineItem(lineItem => ({ ...lineItem, pageURLsEx: checked }))
                      pageUrlState ? props.selectedItems({ pageURLsIn: checked }) : props.selectedItems({ pageURLsEx: checked })

                      // if(targetNode.isParent){
                      //   pageUrlState ? setLineItem(lineItem => ({ ...lineItem, pageURLsIn:[...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] })) : setLineItem(lineItem => ({ ...lineItem, pageURLsEx: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   pageUrlState ? props.selectedItems({ pageURLsIn: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ pageURLsEx: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] })
                      // }else{
                      //   pageUrlState ? setLineItem(lineItem => ({ ...lineItem, pageURLsIn:[...lineItem.pageURLsIn,{group_name:targetNode.parent.value,group_values:checked}] })) : setLineItem(lineItem => ({ ...lineItem, pageURLsEx: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   pageUrlState ? props.selectedItems({ pageURLsIn: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ pageURLsEx: [...lineItem.pageURLsIn,{group_name:targetNode.value,group_values:checked}] })
                      // }
                    }}
                    onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={pageUrlState}
                  onChange={pageUrlStateChange}
                  disabled={props.selectedArchived || !targetStates.pageURLs}
                />
                <label className="form-check-label">
                  {pageUrlState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="interests" id='interests' checked={targetStates.interests}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='interests'>Interest Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-8' ref={cityRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isInterestOptionsOpen}
                  className={'selectionButton ' + (isInterestOptionsOpen ? "expanded" : "")}
                  onClick={interestToggleOptions}
                  disabled={props.selectedArchived || !targetStates.interests}
                >
                  {interestTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{interestTreeSelect.checked.length + " Interests are Selected"}</span> : "Please Select Interests"}
                </button>
                <ul className={`options ${isInterestOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={interestData}
                    checked={interestTreeSelect.checked}
                    expanded={interestTreeSelect.expanded}
                    onCheck={checked => {
                      setInterestTreeSelect(interestTreeSelect => ({ ...interestTreeSelect, checked: checked }));
                      interestsState ? setLineItem(lineItem => ({ ...lineItem, interestsIn: checked })) : setLineItem(lineItem => ({ ...lineItem, interestsEx: checked }))
                      interestsState ? props.selectedItems({ interestsIn: checked }) : props.selectedItems({ interestsEx: checked })
                    }}
                    onExpand={expanded => setInterestTreeSelect(interestTreeSelect => ({ ...interestTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={interestsState}
                  onChange={interestStateChange}
                  disabled={props.selectedArchived || !targetStates.interests}
                />
                <label className="form-check-label">
                  {interestsState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="keywords" id='keywords' checked={targetStates.keywords}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='keywords'>Keyword Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-8' ref={keyRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isKeywordOptionsOpen}
                  className={'selectionButton ' + (isKeywordOptionsOpen ? "expanded" : "")}
                  onClick={keywordToggleOptions}
                  disabled={props.selectedArchived || !targetStates.keywords}
                >
                  {keywordTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{keywordTreeSelect.checked.length + " Keywords are Selected"}</span> : "Please Select Keywords"}
                </button>
                <ul className={`options ${isKeywordOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={keywordData}
                    checked={keywordTreeSelect.checked}
                    expanded={keywordTreeSelect.expanded}
                    onCheck={checked => {
                      setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: checked }));
                      keywordsState ? setLineItem(lineItem => ({ ...lineItem, keywordsIn: checked })) : setLineItem(lineItem => ({ ...lineItem, keywordsEx: checked }))
                      keywordsState ? props.selectedItems({ keywordsIn: checked }) : props.selectedItems({ keywordsEx: checked })
                    }}
                    onExpand={expanded => setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={keywordsState}
                  onChange={keywordStateChange}
                  disabled={props.selectedArchived || !targetStates.keywords}
                />
                <label className="form-check-label">
                  {keywordsState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="brandAffinities" id='brandAffinities' checked={targetStates.brandAffinities}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='brandAffinities'>Brand Affinity Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-8' ref={brandRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isBrandOptionsOpen}
                  className={'selectionButton ' + (isBrandOptionsOpen ? "expanded" : "")}
                  onClick={brandToggleOptions}
                  disabled={props.selectedArchived || !targetStates.brandAffinities}
                >
                  {brandTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{brandTreeSelect.checked.length + " Brand Affinities are Selected"}</span> : "Please Select Brand Affinities"}
                </button>
                <ul className={`options ${isBrandOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={brandAffinityData}
                    checked={brandTreeSelect.checked}
                    expanded={brandTreeSelect.expanded}
                    onCheck={checked => {
                      setBrandTreeSelect(brandTreeSelect => ({ ...brandTreeSelect, checked: checked }));
                      brandAffinityState ? setLineItem(lineItem => ({ ...lineItem, brandAffinitiesIn: checked })) : setLineItem(lineItem => ({ ...lineItem, brandAffinitiesEx: checked }))
                      brandAffinityState ? props.selectedItems({ brandAffinitiesIn: checked }) : props.selectedItems({ brandAffinitiesEx: checked })
                    }}
                    onExpand={expanded => setBrandTreeSelect(brandTreeSelect => ({ ...brandTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={brandAffinityState}
                  onChange={brandAffinityStateChange}
                  disabled={props.selectedArchived || !targetStates.brandAffinities}
                />
                <label className="form-check-label">
                  {brandAffinityState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default Contextual_Targeting