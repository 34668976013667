import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_IPMAPS,ADD_IPMAP , UPDATE_IPMAP,READ_IPMAP,STATUS_IPMAP,ARCHIVE_IPMAP} from "./actionTypes"

import {
  loadIpMaps,
  noIpMaps,
  getIpMapsSuccess,
  getIpMapsFail,
  addIpMapSuccess,
  addIpMapFail,
  updateIpMapSuccess,
  updateIpMapFail,
  readIpMapSuccess,
  statusIpMapSuccess,
  archiveIpMapSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getIpMaps,createIpMap,updateIpMap,readIpMap,statusChangeIpMap,archiveIpMap } from "../../../helpers/Backend_helper"

function* fetchIpMaps() {
  try {
    yield put(loadIpMaps(true))
    const response = yield call(getIpMaps)
    console.log(response)
    response.responseData.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.responseData.response.length === 0){
    yield put(noIpMaps(true))
  }
    yield put(getIpMapsSuccess(response.responseData.response))
  } catch (error) {
    yield put(getIpMapsFail(error))
  }
}

function* onReadIpMap({ payload: iPMapping }) {
  try {
    const response = yield call(readIpMap, iPMapping)
    yield put(readIpMapSuccess(response))
  } catch (error) {
    yield put(readIpMapSuccess(error))
  }
}

function* onAddIpMap({ payload: iPMapping }) {
  try {
    const response = yield call(createIpMap, iPMapping)
    if(response.responseData.success){
      yield put(addIpMapSuccess(response.responseData))
    }else{
      yield put(addIpMapFail(response.responseData))
    }
  } catch (error) {
    yield put(addIpMapFail(error))
  }
}

function* onUpdateIpMap({ payload: iPMapping }) {
  try {
    const response = yield call(updateIpMap, iPMapping)
    if(response.responseData.success){
      yield put(updateIpMapSuccess(response.responseData))
    }else{
      yield put(updateIpMapFail(response.responseData))
    }
  } catch (error) {
    yield put(updateIpMapFail(error))
  }
}

function* onStatusIpMap({ payload: iPMapping }) {
  try {
    const response = yield call(statusChangeIpMap, iPMapping)
    if(response.success){
      yield put(statusIpMapSuccess(response))
    }else{
      yield put(statusIpMapSuccess(response.message))
    }
  } catch (error) {
    yield put(updateIpMapFail(error))
  }
}

function* onArchiveIpMap({ payload: iPMapping }) {
  try {
    const response = yield call(archiveIpMap, iPMapping)
    yield put(archiveIpMapSuccess(response))
  } catch (error) {
    yield put(archiveIpMapSuccess(error))
  }
}

function* iPMappingSaga() {
  yield takeEvery(GET_IPMAPS, fetchIpMaps)
  yield takeEvery(ADD_IPMAP, onAddIpMap)
  yield takeEvery(UPDATE_IPMAP, onUpdateIpMap)
  yield takeEvery(READ_IPMAP, onReadIpMap)
  yield takeEvery(STATUS_IPMAP, onStatusIpMap)
  yield takeEvery(ARCHIVE_IPMAP, onArchiveIpMap)
}

export default iPMappingSaga;
