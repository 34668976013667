/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,  Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdvertiser as onADDAdvertiser,
  updateAdvertiser as onUpdateAdvertiser,
  readAdvertiser as onReadAdvertiser,
  addAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserSuccess, 
  updateAdvertiserFail,
} from "store/Demand/Advertiser/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_Advertiser = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [advertiser, setAdvertiser] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  // console.log(props)

  useEffect(() => {
    if(props.selectedAdvertiser  !== '' ){
      setIsEdit(true)
      setAdvertiser(props.selectedAdvertiser)
    }
}, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      advertiserName: (advertiser && advertiser.advertiserName) || '',
    },
    validationSchema: Yup.object({
      advertiserName: Yup.string().required("Advertiser Name is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if(props.selectedAdvertiser  !== ''){
        dispatch(onUpdateAdvertiser({...values,advertiserID:props.selectedAdvertiser.advertiserID,advertiserName:values.advertiserName.trim(), userRole: userInfo.userRole, userID: userInfo.userID}));
      }else{
        dispatch(onADDAdvertiser({...values,advertiserName:values.advertiserName.trim(), userRole: userInfo.userRole, userID: userInfo.userID}));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.advertisers.errorMsg,
    successMsg: state.advertisers.successMsg,
  }));

    useEffect(() => {
    if(successMsg !== null){
       if(successMsg.success){
        dispatch(addAdvertiserSuccess(""));
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserSuccess(""));
        dispatch(updateAdvertiserFail(""));
        setLoader(false)
        if(nextTab){
          props.closeCanvas('')
          nextRef.current.click()
        }else{
          if(props.selectedAdvertiser  !== ''){
            props.closeCanvas('Update')
          }else{
            props.closeCanvas('Create')
          }
        }
       }
   }
   }, [ successMsg]);

   useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserFail(""));
        props.closeCanvas('')
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  return (
    <React.Fragment>
      {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row  style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Advertiser Name</Label>
                <Input
                  name="advertiserName"
                  type="text"
                  maxLength={65}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  value={validation.values.advertiserName || ""}
                  invalid={
                    validation.touched.advertiserName && validation.errors.advertiserName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.advertiserName && validation.errors.advertiserName ? (
                  <FormFeedback type="invalid">{validation.errors.advertiserName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
              &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit && 
                  <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Save
                </button>
              }
                  &nbsp;&nbsp;
              {!isEdit && 
                   <button
                   type="submit"
                   className="btn btn-primary save-user"
                   onClick={() => setNextTab(true)}
                 >
                   Save & Add IO Group
                 </button>
              }
          
              {isEdit && !props.selectedArchived &&
                 <button
                 type="submit"
                 className="btn btn-primary save-user"
               >
                Update
               </button>
              }
                   {nextTab && successMsg &&
                    <Link ref={nextRef} to={`/Insertion_Order_Group?advertiserId=${successMsg.data.advertiserID}&advertiserName=${successMsg.data.advertiserName}&status=IOGroupCreateByAdvertiser`}  > </Link>
                  }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
  
    </React.Fragment>
  );
};

export default withRouter(Create_Advertiser);

Create_Advertiser.propTypes = {
  history: PropTypes.object,
};