/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {
    Row, Col, Input, Label, Form, FormFeedback,
    Button, Modal, ModalBody, Offcanvas, OffcanvasBody, OffcanvasHeader

} from "reactstrap";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

//redux
import { useSelector, useDispatch } from "react-redux";

import { getPlaylist as onGetPlaylist } from 'store/Media/Playlist/actions'
import moment from "moment";
import Add_Playlist from 'pages/MediaBidsxchange/Video_Playlists/Add_Playlist';

var videoPlaylist = []
var selectedPlaylists = []
var videoContentList = []


const PlayerContent = (props) => {

    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [contentList, setContentList] = useState([])
    const [playlistData, setPlaylistsData] = useState([])
    const [videosList, setVideosList] = useState([])
    const [urlsState, setUrlsState] = useState(true)
    const [videoLoaded, setVideoLoaded] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [showAlert, setShowAlert] = useState(false);
    const [addPlaylistCanvas, setaddPlaylistCanvas] = useState(false)

    console.log(props)

    const toggleaddPlaylistCanvas = value => {
        setUrlsState(true)
        props.selectedItems({ type: 'videoContentSuite' })
        setShowAlert(false)
        setVideosList([])
        videoContentList = []
        let PCL = []
        PCL.push({
            id: 1,
            playListName: '',
            playListID:'',
            playListVideoData: [],
        })
        setContentList(PCL)
        props.selectedItems({ type: 'videoContentSuite' })
    }

    const toggleDrawer = () => {
        setaddPlaylistCanvas(false)
        setShowAlert(false)
        toggleCustom("2")
        videoContentList = []
        setVideosList([])
        // toastr.success('Video Playlist Created Successfully!...')
    }

    const hanldeModal = () => {
        setShowAlert(!showAlert)
    }

    const handleVideoLoadedData = () => {
        setVideoLoaded(true);
    }

    const urlStateChange = e => {
        setUrlsState(e.target.checked)
        if (e.target.checked) {
            let PCL = []
            PCL.push({
                id: 1,
                playListName: '',
                playListID:'',
                playListVideoData: [],
            })
            setContentList(PCL)
            props.selectedItems({ type: 'videoContentSuite' })
        } else {
            let VCL = []
            VCL.push({
                id: 1,
                videoName: '',
                videoURL: '',
                clickThroughURL: '',
                videoThumbnailURL: '',
                url: '',
                selectManually: false,
                selectUpload: true
            })
            setVideosList(VCL)
            props.selectedItems({ type: 'directUrl' })
        }
    }

    useEffect(async () => {
        if (props.selectedPlayer !== '') {
            console.log(props.selectedPlayer.playerSettingsContent.type)
            if (props.selectedPlayer.playerSettingsContent.type === 'directUrl') {
                setUrlsState(false)
            } else {
                setUrlsState(true)
            }

            if (props.selectedPlayer.playerSettingsContent.list.length > 0 && props.selectedPlayer.playerSettingsContent.type === 'directUrl') {
                props.selectedPlayer.playerSettingsContent.list.map((item, index) => {
                    item.id = index + 1
                    item.selectManually = false
                    item.selectUpload = true
                })
            }
            console.log(playlistData)
            videoContentList = props.selectedPlayer.playerSettingsContent.list
            console.log(videoContentList)
            setVideosList([...videoContentList])
        } else {
            // let VCL = []
            // VCL.push({
            //     id: 1,
            //     videoName: '',
            //     videoURL: '',
            //     clickThroughURL: '',
            //     videoThumbnailURL: '',
            //     url: '',
            //     selectManually: false,
            //     selectUpload: true
            // })

            // setVideosList(VCL)
            // videoContentList = VCL
            // let PCL = []
            // PCL.push({
            //     id: 1,
            //     playListName: '',
            //     playListID:'',
            //     playListVideoData: [],
            // })
            // setContentList(PCL)
            props.selectedItems({ type: 'videoContentSuite' })
        }
    }, [])

    const handleVideoTimeUpdate = (index) => {
        console.log("called handle video time update")
        const video = videoRef.current;
        const canvas = canvasRef.current;
        console.log(canvas)
        if (canvas !== null) {
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailUrl = canvas.toDataURL();


            canvas.toBlob((blob) => {
                // uploadSelectedFrame(blob,index);
            }, 'image/png', 0.5);
            console.log(thumbnailUrl);
            // setThumbnailUrl(thumbnailUrl);
            // videoContentList[index] = { ...videoContentList[index], videoThumbnailURL: `ex: url${index}.png` }
            if (thumbnailUrl.includes("data:image/png;base64,")) {
                var captureUrl = thumbnailUrl.replace("data:image/png;base64,", "");
            }
            videoContentList[index] = { ...videoContentList[index], url: captureUrl }
            setVideosList([...videoContentList])
        }

    }

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const addNewVideoContent = () => {
        videoPlaylist = [...contentList]
        videoPlaylist.push({
            id: videoPlaylist.length + 1,
            playListName: '',
            playListID:'',
            playListVideoData: [],
        })
        setContentList([...videoPlaylist])
        console.log(videoPlaylist)
    }

    const addDirectVideoContent = () => {
        if (videosList.length === 5) {
            setShowAlert(true)
        } else {
            videoContentList.push({
                id: videoContentList.length + 1,
                videoName: '',
                videoURL: '',
                clickThroughURL: '',
                videoThumbnailURL: '',
                url: '',
                selectManually: false,
                selectUpload: true
            })
            setVideosList([...videoContentList])
            console.log(videosList)
        }
    }

    const handleChange = (index) => e => {
        const { name, value } = e.target;
        console.log(name, value)
        console.log(playlistData)
        if (name === 'playListName') {
            let i = playlistData.findIndex(x => x.playListName === value)
            console.log(playlistData[i].playListVideoData)
            videoPlaylist[index] = { ...videoPlaylist[index], playListVideoData: playlistData[i].playListVideoData };
            videoPlaylist[index] = { ...videoPlaylist[index], [name]: value };
            videoPlaylist[index] = { ...videoPlaylist[index], playListID: playlistData[i].playListID };
            setContentList([...videoPlaylist])
            let dataList = []
            videoPlaylist.map((item, index) => {
                dataList.push(item.playListID)
              })
            selectedPlaylists =dataList
        }
        props.selectedItems({ list: selectedPlaylists })
    }

    useEffect(() => {
        if (videoPlaylist) {
            if (videoPlaylist.length < 1) {
                addNewVideoContent()
            }
        }
    }, [])

    useEffect(() => {
        if (videoContentList) {
            if (videoContentList.length < 1) {
                addDirectVideoContent()
            }
        }
    }, [])

    const { playlists } = useSelector(state => ({
        playlists: state.playlistsReducer.playlist
    }));

    useEffect(() => {
        if (playlists !== null) {
            dispatch(onGetPlaylist({ filter: '' }));
        }
    }, [addPlaylistCanvas]);

    console.log(playlists)

    useEffect(() => {
        if (playlists !== null && playlists.length > 0) {
            setPlaylistsData(playlists)
            if (props.selectedPlayer !== '') {
                if (props.selectedPlayer.playerSettingsContent.list.length > 0 && props.selectedPlayer.playerSettingsContent.type === 'videoContentSuite') {
                    let lists = []
                    props.selectedPlayer.playerSettingsContent.list.map((item, index) => {
                        if(item){
                            let i = playlists.findIndex(x => x.playListID === item)
                            lists.push(playlists[i])
                            setContentList([...lists])
                            selectedPlaylists.push(item)
                        }
                    })
                }
            }
        }
    }, [playlists]);

    const removeContent = (i) => e => {
        console.log(i)
        console.log(selectedPlaylists)
        console.log(videoPlaylist)

        contentList.splice(i, 1)
        selectedPlaylists.splice(i, 1)
        console.log(selectedPlaylists)
        console.log(videoPlaylist)
        setContentList([...contentList])
        props.selectedItems({ list: selectedPlaylists })
    }

    const videoListCoulumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            hidden: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Video Thumbnail",
            align: 'center',
            formatter: (cellContent, eachRow) => (
                <img src={eachRow.videoContentThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />
            )
        },
        {
            text: 'Video Name',
            dataField: 'videoContentName'
        },
        {
            text: 'Category',
            dataField: 'videoContentCategory'
        },
        {
            text: 'Language',
            dataField: 'videoContentLanguage'
        },
        {
            text: 'Duration',
            dataField: 'videoContentDuration',
            formatter: (cellContent, eachRow) => (
                <span className="mb-2">{moment.unix(eachRow.videoContentDuration).utc().format(' m [min] s [secs]')} </span>
            )
        }
    ]

    const keyField = "id";

    console.log(contentList)
    console.log(videosList)


    const createUI = () => {
        console.log(contentList)
        return contentList.length > 0 &&  contentList.map((item, i) =>
            <div key={i}>
            <div className="contentSettings">
                <h5 className='mt-3 mb-4'>Content {i + 1}</h5>
                <div className="row mt-1">
                    <div className="col-10">
                        <div className="form-group">
                            <Label className="form-label">Select Playlists</Label>
                            <Input
                                type="select"
                                className="form-select"
                                value={item.playListName}
                                onChange={handleChange(i)}
                                name="playListName"
                            >

                                <option value="" disabled >
                                    Select Playlists
                                </option>
                                {playlistData.length > 0 && playlistData.map((item) => (
                                    <option
                                        key={item.playListName}
                                        value={item.playListName}
                                    >
                                        {item.playListName}
                                    </option>
                                ))}
                            </Input>
                        </div>
                        <br />

                        {item.playListName !== '' &&
                            <Row>
                                <Col lg="12">

                                    <React.Fragment>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={keyField}
                                                        data={item.playListVideoData ? item.playListVideoData.length > 0 ? item.playListVideoData : [] : []}
                                                        columns={videoListCoulumns}
                                                        noDataIndication={<h5 className="text-center">No Video Data</h5>}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        }
                    </div>
                    <div className="col-2 p-0">
                        <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeContent(i)} disabled={contentList.length === 1}
                        >Remove Content</button>
                    </div>
                </div>
            </div>
            </div>
      
        )
    }

    const handleDirectChange = (index, videoData) => e => {
        const { name, value } = e.target;
        console.log(name, value)
        if (name === 'videoContentName') {
            videoContentList[index] = { ...videoContentList[index], videoName: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'videoURL') {
            videoContentList[index] = { ...videoContentList[index], videoURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'clickThroughURL') {
            videoContentList[index] = { ...videoContentList[index], clickThroughURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'videoThumbnailURL') {
            videoContentList[index] = { ...videoContentList[index], videoThumbnailURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === `selectUpload-${index}`) {
            videoContentList[index] = { ...videoContentList[index], selectUpload: e.target.checked, selectManually: !e.target.checked };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === `selectManually-${index}`) {
            videoContentList[index] = { ...videoContentList[index], selectManually: e.target.checked, selectUpload: !e.target.checked };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }

        console.log(videoData)
        console.log(videosList)
        props.selectedItems({ list: videoContentList })
        // if (name === 'videoUrl') {
        //     getDuration(value, index)
        // }
    }

    const fileChangeThumbnail = (index) => (file) => {
        console.log("called file upload in player settings", file, index)
        encodeFileBase64(file.target.files[0], index);
    }

    const encodeFileBase64 = (file, index) => {
        var reader = new FileReader();
        console.log(file, index)
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result
                if (base64.includes("data:image/png;base64,")) {
                    base64 = reader.result.replace("data:image/png;base64,", "");
                }
                else if (base64.includes("data:image/jpeg;base64,")) {
                    base64 = reader.result.replace("data:image/jpeg;base64,", "");
                } else if (base64.includes("data:image/gif;base64,")) {
                    base64 = reader.result.replace("data:image/gif;base64,", "");
                }
                console.log(base64)
                if (base64) {
                    videoContentList[index] = { ...videoContentList[index], url: base64 };
                    setVideosList([...videoContentList])
                    props.selectedItems({ list: videoContentList })
                    console.log(videoContentList)
                }
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }

    const removeDirectContent = (i) => e => {
        videoContentList.splice(i, 1)
        setVideosList([...videoContentList])
        props.selectedItems({ list: videoContentList })
    }

    const createContentUI = () => {
        return videosList.map((item, i) =>
            <div key={i}>
                <div className="contentSettings">
                    <h5 className='mt-3 mb-4'>Content {i + 1}</h5>
                    <div className="row mt-1">
                        <div className="col-10">
                            <div className="mb-2">
                                <Label className="form-label">Video Name</Label>
                                <Input
                                    name="videoContentName"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoName}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Video URL</Label>
                                <Input
                                    name="videoURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoURL}
                                    className={'form-control'}
                                />
                            </div>
                            <div className="mb-2">
                                <Label className="form-label">Video Thumbnail URL</Label>
                                <Input
                                    name="videoThumbnailURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoThumbnailURL}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                            {item.videoThumbnailURL === '' &&
                                <div className="mb-2">
                                    <Label className="form-label">Select Thumbnail</Label>
                                    <div className='row'>
                                        <Col md={3}>
                                            <div className="d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md" style={{ paddingLeft: "1rem" }}>
                                                <input
                                                    className="form-check-input m-1"
                                                    type="checkbox"
                                                    name={`selectUpload-${i}`}
                                                    id={`fileUpload${i}`}
                                                    checked={item.selectUpload}
                                                    onChange={handleDirectChange(i)}
                                                />
                                                <label className="form-check-label m-1" htmlFor={`fileUpload${i}`}> File Upload</label>

                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className='d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md' style={{ paddingLeft: "0rem" }}>
                                                <input
                                                    className="form-check-input m-1"
                                                    type="checkbox"
                                                    name={`selectManually-${i}`}
                                                    id={`fileManually${i}`}
                                                    checked={item.selectManually}
                                                    onChange={handleDirectChange(i)}
                                                />
                                                <label className="form-check-label m-1" htmlFor={`fileManually${i}`}> Select Manually  </label>
                                            </div>
                                        </Col>

                                    </div>
                                    {item.selectManually &&
                                        <Col sm="12">
                                            <p style={{ fontWeight: "500" }}>Choose Your Thumbnail: </p>
                                            <div className='d-flex justify-content-around align-items-start'>
                                                <div className='d-flex flex-column justify-content-start justify-conent-center'>


                                                    <video className='m-2' ref={videoRef} src={item.videoURL} controls width={400} height={270} crossOrigin="anonymous" onLoadedData={handleVideoLoadedData}>
                                                        {/* <source  type="video/mp4" /> */}
                                                    </video>
                                                    <Button className=' text-right m-3' onClick={() => handleVideoTimeUpdate(i)} disabled={!videoLoaded}>capture</Button>
                                                </div>
                                                <canvas ref={canvasRef} width={400} height={270} className="d-none" />
                                                {item.url ? <img className='single-frame m-1 ' src={item.url} crossOrigin="anonymous" alt="Video Thumbnail" /> : <div className='single-frame d-flex justify-content-center align-items-center' style={{ backgroundColor: "gray" }}> <h6>Capture dynamically which frame you want</h6></div>}
                                                {/* <Button className='m-3' onClick={uploadSelectedFrame} >Save Frame</Button> */}
                                                {/* {thumbnailUrl && <img className='single-frame' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" />} */}

                                            </div>
                                        </Col>
                                    }
                                    {item.selectUpload &&
                                        <Col sm="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Browse Thumbnail Image File</Label>
                                                <div>
                                                    <Input type="file" accept="image/x-png,image/jpg" onChange={fileChangeThumbnail(i)} />
                                                    <span className="notes">Note: Allowed file types: jpg, png. Max-size 5Mb</span>
                                                </div>
                                                {/* {submitted && !videoContent.videoContentThumbnailPath ? (
                   <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
               ) : null} */}
                                            </div>

                                        </Col>
                                    }

                                </div>
                            }

                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Click Through URL</Label>
                                <Input
                                    name="clickThroughURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.clickThroughURL}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                        </div>
                        <div className="col-2 p-0">
                            <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeDirectContent(i)} disabled={videoContentList.length === 1}
                            >Remove Content</button>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    return (
        <>
            <Col sm="12" xxl="8">
                <div className="player-content player-content2">
                    <div className="container-fluid d-flex">
                        <label className="form-check-label mb-3 " style={{ marginTop: '0.8pc' }}>
                            <span color='text-success' style={{ color: urlsState ? '#a3a3a3' : '#34c38f', marginTop: '' }}>Direct URL</span>&nbsp;&nbsp;
                        </label>
                        <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >

                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={urlsState}
                                onChange={urlStateChange}
                                disabled={props.selectedArchived}
                            />
                            <label className="form-check-label">
                                <span color='text-success' style={{ color: urlsState ? '#34c38f' : '#a3a3a3' }}>Video Content Suite</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    {!urlsState &&
                        <div className="player-content">
                            <div className="container-fluid">
                                {createContentUI()}
                                <div className='col-12'>
                                    <div className='d-flex'>
                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} >Add New Content &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {urlsState &&
                        <div className="player-content">
                            <div className="container-fluid">
                                {createUI()}
                                <div className='col-12'>
                                    <div className='d-flex'>
                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addNewVideoContent} >Add New Content &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Col>
            <Offcanvas isOpen={addPlaylistCanvas} direction='end'  >
                <OffcanvasHeader toggle={toggleaddPlaylistCanvas}>
                    {'Add Playlist'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Add_Playlist closeCanvas={toggleDrawer} />
                </OffcanvasBody>
            </Offcanvas>
            <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
                id="staticBackdrop"
            >
                <div className="modal-content">
                    <ModalBody className="px-2 py-4 text-center ">
                        <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
                        <h5 className="text-muted font-size-16 mb-4">  Would you like to add more videos, we recommend creating a playlist?</h5>
                        <div className="hstack gap-2 justify-content-center mb-0">
                            <button type="button" className="btn btn-light" onClick={() => hanldeModal()}>No</button>
                            <button type="button" className="btn btn-primary" onClick={toggleaddPlaylistCanvas} >Select Playlist from VC Suite</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    )
}

export default PlayerContent;

