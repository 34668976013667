import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import loginLogo from "../../assets/images/bidsxchange/Login_Screen.png";
import successIcon from "../../assets/images/bidsxchange/SuccessIcon.png";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col md={5}>
        <div className="auth-full-bg pt-lg-5 p-4" style={{background:"#00040E"}}>
          <div className="w-100" style={{ height: '90vh' }}>
            <div className="" style={{ background: '#b7d2ed', backgroundPosition: 'center' }}></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 " style={{ marginTop: '10%' }}>
                <div className="row justify-content-center">


                  <div className="text-center">
                  
                  {/*<img src={('../assets/images/bidsxchange/log.png')} />*/}
                    <h4 className="mb-3" style={{letterSpacing: '6px', color: '#ffffff'}}>BIDSXCHANGE</h4>
                    <div dir="ltr">
                      <img
                        src={loginLogo}
                        alt="loginLogo"
                        className="login-logo"
                        style={{ width: '25pc', marginTop: '5%', marginBottom: '2%' }}
                      />
                      
                      {/*<div className="text-center">
                        <h3>Bidsxchange</h3>
                        <div className="col-12">
                        <div className="row justify-content-center" >
                              <ul style={{ listStyle: 'none',width: '16pc', textAlign: 'left',left:'2pc' }}>
                                <li style={{ margin: '10px' }}>
                                  <img src={successIcon} alt="successIcon" className="success-icon" />&nbsp;Monetization Platform
                                </li>
                                <li style={{ margin: '10px' }}>
                                  <img src={successIcon} alt="successIcon" className="success-icon" />&nbsp;Display & Video Adserver
                                </li>
                                <li style={{ margin: '10px' }}>
                                  <img src={successIcon} alt="successIcon" className="success-icon" />&nbsp;High-Impact Ad Formats
                                </li>
                                <li style={{ margin: '10px' }}>
                                  <img src={successIcon} alt="successIcon" className="success-icon" />&nbsp;Multi-Lingual Video Content



                                </li>
                              </ul>
                          </div>
                        </div>
                    </div>*/}
                      {/*<Carousel showThumbs={false} className="slider_css">
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Fantastic theme with a ton of options. If
                                  you just want the HTML to integrate with your
                                  project, then this is the package. You can
                                  find the files in the &apos;dist&lsquo; folder...no need
                                  to install git and all the other stuff the
                                  documentation talks about. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  &quot;If Every Vendor on Envato are as supportive
                                  as Themesbrand, Development with be a nice
                                  experience. You guys are Wonderful. Keep us
                                  the good work. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>*/}
                    </div>
                  </div>


                  {/*<img
                        src={loginLogo}
                        alt="loginLogo"
                        className="login-logo"
                        style={{width: '12pc',marginTop: '25%',marginBottom: '10%'}}
                      />
                      <div className="text-center">
                        <h3>Bidsxchange</h3>
                     
                        <p><img src={successIcon} alt="successIcon" className="success-icon"/>&nbsp;Monetization Platform</p>
                        <p><img src={successIcon} alt="successIcon" className="success-icon"/>&nbsp;Incremental Revenue</p>
                        <p><img src={successIcon} alt="successIcon" className="success-icon"/>&nbsp;In-house Ad Formats</p>
                        <p><img src={successIcon} alt="successIcon" className="success-icon"/>&nbsp;Streamlyn AdServer</p>
                      </div>*/}

                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
