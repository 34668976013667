import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const userInfo = JSON.parse(localStorage.getItem('authUser'));
      const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
      if((window.location.pathname === "/login" || window.location.pathname === '/forgot-password') && localStorage.getItem("authUser")){
        return (
          <Redirect to="/Dashboard" />
        )
      }
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
          // <Redirect to="/page-maintenance" />
        )
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
