import {
    LOAD_ACTIVITIES,
    NO_DATA_HOUSE_AD_CREATIVES,
    GET_HOUSE_AD_CREATIVES_SUCCESS,
    GET_HOUSE_AD_CREATIVES_FAIL,
    ADD_HOUSE_AD_CREATIVE_SUCCESS,
    ADD_HOUSE_AD_CREATIVE_ERROR,
    UPDATE_HOUSE_AD_CREATIVE_SUCCESS,
    UPDATE_HOUSE_AD_CREATIVE_ERROR,
    READ_HOUSE_AD_CREATIVE_SUCCESS,
    STATUS_HOUSE_AD_CREATIVE_SUCCESS,
    ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    crtvs: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    creative: null,
    response:null,
    archiveMsg:null,
}

const creative = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_HOUSE_AD_CREATIVES:
                return {
                    ...state,
                    noData: true,
                }
        case GET_HOUSE_AD_CREATIVES_SUCCESS:
            return {
                ...state,
                loading:false,
                crtvs: action.payload,
            }

        case GET_HOUSE_AD_CREATIVES_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_HOUSE_AD_CREATIVE_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_HOUSE_AD_CREATIVE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HOUSE_AD_CREATIVES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HOUSE_AD_CREATIVE_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_HOUSE_AD_CREATIVES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HOUSE_AD_CREATIVE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HOUSE_AD_CREATIVES_SUCCESS' ? null : action.payload,
            }
        case READ_HOUSE_AD_CREATIVE_SUCCESS:
            return {
                ...state,
                creative: action.payload,
            }
        case STATUS_HOUSE_AD_CREATIVE_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default creative