import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row, Input,
    Button,
    Spinner,Label
} from "reactstrap"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AppBlock from "assets/files/Appblock.xlsx"
import DeviceBlock from "assets/files/deviceBlock.xlsx"
import DomainBlock from "assets/files/domainBlock.xlsx"
import UserAgentBlock from "assets/files/userAgentBlock.xlsx"
import IPV6Block from "assets/files/IPV6Block.xlsx"
import IPV4Block from "assets/files/IPV4block.xlsx"


var fileNameEntry = ''

function DataFeed(props) {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [videoContent, setVideoContent] = useState({
        videoName: '',
        videoUrl: '',
        thumbnail: '',
        duration: false,
        language: '',
        category: ''
    });
    const [loader, setLoader] = useState(false);
    const [publisherReport, setPublisherReport] = useState({
        fileName: '',
        dataFeedType:'deviceBlock',
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const ref = useRef();
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [downloadFileBtn, setDownloadFileBtn] = useState(false)
    const [downloadUrl,setDownloadUrl] = useState('')

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const dataFeedChange = (e) => {
        setPublisherReport(publisherReport => ({ ...publisherReport, dataFeedType: e.target.value }));
    }

    const fileChange = (file) => {
        console.log(file.target.files[0])
        console.log(file.target.files[0].name)
        fileName = file.target.files[0].name
        encodeFileBase64(file.target.files[0])
    };

    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result.replace("data:image/png;base64,", "");
                console.log(base64)
                if (base64) {
                    setVideoContent({ ...videoContent, videoUrl: base64 })
                }
                console.log(videoContent)
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }

    const fileChangepublisherReport = (file) => {
        setDownloadFileBtn(false)
        if (file.target.files[0].name) {
            setLoader(true)
        } else {
            setLoader(false)
        }
        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        fileNameEntry = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("data", newFile);

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

        });

        var newBaseData = toBase64(newFile);
        const newBaseContent = {};
        (async () => {
            newBaseContent.data = await newBaseData;
            newBaseContent.fileName = fileName;
            newBaseContent.type = fileType;
            newBaseContent.location = 'uploadedDataFeed';
            newBaseContent.userID = userInfo.userID;
            newBaseContent.userRole = userInfo.userRole;
            newBaseContent.companyID = userInfo.companyID;
            console.log(newBaseContent)
            const body = {
                "url": "http://127.0.0.1:3022/upload/uploadFile",
                "requestBody": newBaseContent
            }
            axios.post("/v1.0/connectOP", body, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data.responseData.success) {
                        setPublisherReport(publisherReport => ({ ...publisherReport, fileName: fileName }));
                    }
                    else {
                        ref.current.value = "";
                        toastr.error(res.data.responseData.message)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("e", err)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                });
        })();
    };

    const publisherReportUpload = (e) => {
        console.log(publisherReport)
        if (publisherReport.fileName) {
            setLoader(true)
            const bodyReport = {
                "url": "http://127.0.0.1:2002/v1.0/upload/dataFeed",
                "requestBody": publisherReport
            }
            axios.post("/v1.0/connectOP", bodyReport, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data.responseData) {
                        if (res.data.responseData.success) {
                            toastr.success('Data Uploaded Successfully..')
                            setInputKey(Math.random().toString(36))
                            fileNameEntry = ''
                            setPublisherReport(publisherReport => ({ ...publisherReport, dataFeedType: 'deviceBlock' }));

                        } else {
                            toastr.error(res.data.responseData.message)
                                if (res.data.responseData && res.data.responseData.DownloadfileName) {
                                    setDownloadFileBtn(true)
                                    setDownloadUrl(res.data.responseData.DownloadfileName)
                                }
                            setInputKey(Math.random().toString(36))
                            fileNameEntry = ''
                            setPublisherReport(publisherReport => ({ ...publisherReport, dataFeedType: 'deviceBlock' }));
                        }
                    } else {
                        toastr.error(res.data)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                        setPublisherReport(publisherReport => ({ ...publisherReport, dataFeedType: 'deviceBlock' }));
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                    setPublisherReport(publisherReport => ({ ...publisherReport, dataFeedType: 'deviceBlock' }));
                    console.log("e", err)
                    toastr.error('File Upload error')
                });
        }
    }

    var currentTimeStamp = Date.now()

    const downloadCSV = async (url, name) => {
            window.location.assign(url);
      };

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>Data Feed | Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Data Feed" breadcrumbItem="Data Feed" />
                        <div className="card card-body ">
                            <div className="row">
                                <div className="m-4 mt-0">
                                    {loader &&
                                        <Spinner className="ms-2 loader" color="primary" />
                                    }
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                        <div className="mb-3 mt-3">
                                            <Label>Data Feed Type</Label>
                                            <Input type="select" className="form-select" name="dataFeedType" value={publisherReport.dataFeedType} onChange={dataFeedChange}>
                                                <option value='deviceBlock' > Device </option>
                                                <option value='userAgentBlock'>User Agent </option>
                                                <option value='domainBlock'> Domain  </option>
                                                <option value='appBlock' > App </option>
                                                <option value='iPV6Block'> IPV6 </option>
                                                <option value='iPV4Block'> IPV4  </option>
                                            </Input>
                                        </div>
                                            <Card>
                                                <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                                     Network Uploads
                                                </CardTitle>
                                                <CardBody>
                                                    <Input className="form-control" type="file" key={inputKey || ''} id="formFile" ref={ref} onChange={fileChangepublisherReport} accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                                    <div className="col-12  ">
                                                        <div className="row justify-content-center mt-2 ">
                                                            <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={publisherReportUpload} disabled={fileNameEntry === ''}>Upload</Button> &nbsp;&nbsp;
                                                            {downloadFileBtn &&
                                                                <Button color="primary" className="font-16 btn-block btn btn-primary w-25"   onClick={() => downloadCSV(downloadUrl,'Error File-' + currentTimeStamp + '.csv')}>Download Error File</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr className="hr" />
                                                    <span className=" mt-3 mb-1 row justify-content-center">
                                                        Upload Data in Excel format (.xlsx file)
                                                    </span>
                                                    <Row className="justify-content-center text-center">
                                                        <Link to={publisherReport.dataFeedType === 'deviceBlock' ? DeviceBlock :  publisherReport.dataFeedType === 'userAgentBlock' ? UserAgentBlock : publisherReport.dataFeedType === 'domainBlock' ?
                                                         DomainBlock :  publisherReport.dataFeedType === 'appBlock' ? AppBlock : publisherReport.dataFeedType === 'iPV6Block' ? IPV6Block : IPV4Block} target="_blank" className=" color-dark-blue"
                                                          download={publisherReport.dataFeedType === 'deviceBlock' ? 'Device' :  publisherReport.dataFeedType === 'userAgentBlock' ? 'User Agent' : publisherReport.dataFeedType === 'domainBlock' ?
                                                          'Domain' :  publisherReport.dataFeedType === 'appBlock' ? 'App' : publisherReport.dataFeedType === 'iPV6Block' ? 'IPV6' : 'IPV4' + ".xlsx"} style={{ width: 'fit-content' }}>
                                                            Download Template for Data Upload
                                                        </Link>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            {/* </div> */}

        </React.Fragment >
    )
}

DataFeed.propTypes = {}

export default DataFeed
