import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logodark from "../../assets/images/favicons.png";
import CarouselPage from "pages/Authentication/CarouselPage";

const Register = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: '',
      email: '',
      country: '',
      companyName: '',
      companyWebsite: '',
      businessType: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      fullName: Yup.string().required("Please Enter Your Full Name"),
      country: Yup.string().required("Please Enter Country"),
      companyName: Yup.string().required("Please Enter Company Name"),
      companyWebsite: Yup.string().required("Please Enter Company Website"),
      businessType: Yup.string().required("Please Enter Business Name")
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values));
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Bidsxchange</title>
      </MetaTags>
      <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={7}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-50" style={{margin:"auto"}}>
                  <div className="d-flex flex-column h-100">
                  <div className="mb-1 mb-md-2">
                      <h4>
                      <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />&nbsp;&nbsp; </h4>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Register </h5>
                        <p className="text-muted">
                        Get your Bidsxchange account now.
                        </p>
                      </div>

                      <div className="mt-4">

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                             {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Full Name</Label>
                        <Input
                          name="fullName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Full Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fullName || ""}
                          invalid={
                            validation.touched.fullName && validation.errors.fullName ? true : false
                          }
                        />
                        {validation.touched.fullName && validation.errors.fullName ? (
                          <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Company Email</Label>
                        <Input
                          id="company_email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Company Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Company Name</Label>
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Enter Company Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            validation.touched.companyName && validation.errors.companyName ? true : false
                          }
                        />
                        {validation.touched.companyName && validation.errors.companyName ? (
                          <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Company Website</Label>
                        <Input
                          name="companyWebsite"
                          type="text"
                          placeholder="Enter Company Website"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyWebsite || ""}
                          invalid={
                            validation.touched.companyWebsite && validation.errors.companyWebsite ? true : false
                          }
                        />
                        {validation.touched.companyWebsite && validation.errors.companyWebsite ? (
                          <FormFeedback type="invalid">{validation.errors.companyWebsite}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Business Name</Label>
                        <Input
                          name="businessType"
                          type="text"
                          placeholder="Enter Business Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.businessType || ""}
                          invalid={
                            validation.touched.businessType && validation.errors.businessType ? true : false
                          }
                        />
                        {validation.touched.businessType && validation.errors.businessType ? (
                          <FormFeedback type="invalid">{validation.errors.businessType}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Country</Label>
                        <Input
                          name="country"
                          type="text"
                          placeholder="Country"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.country || ""}
                          invalid={
                            validation.touched.country && validation.errors.country ? true : false
                          }
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>

                          <div>
                          <p className="mb-0">
                          By registering you agree to the Bidsxchange<br></br>
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link> &nbsp;|&nbsp;
                          <Link to="#" className="text-primary">
                            Privacy Policy
                          </Link>
                        </p>
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Register
                            </button>
                          </div>

                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                          Already have an account ?
                            <Link to="login" className="font-weight-medium text-primary">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©
                        { new Date().getFullYear()} Bidsxchange
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <footer className="footer" style={{left:'0px'}}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>{new Date().getFullYear()} © Bidsxchange.</Col>
                  <Col md={6}>
                    <div className="text-sm-end d-none d-sm-block">
                  {/* Streamlyn */}
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>
            </Col>
          </Row>
 
        </Container>
    </React.Fragment>
  );
};

export default Register;
