/* CONTEXTUAL_DATA */
export const LOAD_CONTEXTUAL_DATAS = "LOAD_CONTEXTUAL_DATAS"
export const NO_DATA = "NO_DATA"
export const GET_CONTEXTUAL_DATAS = "GET_CONTEXTUAL_DATAS"
export const GET_CONTEXTUAL_DATAS_SUCCESS = "GET_CONTEXTUAL_DATAS_SUCCESS"
export const GET_CONTEXTUAL_DATAS_FAIL = "GET_CONTEXTUAL_DATAS_FAIL"

export const ADD_CONTEXTUAL_DATA = "ADD_CONTEXTUAL_DATA"
export const ADD_CONTEXTUAL_DATA_SUCCESS = "ADD_CONTEXTUAL_DATA_SUCCESS"
export const ADD_CONTEXTUAL_DATA_ERROR = "ADD_CONTEXTUAL_DATA_ERROR"

export const UPDATE_CONTEXTUAL_DATA = "UPDATE_CONTEXTUAL_DATA"
export const UPDATE_CONTEXTUAL_DATA_SUCCESS = "UPDATE_CONTEXTUAL_DATA_SUCCESS"
export const UPDATE_CONTEXTUAL_DATA_ERROR = "UPDATE_CONTEXTUAL_DATA_ERROR"

export const READ_CONTEXTUAL_DATA = "READ_CONTEXTUAL_DATA"
export const READ_CONTEXTUAL_DATA_SUCCESS = "READ_CONTEXTUAL_DATA_SUCCESS"

export const STATUS_CONTEXTUAL_DATA = "STATUS_CONTEXTUAL_DATA"
export const STATUS_CONTEXTUAL_DATA_SUCCESS = "STATUS_CONTEXTUAL_DATA_SUCCESS"

export const ARCHIVE_CONTEXTUAL_DATA = "ARCHIVE_CONTEXTUAL_DATA"
export const ARCHIVE_CONTEXTUAL_DATA_SUCCESS = "ARCHIVE_CONTEXTUAL_DATA_SUCCESS"

export const CALL_API = "CALL_API"
export const CALL_API_SUCCESS = "CALL_API_SUCCESS"