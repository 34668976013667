import React, { } from 'react';
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types'
import {Col, Container, Row, Input, Label, Form,Button} from "reactstrap"

const Video_Library = props => {
    return (
        <React.Fragment>
        {/* <Sidebar />
        <Header />
        <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Video Library| Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Admin" breadcrumbItem="Video Library" />
                </Container>
            </div>
        {/* </div> */}
    </React.Fragment >
      )
    }

Video_Library.propTypes = {}

export default Video_Library