import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  getWebsiteCategorizations as onGetWebsiteCategorizations,
  statusWebsiteCategorization as onStatusWebsiteCategorization,
  archiveWebsiteCategorization as onArchiveWebsiteCategorization,
  statusWebsiteCategorizationSuccess,
  archiveWebsiteCategorizationSuccess
} from "store/Contextual_Settings/WebsiteCategorization/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Web_Categorization from "./Create_Web_Categorization";
import LoadingTable from "pages/LoaderPages/LoadingTable";




var selectedItem = []
var selectedData = ''
var selectedIndex = []

const Web_Categorization = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [selected, setSelected] = useState([])

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelectedRowsStatus(true)
        selectedIndex.push(row.websiteID)
        selectedItem.push(row.websiteID)
        console.log(selectedIndex)
      } else {
        let index = selectedIndex.indexOf(row.websiteID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.websiteID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      console.log(isSelect);
      console.log(rows);
      console.log(e);
      if (isSelect) {
        rows.map((item, index) => {
          selectedIndex.push(item.websiteID)
          selectedItem.push(item.websiteID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { websiteCategorizations } = useSelector(state => ({
    websiteCategorizations: state.websiteCategorizations.websiteCategorizationList,
  }));

  const { loading } = useSelector(state => ({
    loading: state.websiteCategorizations.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.advertisers.noData,
  }));

  const { websiteCategorizationsResponse } = useSelector(state => ({
    websiteCategorizationsResponse: state.websiteCategorizations.response,
  }));

  const { archiveWebsiteCategorizationsResponse } = useSelector(state => ({
    archiveWebsiteCategorizationsResponse: state.websiteCategorizations.archiveMsg,
  }));

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.websiteCategorizations.errorMsg,
    successMsg: state.websiteCategorizations.successMsg,
  }));

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  useEffect(() => {
    // console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);

  console.log(websiteCategorizations)
  useEffect(() => {
    if (websiteCategorizationsResponse !== null) {
      if (websiteCategorizationsResponse.responseData.success) {
        toastr.success('Website Categorization Status Changed Successfully')
        dispatch(onGetWebsiteCategorizations());
        dispatch(statusWebsiteCategorizationSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  }, [dispatch, websiteCategorizationsResponse]);

  useEffect(() => {
    if (archiveWebsiteCategorizationsResponse !== null) {
      if (archiveWebsiteCategorizationsResponse.responseData.success) {
        toastr.success('Website vCategorization Archived Successfully')
        dispatch(onGetWebsiteCategorizations());
        dispatch(archiveWebsiteCategorizationSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  }, [dispatch, archiveWebsiteCategorizationsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    dispatch(onGetWebsiteCategorizations());
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, WebsiteCategorization) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Website Categorization Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Website Categorization Updated Successfully!...')
    }
    selectedData = WebsiteCategorization
    dispatch(onGetWebsiteCategorizations());
    setIsRight(close);
  };

  useEffect(() => {
    if (websiteCategorizations !== null) {
      dispatch(onGetWebsiteCategorizations());
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const websiteCategorizationColoumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "ID",
      dataField: "websiteID",
      sort: true,
    },
    {
      text: "Website",
      dataField: "website",
      sort: true,
    },
    {
      text: "Language",
      dataField: "language",
      sort: true,
    },
    {
      text: "Category",
      dataField: "category",
      sort: true,
    },
    {
      dataField: "websiteStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, websiteCategorization) => (
        <>
          {websiteCategorization.websiteStatus &&
            <span className="active-state">Active</span>
          }
          {!websiteCategorization.websiteStatus &&
            <span className="inactive-state">Inactive</span>
          }
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, websiteCategorization) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <Link className="text-success" to="#" onClick={toggleDrawer(true, websiteCategorization)}>
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveWebsiteCategorization = async () => {
    dispatch(onArchiveWebsiteCategorization({ idList: selectedItem }));
  }

  const statusWebsiteCategorization = (state) => (event) => {
    console.log(state)
    dispatch(onStatusWebsiteCategorization({ idList: selectedItem, websiteStatus: state }));
  }

  console.log(websiteCategorizations)

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Website Categorization | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Contextual Settings" breadcrumbItem="Website Categorization" />
            <div className="text-sm-end">
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={toggleRightCanvas}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add Website Categorization
              </Button>
            </div>
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                          <ToolkitProvider
                            keyField={keyField}
                            data={websiteCategorizations}
                            columns={websiteCategorizationColoumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                      <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                          {/* <button color="primary" className="btn btn-primary " onClick={statusWebsiteCategorization(true)}>Activate</button> */}
                                          <button color="primary" className="btn btn-primary " onClick={statusWebsiteCategorization(true)}>
                                            Activate
                                          </button>
                                          &nbsp;  &nbsp;
                                          <button color="primary" className="btn btn-primary " onClick={statusWebsiteCategorization(false)}>
                                            DeActivate
                                          </button>
                                          &nbsp;  &nbsp;
                                          <Button color="primary" className="btn btn-primary " onClick={archiveWebsiteCategorization} >
                                            Archive
                                          </Button>
                                          <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                            className="mdi mdi-close-thick font-size-18"
                                          ></i></Button>
                                        </div>
                                      </div>
                                    }
                                  </Col>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'>
        {/* <Row className="d-flex" style={{borderBottom: '1px solid #9e9e9e',width:'100%',padding:'20px'}}>
           <button type="button" onClick={() => {  setIsRight(false)}}  className="btn-close" aria-label="Close"></button>
           &nbsp;&nbsp; 
          New WebsiteCategorization
          </Row> */}
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit WebsiteCategorization' : 'WebsiteCategorization'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Web_Categorization closeCanvas={toggleDrawer(false, '')} selectedWebsiteCategorization={selectedData} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default Web_Categorization



