/* AGENCY_SCHEDULE_REPORT */
export const LOAD_AGENCY_SCHEDULE_REPORTS = "LOAD_AGENCY_SCHEDULE_REPORTS"
export const NO_DATA_AGENCY_SCHEDULE_REPORTS = "NO_DATA_AGENCY_SCHEDULE_REPORTS"
export const GET_AGENCY_SCHEDULE_REPORTS = "GET_AGENCY_SCHEDULE_REPORTS"
export const GET_AGENCY_SCHEDULE_REPORTS_SUCCESS = "GET_AGENCY_SCHEDULE_REPORTS_SUCCESS"
export const GET_AGENCY_SCHEDULE_REPORTS_FAIL = "GET_AGENCY_SCHEDULE_REPORTS_FAIL"

export const ADD_AGENCY_SCHEDULE_REPORT = "ADD_AGENCY_SCHEDULE_REPORT"
export const ADD_AGENCY_SCHEDULE_REPORT_SUCCESS = "ADD_AGENCY_SCHEDULE_REPORT_SUCCESS"
export const ADD_AGENCY_SCHEDULE_REPORT_ERROR = "ADD_AGENCY_SCHEDULE_REPORT_ERROR"

export const UPDATE_AGENCY_SCHEDULE_REPORT = "UPDATE_AGENCY_SCHEDULE_REPORT"
export const UPDATE_AGENCY_SCHEDULE_REPORT_SUCCESS = "UPDATE_AGENCY_SCHEDULE_REPORT_SUCCESS"
export const UPDATE_AGENCY_SCHEDULE_REPORT_ERROR = "UPDATE_AGENCY_SCHEDULE_REPORT_ERROR"

export const READ_AGENCY_SCHEDULE_REPORT = "READ_AGENCY_SCHEDULE_REPORT"
export const READ_AGENCY_SCHEDULE_REPORT_SUCCESS = "READ_AGENCY_SCHEDULE_REPORT_SUCCESS"

export const STATUS_AGENCY_SCHEDULE_REPORTS = "STATUS_AGENCY_SCHEDULE_REPORTS"
export const STATUS_AGENCY_SCHEDULE_REPORT_SUCCESS = "STATUS_AGENCY_SCHEDULE_REPORT_SUCCESS"

export const ARCHIVE_AGENCY_SCHEDULE_REPORT = "ARCHIVE_AGENCY_SCHEDULE_REPORT"
export const ARCHIVE_AGENCY_SCHEDULE_REPORT_SUCCESS = "ARCHIVE_AGENCY_SCHEDULE_REPORT_SUCCESS"