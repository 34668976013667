import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HOUSE_ADUNITS,ADD_HOUSE_ADUNIT , UPDATE_HOUSE_ADUNIT,READ_HOUSE_ADUNIT,STATUS_HOUSE_ADUNIT,ARCHIVE_HOUSE_ADUNIT} from "./actionTypes"

import {
  loadActivitiesAds,
  noAds,
  getHouseAdUnitsSuccess,
  getHouseAdUnitsFail,
  addHouseAdUnitSuccess,
  addHouseAdUnitFail,
  updateHouseAdUnitSuccess,
  updateHouseAdUnitFail,
  readHouseAdUnitSuccess,
  statusHouseAdUnitSuccess,
  archiveHouseAdUnitSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAdUnits,postAdUnits,updateAdUnits,readAdUnits,statusChangeAdUnits,archiveAdUnits } from "../../../helpers/Backend_helper"

function* fetchHouseAdUnits({ payload: adUt }) {
  try {
    yield put(loadActivitiesAds(true))
    var getAdUList
      if(adUt){
        getAdUList = yield call(getAdUnits,adUt)
        getAdUList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
      if(getAdUList.responseData.response.length === 0){
        yield put(noAds(true))
      }
        yield put(getHouseAdUnitsSuccess(getAdUList.responseData.response))
      }
      else{
        getAdUList = []
        yield put(getHouseAdUnitsSuccess(getAdUList))
      }
  } catch (error) {
    yield put(getHouseAdUnitsFail(error))
  }
}

function* onReadHouseAdUnit({ payload: adUt }) {
  try {
    const response = yield call(readAdUnits, adUt)
    yield put(readHouseAdUnitSuccess(response))
  } catch (error) {
    yield put(readHouseAdUnitSuccess(error))
  }
}

function* onAddHouseAdUnit({ payload: adUt }) {
  try {
    const response = yield call(postAdUnits, adUt)
    if(response.responseData.success){
      yield put(addHouseAdUnitSuccess(response.responseData))
    }else{
      yield put(addHouseAdUnitFail(response.responseData))
    }
  } catch (error) {
    yield put(addHouseAdUnitFail(error))
  }
}

function* onUpdateHouseAdUnit({ payload: adUt }) {
  try {
    const response = yield call(updateAdUnits, adUt)
    if(response.responseData.success){
      yield put(updateHouseAdUnitSuccess(response.responseData))
    }else{
      yield put(updateHouseAdUnitFail(response.responseData))
    }
  } catch (error) {
    yield put(updateHouseAdUnitFail(error))
  }
}

function* onStatusHouseAdUnit({ payload: adUt }) {
  try {
    const response = yield call(statusChangeAdUnits, adUt)
    yield put(statusHouseAdUnitSuccess(response))
  } catch (error) {
    yield put(updateHouseAdUnitFail(error))
  }
}

function* onArchiveHouseAdUnit({ payload: adUt }) {
  try {
    const response = yield call(archiveAdUnits, adUt)
    yield put(archiveHouseAdUnitSuccess(response))
  } catch (error) {
    yield put(archiveHouseAdUnitSuccess(error))
  }
}

function* adUnitsSaga() {
  yield takeEvery(GET_HOUSE_ADUNITS, fetchHouseAdUnits)
  yield takeEvery(ADD_HOUSE_ADUNIT, onAddHouseAdUnit)
  yield takeEvery(UPDATE_HOUSE_ADUNIT, onUpdateHouseAdUnit)
  yield takeEvery(READ_HOUSE_ADUNIT, onReadHouseAdUnit)
  yield takeEvery(STATUS_HOUSE_ADUNIT, onStatusHouseAdUnit)
  yield takeEvery(ARCHIVE_HOUSE_ADUNIT, onArchiveHouseAdUnit)
}

export default adUnitsSaga;
