import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_INTERESTS,ADD_INTEREST , UPDATE_INTEREST,READ_INTEREST,STATUS_INTEREST,ARCHIVE_INTEREST} from "./actionTypes"

import {
  loadInterests,
  noInterests,
  getInterestsSuccess,
  getInterestsFail,
  addInterestSuccess,
  addInterestFail,
  updateInterestSuccess,
  updateInterestFail,
  readInterestSuccess,
  statusInterestSuccess,
  archiveInterestSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getInterests,createInterest,updateInterest,readInterest,statusChangeInterest,archiveInterest } from "../../../helpers/Backend_helper"

function* fetchInterests() {
  try {
    yield put(loadInterests(true))
    const response = yield call(getInterests)
    console.log(response)
    response.responseData.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.responseData.response.length === 0){
    yield put(noInterests(true))
  }
    yield put(getInterestsSuccess(response.responseData.response))
  } catch (error) {
    yield put(getInterestsFail(error))
  }
}

function* onReadInterest({ payload: brandAffinity }) {
  try {
    const response = yield call(readInterest, brandAffinity)
    yield put(readInterestSuccess(response))
  } catch (error) {
    yield put(readInterestSuccess(error))
  }
}

function* onAddInterest({ payload: brandAffinity }) {
  try {
    const response = yield call(createInterest, brandAffinity)
    if(response.responseData.success){
      yield put(addInterestSuccess(response.responseData))
    }else{
      yield put(addInterestFail(response.responseData))
    }
  } catch (error) {
    yield put(addInterestFail(error))
  }
}

function* onUpdateInterest({ payload: brandAffinity }) {
  try {
    const response = yield call(updateInterest, brandAffinity)
    if(response.responseData.success){
      yield put(updateInterestSuccess(response.responseData))
    }else{
      yield put(updateInterestFail(response.responseData))
    }
  } catch (error) {
    yield put(updateInterestFail(error))
  }
}

function* onStatusInterest({ payload: brandAffinity }) {
  try {
    const response = yield call(statusChangeInterest, brandAffinity)
    if(response.success){
      yield put(statusInterestSuccess(response))
    }else{
      yield put(statusInterestSuccess(response.message))
    }
  } catch (error) {
    yield put(updateInterestFail(error))
  }
}

function* onArchiveInterest({ payload: brandAffinity }) {
  try {
    const response = yield call(archiveInterest, brandAffinity)
    yield put(archiveInterestSuccess(response))
  } catch (error) {
    yield put(archiveInterestSuccess(error))
  }
}

function* interestSaga() {
  yield takeEvery(GET_INTERESTS, fetchInterests)
  yield takeEvery(ADD_INTEREST, onAddInterest)
  yield takeEvery(UPDATE_INTEREST, onUpdateInterest)
  yield takeEvery(READ_INTEREST, onReadInterest)
  yield takeEvery(STATUS_INTEREST, onStatusInterest)
  yield takeEvery(ARCHIVE_INTEREST, onArchiveInterest)
}

export default interestSaga;
