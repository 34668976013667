/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,Spinner } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewProxyAdUnit as onADDProxyAdUnit,
  updateProxyAdUnit as onUpdateProxyAdUnit,
  readProxyAdUnit as onReadProxyAdUnit,
  addProxyAdUnitSuccess,
  addProxyAdUnitFail,
  updateProxyAdUnitSuccess,
  updateProxyAdUnitFail,
} from "store/Proxy/ProxyAdUnits/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const Create_ProxyAdUnits = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [proxyAdUnit, setProxyAdUnit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [sizeData, setSizeData] = useState([])
  const [selectedSize,setSelectedSize] = useState(null);
  const [loader, setLoader] = useState(false);
  console.log(props)


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      proxyAdUnitName: (proxyAdUnit && proxyAdUnit.proxyAdUnitName) || '',
      proxyAdUnitSize:(selectedSize ? selectedSize.value : '') || '',
      proxyPassbackPlatformName: (proxyAdUnit && (proxyAdUnit.proxyPassbackPlatformName || proxyAdUnit.ProxyPassbackPlatformName)) || '',
      proxyAdUnitContent:(proxyAdUnit && proxyAdUnit.proxyAdUnitContent) || '',
      proxyPassbackCreativeName: (proxyAdUnit && proxyAdUnit.proxyPassbackCreativeName) || '',
      sourcePlatformName:(proxyAdUnit && proxyAdUnit.sourcePlatformName) || '',
      sourcePlatformID: (proxyAdUnit && proxyAdUnit.sourcePlatformID) || '',
    },
    validationSchema: Yup.object({
      proxyAdUnitName: Yup.string().required("Proxy Ad Unit Name is required"),
      proxyPassbackPlatformName: Yup.string().required("Passback Platform Name is required"),
      proxyAdUnitContent: Yup.string().required("Passback Tag is required"),
      sourcePlatformID: Yup.string().required("Source Platform ID is required"),
      sourcePlatformName: Yup.string().required("Source Platform Name is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if(props.selectedProxyAdUnit  !== ''){
        dispatch(onUpdateProxyAdUnit({...values,proxyAdUnitID:props.selectedProxyAdUnit.proxyAdUnitID,proxyAdUnitName:values.proxyAdUnitName.trim()}));
      }else{
        dispatch(onADDProxyAdUnit({...values,proxyAdUnitName:values.proxyAdUnitName.trim()}));
      }
    }
  });

  useEffect(() => {
    setSizeData([{value:'0x0',label:'0x0'},
    {value:'1x1',label:'1x1'},
    {value:"300x250",label:"300x250"},
    {value:"300x600",label:"300x600"},
    {value:"320x442",label:"320x442"},
    {value:"320x480",label:"320x480"},
    {value:"360x480",label:"360x480"},
    {value:"728x90" ,label:"728x90" },
    {value:"120x600",label:"120x600"},
    {value:"160x600",label:"160x600"},
    {value:"970x250",label:"970x250"},
    {value:"970x90" ,label:"970x90" },
    {value:"320x50" ,label:"320x50" },
    {value:"320x100",label:"320x100"},
    {value:"300x50" ,label:"300x50" },
    {value:"300x100",label:"300x100"},
    {value:"468x60" ,label:"468x60" },
    {value:"468x90" ,label:"468x90" },
    {value:"336x280",label:"336x280"},
    {value:"640x360" ,label:"640x360" },
    {value:"400x225" ,label:"400x225" },
    {value:"320x180",label:"320x180"},
    {value:"320x90",label:"320x90"},
    {value:'800x485',label:'800x485'}
  ])
    setProxyAdUnit(validation.values)
    if(props.selectedProxyAdUnit  !== '' ){
      setIsEdit(true)
      setProxyAdUnit(props.selectedProxyAdUnit)
      setSelectedSize({value:props.selectedProxyAdUnit.proxyAdUnitSize,label:props.selectedProxyAdUnit.proxyAdUnitSize});
    }
}, []);

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.proxyAdUnit.errorMsg,
    successMsg: state.proxyAdUnit.successMsg,
  }));

  console.log(errorMsg, successMsg)

    useEffect(() => {
    if(successMsg !== null){
       if(successMsg.success){
        dispatch(addProxyAdUnitSuccess(""));
        dispatch(addProxyAdUnitFail(""));
        dispatch(updateProxyAdUnitSuccess(""));
        dispatch(updateProxyAdUnitFail(""));
        setLoader(false)
        if(props.selectedProxyAdUnit  !== ''){
          props.closeCanvas('Update')
        }else{
          props.closeCanvas('Create')
        }
       }
   }
   }, [ successMsg]);

   useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addProxyAdUnitSuccess(""));
        dispatch(addProxyAdUnitFail(""));
        dispatch(updateProxyAdUnitSuccess(""));
        dispatch(updateProxyAdUnitFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addProxyAdUnitSuccess(""));
    dispatch(addProxyAdUnitFail(""));
    dispatch(updateProxyAdUnitSuccess(""));
    dispatch(updateProxyAdUnitFail(""));
        props.closeCanvas('')
  };

  function handleSelectSize(selectSize) {
    setProxyAdUnit(validation.values)
    setSelectedSize(selectSize);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  return (
    <React.Fragment>
      {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row  style={{ padding: '1% 3%', height: '80vh', overflow: 'auto',width:'100%' }}>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Proxy Ad Unit Name</Label>
                <Input
                  name="proxyAdUnitName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={110}
                  value={validation.values.proxyAdUnitName || ""}
                  invalid={
                    validation.touched.proxyAdUnitName && validation.errors.proxyAdUnitName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.proxyAdUnitName && validation.errors.proxyAdUnitName ? (
                  <FormFeedback type="invalid">{validation.errors.proxyAdUnitName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label> Ad Unit Size</Label>
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Source Platform ID</Label>
                <Input
                  name="sourcePlatformID"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.sourcePlatformID || ""}
                  invalid={
                    validation.touched.sourcePlatformID && validation.errors.sourcePlatformID ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.sourcePlatformID && validation.errors.sourcePlatformID ? (
                  <FormFeedback type="invalid">{validation.errors.sourcePlatformID}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Source Platform Name</Label>
                <Input
                  name="sourcePlatformName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.sourcePlatformName || ""}
                  invalid={
                    validation.touched.sourcePlatformName && validation.errors.sourcePlatformName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.sourcePlatformName && validation.errors.sourcePlatformName ? (
                  <FormFeedback type="invalid">{validation.errors.sourcePlatformName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Passback Platform Name</Label>
                <Input
                  name="proxyPassbackPlatformName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.proxyPassbackPlatformName || ""}
                  invalid={
                    validation.touched.proxyPassbackPlatformName && validation.errors.proxyPassbackPlatformName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.proxyPassbackPlatformName && validation.errors.proxyPassbackPlatformName ? (
                  <FormFeedback type="invalid">{validation.errors.proxyPassbackPlatformName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Passback Creative Name</Label>
                <Input
                  name="proxyPassbackCreativeName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.proxyPassbackCreativeName || ""}
                  readOnly={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Passback Tag</Label>
                <Input
                  name="proxyAdUnitContent"
                  type="textarea"
                  rows="5"
                  placeholder=" Passback Tag"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.proxyAdUnitContent || ""}
                  invalid={
                    validation.touched.proxyAdUnitContent && validation.errors.proxyAdUnitContent ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.proxyAdUnitContent && validation.errors.proxyAdUnitContent ? (
                  <FormFeedback type="invalid">{validation.errors.proxyAdUnitContent}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
              {isEdit && !props.selectedArchived &&
                 <button
                 type="submit"
                 className="btn btn-primary save-user"
               >
                Update
               </button>
              }
              {!isEdit && 
                  <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Save
                </button>
              }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_ProxyAdUnits);

Create_ProxyAdUnits.propTypes = {
  history: PropTypes.object,
};