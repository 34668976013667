import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  Collapse,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames"

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";

import avatar from "assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "store/actions";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import axios from "axios";
import Basic_Info from "./Basic_Info";
import Account_Info from "./Account_Info";
import P_Notifications from "./P_Notifications";
import P_Terms_Cond from "./P_Terms_Cond";
import Sidebar from "components/VerticalLayout/Sidebar";
import Header from "components/VerticalLayout/Header";

import Toaster from "pages/Toaster/Toaster";
import { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError } from "store/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ProfileImage from "pages/Dashboard/ProfileImage";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

const Publisher_Profile = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const getActiveTab = localStorage.getItem('activeTab');
  console.log(parseInt(getActiveTab))
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [submitted, setSubmitted] = useState(false);

  console.log(activeTab)

  useEffect(() => {
    if (getActiveTab) {
      toggleTab(parseInt(getActiveTab))
    }
  }, []);


  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        localStorage.setItem("activeTab", tab);
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    dispatch(userForgetPasswordSuccess(""));
    dispatch(userForgetPasswordError(""));
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content access-management"> */}
        <div className="page-content">
          <MetaTags>
            <title>Profile | Bidsxchange</title>
          </MetaTags>
          <Container fluid={true}>
            <Row>
              <Col lg="12">
                <Card className="mb-0">
                  <CardBody className="p-2">
                    <div className="d-flex">

                      < ProfileImage />

                      <div className="flex-grow-1 align-self-center ml-3" style={{marginLeft:'1rem'}}>
                        <div className="text-muted ml-3">
                          <h5>{userInfo.name}</h5>
                          <p className="mb-1">{userInfo.username}</p>
                          <p className="mb-0">{userInfo.userRole}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ padding: '1% 1%' }}>
              <Row >
                <Col lg="12" className="p-0">
                  <Card>
                    <CardBody className="p-0">
                      <h4 className="card-title m-3">Profile Settings</h4>
                      <div className=" wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              // disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">1.</span>Basic Info
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              // disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number ">02</span> Account Info
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 3 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                  setactiveTab(3)
                                }}
                              // disabled={!(passedSteps || []).includes(4)}
                              >
                                <span className="number">03</span> Notifications
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 4 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 4 })}
                                onClick={() => {
                                  setactiveTab(4)
                                }}
                              // disabled={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">04</span> Terms and Conditions
                              </NavLink>
                            </NavItem>

                          </ul>
                        </div>
                        <div className="content clearfix ">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <Basic_Info />
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Account_Info />
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                <P_Notifications />
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div>
                                <P_Terms_Cond />
                              </div>
                            </TabPane>

                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 4 ? "next disabled" : "next"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      {/* </div> */}
    </React.Fragment>
  )
}

Publisher_Profile.propTypes = {}

export default Publisher_Profile