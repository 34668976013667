import React, { useState, useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import ProfileImage from "pages/Dashboard/ProfileImage"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const sessionRef = useRef();
  const [username, setusername] = useState("Admin")
  const userRole = localStorage.getItem('UserRole');

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.name)
      }
    }
  }, [props.success])

  const navigateLockScreen = () => {
    localStorage.setItem("userSessionState", ' Locked Screen');
    localStorage.setItem("previousNavigation", (window.location.pathname + window.location.search));
    sessionRef.current.click()
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/*<img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />*/}
          < ProfileImage />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={userRole === 'Publisher' ? '/User_Profile' : '/profile'}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </DropdownItem>
          {userRole !== 'Publisher' &&
            <DropdownItem tag="a" href="#">
              <i className="bx bx-wrench font-size-16 align-middle me-1" />
              {props.t("Settings")}
            </DropdownItem>
          }
          {/* <DropdownItem onClick={navigateLockScreen}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Link ref={sessionRef} to="/locked-screen"></Link>

    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
