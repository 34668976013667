import React from 'react'
import PropTypes from 'prop-types'
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

const LoaderSidebar = props => {
  const userRole = localStorage.getItem('UserRole');
  return (
    <React.Fragment >
      <header id="page-topbar" className="load-sidebar placeholder-glow" >
        <div className="navbar-header">

          <div className='row w-100' >
            <div className='col-md-9 '>

              <span className="placeholder col-2 mt-4 " style={{ marginLeft: '10px' }} ></span>
              <br></br>
              <span className="placeholder col-1 mt-1 " style={{ marginLeft: '10px' }}></span>
            </div>
            <div className='col-md-3'>
              <div className='text-sm-end'>
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars  disabled placeholder" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars  disabled placeholder" />
                </button>
                <span className="placeholder col-4 col-lg-6 "></span>
              </div>


            </div>
          </div>
        </div>
      </header>

      <div className="vertical-menu preLoader load-sidebar  placeholder-glow" style={{ background: userRole === 'Publisher' ? '#303d4e' : '#2a3042' }}>
        <div className="navbar-brand-box text-start" style={{ background: userRole === 'Publisher' ? '#303d4e' : '#2a3042' }}>


          <span className="placeholder col-9 mt-4 bg-light"></span>
          <span className="placeholder col-6 mt-1 bg-light"></span>
        </div>
        <div data-simplebar className="h-100">
          <ul className="metismenu list-unstyled " id="side-menu" style={{ padding: '5% 1% 20% 10%' }}>
            <li>
              <span className="placeholder col-8 mt-4"></span>
            </li>
            <li>
              <span className="placeholder col-6"></span>
            </li>
            <li>
              <span className="placeholder col-8"></span>
            </li>
            <li>
              <span className="placeholder col-8 mt-4"></span>
            </li>
            <li>
              <span className="placeholder col-6"></span>
            </li>
            <li>
              <span className="placeholder col-8"></span>
            </li>
            <li>
              <span className="placeholder col-8 mt-4"></span>
            </li>
            <li>
              <span className="placeholder col-6"></span>
            </li>
            <li>
              <span className="placeholder col-8"></span>
            </li>
            <li>
              <span className="placeholder col-8 mt-4"></span>
            </li>
            <li>
              <span className="placeholder col-6"></span>
            </li>
            <li>
              <span className="placeholder col-8"></span>
            </li>
          </ul>
        </div>
        <div className="sidebar-background"></div>
      </div>
      <div id="preloader12">

      <div id="status1">
          <h3 className=" mb-4">{userRole === 'Publisher' ? 'Switching to Publisher console' : userRole === 'AdOps' ? 'Switching to Internal Console'  : userRole === 'Agency' ? 'Switching to Agency Console' : userRole === 'BSRV21' ? 'Switching to BSRV 2021 Console' : userRole === 'User' ? 'Switching to Internal User Console' :  'Switching to Admin console'}</h3>
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

LoaderSidebar.propTypes = {}

export default LoaderSidebar