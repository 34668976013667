/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Collapse, } from "reactstrap";
import axios from "axios";

import {
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewVideoCreative as onADDVideoCreative,
  updateVideoCreative as onUpdateVideoCreative,
  readVideoCreative as onReadVideoCreative,
  addVideoCreativeSuccess,
  addVideoCreativeFail,
  updateVideoCreativeSuccess,
  updateVideoCreativeFail,
} from "store/Demand/Creative/actions";

import classnames from "classnames";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const intialVcPriorities = [{
      tag: '',
      name:'',
  }
]

const intialVideoCreative = {
  videoCreativeName: '',
  bannerAdTag:'',
  videoCreativeContent:'',
  VW: [{ ...intialVcPriorities}],
  advertiserID: '',
  iOGroupID: '',
  iOID: '',
  lineItemID: '',
  "typeFor":"videoWrapper",
}

const NewVideoCreativeWrapper = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  console.log(props)

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  const [videoCreative, setVideoCreative] = useState({
    ...intialVideoCreative,
    advertiserID: '',
    iOGroupID: '',
    iOID: '',
    lineItemID: '',
    "typeFor":"videoWrapper",
    userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID
  });

  const [newVideoGroupCreative, setNewVideoGroupCreative] = useState(true)
  const [submitted, setSubmitted] = useState(false);
  const [videoCreativeContent, setVideoCreativeContent] = useState([{
        tag: '',
        name:'',
  }])
  const [expanded, setExpanded] = useState('panel1');
  const [submitAlert, setSubmitAlert] = useState(false);
  const [itemOpen, setItemOpen] = useState('')

  const getCreativeData = async (selectedCreativeData) => {
    console.log(selectedCreativeData)
    setVideoCreative({ ...videoCreative, ...selectedCreativeData })
    console.log(videoCreative)
  }

  const addNewVideoGroup = () => {
    console.log(videoCreativeContent)
    const newVcGroup = {
        tag: '',
        name:'',
    }
    setVideoCreativeContent([...videoCreativeContent, newVcGroup]);
  }
 

  const vastHandleChange = (e, pIndex) => {
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {...vcGroup,[name]: value}
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, VW: updatedVastTags }))
  }



  const { errorMsg, successMsg, creativeVideoData } = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    creativeVideoData: state.creatives.creative
  }));

  console.log(errorMsg, successMsg, creativeVideoData)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
        if (props.selectedCreativeData !== '') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addVideoCreativeSuccess(""));
    dispatch(addVideoCreativeFail(null));
    dispatch(updateVideoCreativeSuccess(""));
    dispatch(updateVideoCreativeFail(null));
        props.creativeNewClose('')
  };

  useEffect(() => {
    console.log(props.selectedCreativeData)
    if (props.selectedCreativeData !== '') {
      dispatch(onReadVideoCreative({ ...props.selectedCreativeData, userRole: userInfo.userRole, userID: userInfo.userID }));
      setIsEdit(true)
    }
  }, [])

  useEffect(() => {
    if (creativeVideoData !== null && props.selectedCreativeData !== '') {
      if(creativeVideoData.videoCreativeID === props.selectedCreativeData.creativeID){
        console.log(creativeVideoData)
        setVideoCreative(videoCreative => ({ ...videoCreative, ...creativeVideoData }));
        const newVcGroup = creativeVideoData.VW
        console.log(newVcGroup)
        setVideoCreativeContent(newVcGroup);
        setNewVideoGroupCreative(false);
        console.log(videoCreative)
      }else{
        dispatch(onReadVideoCreative({ ...props.selectedCreativeData, userRole: userInfo.userRole, userID: userInfo.userID }));
      }
    }
  }, [creativeVideoData]);

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target;
    setVideoCreative(videoCreative => ({ ...videoCreative, [name]: value }))
  }

  const handleSubmit = async (e) => {
    console.log("submit click", videoCreative)
    e.preventDefault();
    setSubmitted(true);
    console.log("submit click", videoCreative)
    
    if (videoCreative.videoCreativeName && videoCreative.lineItemID && videoCreative.VW.length > 0) {
      console.log(props.creativeId)
      if (videoCreativeContent.findIndex(group => group.tag === '') > -1) {
        toastr.warning(' Unable to Save with empty Vast tag — check it out!')
        setSubmitAlert(true);
        return;
      }
      if (props.selectedCreativeData !== '') {
        dispatch(onUpdateVideoCreative({ ...videoCreative,videoCreativeName:videoCreative.videoCreativeName.trim(), userRole: userInfo.userRole, userID: userInfo.userID }));
      }
      else {
        dispatch(onADDVideoCreative({ ...videoCreative,videoCreativeName:videoCreative.videoCreativeName.trim(), userRole: userInfo.userRole, userID: userInfo.userID }));
      }

    }
  }


  const removePriorities = (index) => (e) => {
    console.log(index)
    if (videoCreativeContent[index].tag !== '') {
      toastr.warning(' Unable to delete Vast Tag, remove vast tag data to delete — check it out!')
      return false
    }
    const uPriorities = videoCreativeContent.filter((gp, ind) => {
      return ind !== index
    }).map((gpm, p) => {
      return {
        ...gpm,
      }
    })
    setVideoCreativeContent(uPriorities)
  }

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(async () => {
    console.log("VideoCreative--------------", videoCreative)
    console.log(videoCreativeContent);

    if (props.selectedLineItem !== '') {
      setVideoCreative({ ...videoCreative, lineItemID: props.selectedLineItem.lineItemID })
    }
    if (props.creativeId) {
      const bodyCreative = {
        "url": "http://127.0.0.1:2002//v1.0/VideoCreative/read/" + props.creativeId,
        "requestBody": {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
        }
      }
      axios.post("/v1.0/connectOP", bodyCreative,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": `Bearer ${userInfo.token}`
          }
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data.responseData) {
            console.log("response data in useeffect==================", res.data.responseData)
            // setVideoCreative(res.data.responseData)
            setVideoCreative({
              ...videoCreative,
              videoCreativeName: res.data.responseData.response.videoCreativeName,
              lineItemID: res.data.responseData.response.lineItemID,
              VW: res.data.responseData.response.VW,
            });

            const newVcGroup = res.data.responseData.response.VW
            setVideoCreativeContent(newVcGroup);
            setNewVideoGroupCreative(false);
            console.log(videoCreative)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, [])

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      <div className="col-9">

        <Form onSubmit={handleSubmit}>
          <Row form style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Video Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem} />
              <h6 className="text-muted">Video Wrapper Setup</h6>
              <hr></hr>

              <div className="mt-4 mb-4">
                <h5 >
                 Vast Tags
                </h5>

                {videoCreativeContent && videoCreativeContent.map((item, index) => {
                  console.log("group............Item", item, "videoCreativeContent==========", videoCreativeContent);
                  return (
                    <div className="videoCreativeContent-settings" key={index}>
                      <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                        <h2 className="accordion-header row m-0" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: itemOpen !== ('panel' + (index)) }
                            )}
                            type="button"
                            onClick={openCollapsed('panel' + (index))}
                            style={{ cursor: "pointer" }}
                          >
                               <h5 className="heading5 mt-2">Tag {index + 1}</h5>
                            {
                              videoCreativeContent.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Tag</button>
                            }
                            {
                              videoCreativeContent.length !== 1 && <Link to="#" className='btn btn-danger rem-grp-btn' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removePriorities(index)}
                              >Remove Tag</Link>
                            }
                          </button>

                        </h2>
                        <Collapse isOpen={true} className="accordion-collapse">
                                <div className="vast-settings" >
                                  <div className="accordion-body">
                                    <div >
                                      <div className="row form-group">
                                        <div className='col-11 mb-3'>
                                          <Label > Vast Tag Name</Label>
                                          <Input
                                            type="text" name="name" onChange={(e) => vastHandleChange(e, index)}
                                            id="standard-full-width"
                                            className={'form-control' + (submitted && !item.name ? ' is-invalid' : '')}
                                            value={item.name}
                                          />
                                          {submitted && !item.name &&
                                            <div className="invalid-feedback">Vast Tag Name is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                        <div className='col-11 mb-3'>
                                          <Label >
                                            Vast Tag 
                                          </Label>
                                          <Input type="textarea" id="textarea"
                                            rows="2" value={item.tag}
                                            style={{ width: '100%' }}
                                            name="tag"
                                            onChange={(e) => vastHandleChange(e, index)}
                                            className={'form-control' + (submitted && !item.tag ? ' is-invalid' : '')}
                                          />
                                          {submitted && !item.tag &&
                                            <div className="invalid-feedback">Vast Tag is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        </Collapse>
                      </div>
                    </div>
                  )
                }
                )}
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                    onClick={addNewVideoGroup}
                    // disabled={videoCreativeContent && videoCreativeContent.length >= 5}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add New Tag
                  </Button> </div>
              </div>
            </Col>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  )
}

NewVideoCreativeWrapper.propTypes = {
  history: PropTypes.object,
}

export default NewVideoCreativeWrapper