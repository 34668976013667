import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
  } from "reactstrap";

import classnames from "classnames";
import Publisher_Revenue_Report from "./Revenue_Report";
import ScheduleReport from "./ScheduleReport";

const Publisher_Report = props => {
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab);
        }
      };

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content access-management"> */}
                <div className="page-content">
                    <MetaTags>
                        <title> Reporting | Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true}>
                    <Breadcrumbs title="Reporting" breadcrumbItem="Reports" />
                        <Nav tabs className="nav-tabs-custom nav-justified" style={{width:'20pc'}}>
                            <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                toggleCustom("1");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Report Query</span>
                            </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Schedule Report</span>
                            </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <Publisher_Revenue_Report />
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <ScheduleReport tabID='2' />
                                </Col>
                            </Row>
                            </TabPane>
                        </TabContent>
                    </Container>
                </div>
            {/* </div> */}
        </React.Fragment >
    )
}

Publisher_Report.propTypes = {}

export default Publisher_Report