/* NET_BLOCKING */
export const LOAD_NET_BLOCKINGS = "LOAD_NET_BLOCKINGS"
export const NO_DATA = "NO_DATA"
export const GET_NET_BLOCKINGS = "GET_NET_BLOCKINGS"
export const GET_NET_BLOCKINGS_SUCCESS = "GET_NET_BLOCKINGS_SUCCESS"
export const GET_NET_BLOCKINGS_FAIL = "GET_NET_BLOCKINGS_FAIL"

export const ADD_NET_BLOCKING = "ADD_NET_BLOCKING"
export const ADD_NET_BLOCKING_SUCCESS = "ADD_NET_BLOCKING_SUCCESS"
export const ADD_NET_BLOCKING_ERROR = "ADD_NET_BLOCKING_ERROR"

export const UPDATE_NET_BLOCKING = "UPDATE_NET_BLOCKING"
export const UPDATE_NET_BLOCKING_SUCCESS = "UPDATE_NET_BLOCKING_SUCCESS"
export const UPDATE_NET_BLOCKING_ERROR = "UPDATE_NET_BLOCKING_ERROR"

export const READ_NET_BLOCKING = "READ_NET_BLOCKING"
export const READ_NET_BLOCKING_SUCCESS = "READ_NET_BLOCKING_SUCCESS"

export const STATUS_NET_BLOCKING = "STATUS_NET_BLOCKING"
export const STATUS_NET_BLOCKING_SUCCESS = "STATUS_NET_BLOCKING_SUCCESS"

export const ARCHIVE_NET_BLOCKING = "ARCHIVE_NET_BLOCKING"
export const ARCHIVE_NET_BLOCKING_SUCCESS = "ARCHIVE_NET_BLOCKING_SUCCESS"

export const CALL_API = "CALL_API"
export const CALL_API_SUCCESS = "CALL_API_SUCCESS"