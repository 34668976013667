/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem, Collapse, Button,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";
import classnames from "classnames"
import queryString from 'query-string';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { MultiSelect } from "react-multi-select-component";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  updateAdUnitBid as onUpdateHBBuyer,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateAdUnitBidSuccess,
  updateAdUnitBidFail,
} from "store/HeaderBidding/HeaderBid/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const AssociateBuyerSettings = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [itemOpen, setItemOpen] = useState('')
    const [videosList, setVideosList] = useState([])
    const parsed = queryString.parse(window.location.search);
    const [adUnitSettings, setAdUnitSettings] = useState([{
      settingsEnabled: true,
      settingConfigMode: 'SimpleMode',
      settingInfo: videosList
    }])
    const [buyerRequestsState, setBuyerRequestsState] = useState('default')
    const [adUnitSettingsInfo, setAdUnitSettingsInfo] = useState({
      size: '',
      timeout: 1500,
      buyerRequests:2,
      floorPrice: {
        floorType: 'RON',
        floorValues: {
          'RON' : {
            price: 0.01,
            enabled: true,
          }
        }
      },
      optimizationStrategy: {
        type: 'floor',
        price: 0.03
      }
    })
  
    const [buyersListData, setBuyersListData] = useState([])
    const [selectedBuyer, setSelectedBuyer] = useState([]);
  
    console.log(props)
  
    const [hbAdUnitInfoData, setHbBuyerInfoData] = useState("");
  
    const handleChangeInfo = (e) => {
      const { name, value } = e.target;
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: value }));
    }

    const handleChangeInfoNumber = (e) => {
      const { name, value } = e.target;
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: Number(value) }));
    }

    const handleChangeOnBlurRequests = (e) => {
      const { name, value } = e.target;
        if(Number(value) < 1){
          setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: 1 }));
        }else{
          setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: Number(value) }));
        }
    }
  
    const [floorPriceValues, setFloorPriceValues] = useState({
      RON: 0.01,
      ROW: 0.01,
      GCC: 0.01,
      Tier1: 0.01,
      APAC: 0.01,
    })
  
    const changePostBidMeasurementType = (e) => {
      const { name, value } = e.target;
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo,floorPrice:{...adUnitSettingsInfo.floorPrice,[name]: value}  }));
      if (e.target.value === 'RON') {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
      } else if (e.target.value === 'GeoGroup') {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01 }));
      } else {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
      }
    }
  
    const floorPriceValueChange = (e) => {
      const { name, value } = e.target;
      console.log(floorPriceValues)
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, [name]: value }));
    }
  
    const handleChangeStratergy = (e) => {
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: 0.03 } }));
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, type: e.target.name } }));
    }
  
    const handleChangeStratergyPrice = (e) => {
        setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: e.target.value } }));
    }
  
    const openCollapsed = (panel) => (event, newExpanded) => {
      console.log(panel)
      setItemOpen(panel)
    };
  
    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token}`
    }
  
    const body = {
      "url": '',
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        filter:'Active'
      }
    }
  
    toastr.options = {
      positionClass: "toast-top-center",
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 1000,
      extendedTimeOut: 1000
    };
  
    const addNewAdUnitSettings = () => {
      console.log(adUnitSettings)
      const newVcGroup = {
        settingsEnabled: true,
        settingConfigMode: 'SimpleMode',
        settingInfo: videosList
      }
      setAdUnitSettings([...adUnitSettings, newVcGroup]);
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...adUnitSettings, newVcGroup] })
    }
  
    const { errorMsg, successMsg } = useSelector(state => ({
      errorMsg: state.headerBids.errorMsg,
      successMsg: state.headerBids.successMsg,
    }));
  
    useEffect(() => {
      console.log(successMsg)
      if (successMsg !== null) {
        console.log(successMsg)
        if (successMsg.success) {
          dispatch(updateAdUnitBidSuccess(""));
          dispatch(updateAdUnitBidFail(null));
          setLoader(false)
          props.closeCanvas('Update')
        }
      }
    }, [successMsg]);
  
    useEffect(() => {
      if (errorMsg !== null && errorMsg !== '') {
        setLoader(false)
        if (errorMsg.status && errorMsg.status === 500) {
        } else {
          dispatch(updateAdUnitBidSuccess(""));
          dispatch(updateAdUnitBidFail(null));
        }
      }
    }, [errorMsg]);
  
  
    console.log(errorMsg, successMsg)
  
    useEffect(() => {
   if (props.selectedHBBuyer !== '') {
    console.log(props.selectedHBBuyer.HB_Bidder)
      bidderSelectionData(props.selectedHBBuyer.HB_Bidder)
   }
    }, []);

    const bidderSelectionData = (bidder) => {
      console.log(props.selectedHBBuyer.HB_BuyerID)
      body.url = "http://127.0.0.1:2002/v1.0/HB_Buyers/read/getAllBidder"
      axios.post("/v1.0/connectOP", body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is headerBids ==>", res)
          if (res.data.responseData.data) {
            res.data.responseData.data.map((itemBid, index) => {
              if (bidder === itemBid.BidderName) {
                if (itemBid.BidderJSON) {
                  itemBid.BidderJSON.map((ele, index) => {
                    ele.value = ""
                  })
                  let OriginalBidder = itemBid.BidderJSON
                  if (props.selectedHBBuyer !== '') {
                    setIsEdit(true)
                    setVideosList([...itemBid.BidderJSON])
                    setSelectedBuyer({ value: props.selectedHBBuyer.HB_BuyerID, label: props.selectedHBBuyer.HB_BuyerName })
                    const bodyforAdUnit = {
                      "url": (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/getAssociateAdUnit/"+ props.selectedHBBuyer.HB_BuyerID :  "http://127.0.0.1:2002/v1.0/HB_Buyers/getAssociateAdUnit/"  + props.selectedHBBuyer.HB_BuyerID,
                      "requestBody": {
                        userID: userInfo.userID,
                        userRole: userInfo.userRole,
                        companyID: userInfo.companyID,
                        name: userInfo.name,
                        adUnitID: parsed.adUnitID
                      }
                    }
                    if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
                      bodyforAdUnit.requestBody = { ...bodyforAdUnit.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
                    }
                    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" : userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", bodyforAdUnit,
                      {
                        headers: headers
                      })
                      .then((res) => {
                        console.log("result is notAssociated headerBids ==>", res)
                        if (res.data.responseData.response) {
                          console.log(res.data.responseData.response)
                          setAdUnitSettingsInfo(res.data.responseData.response)
                          if(res.data.responseData.response.buyerRequests && res.data.responseData.response.buyerRequests !== 2){
                            setBuyerRequestsState('custom')
                          }
                          if (res.data.responseData.response.floorPrice.floorType === 'RON') {
                            setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: res.data.responseData.response.floorPrice.floorValues.RON.price }));
                          }
                          else  {
                              if (res.data.responseData.response.floorPrice.floorValues.ROW.enabled) {
                                setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: res.data.responseData.response.floorPrice.floorValues.ROW.price }));
                              }
                              if (res.data.responseData.response.floorPrice.floorValues.GCC.enabled) {
                                setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, GCC: res.data.responseData.response.floorPrice.floorValues.GCC.price }));
                              }
                              if (res.data.responseData.response.floorPrice.floorValues.Tier1.enabled) {
                                setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, Tier1: res.data.responseData.response.floorPrice.floorValues.Tier1.price }));
                              }
                              if (res.data.responseData.response.floorPrice.floorValues.APAC.enabled) {
                                setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, APAC: res.data.responseData.response.floorPrice.floorValues.APAC.price }));
                              }
                          }
                          if (res.data.responseData.response.HB_AdUnitsAssociatedInfo && res.data.responseData.response.HB_AdUnitsAssociatedInfo.length > 0) {
                            let resulData = res.data.responseData.response.HB_AdUnitsAssociatedInfo
                            res.data.responseData.response.HB_AdUnitsAssociatedInfo.map((item, i) => {
                              let setsObjecttoArray = []
                              if (item.settingInfo) {
                                // setsObjecttoArray.push(item.settingInfo)
                                let bidderJSONArray = OriginalBidder
                                setsObjecttoArray = Object.entries(item.settingInfo).reduce((acc, curr) => {
                                  const [key, val] = curr;
                                  acc.push({
                                    ParameterName: key,
                                    value: val,
                                    type: 'string',
                                    required: false,
                                  });
                                  return acc;
                                }, []);
                                console.log(setsObjecttoArray, OriginalBidder)
                                const output = OriginalBidder.map(a => ({
                                  ...a,
                                  value: setsObjecttoArray.find(x => x.ParameterName === a.ParameterName ? x.value : "")
                                }));
                                console.log(output)
                                let arr3 = setsObjecttoArray.concat(output.filter(({ ParameterName }) => !setsObjecttoArray.find(f => f.ParameterName == ParameterName)));
                                console.log(arr3)
                                setVideosList(([...arr3]))
  
                                console.log(setsObjecttoArray)
                                resulData[i].settingInfo = [...arr3]
                              }
                            })
                            setAdUnitSettings(resulData);
                          }
                        }
                      })
                      .catch((err) => {
                        console.log("e", err)
                      });
                  } else {
                    console.log(itemBid.BidderJSON)
                    setVideosList([...itemBid.BidderJSON])
                    let videoContentList = [...adUnitSettings]
                    videoContentList[0] = { ...videoContentList[0], settingInfo: [...itemBid.BidderJSON] };
                    setAdUnitSettings([...videoContentList]);
                  }
                }
              }
            })
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  
    useEffect(() => {
      const bodyNotAssociate = {
        "url":(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/read/HB_Buyers_NotAssociated" :  "http://127.0.0.1:2002/v1.0/HB_Buyers/read/HB_Buyers_NotAssociated",
        "requestBody": {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
          adUnitID:parsed.adUnitID
        }
      }
      if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
        bodyNotAssociate.requestBody = { ...bodyNotAssociate.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
      }
      axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" : userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", bodyNotAssociate,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is notAssociated headerBids ==>", res)
          if (res.data.responseData.data) {
            setBuyersListData(objectModal(res.data.responseData.data))
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }, []);
  
    const objectModal = (response) => {
      var dataList = []
      response.map((item, index) => {
        let obj = {}
        obj.value = item.HB_BuyerID
        obj.label = item.HB_BuyerName
        obj.bidder = item.HB_Bidder
        dataList.push(obj)
      })
      return dataList
    }

    const handleSelectBuyer = buyer => {
      console.log(buyer)
      setSelectedBuyer(buyer);
      bidderSelectionData(buyer.bidder)
    }
  
    const removePriorities = (index) => (e) => {
      console.log(index)
      const uPriorities = adUnitSettings.filter((gp, ind) => {
        return ind !== index
      }).map((gpm, p) => {
        return {
          ...gpm,
        }
      })
      setAdUnitSettings(uPriorities)
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: uPriorities })
    }
  
  
    useEffect(() => {
      // if (props.selectedHBBuyer !== '') {
      //   setIsEdit(true)
      //   setHBAdUnit(props.selectedHBBuyer)
      // }
    }, []);
  
    const handleChange = (vIndex, e) => {
      console.log("G", vIndex, e);
      const { name, value } = e.target;
      console.log("G", [name], value);
      const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
        if (vIndex === j) {
          return {
            ...vcGroup, [name]: value
          }
        }
        return vcGroup;
      })
      setAdUnitSettings(updatedVastTags)
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: updatedVastTags })
    }
  
    const handleChangeCheck = (vIndex, e) => {
      const { name, value } = e.target;
      const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
        if (vIndex === j) {
          return {
            ...vcGroup, [name]: e.target.checked
          }
        }
        return vcGroup;
      })
      setAdUnitSettings(updatedVastTags)
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: updatedVastTags })
    }
  
    const handleChangeMode = (index) => e => {
      const { name, value } = e.target;
      let videoContentList = [...adUnitSettings]
      console.log(videoContentList)
      videoContentList[index] = { ...videoContentList[index], settingConfigMode: name };
      console.log(videoContentList)
      setAdUnitSettings([...videoContentList]);
    }
  
    const addDirectVideoContent = (index) => e => {
      console.log(index, adUnitSettings[index].settingInfo)
      let videoContentList = [...adUnitSettings[index].settingInfo]
      videoContentList.push({
        id: videoContentList.length + 1,
        ParameterName: '',
        value: '',
        type: 'string',
        required: false,
      })
      let newList = [...adUnitSettings]
      newList[index].settingInfo = [...videoContentList]
      console.log(newList)
      setAdUnitSettings([...newList]);
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...newList] })
    }
  
    const handleChangeUser = (index, topIndex) => e => {
      const { name, value } = e.target;
      let videoContentList = [...adUnitSettings[topIndex].settingInfo]
      if (videoContentList[index].type === "integer") {
        videoContentList[index] = { ...videoContentList[index], [name]: Number(value) };
      } else if (videoContentList[index].type === "boolean") {
        videoContentList[index] = { ...videoContentList[index], [name]: JSON.parse(value) };
      } else {
        videoContentList[index] = { ...videoContentList[index], [name]: value };
      }
      let newList = [...adUnitSettings]
      newList[topIndex].settingInfo = [...videoContentList]
      console.log(newList)
      setAdUnitSettings([...newList]);
    }
  
    const removeDirectContent = (i, topIndex) => e => {
      let videoContentList = [...adUnitSettings[topIndex].settingInfo]
      videoContentList.splice(i, 1)
      let newList = [...adUnitSettings]
      newList[topIndex].settingInfo = [...videoContentList]
      console.log(newList)
      setAdUnitSettings([...newList]);
      // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...newList] })
    }
  
    var videoContentListforAdUnitSettings = [...adUnitSettings]
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitted(true);
      console.log(floorPriceValues, adUnitSettingsInfo)
      let partitionValidation = checkBidderData()
      if (partitionValidation && selectedBuyer.value && adUnitSettingsInfo.size) {
        let floorPriceSets = {}
        let floorPriceSetValues = {}
        let GeoExistValues = {}
        let floorValueforFP = 0.01
          if (adUnitSettingsInfo.floorPrice.floorType === 'RON') {
            floorPriceSets = {
              floorType: adUnitSettingsInfo.floorPrice.floorType,
              floorValues: {
                "RON": {price:floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01,enabled:true}
              }
            }
            floorValueforFP = floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01
          }
          if (adUnitSettingsInfo.floorPrice.floorType === 'GeoGroup') {
            floorValueforFP =  0.01
              floorPriceSetValues = { ...floorPriceSetValues, 'ROW':{price:floorPriceValues.ROW ? Number(floorPriceValues.ROW) : 0.01,enabled:floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false}}
              floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup',floorValues: floorPriceSetValues }
              GeoExistValues = { ...GeoExistValues, ROW: floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false }
              floorPriceSetValues = { ...floorPriceSetValues, 'GCC':{price:floorPriceValues.GCC ? Number(floorPriceValues.GCC) : 0.01,enabled:floorPriceValues.GCC  && (Number(floorPriceValues.GCC) > 0) ? true : false}}
              floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup',floorValues: floorPriceSetValues }
              GeoExistValues = { ...GeoExistValues, GCC: floorPriceValues.GCC && (Number(floorPriceValues.GCC) > 0) ? true : false }
              floorPriceSetValues = { ...floorPriceSetValues,'Tier1':{price:floorPriceValues.Tier1 ? Number(floorPriceValues.Tier1) : 0.01,enabled:floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false}}
              floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup',floorValues: floorPriceSetValues }
              GeoExistValues = { ...GeoExistValues, Tier1: floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false }
              floorPriceSetValues = { ...floorPriceSetValues,'APAC':{price:floorPriceValues.APAC ? Number(floorPriceValues.APAC) : 0.01,enabled:floorPriceValues.APAC&& (Number(floorPriceValues.APAC) > 0)  ? true : false}}
              floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup',floorValues: floorPriceSetValues }
              GeoExistValues = { ...GeoExistValues, APAC: floorPriceValues.APAC && (Number(floorPriceValues.APAC) > 0) ? true : false }
          }
          if(adUnitSettingsInfo.floorPrice.floorType === 'GeoGroup'){
            let notExistValue = Object.values(GeoExistValues).includes(false)
            if(!notExistValue){
              let bodyAdUnitSettings = {
                adUnitID: parsed.adUnitID,
                adUnitName: parsed.adUnitName,
                floorPrice: floorPriceSets,
                size: adUnitSettingsInfo.size,
                buyerRequests:adUnitSettingsInfo.buyerRequests,
                timeout: Number(adUnitSettingsInfo.timeout),
                adUnitSettings: manipulateData(),
                optimizationStrategy:{
                  type: adUnitSettingsInfo.optimizationStrategy.type,
                  price: Number(adUnitSettingsInfo.optimizationStrategy.price)
                },
                fp:floorValueforFP,
                isUpdate: isEdit
              }
              console.log(bodyAdUnitSettings)
              setLoader(true)
              dispatch(onUpdateHBBuyer({
                ...bodyAdUnitSettings, hB_BuyerID: props.selectedHBBuyer ? props.selectedHBBuyer.HB_BuyerID : selectedBuyer.value,userRole:userInfo.userRole,userID:userInfo.userID 
              }));
            }else{
              toastr.error('Please Enter Price for GeoGroup')
            }
          }else{
            if(floorPriceValues.RON && (Number(floorPriceValues.RON) > 0)){
              let bodyAdUnitSettings = {
                adUnitID: parsed.adUnitID,
                adUnitName: parsed.adUnitName,
                floorPrice: floorPriceSets,
                size: adUnitSettingsInfo.size,
                buyerRequests:adUnitSettingsInfo.buyerRequests,
                timeout: Number(adUnitSettingsInfo.timeout),
                adUnitSettings: manipulateData(),
                optimizationStrategy:{
                  type: adUnitSettingsInfo.optimizationStrategy.type,
                  price: Number(adUnitSettingsInfo.optimizationStrategy.price)
                },
                fp:floorValueforFP,
                isUpdate: isEdit
              }
              console.log(bodyAdUnitSettings)
              setLoader(true)
              dispatch(onUpdateHBBuyer({
                ...bodyAdUnitSettings, hB_BuyerID: props.selectedHBBuyer ? props.selectedHBBuyer.HB_BuyerID : selectedBuyer.value,userRole:userInfo.userRole,userID:userInfo.userID 
              }));
            }else{
              toastr.error('Please Enter Price for RON')
            }
          }
  
      }
    }
  
    const manipulateData = () => {
      let videoContentListforAdUnit = adUnitSettings
      videoContentListforAdUnit.map((item, i) => {
        let setsObject = {}
        if (item.settingInfo && item.settingInfo.length > 0) {
          item.settingInfo.map((elem, j) => {
            if (elem.value) {
              setsObject = { ...setsObject, [elem.ParameterName]: elem.value }
            }
          })
          videoContentListforAdUnit[i].settingInfo = setsObject
        }
      })
      console.log(videoContentListforAdUnit)
      return videoContentListforAdUnit
    }
  
    function allowOnlyNumbers(e) {
      var code = ('charCode' in e) ? e.charCode : e.keyCode;
      if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
    };
  
    const checkBidderData = () => {
      if (!selectedBuyer.value) {
        toastr.error('Please Select Buyer to Associate')
        return false
      }
      else if (adUnitSettingsInfo.timeout && (Number(adUnitSettingsInfo.timeout) < 1000)) {
        toastr.error('Please Enter Bidder Timeout greater than 1000 ms')
        return false
      }
      else if (!adUnitSettingsInfo.timeout) {
        toastr.error('Please Enter Bidder Timeout')
        return false
      }
      // else if (adUnitSettingsInfo.floorPrice && (Number(adUnitSettingsInfo.floorPrice) < 0.01)) {
      //   toastr.error('Please Enter Floor Price greater than 1 cent')
      //   return false
      // }
      // else if (!adUnitSettingsInfo.floorPrice) {
      //   toastr.error('Please Enter Floor Price')
      //   return false
      // } 
      else {
        return true
      }
    }
  
  
  
    return (
      <React.Fragment>
        {loader &&
          <Spinner className="ms-2 loader" color="primary" />
        }
        <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
          <Form onSubmit={handleSubmit}  >
            <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '85vh', overflow: 'auto', width: '100%' }}>
              <Col xl={12} className="mb-4">
                <Row>
                  <Col lg="12" className="p-2">
                    <div className="content clearfix ">
                    <h5>Ad Unit : {parsed.adUnitName}</h5> &nbsp;&nbsp;
                      <div className="mb-3">
                        <Label className="form-label">Associate Buyer </Label>
                        <Select
                          value={selectedBuyer}
                          onChange={s => {
                            handleSelectBuyer(s)
                          }}
                          options={buyersListData}
                          classNamePrefix="select2-selection "
                          className="col-6"
                          isDisabled={isEdit}
                        />
                      </div>
                      <div className="col-6">
                      <div className="mb-1">
                        <Label >Buyer Requests </Label>
                        <div className="row">
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="default"
                                id="default"
                                checked={buyerRequestsState === 'default'}
                                onChange={(e) => {
                                  setBuyerRequestsState('default')
                                  setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, buyerRequests: 2 }))
                                }}
                              />
                              <label className="form-check-label" htmlFor="default"> Default  </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input "
                                type="radio"
                                name="custom"
                                id="custom"
                                checked={buyerRequestsState === 'custom'}
                                onChange={(e) => setBuyerRequestsState('custom')}
                              />
                              <label className="form-check-label" htmlFor="custom"> Custom</label>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">No of Buyer Requests</Label>
                        <Input
                          name="buyerRequests"
                          type="text"
                          onChange={handleChangeInfoNumber} onKeyPress={allowOnlyNumbers}
                          className={'form-control'}
                          value={adUnitSettingsInfo.buyerRequests}
                          onBlur={handleChangeOnBlurRequests}
                          readOnly={buyerRequestsState === 'default'}
                        />
                      </div>
                        <div className="mb-3">
                          <Label className="form-label">Floor Price Type</Label>
                          <Input type="select" name="floorType"
                            onChange={changePostBidMeasurementType} value={adUnitSettingsInfo.floorPrice.floorType}
                            className={'form-control form-select'} >
                            <option value='RON'> RON </option>
                            <option value='GeoGroup'> Geo Group </option>
                          </Input>
                        </div>
                        <div className="mb-3">
                          {adUnitSettingsInfo.floorPrice.floorType === "RON" &&
                            <div className="row mb-3 m-1">
                              <div className="col-5"> <Label className="mt-2">RON Price </Label></div>
                              <div className="col-7">
                                <InputGroup>
                                  <div className="input-group-text">$</div>
                                  <Input
                                    name="RON"
                                    type="text"
                                    onChange={floorPriceValueChange} onKeyPress={allowOnlyNumbers}
                                    className={'form-control'}
                                    value={floorPriceValues.RON}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          }
  
                          {adUnitSettingsInfo.floorPrice.floorType === "GeoGroup" &&
                            <div>
                              <div className="row mb-3 m-1">
                                <div className="col-5" > <Label className="mt-2">ROW </Label></div>
                                <div className="col-7">
                                  <InputGroup>
                                    <div className="input-group-text">$</div>
                                    <Input
                                      name="ROW"
                                      type="text" onKeyPress={allowOnlyNumbers}
                                      onChange={floorPriceValueChange}
                                      value={floorPriceValues.ROW}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
  
                              <div className="row mb-3 m-1">
                                <div className="col-5"> <Label className="mt-2">GCC </Label></div>
                                <div className="col-7">
                                  <InputGroup>
                                    <div className="input-group-text">$</div>
                                    <Input
                                      name="GCC"
                                      type="text" onKeyPress={allowOnlyNumbers}
                                      onChange={floorPriceValueChange}
                                      value={floorPriceValues.GCC}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
  
                              <div className="row mb-3 m-1">
                                <div className="col-5"> <Label className="mt-2">Tier-1 </Label></div>
                                <div className="col-7">
                                  <InputGroup>
                                    <div className="input-group-text">$</div>
                                    <Input
                                      name="Tier1"
                                      type="text" onKeyPress={allowOnlyNumbers}
                                      onChange={floorPriceValueChange}
                                      value={floorPriceValues.Tier1}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
  
                              <div className="row mb-3 m-1">
                                <div className="col-5"> <Label className="ml-2 mt-2">APAC </Label></div>
                                <div className="col-7">
                                  <InputGroup>
                                    <div className="input-group-text">$</div>
                                    <Input
                                      name="APAC"
                                      type="text" onKeyPress={allowOnlyNumbers}
                                      onChange={floorPriceValueChange}
                                      value={floorPriceValues.APAC}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="mb-3">
                          <Label >Optimization Stratergy </Label>
                          <div className="row">
                            <Col md={6}>
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="floor"
                                  id="floor"
                                  checked={adUnitSettingsInfo.optimizationStrategy.type === 'floor'}
                                  onChange={handleChangeStratergy}
                                />
                                <label className="form-check-label" htmlFor="floor"> Optimization for Floor  </label>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input "
                                  type="radio"
                                  name="fill"
                                  id="fill"
                                  checked={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                                  onChange={handleChangeStratergy}
                                />
                                <label className="form-check-label" htmlFor="fill"> Optimization for Fill</label>
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Optimization CPM</Label>
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="price"
                              type="text"
                              onChange={handleChangeStratergyPrice} onKeyPress={allowOnlyNumbers}
                              className={'form-control'}
                              value={adUnitSettingsInfo.optimizationStrategy.price}
                              readOnly={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                            />
                          </InputGroup>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Size</Label>
                          <Input type="select" name="size"
                            onChange={handleChangeInfo} value={adUnitSettingsInfo.size}
                            className={'form-control form-select' + (submitted && !adUnitSettingsInfo.size ? ' is-invalid' : '')}
                            >
                            <option value=''> Select</option>
                            <option value="0x0">0x0 </option>
                            <option value="1x1">1x1 </option>
                            <option value="300x250">300x250 </option>
                            <option value="300x600">300x600 </option>
                            <option value="468x100">468x100 </option>
                            <option value="728x90">728x90 </option>
                            <option value="800x600">800x600 </option>
                            <option value="120x600">120x600 </option>
                            <option value="160x600">160x600 </option>
                            <option value="970x250">970x250 </option>
                            <option value="970x90">970x90</option>
                            <option value="320x50">320x50</option>
                            <option value="320x100">320x100 </option>
                            <option value="320x480">320x480 </option>
                            <option value="300x50">300x50 </option>
                            <option value="300x100">300x100 </option>
                            <option value="468x60">468x60 </option>
                            <option value="468x90">468x90</option>
                            <option value="336x280">336x280 </option>
                            <option value="800x485"> 800x485 </option>
                            <option value="320x442"> 320x442 </option>
  
                          </Input>
                          {submitted && !adUnitSettingsInfo.size ? (
                      <FormFeedback type="invalid">{'Please Select Size'}</FormFeedback>
                    ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Timeout</Label>
                          <InputGroup>
                            <Input
                              name="timeout"
                              type="text"
                              onChange={handleChangeInfo}
                              className={'form-control'} onKeyPress={allowOnlyNumbers}
                              value={adUnitSettingsInfo.timeout}
                            />
                            <div className="input-group-text">ms</div>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="mt-4 mb-4">
  
                        {adUnitSettings && adUnitSettings.map((item, index) => {
                          console.log("group............Item", item, "adUnitSettings==========", adUnitSettings);
                          return (
                            <div className="adUnitSettings-settings" key={index}>
                              <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                                <h2 className="accordion-header row m-0" id="headingOne">
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium", { collapsed: itemOpen !== ('panel' + (index)) }
                                    )}
                                    type="button"
                                    onClick={openCollapsed('panel' + (index))}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h5 className="heading5 mt-2">Condition {index + 1}</h5>
  
                                    {
                                      adUnitSettings.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Condition</button>
                                    }
                                    {
                                      adUnitSettings.length !== 1 && <button className='btn btn-danger rem-grp-btn' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removePriorities(index)}
                                      >Remove Condition</button>
                                    }
                                  </button>
  
                                </h2>
                                {/* isOpen={itemOpen === ('panel'+(index))} */}
                                <Collapse isOpen={true} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="mb-1" style={{ position: 'absolute', right: '6.5pc' }}>
                                      <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="settingsEnabled"
                                          name="settingsEnabled"
                                          checked={item.settingsEnabled}
                                          onChange={(e) => handleChangeCheck(index, e)}
                                        />
                                        <label className="form-check-label " htmlFor="settingsEnabled">
                                          {item.settingsEnabled ? <span color='text-success' style={{ color: '#34c38f' }}>Conditions Enabled </span> : <span style={{ color: '#a3a3a3' }}>Settings Disabled</span>}
                                        </label>
                                      </div>
                                    </div>
  
                                    <div className="mb-3">
                                      <Label >Configuration </Label>
                                      <div className="row">
                                        <Col md={4}>
                                          <div className="form-check mt-2 mb-2">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="SimpleMode"
                                              id="SimpleMode"
                                              checked={item.settingConfigMode === 'SimpleMode'}
                                              onChange={handleChangeMode(index)}
                                            />
                                            <label className="form-check-label" htmlFor="SimpleMode"> Simple mode  </label>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div className="form-check mt-2 mb-2">
                                            <input
                                              className="form-check-input "
                                              type="radio"
                                              name="RawMode"
                                              id="RawMode"
                                              checked={item.settingConfigMode === 'RawMode'}
                                              onChange={handleChangeMode(index)}
                                            />
                                            <label className="form-check-label" htmlFor="RawMode"> Raw mode</label>
                                          </div>
                                        </Col>
                                      </div>
                                    </div>
                                    {item.settingConfigMode === 'SimpleMode' &&
                                      <div className=" mb-3">
                                        {(Array.isArray(item.settingInfo)) && item.settingInfo.map((superSubItem, n) => {
                                          return (
                                            <div key={n}>
                                              <div className="contentSettings">
                                                {/* {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>} */}
                                                <div className="row mt-1">
                                                  <div className="col-4">
                                                    <div className="mb-3">
                                                      <Input
                                                        name="ParameterName"
                                                        type="text"
                                                        onChange={handleChangeUser(n, index)}
                                                        value={superSubItem.ParameterName}
                                                        className={'form-control'}
                                                        placeholder="Enter Key"
                                                      />
                                                    </div>
                                                    <br />
                                                  </div>
                                                  <div className="col-7">
                                                    <div className="mb-3">
                                                      {superSubItem.type === 'integer' ?
                                                        <Input
                                                          name="value"
                                                          type="text"
                                                          onChange={handleChangeUser(n, index)} onKeyPress={allowOnlyNumbers}
                                                          value={superSubItem.value}
                                                          className={'form-control'}
                                                          readOnly={props.selectedArchived}
                                                          placeholder="Enter Value"
                                                        /> : superSubItem.type === 'boolean' ?
                                                          <Input
                                                            name="value"
                                                            type="select"
                                                            onChange={handleChangeUser(n, index)}
                                                            value={superSubItem.value}
                                                            readOnly={props.selectedArchived}
                                                            className={'form-control form-select'}
                                                          >
                                                            <option value="">Select  </option>
                                                            <option value={true}>True</option>
                                                            <option value={false}>False</option>
                                                          </Input> : <Input
                                                            name="value"
                                                            type="text"
                                                            onChange={handleChangeUser(n, index)}
                                                            value={superSubItem.value}
                                                            readOnly={props.selectedArchived}
                                                            className={'form-control'}
                                                            placeholder="Enter Value"
                                                          />
                                                      }
                                                    </div>
                                                    <br />
                                                  </div>
                                                  <div className="col-1 p-0">
                                                    <button type='button' className="text-danger waves-effect btn btn-link" onClick={removeDirectContent(n, index)} style={{ position: 'absolute', right: '50%' }} >
                                                      <i
                                                        className="bx bx-trash font-size-18"
                                                        id="edittooltip"
                                                      ></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })
                                        }
                                        {/* {createContentUI()} */}
                                        <div className='col-12'>
                                          <div className='d-flex'>
                                            <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent(index)} >Add Parameter &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
  
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    {item.settingConfigMode === 'RawMode' &&
                                      <div className="mb-3">
                                        <Label className="form-label">JSON : </Label>
                                        <Input
                                          name="settingInfo"
                                          type="textarea"
                                          rows="7"
                                          onChange={(e) => handleChange(index, e)}
                                          className={'form-control'}
                                          value={JSON.stringify(item.settingInfo)}
                                          readOnly
                                        />
                                      </div>
                                    }
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          )
                        }
                        )}
                        <div className='row'>
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                            onClick={addNewAdUnitSettings}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add New Condition
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
              <Col>
                <div className="text-start">
  
  
                  {isEdit &&
                    <button
                      type="submit"
                      className="btn btn-primary save-user"
                    >
                      Update
                    </button>
                  }
                  {!isEdit &&
                    <button
                      type="submit"
                      className="btn btn-primary save-user"
                    >
                      Save
                    </button>
                  }
  
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    // eslint-disable-next-line react/prop-types
                    onClick={props.closeCanvas}
                  >
                    Back
                  </button>
  
                </div>
              </Col>
            </Row>
          </Form>
          {/* {
            errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message} />
          } */}
        </div >
        {/* {
          errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
          <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
          </div>
        } */}
      </React.Fragment >
    )
  }

AssociateBuyerSettings.propTypes = {

}

export default AssociateBuyerSettings
