/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import NotFoundImage from 'assets/images/bidsxchange/writer.svg';
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";

const ErrorPage = ({ error, resetErrorBoundary }) => {
    console.log('Error occured', error);
    const nextRef = useRef();
    // console.log(window.history.previous.href)
    const toggleMenu = () => {
      console.log(window.history)
      // nextRef.current.click()
      window.history.back()
    }
    return (
        <div className="page-content">
        <MetaTags>
          <title>Error Page | Bidsxchange</title>
        </MetaTags>
      <div className='error-page'>
        <img src={NotFoundImage} alt='Page not found' />
        <p className='error-msg'>
          Something went wrong. Try clicking the refresh page button to reload the
          application.
          <button className='btn' onClick={toggleMenu}>
            Refresh page
          </button>
          {/* <Link ref={nextRef} to={window.history.previous.href} className="color-dark-blue ">
            </Link> */}
        </p>
      </div>
      </div>
    );
  };

export default ErrorPage