import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CATEGORY,ADD_CATEGORY ,  GET_CATEGORY_TYPE,ADD_CATEGORY_TYPE ,UPDATE_CATEGORY,READ_CATEGORY,STATUS_CATEGORY,ARCHIVE_CATEGORY} from "./actionTypes"

import {
  loadActivitiesCategory,
  noCategory,
  getCategorySuccess,
  getCategoryFail,
  addCategorySuccess,
  addCategoryFail,
  updateCategorySuccess,
  updateCategoryFail,
  readCategorySuccess,
  statusCategorySuccess,
  archiveCategorySuccess,
  getCategoryTypeSuccess,
  getCategoryTypeFail,
  addCategoryTypeSuccess,
  addCategoryTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getCategory,postCategory,getCategoryTypes,postCategoryType,updateCategory,readCategory,statusChangeCategory,archiveCategory } from "../../../helpers/Backend_helper"

function* fetchCategory({ payload: ctg }) {
  try {
    yield put(loadActivitiesCategory(true))
    var getCtgList
      if(ctg){
        getCtgList = yield call(getCategory,ctg)
        getCtgList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
      if(getCtgList.responseData.response.length === 0){
        yield put(noCategory(true))
      }
        yield put(getCategorySuccess(getCtgList.responseData.response))
      }
      else{
        getCtgList = []
        yield put(getCategorySuccess(getCtgList))
      }

  } catch (error) {
    yield put(getCategoryFail(error))
  }
}

function* onReadCategory({ payload: ctg }) {
  try {
    const response = yield call(readCategory, ctg)
    yield put(readCategorySuccess(response))
  } catch (error) {
    yield put(readCategorySuccess(error))
  }
}

function* onAddCategory({ payload: ctg }) {
  try {
    const response = yield call(postCategory, ctg)
    if(response.responseData.success){
      yield put(addCategorySuccess(response.responseData))
    }else{
      yield put(addCategoryFail(response.responseData))
    }
  } catch (error) {
    yield put(addCategoryFail(error))
  }
}

function* onUpdateCategory({ payload: ctg }) {
  try {
    const response = yield call(updateCategory, ctg)
    if(response.responseData.success){
      yield put(updateCategorySuccess(response))
    }else{
      yield put(updateCategoryFail(response.responseData))
    }
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onStatusCategory({ payload: ctg }) {
  try {
    const response = yield call(statusChangeCategory, ctg)
    yield put(statusCategorySuccess(response))
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onArchiveCategory({ payload: ctg }) {
  try {
    const response = yield call(archiveCategory, ctg)
    yield put(archiveCategorySuccess(response))
  } catch (error) {
    yield put(archiveCategorySuccess(error))
  }
}

function* fetchCategoryType({ payload: ctg }) {
  try {
    yield put(loadActivitiesCategory(true))
    var getCtgList
      if(ctg){
        getCtgList = yield call(getCategoryTypes,ctg)
        getCtgList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
        yield put(getCategoryTypeSuccess(getCtgList.responseData.response))
      }
      else{
        getCtgList = []
        yield put(getCategoryTypeSuccess(getCtgList))
      }

  } catch (error) {
    yield put(getCategoryTypeFail(error))
  }
}

function* onAddCategoryType({ payload: ctg }) {
  try {
    const response = yield call(postCategoryType, ctg)
    if(response.responseData.success){
      yield put(addCategoryTypeSuccess(response.responseData))
    }else{
      yield put(addCategoryTypeFail(response.responseData.message))
    }
  } catch (error) {
    yield put(addCategoryTypeFail(error))
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(ADD_CATEGORY, onAddCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(READ_CATEGORY, onReadCategory)
  yield takeEvery(STATUS_CATEGORY, onStatusCategory)
  yield takeEvery(ARCHIVE_CATEGORY, onArchiveCategory)
  yield takeEvery(GET_CATEGORY_TYPE, fetchCategoryType)
  yield takeEvery(ADD_CATEGORY_TYPE, onAddCategoryType)
}

export default categoriesSaga;
