import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGOUT_USER, SOCIAL_LOGIN, LOGIN_USERs } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, loginSuccesss } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postLogin
} from "../../../helpers/Backend_helper";

//Import axios *Prash*
import axios from "axios";

var Headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

function* loginUsers({ payload: { user, history, state } }) {
  try {
    // console.log(localStorage.getItem('previousNavigation'))
    const previousURL = localStorage.getItem('previousNavigation');
    // console.log(state)
    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
    });
    // console.log(response)
    if (JSON.stringify(response) !== '{}') {
      if (response.token) {
        localStorage.removeItem('authUser');
        localStorage.removeItem('LoginTime');
        localStorage.setItem("darkMode", 'NO');
        // if (response.userRole !== 'Root' && response.userRole !== 'SuperAdmin') {
        //   history.push("/page-maintenance");
        //   return false
        // }else{
        //   localStorage.setItem("authUser", JSON.stringify(response));
        // }
        localStorage.setItem("authUser", JSON.stringify(response));
       
        yield put(loginSuccess(response));
        if (state === 'login') {
          localStorage.setItem("LoginTime", Date.now());
          // if (response.userRole === 'AdOps') {
          //   localStorage.setItem("UserRole", 'AdOps');
          //   localStorage.setItem("ParentRole", 'AdOps');
          //   history.push("/AdOps_dashboard");
          // }
          // else 
          localStorage.setItem("InventoryType", response.inventoryType);
          localStorage.setItem("loginStatus", 'LoggedIn');
          localStorage.setItem("alertStatus", 'closed');
          sessionStorage.setItem("collapseButton", false);
          var filterBody = {
            userRole: response.userRole,
            userID:response.userID
          }
          axios.post("/v1.0/AMRole/copyRolePrivileges", filterBody,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": `Bearer ${response.token}`
              }
            })
            .then((res) => {
              // console.log("cdsresult is ==>", res)
              if (res.data) {
                // console.log(res.data)
                localStorage.setItem("UserRolePrivileges", JSON.stringify(res.data));
                localStorage.setItem("AdminRolePrivileges", JSON.stringify(res.data));
                let UserRolePrivileges = res.data

                if (response.userRole === 'Partner') {
                  let body = {
                    url: "http://127.0.0.1:3006/v1.0/Partnertype/check",
                    requestBody: {
                      userID: response.userID,
                      userRole: response.userRole,
                      companyID: response.companyID,
                      name: response.name,
                      partnerID: response.userID
                    }
                  }
                  axios.post("/v1.0/connectPartner", body,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "Accept": 'application/json',
                        "Authorization": `Bearer ${response.token}`
                      }
                    })
                    .then((res) => {
                      console.log("cdsresult is ==>", res)
                      if (res.data.responseData.success) {
                        if (res.data.responseData.partnerType === 'agency') {
                          let data = {...response,partnerCurrencyType:res.data.responseData.partnerCurrencyType}
                          localStorage.setItem("authUser", JSON.stringify(data));
                          localStorage.setItem("UserRole", 'Agency');
                          localStorage.setItem("ParentRole", 'Agency');
                          localStorage.setItem("Terms", 'Accepted');
                          localStorage.setItem("NotificationStatus", 'open');
                          history.push("/Dashboard");
                        } else {
                          localStorage.setItem("UserRole", 'Publisher');
                          localStorage.setItem("ParentRole", 'Publisher');
                          localStorage.setItem("Terms", 'Accepted');
                          localStorage.setItem("NotificationStatus", 'open');
                          history.push("/Dashboard");
                        }
                      } else {
                        localStorage.setItem("UserRole", 'Publisher');
                        localStorage.setItem("ParentRole", 'Publisher');
                        localStorage.setItem("Terms", 'Accepted');
                        localStorage.setItem("NotificationStatus", 'open');
                        history.push("/Dashboard");
                      }

                    })
                    .catch((err) => {
                      console.log("e", err)
                      localStorage.setItem("UserRole", 'Publisher');
                      localStorage.setItem("ParentRole", 'Publisher');
                      localStorage.setItem("Terms", 'Accepted');
                      localStorage.setItem("NotificationStatus", 'open');
                      history.push("/Dashboard");
                    });
                }
                else if (response.userRole === 'Root') {
                  localStorage.setItem("UserRole", 'Admin');
                  localStorage.setItem("ParentRole", 'Root');
                  localStorage.setItem("NotificationStatus", 'open');
                  history.push("/Dashboard");
                }
                else if (response.userRole === 'SuperAdmin') {
                  localStorage.setItem("UserRole", 'Admin');
                  localStorage.setItem("ParentRole", 'Root');
                  localStorage.setItem("NotificationStatus", 'open');
                  history.push("/Dashboard");
                }
                else {
                  localStorage.setItem("UserRole", 'User');
                  if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isRevenueAccess && UserRolePrivileges.Dashboard.isAnalyticsAccess) {
                    history.push("/Dashboard");
                  } else if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isRevenueAccess && !UserRolePrivileges.Dashboard.isAnalyticsAccess) {
                    history.push("/Dashboard");
                  }
                  else if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isAnalyticsAccess && !UserRolePrivileges.Dashboard.isRevenueAccess) {
                    history.push("/Dashboard");
                  }
                  else {
                    history.push("/Dashboard");
                  }
                }
              }
            })
            .catch((err) => {
              console.log("e", err)
            });

        } else {
          localStorage.removeItem('previousNavigation')
          history.push(previousURL);
        }

      } else {
        yield put(apiError(response.authorization));
      }
      if (response.passwordExpired) {
        yield put(apiError('Your Password is Expired. Reset link has been sent to your registered Email'));
      }
    }
    else {
      yield put(apiError('Wrong Credentials'));
    }
  } catch (error) {
    console.log(JSON.stringify(error))
    if (error.message === "Request failed with status code 401") {
      yield put(apiError('Wrong Credentials'));
    }
    if (error.message === "Request failed with status code 504") {
      history.push("/page-maintenance");
    }
  }
}

const loginData = (user) => {
  let item = { username: user.email, password: user.password };
  axios.post("/authenticate", item, { headers: Headers })
    .then((res) => {
      console.log("result", res);
      return res
    })
    .catch((err) => {
      console.log("e", err);
      return err
    });
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/Dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  // yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_USERs, loginUsers);
}

export default authSaga;
