/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

const LoadingTableContextual = props => {

    const [timer, setTimer] = useState({ count: 0 })
    const increment = useRef(null)

    useEffect(() => {
        handleStart()
	}, []);

    useEffect(() => {
        // console.log(timer.count,props.loading)
        if(props.loading){
            // if (timer.count >= 6) {
            //     clearInterval(increment.current)
            //     setTimer({ count: 0 })
            //     props.targetLoading()
            // }
        }else{
            if (timer.count >= 1) {
                clearInterval(increment.current)
                setTimer({ count: 0 })
                props.targetLoading()
            }
        }
	
	}, [timer.count,props.loading]);

    const handleStart = () => {
		increment.current = setInterval(() => {
			setTimer(prevState => ({
				count: prevState.count + 1
			}))
      console.log()
		}, 1000)
	}

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 placeholder-glow">
                    <div className="card ">
                        <div className="card-body">
                            <div className="mb-2 row ">
                                <div className="col-sm-8 ">
                                    <div className="search-box ms-2 mb-2 d-inline-block ">
                                        <div className="position-relative ">
                                            <label  className="search-label">
                                                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search"  />
                                            </label><i className="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-xl-12 ">
                                    <div className="table-responsive " style={{overflow:'hidden'}}>
                                        <div className="react-bootstrap-table " style={{overflow:'hidden'}}>
                                            <table className="table table align-middle table-nowrap " style={{overflow:'hidden'}}>
                                                <thead className="thead-light row">
                                                    <tr className='col-12 '>
                                                        <th  aria-label="ID sortable" className="sortable placeholder" style={{width:'20%',whiteSpace:'pre-wrap'}}>   <span className="placeholder col-7" style={{height:'1.3pc'}}></span></th>
                                                        <th  aria-label="Creation Date sortable" className="sortable placeholder" style={{width:'20%',whiteSpace:'pre-wrap'}}>   <span className="placeholder col-7" style={{height:'1.3pc'}}></span></th>
                                                        <th  aria-label="AdUnit Group Name sortable" className="sortable placeholder" style={{width:'20%',whiteSpace:'pre-wrap'}}>   <span className="placeholder col-7" style={{height:'1.3pc'}}></span></th>
                                                        <th  aria-label="AdUnit Group Type sortable" className="sortable placeholder" style={{width:'20%',whiteSpace:'pre-wrap'}}>   <span className="placeholder col-7" style={{height:'1.3pc'}}></span></th>
                                                        <th  aria-label="Status sortable" className="sortable placeholder" style={{width:'20%',whiteSpace:'pre-wrap'}}>   <span className="placeholder col-7" style={{height:'1.3pc'}}></span></th>
                                                    </tr>
                                                </thead>
                                                <tbody className=' row loadingTableData' >
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                    <tr className=' col-12'>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '  >   <span className="placeholder col-7"></span></td>
                                                        <td className=' '>   <span className="placeholder col-7"></span></td>
                                                        <td className=' ' >   <span className="placeholder col-7"></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

LoadingTableContextual.propTypes = {}

export default LoadingTableContextual