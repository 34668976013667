/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import Toaster from "pages/Toaster/Toaster";

import {
  getRoles as onGetRoles,
  addNewRole as onADDRole,
  updateRole as onUpdateRole,
  addRoleSuccess,
  addRoleFail,
  updateRoleSuccess,
  updateRoleFail,
} from "store/RoleManagement/actions";
import { event } from "jquery";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const New_Role = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userRole, setUserRole] = useState('')
  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const [reporting, setReporting] = useState(false);
  const [reportUpload, setReportUpload] = useState(false);
  const [partnerManagement, setPartnerManagement] = useState(false);
  const [billing, setBilling] = useState(false);
  const [contextual, setContextual] = useState(false);
  const [tools, setTools] = useState(false);

  const [admin, setAdmin] = useState(false);
  const [accessManagement, setAccessManagement] = useState(false);
  const [adsTxt, setAdsTxt] = useState(false);
  const [masterLog, setMasterLog] = useState(false);
  const [publisherLog, setPublisherLog] = useState(false);
  const [pubNotification, setPubNotification] = useState(false);
  const [prodNotification, setProdNotification] = useState(false);

  const [finalData, setFinalData] = useState(
    {
      "roleName": "",
      "roleDescription": "",
      "rolePrivileges": {
        "Dashboard": {
          "Revenue": true,
          "Analytics": true,
          isRevenueAccess: false,
          isAnalyticsAccess: false,
          isAgencyAccess: false
        },
        "SwitchAccount": {
          isPartnerAccountAccess: false,
          isAgencyAccountAccess: false
        },
        "Demand": {
          "TC": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "IOs": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "IOGroups": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "Creatives": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HeaderBidding": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "LineItems": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "Advertisers": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          isAdveriserAccess: false,
          isIOGroupAccess: false,
          isIOsAccess: false,
          isLineItemAccess: false,
          isTCAccess: false,
          isCreativeAccess: false,
          isHeaderBiddingAccess: false,
        },
        "adminAccess": {
          "RoleManagement": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "UserManagement": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "Notification": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          isRoleManagementAccess: false,
          isUserManagementAccess: false,
          isNotificationAccess: false,
          isPublisherApprovalAccess: false,
          isSupportTicketAccess: false,
        },
        "Supply": {
          "AdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "partners": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "Publishers": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "AdUnitGroups": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "ChildAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HighImpactSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "STDBSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "PlayerSettings": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          isPartnerAccess: false,
          isPublisherAccess: false,
          isAdUnitGroupAccess: false,
          isAdUnitAccess: false,
          isChildAdUnitAccess: false,
          isHighImpactSettingAccess:false,
          isSTDBSettingAccess:false,
          isPlayerSettingAccess:false

        },
        "HouseAds": {
          "Fsrv": {
            "read": true,
            "report": true
          },
          "NativeAds": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HouseAdsAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HouseAdsCreatives": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HouseAdsCategories": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          "HouseAdsPublishers": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          isHouseAdsPublisherAccess: false,
          isHouseAdsAdUnitAccess: false,
          isHouseAdCreativeAccess: false,
          isNativeAdAccess: false,
          isHouseAdsCategoryAccess: false,
          isFsrvAccess: false,
        },
        "ProxyAds": {
          "Ssrv": {
            "read": true,
            "report": true
          },
          "ProxyAdUnits": {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          },
          isProxyAdUnitAccess: true,
          isSsrvAccess: true,
        },
        "PartnerManagement": {
          PartnerAccess: {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          }
        },
        "Report": {
          "Query": {
            "Pageurl": true,
            "Revenue": true,
            "Analytic": true
          },
          isQueryAccess: true,
          isPageUrlReportAccess: false,
          isRevenueReportAccess: false,
          isAnalyticReportAccess: false,
          isYMAnalyticReportAccess: false,
          isASAnalyticReportAccess: false,
          isMISAnalyticReportAccess: false,
          isTeamPageUrlReportAccess: false,
          isAgencyReportAccess: false,
        },
        "ReportUploading": {
          "PublihserReport": {
            "Revenue": true
          },
          isPublihserReportAccess: true,
          isAgencyReportUploadAccess: false,
        },
        "Billing": {
          "Invoices": {
            "Approve": true,
            "History": true,
            "Payment": true
          },
          isInvoiceApproveAccess: false,
          isInvoiceHistoryAccess: false,
          isInvoicePaymentAccess: false
        },

        ContextualSettings: {
          isBrandAffinityAccess: false,
          isInterestAccess: false,
          isKeywordAccess: false,
          isPageUrlAccess: false,
          isWebCategorizationAccess: false
        },
        Tools: {
          isVideoCompresser: false,
          isVideoContentLibrarayAccess: false,
          isScreenShotToolAccess: false,
          isAdTagTesterAccess: false,
          isVastTagTesterAccess: false,
          isAdsTxtValidatorAccess: false
        },
        VCs: {
          isVideoContentLibrarayAccess: false,
          isVideoPlayListAccess: false,
          isVideoImportAccess: false,
          isContentMarketplaceAccess: false,
        },
        "admin": {
          "ads.txtEntries": {
            "read": true,
            "create": true,
            "update": true
          },
          "ads.txtMonitor": {
            "read": true
          },
          isAccessManagementAccess: false,
          isAdsTxtEntriesAccess: false,
          isAdsTxtMonitorAccess: false,
          isAdsTxtManagementAccess: false,
          isMasterLogAccess: false,
          isPublisherLogAccess: false,
        },
        "TagController": {
          TCs: {
            "read": true,
            "create": false,
            "status": false,
            "update": false,
            "archive": false
          }
        },
        isDashboardAccess: false,
        isDemandAccess: false,
        isSupplyAccess: false,
        isHouseAdAccess: false,
        isProxyAdAccess: false,
        isReportUploadingAccess: false,
        isReportAccess: false,
        isBillingAccess: false,
        isPartnerManagementAccess: false,
        isSwitchManagementAccess: false,
        isContextualSettingsAccess: false,
        isHBSettingAccess: false,
        isNetworkBlockingAccess: false,
        isNetworkSettingAccess: false,
        isToolsAccess: false,
        isVCsAccess: false,
        isAdminAccess: false,
        isTagControllerAccess: false,
      }
    }
  )

  const selectDashboard = (event) => (e) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, Dashboard: {
          ...finalData.rolePrivileges.Dashboard,
          isRevenueAccess: event,
          isAnalyticsAccess: event,
          isAgencyAccess: event
        }
      }
    }))
  }

  const selectDemand = (event) => (e) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, Demand: {
          ...finalData.rolePrivileges.Demand,
          isAdveriserAccess: event,
          isIOGroupAccess: event,
          isIOsAccess: event,
          isLineItemAccess: event,
          isTCAccess: event,
          isCreativeAccess: event,
          isHeaderBiddingAccess: event,
        }
      }
    }))
  }

  const selectSupply = (event) => (e) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, Supply: {
          ...finalData.rolePrivileges.Supply,
          isPartnerAccess: event,
          isPublisherAccess: event,
          isAdUnitGroupAccess: event,
          isAdUnitAccess: event,
          isChildAdUnitAccess: event,
          isHighImpactSettingAccess:event,
          isSTDBSettingAccess:event,
          isPlayerSettingAccess:event
        }
      }
    }))
  }

  const selectHouseAd = (event) => (e) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, HouseAds: {
          ...finalData.rolePrivileges.HouseAds,
          isHouseAdsPublisherAccess: event,
          isHouseAdsAdUnitAccess: event,
          isHouseAdCreativeAccess: event,
          isNativeAdAccess: event,
          isHouseAdsCategoryAccess: event,
          isFsrvAccess: event,
        }
      }
    }))
  }

  const selectProxy = (event) => (e) => {
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges, ProxyAds: {
          ...finalData.rolePrivileges.ProxyAds,
          isProxyAdUnitAccess: event,
          isSsrvAccess: event,
        }
      }
    }))
  }

  const changeData = (parent, child, action) => (e) => {
    const { name, value } = e.target;
    console.log(e.target.checked)
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges,
        [parent]: {
          ...finalData.rolePrivileges[parent],
          [child]: { ...finalData.rolePrivileges[parent][child], [name]: e.target.checked }
        }
      }
    }));
  }

  const createReportUI = () => {
    return (
      <div className="row">

        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
            />
            <label
              className="form-check-label"
            >
              Generate
            </label>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
            />
            <label
              className="form-check-label"
            >
              Download
            </label>
          </div>
        </Col>
      </div>
    )

  }

  const changeChildAccess = (parent, child, action) => (e) => {
    const { name, value } = e.target;
    console.log(e.target.checked)
    setFinalData(finalData => ({
      ...finalData, rolePrivileges: {
        ...finalData.rolePrivileges,
        [parent]: {
          ...finalData.rolePrivileges[parent],
          [child]: { ...finalData.rolePrivileges[parent][child], read: action, create: action, status: action, update: action, archive: action, }
        }
      }
    }));
  }

  const createUI = (parent, child) => {
    console.log(parent, child,finalData.rolePrivileges[`${parent}`][`${child}`])
    return (
      <div className="row">
        <Col md={2} className="mt-2 mb-2">
          Select :  &nbsp; <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
          <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, false)} style={{ cursor: 'pointer' }}>None</Link>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="read"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['read']}
              onChange={changeData(`${parent}`, `${child}`, `read`)}
              id={`${parent}${child}Read`}
              disabled
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Read`}
            >
              Read
            </label>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="create"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['create']}
              onChange={changeData(`${parent}`, `${child}`, `create`)}
              id={`${parent}${child}Create`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Create`}
            >
              Create
            </label>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="update"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['update']}
              onChange={changeData(`${parent}`, `${child}`, `update`)}
              id={`${parent}${child}Update`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Update`}
            >
              Update
            </label>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="archive"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['archive']}
              onChange={changeData(`${parent}`, `${child}`, `archive`)}
              id={`${parent}${child}Archive`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}Archive`}
            >
              Archive
            </label>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              name="status"
              checked={finalData.rolePrivileges[`${parent}`][`${child}`]['status']}
              onChange={changeData(`${parent}`, `${child}`, `status`)}
              id={`${parent}${child}ActiveDeactivate`}
            />
            <label
              className="form-check-label"
              htmlFor={`${parent}${child}ActiveDeactivate`}
            >
              Active / DeActivate
            </label>
          </div>
        </Col>
      </div>
    )

  }

  const { roles } = useSelector(state => ({
    roles: state.roles.roles,
  }));

  useEffect(() => {
    if (roles !== null) {
      dispatch(onGetRoles({ filter: '' }));
    }
  }, []);

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.roles.errorMsg,
    successMsg: state.roles.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addRoleSuccess(""));
        dispatch(addRoleFail(null));
        dispatch(updateRoleSuccess(""));
        dispatch(updateRoleFail(null));
        setLoader(false)
        if (props.selectedRole !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addRoleSuccess(""));
        dispatch(addRoleFail(null));
        dispatch(updateRoleSuccess(""));
        dispatch(updateRoleFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addRoleSuccess(""));
    dispatch(addRoleFail(null));
    dispatch(updateRoleSuccess(""));
    dispatch(updateRoleFail(null));
        props.closeCanvas('')
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(finalData => ({ ...finalData, [name]: value }));
  }

  const handleChangeRole = (e) => {
    setUserRole(e.target.value)
    var filterBody = {
      userRole: e.target.value
    }
    axios.post("/v1.0/AMRole/copyRolePrivileges", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ...res.data } }));
        }

      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  useEffect(() => {
    if (props.selectedRole !== '') {
      setIsEdit(true)
      console.log(finalData, props.selectedRole)
      let roleData = { ...finalData.rolePrivileges, ...props.selectedRole.rolePrivileges }
      console.log(roleData)
      setFinalData(finalData => ({ ...finalData, ...props.selectedRole }));
      setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...roleData } }));
      console.log(finalData)
      console.log(finalData.rolePrivileges['Demand']['Advertisers'])
      console.log(finalData.rolePrivileges['adminAccess']['RoleManagement'])
      if(props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.HighImpactSettings){
        setFinalData(finalData => ({
          ...finalData, rolePrivileges: {
            ...finalData.rolePrivileges, Supply: {
              ...finalData.rolePrivileges.Supply,
              "HighImpactSettings": {
                "read": true,
                "create": false,
                "status": false,
                "update": false,
                "archive": false
              },isHighImpactSettingAccess:false,
            }
          }
        }))
      }
      if(props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.PlayerSettings){
        setFinalData(finalData => ({
          ...finalData, rolePrivileges: {
            ...finalData.rolePrivileges, Supply: {
              ...finalData.rolePrivileges.Supply,
              "PlayerSettings": {
                "read": true,
                "create": false,
                "status": false,
                "update": false,
                "archive": false
              },
              isPlayerSettingAccess:false
            }
          }
        }))
      }
      if(props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.STDBSettings){
        setFinalData(finalData => ({
          ...finalData, rolePrivileges: {
            ...finalData.rolePrivileges, Supply: {
              ...finalData.rolePrivileges.Supply,
              "STDBSettings": {
                "read": true,
                "create": false,
                "status": false,
                "update": false,
                "archive": false
              }, isSTDBSettingAccess:false,
            }
          }
        }))
      }
      if (props.selectedRole.rolePrivileges.Demand && !props.selectedRole.rolePrivileges.Demand.HeaderBidding) {
        setFinalData(finalData => ({
          ...finalData, rolePrivileges: {
            ...finalData.rolePrivileges, Demand: {
              ...finalData.rolePrivileges.Demand,
              "HeaderBidding": {
                "read": true,
                "create": false,
                "status": false,
                "update": false,
                "archive": false
              }, isHeaderBiddingAccess: false,
            }
          }
        }))
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (finalData.roleName) {
      setLoader(true)
      if (props.selectedRole !== '') {
        dispatch(onUpdateRole({ ...finalData,roleName:finalData.roleName.trim() }));
      } else {
        dispatch(onADDRole({ ...finalData,roleName:finalData.roleName.trim() }));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="col-12">
        {loader &&
          <Spinner className="ms-2 loader" color="primary" />
        }
        <Form onSubmit={handleSubmit}>
          <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <div className="mb-3 w-75">
                <Label className="form-label"> Name</Label>
                <Input
                  name="roleName"
                  type="text"
                  value={finalData.roleName}
                  onChange={handleChange}
                  className={'form-control' + (submitted && !finalData.roleName ? ' is-invalid' : '')}
                />
                {submitted && !finalData.roleName && (
                  <FormFeedback type="invalid">{'Name is required'}</FormFeedback>
                )}
              </div>
              <div className="mb-3 w-75">
                <Label className="form-label"> Copy Permission from role</Label>
                <Input
                  name="userRole"
                  type="select"
                  onChange={handleChangeRole}
                  value={userRole}
                  className={' form-select'}
                >
                  <option value=''>Select</option>
                  {roles && roles.map((elem) => (
                    <option key={elem.roleID} value={elem.roleName}>{elem.roleName}</option>
                  ))}
                </Input>
              </div>
              <div className="mb-3 w-75">
                <Label className="form-label"> Description</Label>
                <Input
                  type="text"
                  name="roleDescription"
                  value={finalData.roleDescription}
                  onChange={handleChange}
                />
              </div>
              <hr />
              <h5>Access</h5>

              <Row className="m-4">
                {/* <div className="form-check mt-2 mb-2 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isDashboardAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData,rolePrivileges:{...finalData.rolePrivileges , isDashboardAccess:e.target.checked }}))}
                  />
                  <label
                    className="form-check-label"
                  >
                    Dashboard
                  </label>
                </div> */}
                <label
                  className="form-check-label"
                >
                  Dashboards
                </label>
                <div>
                  <Col md={12} className="m-1">
                    Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectDashboard(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                    <Link to="#" className="text-blue link" onClick={selectDashboard(false)} style={{ cursor: 'pointer' }}>None</Link>
                  </Col>
                  <Col md={12} className="m-1">
                    <Row>
                      <Col md={4}>
                        <div className="form-check mt-2 mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={finalData.rolePrivileges.Dashboard.isRevenueAccess}
                            onChange={(e) => setFinalData(finalData => ({
                              ...finalData, rolePrivileges: {
                                ...finalData.rolePrivileges, Dashboard: {
                                  ...finalData.rolePrivileges.Dashboard,
                                  isRevenueAccess: e.target.checked
                                }
                              }
                            }))}
                            id="marketplaceDashboard"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="marketplaceDashboard"
                          >
                            Marketplace Dashboard
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="m-1">
                    <Row>
                      <Col md={4}>
                        <div className="form-check mt-2 mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={finalData.rolePrivileges.Dashboard.isAnalyticsAccess}
                            onChange={(e) => setFinalData(finalData => ({
                              ...finalData, rolePrivileges: {
                                ...finalData.rolePrivileges, Dashboard: {
                                  ...finalData.rolePrivileges.Dashboard,
                                  isAnalyticsAccess: e.target.checked
                                }
                              }
                            }))}
                            id="analytics"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="analytics"
                          >
                          Server Dashboard
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="m-1">
                  <Row>
                    <Col md={4}>
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Dashboard.isAgencyAccess}
                          onChange={(e) => setFinalData(finalData => ({
                            ...finalData, rolePrivileges: {
                              ...finalData.rolePrivileges, Dashboard: {
                                ...finalData.rolePrivileges.Dashboard,
                                isAgencyAccess: e.target.checked
                              }
                            }
                          }))}
                          id="isAgencyAccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isAgencyAccess"
                        >
                          Agency Dashboard
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
                </div>

              </Row>
              <Row className="m-4">
              <div className="form-check mt-2 mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                checked={finalData.rolePrivileges.isSwitchManagementAccess}
                onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSwitchManagementAccess: e.target.checked } }))}
                id="swithUserManagement"
              />
              <label
                className="form-check-label"
                htmlFor="swithUserManagement"
              >
                Switch User Management
              </label>
              </div>
              {finalData.rolePrivileges.isSwitchManagementAccess &&
                <div>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.SwitchAccount.isPartnerAccountAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, SwitchAccount: { ...finalData.rolePrivileges.SwitchAccount, isPartnerAccountAccess: e.target.checked } } }));
                        }
                        }
                        id="isPartnerAccountAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isPartnerAccountAccess"
                      >
                        Partner Accounts
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.SwitchAccount.isAgencyAccountAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, SwitchAccount: { ...finalData.rolePrivileges.SwitchAccount, isAgencyAccountAccess: e.target.checked } } }));
                        }
                        }
                        id="isAgencyAccountAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAgencyAccountAccess"
                      >
                        Agency Accounts
                      </label>
                    </div>
                  </Col>
                </div>
              }
            </Row>
              <Row className="m-4">
                <div className="form-check mt-2 mb-2 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isDemandAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isDemandAccess: e.target.checked } }))}
                    id="demand"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="demand"
                  >
                    Demand
                  </label>
                </div>

                {finalData.rolePrivileges.isDemandAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectDemand(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                      <Link to="#" className="text-blue link" onClick={selectDemand(false)} style={{ cursor: 'pointer' }}>None</Link>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isAdveriserAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isAdveriserAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="advertiser"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="advertiser"
                            >
                              Advertiser
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isAdveriserAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'Advertisers')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isIOGroupAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isIOGroupAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="IoGroup"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="IoGroup"
                            >
                              IO Group
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isIOGroupAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'IOGroups')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isIOsAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isIOsAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="insertionOrder"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="insertionOrder"
                            >
                              Insertion Order
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isIOsAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'IOs')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isLineItemAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isLineItemAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="lineItem"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="lineItem"
                            >
                              Line Item
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isLineItemAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'LineItems')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    {/* <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isTCAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isTCAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="tagController"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tagController"
                            >
                              Tag Controller
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isTCAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'TC')}
                          </Col>
                        }
                      </Row>
                    </Col> */}
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Demand.isCreativeAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Demand: {
                                    ...finalData.rolePrivileges.Demand,
                                    isCreativeAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="creative"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="creative"
                            >
                              Creative
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Demand.isCreativeAccess &&
                          <Col md={10} >
                            {createUI('Demand', 'Creatives')}
                          </Col>
                        }
                      </Row>
                    </Col>

                    <Col md={12} className="m-1">
                    <Row>
                      <Col md={2}>
                        <div className="form-check mt-2 mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={finalData.rolePrivileges.Demand.isHeaderBiddingAccess}
                            onChange={(e) => setFinalData(finalData => ({
                              ...finalData, rolePrivileges: {
                                ...finalData.rolePrivileges, Demand: {
                                  ...finalData.rolePrivileges.Demand,
                                  isHeaderBiddingAccess: e.target.checked
                                }
                              }
                            }))}
                            id="HeaderBidding"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="HeaderBidding"
                          >
                            Header Bidding
                          </label>
                        </div>
                      </Col>
                      {finalData.rolePrivileges.Demand.isHeaderBiddingAccess &&
                        <Col md={10} >
                          {createUI('Demand', 'HeaderBidding')}
                        </Col>
                      }
                    </Row>
                  </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isSupplyAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupplyAccess: e.target.checked } }))}
                    id="supply"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="supply"
                  >
                    Supply
                  </label>
                </div>
                {finalData.rolePrivileges.isSupplyAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectSupply(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                      <Link to="#" className="text-blue link" onClick={selectSupply(false)} style={{ cursor: 'pointer' }}>None</Link>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isPartnerAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isPartnerAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="supplyPartner"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="supplyPartner"
                            >
                              Supply Partner
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isPartnerAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'partners')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isPublisherAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isPublisherAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="supplyPublisher"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="supplyPublisher"
                            >
                              Publisher
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isPublisherAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'Publishers')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isAdUnitGroupAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isAdUnitGroupAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="supplyAdunitGroup"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="supplyAdunitGroup"
                            >
                              Ad Unit Group
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isAdUnitGroupAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'AdUnitGroups')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isAdUnitAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isAdUnitAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="supplyAdUnit"

                            />
                            <label
                              className="form-check-label"
                              htmlFor="supplyAdUnit"
                            >
                              Ad Unit
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isAdUnitAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'AdUnits')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isChildAdUnitAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isChildAdUnitAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="supplyChildUnit"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="supplyChildUnit"
                            >
                              Child AdUnit
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isChildAdUnitAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'ChildAdUnits')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isPlayerSettingAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isPlayerSettingAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="isPlayerSettingAccess"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isPlayerSettingAccess"
                            >
                              Player Settings
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isPlayerSettingAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'PlayerSettings')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isHighImpactSettingAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isHighImpactSettingAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="isHighImpactSettingAccess"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isHighImpactSettingAccess"
                            >
                              High Impact Settings
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isHighImpactSettingAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'HighImpactSettings')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.Supply.isSTDBSettingAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, Supply: {
                                    ...finalData.rolePrivileges.Supply,
                                    isSTDBSettingAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="isSTDBSettingAccess"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isSTDBSettingAccess"
                            >
                              Standard Banner Settings
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.Supply.isSTDBSettingAccess &&
                          <Col md={10} >
                            {createUI('Supply', 'STDBSettings')}
                          </Col>
                        }
                      </Row>
                    </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isHouseAdAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isHouseAdAccess: e.target.checked } }))}
                    id="houseAds"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="houseAds"
                  >
                    House Ads
                  </label>
                </div>

                {finalData.rolePrivileges.isHouseAdAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectHouseAd(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                      <Link to="#" className="text-blue link" onClick={selectHouseAd(false)} style={{ cursor: 'pointer' }}>None</Link>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isHouseAdsPublisherAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isHouseAdsPublisherAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="housePublisher"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="housePublisher"
                            >
                              Publisher
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.HouseAds.isHouseAdsPublisherAccess &&
                          <Col md={10} >
                            {createUI('HouseAds', 'HouseAdsPublishers')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isHouseAdsAdUnitAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isHouseAdsAdUnitAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="houseAdUnit"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="houseAdUnit"
                            >
                              Ad Unit
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.HouseAds.isHouseAdsAdUnitAccess &&
                          <Col md={10} >
                            {createUI('HouseAds', 'HouseAdsAdUnits')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isHouseAdCreativeAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isHouseAdCreativeAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="houseCreatives"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="houseCreatives"
                            >
                              Creatives
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.HouseAds.isHouseAdCreativeAccess &&
                          <Col md={10} >
                            {createUI('HouseAds', 'HouseAdsCreatives')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isNativeAdAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isNativeAdAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="houseNativeAd"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="houseNativeAd"
                            >
                              Native Ad
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.HouseAds.isNativeAdAccess &&
                          <Col md={10} >
                            {createUI('HouseAds', 'NativeAds')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isHouseAdsCategoryAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isHouseAdsCategoryAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="houseCategories"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="houseCategories"
                            >
                              Categories
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.HouseAds.isHouseAdsCategoryAccess &&
                          <Col md={10} >
                            {createUI('HouseAds', 'HouseAdsCategories')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.HouseAds.isFsrvAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, HouseAds: {
                                    ...finalData.rolePrivileges.HouseAds,
                                    isFsrvAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="houseReport"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="houseReport"
                            >
                              Report
                            </label>
                          </div>
                        </Col>
                        {/* {houseAdReport &&
                          <Col md={10} >
                            {createReportUI()}
                          </Col>
                        } */}
                      </Row>
                    </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isProxyAdAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isProxyAdAccess: e.target.checked } }))}
                    id="proxy"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="proxy"
                  >
                    Proxy
                  </label>
                </div>

                {finalData.rolePrivileges.isProxyAdAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectProxy(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                      <Link to="#" className="text-blue link" onClick={selectProxy(false)} style={{ cursor: 'pointer' }}>None</Link>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.ProxyAds.isProxyAdUnitAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, ProxyAds: {
                                    ...finalData.rolePrivileges.ProxyAds,
                                    isProxyAdUnitAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="proxyAdUnit"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="proxyAdUnit"
                            >

                              Ad Unit
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.ProxyAds.isProxyAdUnitAccess &&
                          <Col md={10} >
                            {createUI('ProxyAds', 'ProxyAdUnits')}
                          </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.ProxyAds.isSsrvAccess}
                              onChange={(e) => setFinalData(finalData => ({
                                ...finalData, rolePrivileges: {
                                  ...finalData.rolePrivileges, ProxyAds: {
                                    ...finalData.rolePrivileges.ProxyAds,
                                    isSsrvAccess: e.target.checked
                                  }
                                }
                              }))}
                              id="proxyReport"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="proxyReport"
                            >
                              Report
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isReportAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isReportAccess: e.target.checked } }))}
                    id="reporting"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="reporting"
                  >
                    Reporting
                  </label>
                </div>
                {finalData.rolePrivileges.isReportAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Report.isAnalyticReportAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isAnalyticReportAccess: e.target.checked } } }));
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Analytic: e.target.checked } } } }));
                          }
                          }
                          id="reportingAnalyticsReport"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="reportingAnalyticsReport"
                        >
                          Analytics Report
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Report.isPageUrlReportAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isPageUrlReportAccess: e.target.checked } } }))
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Pageurl: e.target.checked } } } }));
                          }}
                          id="PageUrlReport"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="PageUrlReport"
                        >
                          Page URL  Report
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Report.isRevenueReportAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isRevenueReportAccess: e.target.checked } } }))
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Revenue: e.target.checked } } } }));
                          }}
                          id="revenueReport"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="revenueReport"
                        >
                          Revenue Report
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.Report.isYMAnalyticReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isYMAnalyticReportAccess: e.target.checked } } }));
                        }
                        }
                        id="ymreportingAnalyticsReport"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="ymreportingAnalyticsReport"
                      >
                        YM Analytics Report
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.Report.isASAnalyticReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isASAnalyticReportAccess: e.target.checked } } }));
                        }
                        }
                        id="asreportingAnalyticsReport"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="asreportingAnalyticsReport"
                      >
                        AS Analytics Report
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.Report.isMISAnalyticReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isMISAnalyticReportAccess: e.target.checked } } }));
                        }
                        }
                        id="misreportingAnalyticsReport"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="misreportingAnalyticsReport"
                      >
                       MIS Analytics Report
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.Report.isTeamPageUrlReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isTeamPageUrlReportAccess: e.target.checked } } }))
                        }}
                        id="isTeamPageUrlReportAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isTeamPageUrlReportAccess"
                      >
                        Team Page URL  Report
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.Report.isAgencyReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isAgencyReportAccess: e.target.checked } } }))
                        }}
                        id="isAgencyReportAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAgencyReportAccess"
                      >
                        Agency Report
                      </label>
                    </div>
                  </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
              <div className="form-check mt-2 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={finalData.rolePrivileges.isReportUploadingAccess}
                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isReportUploadingAccess: e.target.checked } }))}
                  id="reportUpload"
                />
                <label
                  className="form-check-label"
                  htmlFor="reportUpload"
                >
                  Report Upload
                </label>
              </div>
              {finalData.rolePrivileges.isReportUploadingAccess &&
                <div>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.ReportUploading.isPublihserReportAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ReportUploading: { ...finalData.rolePrivileges.ReportUploading, isPublihserReportAccess: e.target.checked } } }));
                        }
                        }
                        id="isPublihserReportAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isPublihserReportAccess"
                      >
                        Publisher Report Upload
                      </label>
                    </div>
                  </Col>
                  <Col md={12} className="m-1">
                    <div className="form-check mt-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={finalData.rolePrivileges.ReportUploading.isAgencyReportUploadAccess}
                        onChange={(e) => {
                          setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ReportUploading: { ...finalData.rolePrivileges.ReportUploading, isAgencyReportUploadAccess: e.target.checked } } }));
                        }
                        }
                        id="isAgencyReportUploadAccess"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAgencyReportUploadAccess"
                      >
                        Agency Report Upload
                      </label>
                    </div>
                  </Col>
                </div>
              }
            </Row>

              <Row className="m-4">
                <Col md={2} className='p-0'>
                  <div className="form-check mt-2 mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={finalData.rolePrivileges.isPartnerManagementAccess}
                      onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isPartnerManagementAccess: e.target.checked } }))}
                      id="partnerMangement"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="partnerMangement"
                    >
                      Partner Management
                    </label>
                  </div>
                </Col>
                {finalData.rolePrivileges.isPartnerManagementAccess &&
                  <Col md={10} >
                    {createUI('PartnerManagement', 'PartnerAccess')}
                  </Col>
                }
              </Row>

              <Row className="m-4">
                <Col md={2} className='p-0'>
                  <div className="form-check mt-2 mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={finalData.rolePrivileges.isTagControllerAccess}
                      onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isTagControllerAccess: e.target.checked } }))}
                      id="tagController"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="tagController"
                    >
                      Tag Controller
                    </label>
                  </div>
                </Col>
                {finalData.rolePrivileges.isTagControllerAccess &&
                  <Col md={10} >
                    {createUI('TagController', 'TCs')}
                  </Col>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isBillingAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isBillingAccess: e.target.checked } }))}
                    id="billing"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="billing"
                  >
                    Billing
                  </label>
                </div>
                {finalData.rolePrivileges.isBillingAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Billing.isInvoiceApproveAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceApproveAccess: e.target.checked } } }))
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Approve: e.target.checked } } } }));
                          }}
                          id="billingInvoiceApproval"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="billingInvoiceApproval"
                        >
                          Invoices Approval
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Billing.isInvoiceHistoryAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceHistoryAccess: e.target.checked } } }))
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, History: e.target.checked } } } }));
                          }}
                          id="billingInvoiceHistory"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="billingInvoiceHistory"
                        >
                          Invoice History
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Billing.isInvoicePaymentAccess}
                          onChange={(e) => {
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoicePaymentAccess: e.target.checked } } }))
                            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Payment: e.target.checked } } } }));
                          }}
                          id="billingPayments"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="billingPayments"
                        >
                          Payments
                        </label>
                      </div>
                    </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isContextualSettingsAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isContextualSettingsAccess: e.target.checked } }))}
                    id="contextualSettings"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="contextualSettings"
                  >
                    Contextual Settings
                  </label>
                </div>
                {finalData.rolePrivileges.isContextualSettingsAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.ContextualSettings.isBrandAffinityAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isBrandAffinityAccess: e.target.checked } } }))}
                          id="brandAffinity"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="brandAffinity"
                        >
                          Brand Affinity
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.ContextualSettings.isInterestAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isInterestAccess: e.target.checked } } }))}
                          id="contextualInterests"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="contextualInterests"
                        >
                          Interests
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.ContextualSettings.isKeywordAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isKeywordAccess: e.target.checked } } }))}
                          id="contextualKeywords"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="contextualKeywords"
                        >
                          Keywords
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.ContextualSettings.isPageUrlAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isPageUrlAccess: e.target.checked } } }))}
                          id="contexualPageUrl"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="contexualPageUrl"
                        >
                          Page URL
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.ContextualSettings.isWebCategorizationAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isWebCategorizationAccess: e.target.checked } } }))}
                          id="webCategorization"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="webCategorization"
                        >
                          Web Categorization
                        </label>
                      </div>
                    </Col>
                  </div>
                }
              </Row>
              
              <Row className="m-4">
              <div className="form-check mt-2 mb-2 ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={finalData.rolePrivileges.isNetworkSettingAccess}
                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isNetworkSettingAccess: e.target.checked } }))}
                  id="isNetworkSettingAccess"
                />
                <label
                  className="form-check-label"
                  htmlFor="isNetworkSettingAccess"
                >
                  Network Settings
                </label>
              </div>
            </Row>
            <Row className="m-4">
              <div className="form-check mt-2 mb-2  ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={finalData.rolePrivileges.isNetworkBlockingAccess}
                  onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isNetworkBlockingAccess: e.target.checked } }))}
                  id="isNetworkBlockingAccess"
                />
                <label
                  className="form-check-label"
                  htmlFor="isNetworkBlockingAccess"
                >
                  Network Blocking
                </label>
              </div>
            </Row>


              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isToolsAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isToolsAccess: e.target.checked } }))}
                    id="tools"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="tools"
                  >
                    Tools
                  </label>
                </div>
                {finalData.rolePrivileges.isToolsAccess &&
                  <div>
                    {/* <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isVideoContentLibrarayAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isVideoContentLibrarayAccess: e.target.checked } } }))}
                          id="videoContentLibrary"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="videoContentLibrary"
                        >
                          Video Content Library
                        </label>
                      </div>
                    </Col> */}
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isScreenShotToolAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isScreenShotToolAccess: e.target.checked } } }))}
                          id="screenshotTool"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="screenshotTool"
                        >
                          Screenshot Tool
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isAdTagTesterAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isAdTagTesterAccess: e.target.checked } } }))}
                          id="adTagTester"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="adTagTester"
                        >
                          Ad Tag Tester
                        </label>
                      </div>
                    </Col>
                    {/* <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isVastTagTesterAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isVastTagTesterAccess: e.target.checked } } }))}
                          id="vastTagTester"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="vastTagTester"
                        >
                          Vast Tag Tester
                        </label>
                      </div>
                    </Col> */}
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isAdsTxtValidatorAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isAdsTxtValidatorAccess: e.target.checked } } }))}
                          id="adsTagValidator"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="adsTagValidator"
                        >
                          Ads.txt Validator
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.Tools.isVideoCompresser}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isVideoCompresser: e.target.checked } } }))}
                          id="videoCompresser"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="videoCompresser"
                        >
                          Video Compresser
                        </label>
                      </div>
                    </Col>
                  </div>
                }
              </Row>


              <Row className="m-4">
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isVCsAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isVCsAccess: e.target.checked } }))}
                    id="vcs"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="vcs"
                  >
                    Video Content Suite
                  </label>
                </div>
                {finalData.rolePrivileges.isVCsAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.VCs.isVideoContentLibrarayAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: e.target.checked } } }))}
                          id="videoContentLibrarys"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="videoContentLibrarys"
                        >
                          Video Content Library
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.VCs.isVideoPlayListAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoPlayListAccess: e.target.checked } } }))}
                          id="playList"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="playList"
                        >
                          Video Play List
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.VCs.isVideoImportAccess}
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoImportAccess: e.target.checked } } }))}
                          id="isVideoImportAccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isVideoImportAccess"
                        >
                          Video Import
                        </label>
                      </div>
                    </Col>
                    <Col md={12} className="m-1">
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={finalData.rolePrivileges.VCs.isContentMarketplaceAccess
                          }
                          onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isContentMarketplaceAccess: e.target.checked } } }))}
                          id="isContentMarketplaceAccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isContentMarketplaceAccess"
                        >
                          Content Marketplace
                        </label>
                      </div>
                    </Col>
                  </div>
                }
              </Row>

              <Row className="m-4">
                <div className="form-check mt-2 mb-2 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={finalData.rolePrivileges.isAdminAccess}
                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isAdminAccess: e.target.checked } }))}
                    id="admin"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="admin"
                  >
                    Admin
                  </label>
                </div>
                {finalData.rolePrivileges.isAdminAccess &&
                  <div>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.admin.isAccessManagementAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isAccessManagementAccess: e.target.checked } } }))}
                              id="accessManagement"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="accessManagement"
                            >
                              Access Management
                            </label>
                          </div>
                        </Col>
                      </Row>
                      {finalData.rolePrivileges.admin.isAccessManagementAccess &&
                        <>
                          <Row className="m-4">
                            <Col md={2} className='p-0'>
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.adminAccess.isUserManagementAccess}
                                  onChange={(e) => setFinalData(finalData => ({
                                    ...finalData, rolePrivileges: {
                                      ...finalData.rolePrivileges, adminAccess: {
                                        ...finalData.rolePrivileges.adminAccess,
                                        isUserManagementAccess: e.target.checked
                                      }
                                    }
                                  }))}
                                  id="userManagement"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="userManagement"
                                >
                                  User Management
                                </label>
                              </div>
                            </Col>
                            {finalData.rolePrivileges.adminAccess.isUserManagementAccess &&
                              <Col md={10} >
                                          {createUI('adminAccess', 'UserManagement')}
                              </Col>
                            }
                          </Row>
                          <Row className="m-4">
                            <Col md={2} className='p-0'>
                              <div className="form-check mt-2 mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={finalData.rolePrivileges.adminAccess.isRoleManagementAccess}
                                  onChange={(e) => setFinalData(finalData => ({
                                    ...finalData, rolePrivileges: {
                                      ...finalData.rolePrivileges, adminAccess: {
                                        ...finalData.rolePrivileges.adminAccess,
                                        isRoleManagementAccess: e.target.checked
                                      }
                                    }
                                  }))}
                                  id="roleManagement"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="roleManagement"
                                >
                                  Role Management
                                </label>
                              </div>
                            </Col>
                            {finalData.rolePrivileges.adminAccess.isRoleManagementAccess &&
                              <Col md={10} >
                                {createUI('adminAccess', 'RoleManagement')}
                              </Col>
                            }
                          </Row>
                        </>
                      }

                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.admin.isAdsTxtManagementAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isAdsTxtManagementAccess: e.target.checked } } }))}
                              id="adsTxtManagement"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="adsTxtManagement"
                            >
                              Ads.Txt Management
                            </label>
                          </div>
                        </Col>
                        {/* {masterLog &&
                          <Col md={2}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                              />
                              <label
                                className="form-check-label"
                              >
                                Ads.Txt Queries
                              </label>
                            </div>
                          </Col>
                        }
                        {masterLog &&
                          <Col md={2}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                              />
                              <label
                                className="form-check-label"
                              >
                                   Ads.Txt Entries
                              </label>
                            </div>
                          </Col>
                        } */}
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.admin.isMasterLogAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isMasterLogAccess: e.target.checked } } }))}
                              id="masterLog"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="masterLog"
                            >
                              Master Log
                            </label>
                          </div>
                        </Col>

                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.admin.isPublisherLogAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isPublisherLogAccess: e.target.checked } } }))}
                              id="publisherLog"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="publisherLog"
                            >
                              Publisher Log
                            </label>
                          </div>
                        </Col>
                        {/* {publisherLog &&
                          <Col md={2}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                              />
                              <label
                                className="form-check-label"
                              >
                                View
                              </label>
                            </div>
                          </Col>
                        } */}
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={2}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.adminAccess.isNotificationAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isNotificationAccess: e.target.checked } } }))}
                              id="publisherNotification"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="publisherNotification"
                            >
                              Notification
                            </label>
                          </div>
                        </Col>
                        {finalData.rolePrivileges.adminAccess.isNotificationAccess &&
                              <Col md={10} >
                                {createUI('adminAccess', 'Notification')}
                              </Col>
                        }
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.adminAccess.isPublisherApprovalAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isPublisherApprovalAccess: e.target.checked } } }))}
                              id="publisherApproval"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="publisherApproval"
                            >
                              Publisher Approval
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="m-1">
                      <Row>
                        <Col md={4}>
                          <div className="form-check mt-2 mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={finalData.rolePrivileges.adminAccess.isSupportTicketAccess}
                              onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isSupportTicketAccess: e.target.checked } } }))}
                              id="supportTicket"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="supportTicket"
                            >
                              Suport Ticket
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                }
              </Row>

            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;

                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default New_Role

New_Role.propTypes = {
  history: PropTypes.object,
};