import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_VIDEO_CONTENTS, ADD_VIDEO_CONTENT, DELETE_VIDEO_CONTENT } from "./actionTypes"

import {
  loadVideoContents,
  getVideoContentsSuccess,
  getVideoContentsFail,
  addVideoContentSuccess,
  addVideoContentFail,
  deleteVideoContentSuccess,
  deleteVideoContentFail
} from "./actions"

//Include Both Helper File with needed methods
import { getVideoContents,getVideoContentsPartner, postVideoContent, deleteVideoContent } from "../../../helpers/Backend_helper"


function* fetchVideoContents({ payload: vcs }) {
  try {
    yield put(loadVideoContents(true))
    var getContentsList
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    if (vcs) {
      if(userInfo && userInfo.userRole === "Partner"){
        
        getContentsList = yield call(getVideoContentsPartner, vcs)
      }else{
        getContentsList = yield call(getVideoContents, vcs)
      }
    
      console.log("saga console response==============", getContentsList)

      //filtering data 

      const filteredData = getContentsList.responseData.response.filter(item => !item.isDeleted);

      console.log("filtered data -------->", filteredData)

      filteredData.map((item, index) => {
        item.id = index + 1
        item.checked = false
      })
      console.log("modified Data ------------>",filteredData)
      // yield put(getVideoContentsSuccess(modifiedData))
      
      getContentsList.responseData.response.map((item, index) => {
        item.id = index + 1
        item.checked = false
      })
      yield put(getVideoContentsSuccess(getContentsList.responseData.response))

    }else{
      getContentsList = []
      yield put(getVideoContentsSuccess(getContentsList))
    }

  } catch (error) {
    yield put(getVideoContentsFail(error))
  }
}

function* onAddVideoContent({ payload: vcons }) {
  try {
    const response = yield call(postVideoContent, vcons)
    console.log(response)
    if (response.responseData.success) {
      yield put(addVideoContentSuccess(response.responseData))
    } else {
      yield put(addVideoContentFail(response.responseData.message))
    }
  } catch (error) {
    yield put(addVideoContentFail(error))
  }
}

function* onDeleteVideoContent({ payload: vid }) {
  console.log("called ondeletevideocontent data")
  try {
    const response = yield call(deleteVideoContent, vid)
    console.log(response)
    if (response.responseData.success) {
      yield put(deleteVideoContentSuccess(response.responseData))
    } else {
      yield put(deleteVideoContentFail(response.responseData))
    }
  } catch (error) {
    yield put(deleteVideoContentFail(error))
  }
}

function* videoContentsSaga() {
  yield takeEvery(GET_VIDEO_CONTENTS, fetchVideoContents)
  yield takeEvery(ADD_VIDEO_CONTENT, onAddVideoContent)
  yield takeEvery(DELETE_VIDEO_CONTENT, onDeleteVideoContent)
}

export default videoContentsSaga;
