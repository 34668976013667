import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_KEYWORDS,ADD_KEYWORD , UPDATE_KEYWORD,READ_KEYWORD,STATUS_KEYWORD,ARCHIVE_KEYWORD} from "./actionTypes"

import {
  loadKeywords,
  noKeywords,
  getKeywordsSuccess,
  getKeywordsFail,
  addKeywordSuccess,
  addKeywordFail,
  updateKeywordSuccess,
  updateKeywordFail,
  readKeywordSuccess,
  statusKeywordSuccess,
  archiveKeywordSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getKeywords,createKeyword,updateKeyword,readKeyword,statusChangeKeyword,archiveKeyword } from "../../../helpers/Backend_helper"

function* fetchKeywords() {
  try {
    yield put(loadKeywords(true))
    const response = yield call(getKeywords)
    console.log(response)
    response.responseData.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.responseData.response.length === 0){
    yield put(noKeywords(true))
  }
    yield put(getKeywordsSuccess(response.responseData.response))
  } catch (error) {
    yield put(getKeywordsFail(error))
  }
}

function* onReadKeyword({ payload: keyword }) {
  try {
    const response = yield call(readKeyword, keyword)
    yield put(readKeywordSuccess(response))
  } catch (error) {
    yield put(readKeywordSuccess(error))
  }
}

function* onAddKeyword({ payload: keyword }) {
  try {
    const response = yield call(createKeyword, keyword)
    if(response.responseData.success){
      yield put(addKeywordSuccess(response.responseData))
    }else{
      yield put(addKeywordFail(response.responseData))
    }
  } catch (error) {
    yield put(addKeywordFail(error))
  }
}

function* onUpdateKeyword({ payload: keyword }) {
  try {
    const response = yield call(updateKeyword, keyword)
    if(response.responseData.success){
      yield put(updateKeywordSuccess(response.responseData))
    }else{
      yield put(updateKeywordFail(response.responseData))
    }
  } catch (error) {
    yield put(updateKeywordFail(error))
  }
}

function* onStatusKeyword({ payload: keyword }) {
  try {
    const response = yield call(statusChangeKeyword, keyword)
    if(response.success){
      yield put(statusKeywordSuccess(response))
    }else{
      yield put(statusKeywordSuccess(response.message))
    }
  } catch (error) {
    yield put(updateKeywordFail(error))
  }
}

function* onArchiveKeyword({ payload: keyword }) {
  try {
    const response = yield call(archiveKeyword, keyword)
    yield put(archiveKeywordSuccess(response))
  } catch (error) {
    yield put(archiveKeywordSuccess(error))
  }
}

function* keywordSaga() {
  yield takeEvery(GET_KEYWORDS, fetchKeywords)
  yield takeEvery(ADD_KEYWORD, onAddKeyword)
  yield takeEvery(UPDATE_KEYWORD, onUpdateKeyword)
  yield takeEvery(READ_KEYWORD, onReadKeyword)
  yield takeEvery(STATUS_KEYWORD, onStatusKeyword)
  yield takeEvery(ARCHIVE_KEYWORD, onArchiveKeyword)
}

export default keywordSaga;
