import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PUB_VIDEO_CONTENTS, ADD_PUB_VIDEO_CONTENT, DELETE_PUB_VIDEO_CONTENT } from "./actionTypes"

import {
  loadPubVideoContents,
  getPubVideoContentsSuccess,
  getPubVideoContentsFail,
  addPubVideoContentSuccess,
  addPubVideoContentFail,
  deletePubVideoContentSuccess,
  deletePubVideoContentFail
} from "./actions"

//Include Both Helper File with needed methods
import { getPubVideoContents, postPubVideoContent, deletePubVideoContent } from "../../../helpers/Backend_helper"


function* fetchPubVideoContents({ payload: vcs }) {
  try {
    yield put(loadPubVideoContents(true))
    var getContentsList
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    if (vcs) {
      if(userInfo && userInfo.userRole === "Partner"){
        
        getContentsList = yield call(getPubVideoContents, vcs)
      }else{
        getContentsList = yield call(getPubVideoContents, vcs)
      }
    
      console.log("saga console response==============", getContentsList)

      //filtering data 

      const filteredData = getContentsList.responseData.response.filter(item => !item.isDeleted);

      console.log("filtered data -------->", filteredData)

      filteredData.map((item, index) => {
        item.id = index + 1
        item.checked = false
      })
      console.log("modified Data ------------>",filteredData)
      // yield put(getVideoContentsSuccess(modifiedData))
      
      getContentsList.responseData.response.map((item, index) => {
        item.id = index + 1
        item.checked = false
      })
      yield put(getPubVideoContentsSuccess(getContentsList.responseData.response))

    }else{
      getContentsList = []
      yield put(getPubVideoContentsSuccess(getContentsList))
    }

  } catch (error) {
    yield put(getPubVideoContentsFail(error))
  }
}

function* onAddPubVideoContent({ payload: vcons }) {
  try {
    const response = yield call(postPubVideoContent, vcons)
    console.log(response)
    if (response.responseData.success) {
      yield put(addPubVideoContentSuccess(response.responseData))
    } else {
      yield put(addPubVideoContentFail(response.responseData.message))
    }
  } catch (error) {
    yield put(addPubVideoContentFail(error))
  }
}

function* onDeletePubVideoContent({ payload: vid }) {
  console.log("called ondeletevideocontent data")
  try {
    const response = yield call(deletePubVideoContent, vid)
    console.log(response)
    if (response.responseData.success) {
      yield put(deletePubVideoContentSuccess(response.responseData))
    } else {
      yield put(deletePubVideoContentFail(response.responseData.response))
    }
  } catch (error) {
    yield put(deletePubVideoContentFail(error))
  }
}

function* pubVideoContentsSaga() {
  yield takeEvery(GET_PUB_VIDEO_CONTENTS, fetchPubVideoContents)
  yield takeEvery(ADD_PUB_VIDEO_CONTENT, onAddPubVideoContent)
  yield takeEvery(DELETE_PUB_VIDEO_CONTENT, onDeletePubVideoContent)
}

export default pubVideoContentsSaga;
