/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";
import { WithContext as ReactTags } from 'react-tag-input';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewIpMap as onADDIpMap,
  updateIpMap as onUpdateIpMap,
  readIpMap as onReadIpMap,
  addIpMapSuccess,
  addIpMapFail,
  updateIpMapSuccess,
  updateIpMapFail,
} from "store/Contextual_Settings/IPMap/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var list = []
var iPMappingsList = []


const Create_IpMaps = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [iPMapping, setIpMap] = useState({
    iPMappingGroup: '',
    iPMappingList: [],
  });
  console.log(props)

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    if (props.selectedIpMap !== '') {
      list = []
      iPMappingsList = []
      console.log(props.selectedIpMap)
      setIsEdit(true)
      setIpMap(props.selectedIpMap)
      iPMappingsList = props.selectedIpMap.iPMappingList.map((item, index) =>
        Object.assign({}, { text: item, id: item })
      )
      console.log(iPMappingsList)
      props.selectedIpMap.iPMappingList.map((item, index) => {
        list.push(item)
        return list
      })

      setTags(iPMappingsList)
    } else {
      list = []
      iPMappingsList = []
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIpMap(iPMapping => ({ ...iPMapping, [name]: value }));
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.iPMappings.errorMsg,
    successMsg: state.iPMappings.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addIpMapSuccess(""));
        dispatch(addIpMapFail(""));
        dispatch(updateIpMapSuccess(""));
        dispatch(updateIpMapFail(""));
        if (props.selectedIpMap !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addIpMapSuccess(""));
        dispatch(addIpMapFail(""));
        dispatch(updateIpMapSuccess(""));
        dispatch(updateIpMapFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addIpMapFail(""));
    dispatch(updateIpMapFail(""));
    props.closeCanvas('')
};

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    list.splice(i, 1)
    setIpMap(iPMapping => ({ ...iPMapping, iPMappingList: list }));
  };

  const handleAddition = tag => {
    let ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if(tag.id.match(ipformat)){
      list.push(tag.id)
      setTags([...tags, tag]);
      setIpMap(iPMapping => ({ ...iPMapping, iPMappingList: list }));
      return true;
    }else{
      toastr.error('Please Enter Valid IPV4 Address')
      return false
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (iPMapping.iPMappingGroup) {
      if (props.selectedIpMap !== '') {
        dispatch(onUpdateIpMap({ ...iPMapping }));
      } else {
        dispatch(onADDIpMap(iPMapping));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="col-6">
        <Form onSubmit={handleSubmit}>
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">IP Mapping Group Name</Label>
                <Input
                  type="text" name="iPMappingGroup" value={iPMapping.iPMappingGroup} onChange={handleChange} className={'form-control' + (submitted && !iPMapping.iPMappingGroup ? ' is-invalid' : '')}
                />
                {submitted && !iPMapping.iPMappingGroup &&
                  <div className="invalid-feedback">IP Mapping Group Name is required</div>
                }
              </div>
              <div className="mb-3">
                <Label className="form-label">IP Mapping</Label>

                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="inline"
                  style={{ width: '100%' }}
                  autocomplete
                  placeholder="Press enter to add new IP Mapping"
                />
              </div>
              <h6>or</h6>
              <div className="mt-3">
                <Label htmlFor="formFile" className="form-label">Upload IpMap List</Label>
                <Input className="form-control" type="file" id="formFile" />
                <br></br>
                <span href="#">Download sample file <i className="bx bx-cloud-download"></i></span>
              </div>


            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save IP Mapping Group
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_IpMaps);

Create_IpMaps.propTypes = {
  history: PropTypes.object,
};