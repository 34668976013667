import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_HIGH_IMPACT_SETTINGS_SUCCESS,
    GET_HIGH_IMPACT_SETTINGS_FAIL,
    ADD_HIGH_IMPACT_SETTING_SUCCESS,
    ADD_HIGH_IMPACT_SETTING_ERROR,
    UPDATE_HIGH_IMPACT_SETTING_SUCCESS,
    UPDATE_HIGH_IMPACT_SETTING_ERROR,
    READ_HIGH_IMPACT_SETTING_SUCCESS,
    STATUS_HIGH_IMPACT_SETTING_SUCCESS,
    ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    highImpactSettingList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    highImpactSetting: null,
    response:null,
    archiveMsg:null,
}

const highImpactSettings = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_HIGH_IMPACT_SETTINGS_SUCCESS:
            return {
                ...state,
                loading:false,
                highImpactSettingList: action.payload,
            }

        case GET_HIGH_IMPACT_SETTINGS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_HIGH_IMPACT_SETTING_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_HIGH_IMPACT_SETTING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HIGH_IMPACT_SETTINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HIGH_IMPACT_SETTING_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_HIGH_IMPACT_SETTINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HIGH_IMPACT_SETTING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HIGH_IMPACT_SETTINGS_SUCCESS' ? null : action.payload,
            }
        case READ_HIGH_IMPACT_SETTING_SUCCESS:
            return {
                ...state,
                highImpactSetting: action.payload,
            }
        case STATUS_HIGH_IMPACT_SETTING_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default highImpactSettings