/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, InputGroup } from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdUnit as onADDAdUnit,
  updateAdUnit as onUpdateAdUnit,
  readAdUnit as onReadAdUnit,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

import {
  getPartnersList as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getCommonSizes as onGetSizes,
} from "store/CommonEvents/actions";
import Toaster from "pages/Toaster/Toaster";

import {
  getNetworkSetting as onGetNetworkSetting,
} from 'store/Supply/NetworkSettings/actions'

import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_AdUnit = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [adUnit, setAdUnit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [adUnitGroupData, setAdUnitGroupData] = useState([])
  const [sizeData, setSizeData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(userInfo.userRole === 'Partner' ? { value: userInfo.userID, label: userInfo.name } : null);
  const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
  const [selectedDealType, setSelectedDealType] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props)
  const [AMPState, setAMPState] = useState(false)
  const [HBState, setHBState] = useState(false)
  const [networkState, setNetworkState] = useState(false)
  const [overALLState, setOverALLState] = useState(false)
  const [childAdUnitType, setChildAdUnitType] = useState('')
  const [networkData, setNetworkData] = useState([])
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [dealType, setDealType] = useState('')
  const [dealPricing, setDealPricing] = useState({
    "Type": "revShare",
    "Publisher": 60,
    "Us": 40
  })

  var revSharePricing = {
    "Type": "revShare",
    "Publisher": 60,
    "Us": 40
  }

  var CPMPricing = {
    "Type": "CPM",
    "Publisher": 0.5,
    "Us": 0
  }

  var fixedCostPricing = {
    "Type": "fixedCost",
    "Publisher": "2000",
    "Period": "month",
    "Us": 0
  }

  const [measurementType, setMeasurementType] = useState('overAll')

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      console.log(props.selectedAdUnit.measurement)
      if (JSON.stringify(props.selectedAdUnit.adUnitInventoryMeasurement) !== "{}") {
        setMeasurementType(props.selectedAdUnit.adUnitInventoryMeasurement.measurementType ? props.selectedAdUnit.adUnitInventoryMeasurement.measurementType : 'overAll')
        if (props.selectedAdUnit.adUnitInventoryMeasurement.measurementType && (props.selectedAdUnit.adUnitInventoryMeasurement.measurementType !== 'overAll')) {
          setMeasurementValues(measurementValues => ({
            ...measurementValues, desktop: props.selectedAdUnit.adUnitInventoryMeasurement.desktop ? props.selectedAdUnit.adUnitInventoryMeasurement.desktop : '',
            mobile: props.selectedAdUnit.adUnitInventoryMeasurement.phone ? props.selectedAdUnit.adUnitInventoryMeasurement.phone : '',
            app: props.selectedAdUnit.adUnitInventoryMeasurement.app ? props.selectedAdUnit.adUnitInventoryMeasurement.app : '',
            ctv: props.selectedAdUnit.adUnitInventoryMeasurement.tv ? props.selectedAdUnit.adUnitInventoryMeasurement.tv : ''
          }));
        }
        if (props.selectedAdUnit.adUnitInventoryMeasurement.measurementType && (props.selectedAdUnit.adUnitInventoryMeasurement.measurementType === 'overAll')) {
          setMeasurementValues(measurementValues => ({ ...measurementValues, overAll: props.selectedAdUnit.adUnitInventoryMeasurement.overAll ? props.selectedAdUnit.adUnitInventoryMeasurement.overAll : '' }));
        }
      }

    }
  }, []);

  const changeMeasurementType = (e) => {
    setMeasurementType(e.target.value)
    if (e.target.value === 'overAll') {
      setMeasurementValues(measurementValues => ({ ...measurementValues, desktop: '', mobile: '', app: '', ctv: '' }));
    } else if (e.target.value === 'deviceWise') {
      setMeasurementValues(measurementValues => ({ ...measurementValues, overAll: '' }));
    } else {
      setMeasurementValues(measurementValues => ({ ...measurementValues, overAll: '', desktop: '', mobile: '', app: '', ctv: '' }));
    }
  }

  const [measurementValues, setMeasurementValues] = useState({
    overAll: '',
    desktop: '',
    mobile: '',
    app: '',
    ctv: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMeasurementValues(measurementValues => ({ ...measurementValues, [name]: value }));
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  var dealTypeOptions = [
    { value: 'revShare', label: 'RevShare' },
    { value: 'CPM', label: 'CPM' },
    { value: 'fixedCost', label: 'Fixed Cost' },
  ]


  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
    dispatch(onGetAdUnitGroups(null));
  }, [])

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      setIsEdit(true)
      setAdUnit(props.selectedAdUnit)
      if (props.selectedAdUnit.isHBEnabled) {
        setHBState(props.selectedAdUnit.isHBEnabled)
      }
      setAMPState(props.selectedAdUnit.adUnitAMP ? true : false)
      setNetworkState(props.selectedAdUnit.isAntiMalicious ? true : false)
      setSelectedDealType({ value: props.selectedAdUnit.adUnitDealType, label: props.selectedAdUnit.adUnitDealType });
      setDealType(props.selectedAdUnit.adUnitDealType)
      setSelectedSize({ value: props.selectedAdUnit.adUnitSize, label: props.selectedAdUnit.adUnitSize });
      if (props.selectedAdUnit.networkSettingID && props.selectedAdUnit.networkSettingID !== 0) {
        setSelectedNetwork({ value: props.selectedAdUnit.networkSettingID, label: props.selectedAdUnit.networkSettingName });
      }
      getParentAdUnit()
    }
    if (props.selectedAdUnitGroup !== '' || props.selectedAdUnit === '') {
      getParentAdUnit()
    }
  }, []);

  const getParentAdUnit = (publisherId, adUnitGroupId) => {
    var filterBody = {
      url: (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/supply/getParent' : "http://127.0.0.1:2002/v1.0/supply/getParent",
      requestBody: {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
      }
    }
    if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
      filterBody.requestBody = { ...filterBody.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
    }
    if (props.selectedAdUnit !== '') {
      filterBody.requestBody.adUnitGroupID = props.selectedAdUnit.adUnitGroupID
    }
    if (props.selectedAdUnitGroup !== '') {
      filterBody.requestBody.adUnitGroupID = props.selectedAdUnitGroup.adUnitGroupId
    }
    if (publisherId) {
      filterBody.requestBody.publisherID = publisherId
    }
    if (adUnitGroupId) {
      filterBody.requestBody.adUnitGroupID = adUnitGroupId
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" : userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.responseData) {
          setSelectedPublisher({ value: res.data.responseData.publisherID, label: res.data.responseData.publisherName });
          setSelectedPartner({ value: res.data.responseData.partnerID, label: res.data.responseData.username });
          if (!publisherId) {
            setSelectedAdUnitGroup({ value: res.data.responseData.adUnitGroupID, label: res.data.responseData.adUnitGroupName });
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      adUnitName: (adUnit && adUnit.adUnitName) || '',
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupID: (selectedAdUnitGroup ? selectedAdUnitGroup.value : '') || '',
      // adUnitPricing: (adUnit && parseFloat(adUnit.adUnitPricing)) || '0',
      adUnitAMP: (AMPState) || false,
      networkSettingID: (selectedNetwork ? selectedNetwork.value : 0) || 0,
      isAntiMalicious: (networkState) || false,
      adUnitDealType: (selectedDealType ? selectedDealType.value : '') || '',
      revShareValue: (adUnit && adUnit.revShareValue) || '0',
      CPMRate: (adUnit && adUnit.CPMRate) || '0',
      fixedCostValue: (adUnit && adUnit.fixedCostValue) || '0',
      fixedCostPeriod: (adUnit && adUnit.fixedCostPeriod) || 'day',
      adUnitInventoryVerification: (adUnit && adUnit.adUnitInventoryVerification ? adUnit.adUnitInventoryVerification : '') || '',
      adUnitType: props.selectedAdUnitType || props.selectedAdUnit.adUnitType,
      adUnitSize: (selectedSize ? selectedSize.value : '') || '',
      playerSettings: '',
      isHBEnabled: (HBState) || false,
    },
    validationSchema: Yup.object({
      adUnitName: Yup.string().required("AdUnit Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      setChildAdUnitType(values.adUnitType)
      let measurements = {}
      if (measurementType === 'overAll') {
        measurements = {
          measurementType: measurementType,
          overAll: 0
        }
        measurements = { ...measurements, overAll: measurementValues.overAll ? Number(measurementValues.overAll) : 0 }
      }
      if (measurementType === 'deviceWise') {
        measurements = {
          measurementType: measurementType,
          desktop: 0,
          phone: 0,
          app: 0,
          tv: 0
        }
        if (measurementValues.desktop) {
          measurements = { ...measurements, desktop: Number(measurementValues.desktop) }
        }
        if (measurementValues.mobile) {
          measurements = { ...measurements, phone: Number(measurementValues.mobile) }
        }
        if (measurementValues.app) {
          measurements = { ...measurements, app: Number(measurementValues.app) }
        }
        if (measurementValues.ctv) {
          measurements = { ...measurements, tv: Number(measurementValues.ctv) }
        }
      }
      if (props.selectedAdUnit !== '') {
        dispatch(onUpdateAdUnit({ ...values, adUnitID: props.selectedAdUnit.adUnitID, oldPlayerSettings: props.selectedAdUnit.playListIDs, adUnitName: values.adUnitName.trim(), adUnitInventoryMeasurement: measurements, userRole: userInfo.userRole, userID: userInfo.userID }));
      } else {
        dispatch(onADDAdUnit({ ...values, adUnitName: values.adUnitName.trim(), adUnitInventoryMeasurement: measurements, userRole: userInfo.userRole, userID: userInfo.userID }));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.adUnits.errorMsg,
    successMsg: state.adUnits.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addAdUnitSuccess(""));
        dispatch(addAdUnitFail(""));
        dispatch(updateAdUnitSuccess(""));
        dispatch(updateAdUnitFail(""));
        setLoader(false)
        if (nextTab && (validation.values.adUnitType === 'highImpact' || validation.values.adUnitType === 'play')) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedAdUnit !== '') {
            const dataParams = {
              'entity': 'ad_unit',
              'id': props.selectedAdUnit.adUnitID,
              'event': 'update',
            };

            const other_params = {
              headers: { "content-type": "application/json; charset=UTF-8" },
              body: dataParams,
              method: "POST",
              mode: "cors"
            };
            fetch("https://ascp.servicediscovery.cloud", other_params)
              .then(function (response) {
                if (response.ok) {
                  console.log(response)
                  return response.json();
                } else {
                  throw new Error("Could not reach the API: " + response.statusText);
                }
              }).then(function (data) {
                console.log(data)
              }).catch(function (error) {
                console.log(error.message)
              });
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addAdUnitSuccess(""));
        dispatch(addAdUnitFail(""));
        dispatch(updateAdUnitSuccess(""));
        dispatch(updateAdUnitFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addAdUnitSuccess(""));
    dispatch(addAdUnitFail(""));
    dispatch(updateAdUnitSuccess(""));
    dispatch(updateAdUnitFail(""));
    props.closeCanvas('')
  };

  const { partners, publishers, adUnitGroups, sizes } = useSelector(state => ({
    partners: state.partners.partnerList,
    publishers: state.publishers.publisherList,
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    sizes: state.commonEvents.sizeList

  }));

  const { NetworkSettings } = useSelector(state => ({
    NetworkSettings: state.NetworkSettings.NetworkSettingList,
  }));

  console.log(partners, publishers, adUnitGroups, sizes, NetworkSettings)

  useEffect(() => {
    if (publishers !== null && adUnitGroups !== null && sizes !== null) {
      dispatch(onGetPublishers({ userRole: userInfo.userRole, userID: userInfo.userID, partnerID: '', filter: 'Active', type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
      dispatch(onGetAdUnitGroups({ userRole: userInfo.userRole, userID: userInfo.userID, publisherID: '', filter: 'Active', adUnitGroupType: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
      dispatch(onGetSizes());
    }
  }, []);

  useEffect(() => {
    if (NetworkSettings !== null) {
      dispatch(onGetNetworkSetting({ filter: 'Active' }));
    }
  }, []);

  useEffect(() => {
    if (NetworkSettings.length > 0) {
      setNetworkData(objectNetworkModal(NetworkSettings))
    }
  }, [NetworkSettings]);

  useEffect(() => {
    if (partners !== null) {
      dispatch(onGetPartners({ type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
    }
  }, []);

  useEffect(() => {
    if (partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  useEffect(() => {
    if (adUnitGroups.length > 0) {
      setAdUnitGroupData(objectAdUnitGroupModal(adUnitGroups))
      console.log(adUnitGroups)
    }
  }, [adUnitGroups]);

  useEffect(() => {
    if (sizes.length > 0) {
      if (props.selectedAdUnitType === 'play' || props.selectedAdUnitType === 'highImpact') {
        setSizeData([{ value: '1x1', label: '1x1' }])
        setSelectedSize({ value: '1x1', label: '1x1' })
      } else {
        let sizess = objectSizesModal(sizes)
        setSizeData([...sizess, ...[{ value: '800x485', label: '800x485' }, { value: '320x442', label: '320x442' }, { value: '320x480', label: '320x480' }, { value: '360x480', label: '360x480' }, { value: '360x600', label: '360x600' }, { value: '640x480', label: '640x480' }]])
      }
      console.log(sizes)
    }
  }, [sizes]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitGroupID
      obj.label = item.adUnitGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectSizesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const objectNetworkModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.networkSettingID
      obj.label = item.networkSettingName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setAdUnit(validation.values)
    setSelectedPartner(selectPartner);
    dispatch(onGetPublishers({ userRole: userInfo.userRole, userID: userInfo.userID, partnerID: selectPartner.value, filter: 'Active', type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    getParentAdUnit(selectPublisher.value, '')
    setAdUnit(validation.values)
    setSelectedPublisher(selectPublisher);
    dispatch(onGetAdUnitGroups({ userRole: userInfo.userRole, userID: userInfo.userID, publisherID: selectPublisher.value, filter: 'Active', adUnitGroupType: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
    setSelectedAdUnitGroup(null)
  }

  function handleSelectAdUnitGroup(selectAdUnitGroup) {
    getParentAdUnit('', selectAdUnitGroup.value)
    setAdUnit(validation.values)
    setSelectedAdUnitGroup(selectAdUnitGroup);
  }

  function handleSelectDealType(selectDealType) {
    setAdUnit(validation.values)
    setSelectedDealType(selectDealType);
    setDealType(selectDealType.value)
    setDealPricing(dealPricing => ({ ...dealPricing, Type: selectDealType.value }));
  }

  function handleNetowrkSelection(Network) {
    setAdUnit(validation.values)
    setSelectedNetwork(Network);
  }

  function handleSelectSize(selectSize) {
    setAdUnit(validation.values)
    setSelectedSize(selectSize);
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  const AMPStateChange = e => {
    setAdUnit(validation.values)
    setAMPState(e.target.checked)
  }

  const HBStateChange = e => {
    setAdUnit(validation.values)
    setHBState(e.target.checked)
  }

  const NetworkStateChange = e => {
    setAdUnit(validation.values)
    setNetworkState(e.target.checked)
  }

  const [controls, setControls] = useState({
    device: false,
    mobile: false,
    app: false,
    ctv: false,
  })

  const handleCheck = (event) => {
    setOverALLState(false)
    setControls({
      ...controls,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              {userInfo.userRole !== 'Partner' &&
                <div className="mb-3">
                  <Label>Partner</Label>
                  <Select
                    value={selectedPartner}
                    onChange={s => {
                      handleSelectPartner(s)
                    }}
                    options={partnerData}
                    invalid={
                      validation.touched.partnerID && validation.errors.partnerID ? true : false
                    }
                    classNamePrefix="select2-selection"
                    isDisabled={props.selectedArchived || isEdit}
                  />
                  {validation.touched.partnerID && validation.errors.partnerID ? (
                    <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
                  ) : null}

                </div>
              }
              <div className="mb-3">
                <Label>Publisher</Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  invalid={
                    validation.touched.publisherID && validation.errors.publisherID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.publisherID && validation.errors.publisherID ? (
                  <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>Ad Unit Group</Label>
                <Select
                  value={selectedAdUnitGroup}
                  onChange={s => {
                    handleSelectAdUnitGroup(s)
                  }}
                  options={adUnitGroupData}
                  invalid={
                    validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitGroupID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">Ad Unit Name</Label>
                <Input
                  name="adUnitName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={110}
                  value={validation.values.adUnitName || ""}
                  invalid={
                    validation.touched.adUnitName && validation.errors.adUnitName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.adUnitName && validation.errors.adUnitName ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitName}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label >AMP : </Label>
                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd1"
                    checked={AMPState}
                    onChange={AMPStateChange}
                    disabled={props.selectedArchived}
                  />
                  <label className="form-check-label" htmlFor="customSwitchsizemd1">
                    {AMPState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                  </label>
                </div>
              </div>
              {(validation.values.adUnitType === 'highImpact' || validation.values.adUnitType === 'stdb') &&
                <div className="mb-3">
                  <Label >Malicious State : </Label>
                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd1"
                      checked={networkState}
                      onChange={NetworkStateChange}
                      disabled={props.selectedArchived}
                    />
                    <label className="form-check-label" htmlFor="customSwitchsizemd1">
                      {networkState ? <span color='text-success' style={{ color: '#34c38f' }}>Anti Malicious </span> : <span style={{ color: '#a3a3a3' }}>Normal </span>}
                    </label>
                  </div>
                </div>
              }
              <div className="mb-3">
                <div className="row">
                  <div className="col-6">
                    <Label >Header Bidding : </Label>
                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd32"
                        checked={HBState}
                        onChange={HBStateChange}
                        disabled={props.selectedArchived}
                      />
                      <label className="form-check-label" htmlFor="customSwitchsizemd32">
                        {HBState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Label>Attach Network Settings</Label>
                <Select
                  value={selectedNetwork}
                  onChange={s => {
                    handleNetowrkSelection(s)
                  }}
                  options={networkData}
                  isClearable={true}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Inventory Verification</Label>
                <Input
                  type="select"
                  className="form-select"
                  name="adUnitInventoryVerification"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.adUnitInventoryVerification || ""}
                  readOnly={props.selectedArchived}
                >

                  <option value="" >
                    Select...
                  </option>
                  <option value="fraudlogix">Fraudlogix-PostBid</option>
                </Input>
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">Over all Measurement</Label>
                <InputGroup>
                  <div className="input-group-text">%</div>
                  <Input
                    name="measurement"
                    type="text" onKeyPress={allowOnlyNumbers}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.measurement}
                    readOnly={props.selectedArchived}
                  />
                </InputGroup>
              </div> */}
              <div className="mb-3">
                <Label className="form-label">Measurement</Label>
                <Input
                  type="select"
                  className="form-select"
                  name="measurementType"
                  onChange={changeMeasurementType}
                  value={measurementType}
                >

                  <option value="" disabled >
                    Select...
                  </option>
                  <option value="overAll">Over All Measurement</option>
                  <option value="deviceWise">Device wise Measurement </option>
                </Input>
              </div>
              <div className="mb-3">
                {measurementType === "overAll" &&
                  <div className="row mb-3 m-1">
                    <div className="col-5"> <Label className="mt-2">Over ALL Measurement </Label></div>
                    {/* <div className="col-4">  <div className="form-check form-switch form-check-success form-switch-md  " >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="overAll"
                          name="overAll"
                          checked={overALLState}
                          onChange={(e)=>setOverALLState(e.target.checked)}
                          disabled={props.selectedArchived}
                        />
                        <label className="form-check-label" htmlFor="overAll">
                          {overALLState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                        </label>
                      </div></div> */}
                    <div className="col-7">
                      <InputGroup>
                        <div className="input-group-text">%</div>
                        <Input
                          name="overAll"
                          type="text" onKeyPress={allowOnlyNumbers}
                          onChange={handleChange}
                          value={measurementValues.overAll}
                        />
                      </InputGroup>
                    </div>
                  </div>
                }

                {measurementType === "deviceWise" &&
                  <div>
                    <div className="row mb-3 m-1">
                      <div className="col-5" > <Label className="mt-2">Desktop </Label></div>
                      {/* <div className="col-4">  <div className="form-check form-switch form-check-success form-switch-md  " >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd2"
                                      name="desktop"
                                      checked={controls.desktop}
                                      onChange={handleCheck}
                                      disabled={props.selectedArchived}
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd2">
                                      {controls.desktop ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div></div> */}
                      <div className="col-7">
                        <InputGroup>
                          <div className="input-group-text">%</div>
                          <Input
                            name="desktop"
                            type="text" onKeyPress={allowOnlyNumbers}
                            onChange={handleChange}
                            value={measurementValues.desktop}
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div className="row mb-3 m-1">
                      <div className="col-5"> <Label className="mt-2">Mobile </Label></div>
                      {/* <div className="col-4">  <div className="form-check form-switch form-check-success form-switch-md  " >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd3"
                                      name="mobile"
                                      checked={controls.mobile}
                                      onChange={handleCheck}
                                      disabled={props.selectedArchived}
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd3">
                                      {controls.mobile ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div></div> */}
                      <div className="col-7">
                        <InputGroup>
                          <div className="input-group-text">%</div>
                          <Input
                            name="mobile"
                            type="text" onKeyPress={allowOnlyNumbers}
                            onChange={handleChange}
                            value={measurementValues.mobile}
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div className="row mb-3 m-1">
                      <div className="col-5"> <Label className="mt-2">App </Label></div>
                      {/* <div className="col-4">  <div className="form-check form-switch form-check-success form-switch-md  " >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd4"
                                      name="app"
                                      checked={controls.app}
                                      onChange={handleCheck}
                                      disabled={props.selectedArchived}
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd4">
                                      {controls.app ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div></div> */}
                      <div className="col-7">
                        <InputGroup>
                          <div className="input-group-text">%</div>
                          <Input
                            name="app"
                            type="text" onKeyPress={allowOnlyNumbers}
                            onChange={handleChange}
                            value={measurementValues.app}
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <div className="row mb-3 m-1">
                      <div className="col-5"> <Label className="ml-2 mt-2">CTV </Label></div>
                      {/* <div className="col-4">  <div className="form-check form-switch form-check-success form-switch-md  " >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd5"
                                      name="ctv"
                                      checked={controls.ctv}
                                      onChange={handleCheck}
                                      disabled={props.selectedArchived}
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd5">
                                      {controls.ctv ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div></div> */}
                      <div className="col-7">
                        <InputGroup>
                          <div className="input-group-text">%</div>
                          <Input
                            name="ctv"
                            type="text" onKeyPress={allowOnlyNumbers}
                            onChange={handleChange}
                            value={measurementValues.ctv}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">Ad Unit Pricing</Label>
                <InputGroup>
                  <div className="input-group-text">$</div>
                  <Input
                    name="adUnitPricing"
                    type="text" onKeyPress={allowOnlyNumbers}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.adUnitPricing}
                    invalid={
                      validation.touched.adUnitPricing && validation.errors.adUnitPricing ? true : false
                    }
                    readOnly={props.selectedArchived}
                  />
                </InputGroup>

                {validation.touched.adUnitPricing && validation.errors.adUnitPricing ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitPricing}</FormFeedback>
                ) : null}
              </div> */}

              <div className="mb-3">
                <Label>Ad Unit Deal Type</Label>
                <Select
                  value={selectedDealType}
                  onChange={s => {
                    handleSelectDealType(s)
                  }}
                  options={dealTypeOptions}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                {dealType === 'revShare' &&
                  <InputGroup>
                    <div className="input-group-text">%</div>
                    <Input
                      name="revShareValue" onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.revShareValue}
                      type="text" onKeyPress={allowOnlyNumbers}
                      readOnly={props.selectedArchived}
                    />
                  </InputGroup>
                } {dealType === 'CPM' &&
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="CPMRate" onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.CPMRate}
                      type="text" onKeyPress={allowOnlyNumbers}
                      readOnly={props.selectedArchived}
                    />
                  </InputGroup>}{dealType === 'fixedCost' &&
                    <div className='row'>
                      <div className='col-8'>
                        <Label >Fixed Cost </Label>
                        <InputGroup>
                          <div className="input-group-text">$</div>
                          <Input
                            name="fixedCostValue" onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fixedCostValue}
                            type="text" onKeyPress={allowOnlyNumbers}
                            readOnly={props.selectedArchived}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-4'>
                        <Label >Per</Label>
                        <Input type="select" className="form-select" name="fixedCostPeriod" onChange={validation.handleChange} readOnly={props.selectedArchived}
                          onBlur={validation.handleBlur}
                          value={validation.values.fixedCostPeriod}>
                          <option value='day'>  Day</option>
                          <option value='month'> Month</option>
                        </Input>
                      </div>
                    </div>
                }


              </div>

              <div style={{ marginBottom: '5%' }}>
                <Label>Ad Unit Size</Label>
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  invalid={
                    validation.touched.adUnitSize && validation.errors.adUnitSize ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.adUnitSize && validation.errors.adUnitSize ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitSize}</FormFeedback>
                ) : null}

              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit && (validation.values.adUnitType === 'highImpact') &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add Child Ad Unit
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/ChildAdUnit?adUnitId=${successMsg.data.adUnitID}&adUnitName=${successMsg.data.adUnitName}&childAdUnitType=${childAdUnitType}&status=ChildAdUnitCreateByAdUnit`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

export default withRouter(Create_AdUnit);

Create_AdUnit.propTypes = {
  history: PropTypes.object,
};