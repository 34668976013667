import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Modal,
    Input,
    Spinner,
    UncontrolledPopover,
    Popover,
    PopoverHeader,
    PopoverBody,
    Label
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'
import moment from "moment";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import New_Supply_Tag from "../House_AdUnits/New_Supply_Tag";

import {
    getHouseAdUnits as onGetAdUnits,
    statusHouseAdUnit as onStatusAdUnit,
    archiveHouseAdUnit as onArchiveAdUnit,
    statusHouseAdUnitSuccess,
    archiveHouseAdUnitSuccess,
    addHouseAdUnitFail,
    updateHouseAdUnitFail,
} from "store/customTracking/AdUnits/actions";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

var fromDate = moment()
var toDate = moment()


const HouseAdUnit = (props) => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [supplyTag, setSupplyTag] = useState([])
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)

    const [jsTagModalState, setJsTagModalState] = useState(false);
    const [popoverdismiss, setpopoverdismiss] = useState(false);
    const [readUnitData, setReadUnitData] = useState('')
    const inputRef = useRef();
    const [requestTotal, setRequestTotal] = useState(0)
    const [clicksTotal, setClicksTotal] = useState(0)
    const [statusSearch, setStatusSearch] = useState('Active')


    const statusChange = (e) => {
        setStatusSearch(e.target.value)
        dispatch(onGetAdUnits({
            filter: e.target.value, "startDate": moment(fromDate).format("YYYY-MM-DD"),
            "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        fromDate = event.startDate._d.toISOString();
        toDate = event.endDate._d.toISOString();
        dispatch(onGetAdUnits({
            filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
            "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
    }

    toastr.options = {
        positionClass: "toast-top-right",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 1000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.houseAdsAdUnitID)
                selectedItem.push(row.houseAdsAdUnitID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.houseAdsAdUnitID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.houseAdsAdUnitID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.houseAdsAdUnitID)
                    selectedItem.push(item.houseAdsAdUnitID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }

        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
    }

    const { adUnits } = useSelector(state => ({
        adUnits: state.adUnit.adUs,
    }));

    const { adUnitsResponse } = useSelector(state => ({
        adUnitsResponse: state.adUnit.response,
    }));

    const { archiveAdUnitsResponse } = useSelector(state => ({
        archiveAdUnitsResponse: state.adUnit.archiveMsg,
    }));

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.adUnits.errorMsg,
    //   successMsg: state.adUnits.successMsg,
    // }));

    console.log(adUnitsResponse)
    useEffect(() => {
        if (adUnitsResponse !== null) {
            if (adUnitsResponse.success) {
                toastr.success('AdUnit Status Changed Successfully')
                dispatch(onGetAdUnits({
                    filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
                    "endDate": moment(toDate).format("YYYY-MM-DD")
                }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(statusHouseAdUnitSuccess(null));
            }
        }
    }, [dispatch, adUnitsResponse]);

    useEffect(() => {
        if (archiveAdUnitsResponse !== null) {
            if (archiveAdUnitsResponse.success) {
                toastr.success('AdUnit Archived Successfully')
                dispatch(onGetAdUnits({
                    filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
                    "endDate": moment(toDate).format("YYYY-MM-DD")
                }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(archiveHouseAdUnitSuccess(null));
            }
        }
    }, [dispatch, archiveAdUnitsResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetAdUnits({
            filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
            "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitFail(""));
        setIsRight(!isRight);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, adUnit) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('AdUnit Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('AdUnit Updated Successfully!...')
        }
        selectedData = adUnit
        setIsArchived(adUnit.isHouseAdsCategoryArchived)
        dispatch(onGetAdUnits({
            filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
            "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitFail(""));
        setIsRight(close);
    };


    useEffect(() => {
        if (adUnits !== null) {
            dispatch(onGetAdUnits({
                filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD")
            }));
        }
    }, []);
    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const supplyTagListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "houseAdsAdUnitID",
            text: "ID",
            sort: true,
            footer: 'Total Requests'
        },

        {
            dataField: "houseAdsAdUnitName",
            text: "Name",
            sort: true,
            footer: '',
            style: { wordBreak: 'break-word' }
        },
        {
            dataField: "houseAdsAdUnitSize",
            text: "Size",
            sort: true,
            footer: ''
        },
        {
            dataField: "houseAdsCategoryStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, adUnit) => (
                <>
                    {adUnit.houseAdsCategoryStatus && !adUnit.isHouseAdsCategoryArchived &&
                        <span className="active-state">Active</span>
                    }
                    {!adUnit.houseAdsCategoryStatus && !adUnit.isHouseAdsCategoryArchived &&
                        <span className="inactive-state">Inactive</span>
                    }
                    {adUnit.isHouseAdsCategoryArchived &&
                        <span className="archived-state">Archived</span>
                    }
                </>
            ),
            footer: ''
        },
        {
            dataField: "requests",
            text: "Requests",
            sort: true,
            footer: `${requestTotal}`
        },
        {
            dataField: "clicks",
            text: "clicks",
            sort: true,
            footer: `${clicksTotal}`
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adUnit) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, adUnit)}>
                        {adUnit.isHouseAdsCategoryArchived &&
                            <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                        {!adUnit.isHouseAdsCategoryArchived &&
                            <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                            ></i>
                        }

                    </Link>
                    {!adUnit.isHouseAdsCategoryArchived &&
                        <span onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }}>
                            <i
                                className="bx bx-copy-alt font-size-18"
                                id="edittooltip"
                            ></i>
                        </span>
                    }

                </div>
            ),
        },
    ];

    useEffect(() => {
        console.log(adUnits)
        if (adUnits !== null && adUnits.length > 0) {
            let totalRequest = 0
            let totalClicks = 0
            adUnits.map((item, index) => {
                totalRequest = totalRequest + item.requests
                totalClicks = totalClicks + item.clicks
            })
            setRequestTotal(totalRequest)
            setClicksTotal(totalClicks)
        }

    }, [adUnits]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];


    const archiveAdUnit = async () => {
        dispatch(onArchiveAdUnit({ idList: selectedItem }));
    }

    const statusAdUnit = (state) => (event) => {
        console.log(state)
        dispatch(onStatusAdUnit({ idList: selectedItem, houseAdsAdUnitStatus: state }));
    }



    if (adUnits.length > 0) {
        adUnits.map((item, index) => {
            if (item.isHouseAdsCategoryArchived) {
                selectRow.nonSelectable.push(index + 1)
            }
        })
    }



    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
            setJsTagModalState(!jsTagModalState);
            setReadUnitData(adUnit)
        } else {
            setJsTagModalState(!jsTagModalState);
        }
    }

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([readUnitData.houseAdUnitTag], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = (readUnitData.houseAdsAdUnitName + ".txt");
        document.body.appendChild(element);
        element.click();
    };

    const copyTag = (e) => {
        setpopoverdismiss(!popoverdismiss)
        navigator.clipboard.writeText(readUnitData.houseAdUnitTag)
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header /> */}
            <div className="page-content">
                <MetaTags>
                    <title>House AdUnits | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Custom Tracking" breadcrumbItem="Ad Units" />
                            <div className=" text-sm-end">
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Add Ad Unit
                                </Button>
                            </div>
                        
                    </div>
                    <Card>
                        <CardBody aria-hidden="true">
                            <Row >
                                <Col sm="2">
                                    <Label className="form-label">Status</Label>
                                    <div className=" text-sm-end">
                                        <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                            <option value=''> All</option>
                                            <option value='Active' > Active</option>
                                            <option value='Inactive'> InActive</option>
                                            <option value='Archived'> Archived</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col sm="4" className="date-range">
                                    <Label className="form-label">Date Range</Label> <br />
                                    <DateRangePickers datePick={changeDatePick} startDate={fromDate} endDate={toDate} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={adUnits}
                                                    columns={supplyTagListColumns}
                                                    // bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="8">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                        <div className="row m-0 React-Table-Selection">
                                                                            {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                            <div style={{ padding: '0.5% 2%' }}>
                                                                                {/* <button color="primary" className="btn btn-primary " onClick={statusAdUnit(true)}>Activate</button> */}

                                                                                    <button color="primary" className="btn btn-primary " onClick={statusAdUnit(true)}>
                                                                                        Activate
                                                                                    </button>
                                                                                
                                                                                &nbsp;  &nbsp;

                                                                                    <button color="primary" className="btn btn-primary " onClick={statusAdUnit(false)}>
                                                                                        DeActivate
                                                                                    </button>
                                                                                
                                                                                &nbsp;  &nbsp;

                                                                                    <Button color="primary" className="btn btn-primary " onClick={archiveAdUnit} >
                                                                                        Archive
                                                                                    </Button>
                                                                                
                                                                                <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                    className="mdi mdi-close-thick font-size-18"
                                                                                ></i></Button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col xl="12" className="table-responsive">
                                                                    <div >
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            pagination={paginationFactory(pageOptions)}
                                                                            noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                        // ref={node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div> 
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Add Ad Unit'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <New_Supply_Tag closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedAdUnit={selectedData} />
                </OffcanvasBody>
            </Offcanvas>

            {readUnitData &&
                <Modal
                    size="lg"
                    isOpen={jsTagModalState}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Ad Unit : {readUnitData.houseAdsAdUnitName}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setJsTagModalState(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h5>JS Tag</h5>
                        <br />
                        <Input
                            ref={inputRef}
                            type="textarea"
                            id="textarea"
                            rows="8"
                            readOnly
                            name="jsTag" value={readUnitData.houseAdUnitTag}
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setJsTagModalState(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <Button
                            color="primary"
                            id="Popoverdismiss"
                            className="font-16 btn-block btn btn-primary"
                            onClick={copyTag}
                        >
                            <i className="bx bx-copy me-1" />
                            Copy Tag
                        </Button>
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={downloadTxtFile}
                        >
                            <i className="bx bx-download me-1" />
                            Download Tag
                        </Button>
                    </div>
                </Modal>
            }

        </React.Fragment >
    )
}


export default HouseAdUnit