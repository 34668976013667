/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip, Modal, ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";
import classnames from "classnames"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actionsntn
import {
  addNewPartner as onADDPartner,
  updatePartner as onUpdatePartner,
  addPartnerSuccess,
  addPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
} from "store/PartnerManagement/actions";
import PasswordStrengthIndicator from "pages/Authentication/PasswordStrengthIndicator";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

// var fORM10F = ''
// var NPE = ''
// var TRC = ''

var country = ''
var countriesList = {}

const Create_Partner = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  // const [partner, setPartner] = useState({
  //   password: ''
  // });
  const [isEdit, setIsEdit] = useState(false);
  const [regionList, setRegionList] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [form10F, setForm10F] = useState('')
  const [NPE, setNPE] = useState('')
  const [TRC, setTRC] = useState('')
  const [loader, setLoader] = useState(false);
  const [forcePassword, setForcePassword] = useState(false);
  const [publisherManagerData, setPublisherManagerData] = useState([])
  const [adOPsManagerData, setAdOpsManagerData] = useState([])
  const [accountManagerData, setAccountManagerData] = useState([])

  const [selectedPublisherManager, setSelectedPublisherManager] = useState([]);
  const [selectedAdOPsManager, setSelectedAdOPsManager] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState([]);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [showAlertFile, setShowAlertFile] = useState(false);

  console.log(props)

  const [partner, setPartner] = useState({
    userName: '',
    password: generatePassword(),
    userRole: 'Partner',
    userEmail: '',
    confirmEmail: '',
    generatePasswordEmail: forcePassword,
    userlog: {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name
    },
    companyID: userInfo.companyID,
    partnerLegalBusinessName: '',
    partnerRegion: '',
    partnerType: '',
    publisherManager: [],
    partnerAccountType: '',
    partnerInvertoryType: '',
    adOpsManager: [],
    accountManager: [],
    partnerContactDesignation: '',
    partnerCountry: '',
    partnerPaymentTerms: '',
    partnerBeneficiaryName: '',
    partnerFinanceContactName: '',
    partnerFinanceContactPhone: '',
    partnerFinanceContactEmail: '',
    partnerPaymentMode: '',
    partnerBeneficiaryAccountNo: '',
    partnerBankIFSOrSwiftCode: '',
    partnerBeneficiaryAddress: '',
    partnerBankBrachName: '',
    partnerBankName: '',
    partnerBankAddress: '',
    partnerOtherInfo: '',
    partnerPANTAXID: '',
    partnerCIN: '',
    partnerGSTNo: '',
    partnerFORM10F: { name: '' },
    partnerNoPermanentEstablishment: { name: '' },
    partnerTaxResidencyCertificate: { name: '' },
    isCDNEnabled: false,
    domainType: "",
    bucketType: "",
    bucketName: "",
    cDNBucketCname: "",
    IsTCDisabled: true,
    partnerRolePrivilages: {
      "Dashboard": {
        isRevenueAccess: true,
        isAnalyticsAccess: false,
        isAgencyAccess: false
      },
      "Demand": {
        "TC": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "IOs": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "IOGroups": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "Creatives": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HeaderBidding": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "LineItems": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "Advertisers": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        isAdveriserAccess: false,
        isIOGroupAccess: false,
        isIOsAccess: false,
        isLineItemAccess: false,
        isTCAccess: false,
        isCreativeAccess: false,
        isHeaderBiddingAccess: false,
      },

      "Supply": {
        "AdUnits": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "Publishers": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "AdUnitGroups": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "ChildAdUnits": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HighImpactSettings": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "STDBSettings": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "PlayerSettings": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        isPublisherAccess: true,
        isAdUnitGroupAccess: true,
        isAdUnitAccess: true,
        isChildAdUnitAccess: false,
        isHighImpactSettingAccess: false,
        isSTDBSettingAccess: false,
        isPlayerSettingAccess: false
      },
      "HouseAds": {
        "NativeAds": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HouseAdsAdUnits": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HouseAdsCreatives": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HouseAdsCategories": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        "HouseAdsPublishers": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        isHouseAdsPublisherAccess: false,
        isHouseAdsAdUnitAccess: false,
        isHouseAdCreativeAccess: false,
        isNativeAdAccess: false,
        isHouseAdsCategoryAccess: false,
        isFsrvAccess: false,
      },
      "ProxyAds": {
        "ProxyAdUnits": {
          "read": true,
          "create": false,
          "status": false,
          "update": false,
          "archive": false
        },
        isProxyAdUnitAccess: true,
        isSsrvAccess: true,
      },
      "Report": {
        "Query": {
          "Pageurl": false,
          "Revenue": true,
          "Analytic": false
        },
        isQueryAccess: true,
        isPageUrlReportAccess: false,
        isRevenueReportAccess: true,
        isAnalyticReportAccess: false,
        isYMAnalyticReportAccess: false,
        isASAnalyticReportAccess: false,
        isMISAnalyticReportAccess: false,
        isTeamPageUrlReportAccess: false,
        isAgencyReportAccess: false,
      },
      "Billing": {
        "Invoices": {
          "History": true,
          "Payment": true
        },
        isInvoiceHistoryAccess: true,
        isInvoicePaymentAccess: true
      },

      ContextualSettings: {
        isBrandAffinityAccess: false,
        isInterestAccess: false,
        isKeywordAccess: false,
        isPageUrlAccess: false,
        isWebCategorizationAccess: false
      },
      Tools: {
        isVideoContentLibrarayAccess: false,
        isScreenShotToolAccess: false,
        isAdTagTesterAccess: false,
        isVastTagTesterAccess: false,
        isAdsTxtValidatorAccess: false
      },
      VCs: {
        isVideoContentLibrarayAccess: true,
        isVideoPlayListAccess: true,
        isVideoImportAccess: true,
        isContentMarketplaceAccess: true,
      },
      settings: {
        isAdsTxtManagementAccess: true,
        isBrandSafetyAccess: true,
        isCMSConfigurationAccess: true,
        isReportingAPIAccess: true
      },
      isDashboardAccess: true,
      isDemandAccess: false,
      isSupplyAccess: true,
      isHouseAdAccess: false,
      isProxyAdAccess: false,
      isReportAccess: true,
      isBillingAccess: true,
      isPartnerManagementAccess: false,
      isSwitchManagementAccess: false,
      isContextualSettingsAccess: false,
      isHBSettingAccess: false,
      isNetworkBlockingAccess: false,
      isNetworkSettingAccess: false,
      isToolsAccess: false,
      isVCsAccess: true,
      isRevenueOpportunitiesAccess: true,
      isPublisherSettingsAccess: true,
      isSupportTicketAccess: true,
    },
    partnerRolePrivilagesType: 'default',
    partnerCurrencyType: "USD"
  });

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleSelectPublisherManager = publisherManager => {
    var publisherManagerLists = []
    setSelectedPublisherManager(publisherManager);
    publisherManager.map((item, index) => {
      publisherManagerLists.push(item.value)
    })
    setPartner(partner => ({ ...partner, publisherManager: publisherManagerLists }))
  }

  const handleSelectAdOPsManager = adOPsManager => {
    var adOPsManagerLists = []
    setSelectedAdOPsManager(adOPsManager);
    adOPsManager.map((item, index) => {
      adOPsManagerLists.push(item.value)
    })
    setPartner(partner => ({ ...partner, adOpsManager: adOPsManagerLists }))
  }

  const handleSelectAccountManager = accountManager => {
    var accountManagerLists = []
    setSelectedAccountManager(accountManager);
    accountManager.map((item, index) => {
      accountManagerLists.push(item.value)
    })
    setPartner(partner => ({ ...partner, accountManager: accountManagerLists }))
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  toastr.options = {
    positionClass: "toast-top-right",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  useEffect(() => {

    body.url = "http://127.0.0.1:2002/v1.0/regions/list"
    axios.post("/v1.0/connectOP", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.responseData) {
          countriesList = res.data.responseData
          setRegionList([{
            label: 'Africa',
            options: objectRegionModal(res.data.responseData.Africa)
          }, {
            label: 'America',
            options: objectRegionModal(res.data.responseData.Americas)
          }, {
            label: 'Asia',
            options: objectRegionModal(res.data.responseData.Asia)
          }, {
            label: 'Europe',
            options: objectRegionModal(res.data.responseData.Europe)
          }, {
            label: 'GDPR',
            options: objectRegionModal(res.data.responseData.GDPR)
          }, {
            label: 'Oceania',
            options: objectRegionModal(res.data.responseData.Oceania)
          }])
          // countriesList = [...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania]
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
    if (props.selectedPartner !== '') {
      setIsEdit(true)
      setSelectedPublisherManager(props.selectedPartner.publisherManager.length > 0 ? objectModal(props.selectedPartner.publisherManager) : [])
      setSelectedAdOPsManager(props.selectedPartner.adOpsManager.length > 0 ? objectModal(props.selectedPartner.adOpsManager) : [])
      setSelectedAccountManager(props.selectedPartner.accountManager.length > 0 ? objectModal(props.selectedPartner.accountManager) : [])
      setPartner(partner => ({ ...partner, ...props.selectedPartner, password: '', userRole: 'Partner' }))
      if (props.selectedPartner.bucket) {
        setPartner(partner => ({ ...partner, ...props.selectedPartner, bucketName: props.selectedPartner.bucket.cDNBucketName }))
      }
      setSelectedCountry({ label: props.selectedPartner.partnerCountry, value: props.selectedPartner.partnerCountry })
      if (props.selectedPartner.partnerFORM10F) {
        console.log(props.selectedPartner.partnerFORM10F.name)
        let partnerFORM10F = JSON.parse(props.selectedPartner.partnerFORM10F)
        setForm10F(partnerFORM10F.name)
      }
      if (props.selectedPartner.partnerNoPermanentEstablishment.name) {
        let partnerNoPermanentEstablishment = JSON.parse(props.selectedPartner.partnerNoPermanentEstablishment)
        setNPE(partnerNoPermanentEstablishment.name)
      }
      if (props.selectedPartner.partnerTaxResidencyCertificate.name) {
        let partnerTaxResidencyCertificate = JSON.parse(props.selectedPartner.partnerTaxResidencyCertificate)
        setTRC(partnerTaxResidencyCertificate.name)
      }
    }
  }, []);


  const objectRegionModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.label
      obj.label = item.label
      dataList.push(obj)
    })
    return dataList
  }


  useEffect(() => {

    let managersBody = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    }

    axios.post("/v1.0/getPartnerManager", managersBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.PublisherManger) {
          setPublisherManagerData(objectModal(res.data.PublisherManger))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

    axios.post("/v1.0/getAccountManger", managersBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.AccountManger) {
          setAccountManagerData(objectModal(res.data.AccountManger))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

    axios.post("/v1.0/getAdopsManger", managersBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.AdopsManger) {
          setAdOpsManagerData(objectModal(res.data.AdopsManger))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectRegion(selectCountry) {
    console.log(selectCountry)
    setSelectedCountry(selectCountry);
    setPartner(partner => ({ ...partner, partnerCountry: selectCountry.label }));
  }



  // const handleChange = (e) => {
  //   setSelectedRegion(e.target.value)
  //   partner.partnerRegion = e.target.value
  //   setRegionList(countriesList[e.target.value])
  // }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.partners.errorMsg,
    successMsg: state.partners.successMsg,
  }));

  console.log(errorMsg, successMsg)



  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPartnerSuccess(""));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerSuccess(""));
        dispatch(updatePartnerFail(null));
        setLoader(false)
        if (props.selectedPartner !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addPartnerSuccess(""));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerSuccess(""));
        dispatch(updatePartnerFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addPartnerSuccess(""));
    dispatch(addPartnerFail(null));
    dispatch(updatePartnerSuccess(""));
    dispatch(updatePartnerFail(null));
    props.closeCanvas('')
  };

  const form10FFileChange = async (file) => {
    console.log(file.target.files[0].size)
    const fileName = file.target.files[0].name
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    setForm10F(fileName)
    if (file.target.files[0].size < 7e6) {

      setPartner({
        ...partner,
        partnerFORM10F: {
          // ...file, 
          name: fileName,
          base64: tempBase64
        }
      })
    } else {
      setForm10F('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }

  };

  const estFileChange = async (file) => {
    console.log(file.target.files[0])
    const fileName = file.target.files[0].name
    setNPE(fileName)
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    if (file.target.files[0].size < 7e6) {
      setPartner({
        ...partner,
        partnerNoPermanentEstablishment: {
          name: fileName,
          base64: tempBase64
        }
      })

    } else {
      setNPE('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }
  };


  const taxFileChange = async (file) => {
    console.log(file.target.files[0])
    const fileName = file.target.files[0].name
    setTRC(fileName)
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    if (file.target.files[0].size < 7e6) {
      setPartner({
        ...partner,
        partnerTaxResidencyCertificate: {
          name: fileName,
          base64: tempBase64
        }
      })
    } else {
      setTRC('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }
  };

  const encodeFileBase64 = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          const base64 = reader.result.replace("data:application/pdf;base64,", "");
          if (base64) {
            resolve(base64);
          }
          resolve('');
        }
        reader.onerror = (error) => {
          console.log("error: ", error)
          resolve('');
        };
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newKey = name;
    if (newKey === 'partnerRegion') {
      setSelectedRegion(e.target.value)
      setRegionList(countriesList[e.target.value])
    }

    setPartner(partner => ({ ...partner, [newKey]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(partner)
    if (props.selectedPartner === '' && partner.confirmEmail !== partner.userEmail) {
      toastr.error('Confirm Email is must match with Partner Email')
      return
    }
    if (partner.userName && partner.userEmail && partner.partnerInvertoryType && partner.partnerLegalBusinessName && partner.partnerPaymentTerms && partner.partnerRegion && partner.partnerCountry && partner.partnerType && partner.partnerPaymentMode) {
      setLoader(true)
      if (props.selectedPartner !== '') {
        dispatch(onUpdatePartner({
          ...partner, userlog: {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name
          }, password: '', generatePasswordEmail: forcePassword, userName: partner.userName.trim(), userEmail: partner.userEmail.trim()
        }));
      } else {
        dispatch(onADDPartner({ ...partner, userName: partner.userName.trim(), userEmail: partner.userEmail.trim() }));
      }
    }
  }

  const reGeneratePassword = () => {
    setPartner(partner => ({ ...partner, password: generatePassword() }));
  }

  function generatePassword() {

    var keyListAlpha = "qwertyuiopasdfghjklzxcvbnm",
      keyListAlphaUpper = "QWERTYUIOPASDFGHJKLZXCVBNM",
      keyListInt = "1234567890",
      keyListSpec = "!@#$%^&*",
      password = '';
    var len = Math.ceil(7 / 2);
    len = len - 1;
    var lenSpec = 7 - 2 * len;

    for (let i = 0; i < len; i++) {
      password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
      password += keyListAlphaUpper.charAt(Math.floor(Math.random() * keyListAlphaUpper.length));
      password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
    }

    for (let i = 0; i < lenSpec; i++)
      password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length));

    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
    return password;
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  const [videosList, setVideosList] = useState([
    { id: 1, userName: userInfo.name, userEmail: userInfo.username, confirmEmail: userInfo.username, password: '', forcePassword: true }
  ])
  const addDirectVideoContent = () => {
    let videoContentList = [...videosList]
    videoContentList.push({
      id: videoContentList.length + 1,
      userName: '',
      userEmail: '',
      confirmEmail: '',
      password: generatePassword(),
      forcePassword: true
    })
    setVideosList([...videoContentList])
  }

  const handleChangeUser = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], [name]: value };
    setVideosList([...videoContentList])
  }

  const handleChangeUserForce = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], [name]: e.target.checked };
    setVideosList([...videoContentList])
  }

  const reGeneratePasswordUser = (index) => e => {
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], password: generatePassword() };
    setVideosList([...videoContentList])
  }

  const removeDirectContent = (i) => e => {
    let videoContentList = [...videosList]
    videoContentList.splice(i, 1)
    setVideosList([...videoContentList])
  }

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [postBidServiceState, setPostBidServiceState] = useState(false)

  const onToggleTooltip = (e) => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  const copyTag = (tag) => e => {
    navigator.clipboard.writeText(tag)
  }

  const createContentUI = () => {
    return videosList.map((item, i) =>
      <div key={i}>
        <div className="contentSettings">
          {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>}
          <div className="row mt-1">
            <div className="col-6">
              <div className="mb-3">
                <Label className="form-label">User Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userName"
                  onChange={handleChangeUser(i)}
                  value={item.userName}
                  className={'form-control' + (submitted && !item.userName ? ' is-invalid' : '')}
                  readOnly={i === 0}
                />
                {submitted && !item.userName && (
                  <FormFeedback type="invalid">{'User Name is required'}</FormFeedback>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label">User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userEmail"
                  type="email"
                  onChange={handleChangeUser(i)}
                  value={item.userEmail}
                  className={'form-control' + (submitted && !item.userEmail ? ' is-invalid' : '')}
                  readOnly={i === 0}
                />
                {submitted && !item.userEmail && (
                  <FormFeedback type="invalid">{'User Email is required'}</FormFeedback>
                )}
              </div>
              {i !== 0 &&
                <div className="mb-3">
                  <Label className="form-label">Confirm User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <Input
                    name="confirmEmail"
                    type="email"
                    onChange={handleChangeUser(i)}
                    value={item.confirmEmail}
                    className={'form-control' + (submitted && !item.confirmEmail ? ' is-invalid' : '')}
                  />
                  {submitted && !item.confirmEmail && (
                    <FormFeedback type="invalid">{'Confirm Email is required'}</FormFeedback>
                  )}
                  {submitted && !(item.confirmEmail === item.userEmail) && (
                    <FormFeedback type="invalid">{'Confirm Email is must match with User Email'}</FormFeedback>
                  )}
                </div>
              }
              {i !== 0 &&
                <div className="mb-3">
                  <Label className="form-label">User Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <div className="row">
                    <div className="col-9">
                      <InputGroup>
                        <Input
                          name="password"
                          type="text"
                          value={item.password}
                          readOnly
                        />
                        <div className="input-group-text">
                          <span className=" " id="controlledTooltip" onClick={copyTag(item.password)} onMouseLeave={() => {
                            setIsTooltipOpen(false)
                          }} style={{ cursor: 'pointer' }}>
                            <i
                              className="bx bx-copy-alt font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </InputGroup>
                      <Tooltip
                        isOpen={isTooltipOpen}
                        placement="top"
                        target="controlledTooltip"
                        toggle={onToggleTooltip}
                        trigger="click"
                      >
                        Copied
                      </Tooltip>

                    </div>
                    <div className="col-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        // eslint-disable-next-line react/prop-types
                        onClick={reGeneratePasswordUser(i)}
                      >
                        Generate Password
                      </button>
                    </div>
                  </div>
                </div>
              }
              {i !== 0 &&
                <div className="mb-3">
                  <div className="form-check mt-2 mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="forcePassword"
                      checked={item.forcePassword}
                      onChange={handleChangeUserForce(i)}
                      id="forceUserToChange"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="forceUserToChange"
                    >
                      Force user(s) to change password on login
                    </label>
                  </div>
                </div>
              }
              <br />
            </div>
            <div className="col-2 p-0">
              {i !== 0 &&
                <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeDirectContent(i)} disabled={videosList.length === 1}
                >Remove User</button>
              }
            </div>
          </div>
        </div>
      </div>

    )

  }

  const CDNEnabledStateChange = e => {
    if (e.target.checked) {
      setPartner(partner => ({ ...partner, domainType: 'inHouse', bucketType: 'default', isCDNEnabled: e.target.checked }))
    } else {
      setPartner(partner => ({ ...partner, domainType: '', bucketType: '', isCDNEnabled: e.target.checked, bucketName: '' }))
    }
  }

  const TCEnabledStateChange = (status) => e => {
    console.log(status)
    setShowAlertFile(false)
    setPartner(partner => ({ ...partner, IsTCDisabled: !status }))
  }

  const handleCheckType = (e) => {
    const { name, value } = e.target;
    setPartner(partner => ({ ...partner, bucketType: name }))
  }

  function dontAllowDots(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    console.log(code)
    if ((code > 45 && code < 47)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}  >
          <Row style={{ padding: '3%', height: '85vh', overflow: 'auto', width: '100%' }}>
            <Col xl={12} className="mb-4">

              <Row >
                <Col lg="12" className="p-0">
                  <Card>
                    <CardBody className="p-0">
                      <div className=" wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              // disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">01.</span>Partner Access
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              // disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number ">02.</span> Partner Information
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 3 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                  setactiveTab(3)
                                }}
                              // disabled={!(passedSteps || []).includes(4)}
                              >
                                <span className="number">03.</span> Partner Finance Information
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 4 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 4 })}
                                onClick={() => {
                                  setactiveTab(4)
                                }}
                              // disabled={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">04.</span> Users
                              </NavLink>
                            </NavItem>

                          </ul>
                        </div>
                        <div className="content clearfix ">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <div className="col-6">


                                <div className="mb-3">
                                  <Label className="form-label">Partner Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="userName"
                                    onChange={handleChange}
                                    value={partner.userName}
                                    maxLength={65}
                                    onKeyPress={allowAlphaNumericSpace}
                                    className={'form-control' + (submitted && !partner.userName ? ' is-invalid' : '')}
                                  />
                                  {submitted && !partner.userName && (
                                    <FormFeedback type="invalid">{'Partner Name is required'}</FormFeedback>
                                  )}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="userEmail"
                                    type="email"
                                    onChange={handleChange}
                                    value={partner.userEmail}
                                    className={'form-control' + (submitted && !partner.userEmail ? ' is-invalid' : '')}
                                  
                                  />
                                  {submitted && !partner.userEmail && (
                                    <FormFeedback type="invalid">{'Partner Email is required'}</FormFeedback>
                                  )}
                                </div>
                                {props.selectedPartner === '' &&
                                  <div className="mb-3">
                                    <Label className="form-label">Confirm Partner Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                    <Input
                                      name="confirmEmail"
                                      type="email"
                                      onChange={handleChange}
                                      value={partner.confirmEmail}
                                      className={'form-control' + (submitted && !partner.confirmEmail ? ' is-invalid' : '')}
                                    />
                                    {submitted && !partner.confirmEmail && (
                                      <FormFeedback type="invalid">{'Confirm Email is required'}</FormFeedback>
                                    )}
                                    {submitted && !(partner.confirmEmail === partner.userEmail) && (
                                      <FormFeedback type="invalid">{'Confirm Email is must match with Partner Email'}</FormFeedback>
                                    )}
                                  </div>
                                }
                                {props.selectedPartner === '' &&
                                  <div className="mb-3">
                                    <Label className="form-label">Partner Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                    <div className="row">
                                      <div className="col-9">
                                        <Input
                                          name="password"
                                          type="text"
                                          value={partner.password}
                                          readOnly
                                        />
                                      </div>
                                      <div className="col-3">
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary w-100"
                                          // eslint-disable-next-line react/prop-types
                                          onClick={reGeneratePassword}
                                        >
                                          Generate Password
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                }


                                <div className="mb-3">
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={forcePassword}
                                      onChange={() => setForcePassword(!forcePassword)}
                                    />
                                    <label
                                      className="form-check-label"
                                    >
                                      Force user(s) to change password on login
                                    </label>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <Label >Terms and Conditions : </Label>
                                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd12"
                                      checked={partner.IsTCDisabled}
                                      onChange={() => { setShowAlertFile(!showAlertFile) }}
                                      disabled
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd12">
                                      {partner.IsTCDisabled ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <Label >CDN : </Label>
                                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd1"
                                      checked={partner.isCDNEnabled}
                                      onChange={CDNEnabledStateChange}
                                      disabled
                                    />
                                    <label className="form-check-label" htmlFor="customSwitchsizemd1">
                                      {partner.isCDNEnabled ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                    </label>
                                  </div>
                                </div>
                                {partner.isCDNEnabled &&
                                  <>
                                    <div className='mb-3'>
                                      <Label >Domain Type</Label>
                                      <Input type="select" className="form-select" name="domainType" onChange={handleChange}
                                        value={partner.domainType}>
                                        <option value='inHouse'> InHouse Domain</option>
                                        <option value='custom'> Custom Domain</option>
                                      </Input>
                                    </div>
                                    {partner.domainType !== 'custom' &&
                                      <div className="mb-3">
                                        <Label >Bucket Type</Label>
                                        <div className="row">
                                          <Col md={2}>
                                            <div className="form-check mt-2 mb-2">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="default"
                                                id="default"
                                                checked={partner.bucketType === 'default'}
                                                onChange={handleCheckType}
                                              />
                                              <label className="form-check-label" htmlFor="default"> Default  </label>
                                            </div>
                                          </Col>
                                          <Col md={2}>
                                            <div className="form-check mt-2 mb-2">
                                              <input
                                                className="form-check-input "
                                                type="radio"
                                                name="custom"
                                                id="custom"
                                                checked={partner.bucketType === 'custom'}
                                                onChange={handleCheckType}
                                              />
                                              <label className="form-check-label" htmlFor="custom"> Custom</label>
                                            </div>
                                          </Col>
                                        </div>
                                      </div>
                                    }
                                    {partner.bucketType === 'custom' && partner.domainType !== 'custom' &&
                                      <div className="mb-3">
                                        <Label className="form-label">InHouse Bucket Name </Label>
                                        <InputGroup>
                                          <Input
                                            name="bucketName"
                                            className={'form-control'}
                                            onChange={handleChange}
                                            value={partner.bucketName}
                                            onKeyPress={dontAllowDots}
                                            placeholder="Please Enter your Bucket Name"
                                          />
                                          <div className="input-group-text">.bidsxchange.com</div>
                                        </InputGroup>
                                      </div>
                                    }

                                    {partner.domainType === 'custom' &&
                                      <div>
                                        {/* <div className="mb-3">
                                    <Label className="form-label">CName </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter CName"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">Data </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter Data"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">TTL </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter TTL"
                                    />
                                  </div> */}

                                        <h5 className="mb-3">Steps for Creating Custom Domain</h5>
                                        <div className="mb-3">
                                          <ol>
                                            <li>Sign in to your GoDaddy <a href="https://dcc.godaddy.com/control/portfolio" target="_blank" rel="noreferrer">Domain Portfolio</a>. (Need help logging in? <a target="_blank" href="https://in.godaddy.com/help/i-cant-sign-in-to-my-godaddy-account-19319" rel="noreferrer">Find your username or password</a>.)</li>
                                            <li>Select <strong>Domain Edit Options</strong> next to your domain and then select <strong>Edit DNS</strong>. You may need to scroll down to see <strong>Edit DNS</strong>.
                                            </li>
                                            <li>Select <strong>Add New Record</strong></li>

                                            <li>Select <strong>CNAME</strong> from the <strong>Type</strong> menu.</li>
                                            <li>Enter the details for your new CNAME record:
                                              <ul>
                                                <li><strong>Name</strong>: The hostname or prefix of the record, without the domain name, such as <em>blog</em> or <em>shop</em>. The <strong>Name</strong> must follow these guidelines:
                                                  <ul>
                                                    <li>Periods (.) are allowed but not as the first or last character</li>
                                                    <li>Consecutive periods (…) are not allowed</li>
                                                    <li>Cannot exceed 63 characters</li>
                                                    <li>Cannot be the <em>@</em> symbol.</li>
                                                    <li>Not already in use by an existing <strong>A record</strong>, <strong>TXT record</strong> or <strong>MX record</strong>.</li>
                                                  </ul></li>
                                                <li><strong>Value</strong>: The URL you are setting as the destination for the host. Type <em>@</em> to point directly to your root domain name.</li>
                                                <li><strong>TTL (Time to Live)</strong>: The amount of time the server should cache information before refreshing. The default setting is 1 hour.</li>
                                              </ul></li>
                                            <li>Select <strong>Save</strong> to add your new record. If you added multiple records at the same time, select <strong>Save All Records</strong>.</li>
                                          </ol>
                                        </div>
                                      </div>
                                    }

                                    {partner.domainType === 'custom' &&
                                      <div className="mb-3">
                                        <Label className="form-label">Custom Bucket Name </Label>
                                        <Input
                                          name="bucketName"
                                          className={'form-control'}
                                          onChange={handleChange}
                                          value={partner.bucketName}
                                          placeholder="Please Enter your Bucket Name"
                                        />
                                      </div>
                                    }
                                  </>
                                }
                              </div>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div className="col-6">
                                <div className="mb-3">
                                  <Label className="form-label">Account Manager </Label>
                                  <MultiSelect
                                    options={accountManagerData}
                                    value={selectedAccountManager}
                                    onChange={handleSelectAccountManager}
                                    overrideStrings={{ "selectSomeItems": "Please Select Account Manager" }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Publisher Manager </Label>
                                  <MultiSelect
                                    options={publisherManagerData}
                                    value={selectedPublisherManager}
                                    onChange={handleSelectPublisherManager}
                                    overrideStrings={{ "selectSomeItems": "Please Select Publisher Manager" }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Ad Ops Manager </Label>
                                  <MultiSelect
                                    options={adOPsManagerData}
                                    value={selectedAdOPsManager}
                                    onChange={handleSelectAdOPsManager}
                                    overrideStrings={{ "selectSomeItems": "Please Select Ad Ops Manager" }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Legal Business Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerLegalBusinessName"
                                    onChange={handleChange}

                                    value={partner.partnerLegalBusinessName}
                                    className={'form-control' + (submitted && !partner.partnerLegalBusinessName ? ' is-invalid' : '')}
                                  />
                                  {submitted && !partner.partnerLegalBusinessName &&
                                    <div className="invalid-feedback">Partner Business Name is required</div>
                                  }
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Partner Payment Terms <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerPaymentTerms"
                                    type="select"
                                    onChange={handleChange}
                                    className={'form-control form-select' + (submitted && !partner.partnerPaymentTerms ? ' is-invalid' : '')}
                                    value={partner.partnerPaymentTerms}
                                  >
                                    <option value=''>Select</option>
                                    <option value="NET+30">NET+30</option>
                                    <option value="NET+60">NET+60</option>
                                    <option value="NET+90">NET+90</option>
                                  </Input>
                                  {submitted && !partner.partnerPaymentTerms &&
                                    <div className="invalid-feedback">Partner Payment Terms is required</div>
                                  }
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Account Type </Label>
                                  <Input
                                    name="partnerAccountType"
                                    type="select"
                                    onChange={handleChange}
                                    value={partner.partnerAccountType}
                                    className={'form-control form-select'}
                                  >
                                    <option value=''>Select</option>
                                    <option value='streamlyn_India'>Streamlyn - India</option>
                                    <option value='streamlyn_Singapore'>Streamlyn - Singapore</option>
                                  </Input>
                                  {/* {submitted && !partner.partnerAccountType &&
                                    <div className="invalid-feedback">Partner Account Type is required</div>
                                  } */}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Contact Designation</Label>
                                  <Input
                                    name="partnerContactDesignation"
                                    onChange={handleChange}

                                    value={partner.partnerContactDesignation}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Region <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerRegion"
                                    type="select"
                                    onChange={handleChange}
                                    value={partner.partnerRegion}
                                    className={'form-control form-select' + (submitted && !partner.partnerRegion ? ' is-invalid' : '')}
                                  >
                                    <option value=''>Select</option>
                                    <option value='Africa'>Africa</option>
                                    <option value='Americas'>America</option>
                                    <option value='Asia'>Asia</option>
                                    <option value='Europe'>Europe</option>
                                    <option value='GDPR'>GDPR</option>
                                    <option value='Oceania'>Oceania</option>
                                  </Input>
                                  {submitted && !partner.partnerRegion &&
                                    <div className="invalid-feedback">Partner Region is required</div>
                                  }
                                </div>
                                <div className="mb-3">
                                  <Label>Partner Country <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Select
                                    value={selectedCountry}
                                    onChange={s => {
                                      handleSelectRegion(s)
                                    }}
                                    options={regionList}
                                    classNamePrefix="select2-selection"
                                    className={(submitted && !partner.partnerCountry ? ' is-invalid' : '')}
                                  />
                                  {submitted && !partner.partnerCountry &&
                                    <div className="invalid-feedback">Partner Country is required</div>
                                  }
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Type <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerType"
                                    type="select"
                                    onChange={handleChange}
                                    value={partner.partnerType}
                                    className={'form-control form-select' + (submitted && !partner.partnerType ? ' is-invalid' : '')}
                                  >
                                    <option value=''>Select</option>
                                    <option value='network' disabled={userInfo.userRole === "DC_Manager"}>Network</option>
                                    <option value='publisher' disabled={userInfo.userRole === "DC_Manager"}>Publisher</option>
                                    <option value='agency'>Agency</option>
                                    <option value='platformPartner' disabled={userInfo.userRole === "DC_Manager"}>Platform Partner</option>
                                  </Input>
                                  {submitted && !partner.partnerType &&
                                    <div className="invalid-feedback">Partner Type is required</div>
                                  }
                                </div>
                                {partner.partnerType === 'agency' &&
                                  <div className="mb-3">
                                    <Label className="form-label">Agency Currency Type <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                    <Input
                                      name="partnerCurrencyType"
                                      type="select"
                                      onChange={handleChange}
                                      value={partner.partnerCurrencyType}
                                      className={'form-control form-select'}
                                    >
                                      <option value='USD' >USD $</option>
                                      <option value='INR'>INR ₹</option>
                                    </Input>
                                  </div>
                                }
                                <div className="mb-3">
                                  <Label className="form-label">Partner Inventory Type <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerInvertoryType"
                                    type="select"
                                    onChange={handleChange}
                                    value={partner.partnerInvertoryType}
                                    className={'form-control form-select' + (submitted && !partner.partnerInvertoryType ? ' is-invalid' : '')}
                                  >
                                    <option value=''>Select</option>
                                    <option value='display'>Display</option>
                                    <option value='video'>Video</option>
                                    <option value='both'>Both</option>
                                  </Input>
                                  {submitted && !partner.partnerInvertoryType &&
                                    <div className="invalid-feedback">Partner Inventory Type is required</div>
                                  }
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Finance Contact Name</Label>
                                  <Input
                                    name="partnerFinanceContactName"
                                    type="text"
                                    onChange={handleChange}
                                    value={partner.partnerFinanceContactName}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Finance Contact Email</Label>
                                  <Input
                                    name="partnerFinanceContactEmail"
                                    type="email"
                                    onChange={handleChange}
                                    value={partner.partnerFinanceContactEmail}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Finance Contact Number </Label>
                                  <Input
                                    name="partnerFinanceContactPhone"
                                    type="number"
                                    value={partner.partnerFinanceContactPhone}
                                    onChange={handleChange}
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div className="col-6">
                                <div className="mb-3">
                                  <Label className="form-label">Partner Payment Mode <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="partnerPaymentMode"
                                    type="select"
                                    onChange={handleChange}
                                    value={partner.partnerPaymentMode}
                                    className={'form-control form-select' + (submitted && !partner.partnerPaymentMode ? ' is-invalid' : '')}
                                  >
                                    <option value=''>Select</option>
                                    <option value='Wire'>Wire</option>
                                    <option value='Paypal'>Paypal</option>
                                  </Input>
                                  {submitted && !partner.partnerPaymentMode &&
                                    <div className="invalid-feedback">Partner Payment Mode is required</div>
                                  }
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Partner Bank Name </Label>
                                  <Input
                                    type="text"
                                    name="partnerBankName"
                                    onChange={handleChange}
                                    value={partner.partnerBankName}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Bank Account No  </Label>
                                  <Input
                                    name="partnerBeneficiaryAccountNo"
                                    type="text"
                                    onChange={handleChange}
                                    value={partner.partnerBeneficiaryAccountNo}
                                    className={'form-control '}
                                  >
                                  </Input>
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Beneficiary Name </Label>
                                  <Input
                                    name="partnerBeneficiaryName"
                                    onChange={handleChange}

                                    value={partner.partnerBeneficiaryName}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Beneficiary Address  </Label>
                                  <Input
                                    type="text"
                                    name="partnerBeneficiaryAddress"
                                    onChange={handleChange}
                                    value={partner.partnerBeneficiaryAddress}
                                    className={'form-control '}
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Partner Bank {partner.partnerCountry === 'India' ? 'IFSC' : 'Swift'} Code </Label>
                                  <Input
                                    name="partnerBankIFSOrSwiftCode"
                                    type="text"
                                    onChange={handleChange}
                                    value={partner.partnerBankIFSOrSwiftCode}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Bank Branch Name  </Label>
                                  <Input
                                    name="partnerBankBrachName"
                                    type="text"
                                    onChange={handleChange}
                                    value={partner.partnerBankBrachName}
                                    className={'form-control '}
                                  >
                                  </Input>
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Partner Bank Address </Label>
                                  <Input
                                    type="text"
                                    name="partnerBankAddress"
                                    onChange={handleChange}
                                    value={partner.partnerBankAddress}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Other Information</Label>
                                  <Input
                                    type="text"
                                    name="partnerOtherInfo"
                                    onChange={handleChange}
                                    value={partner.partnerOtherInfo}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner PAN/TAX ID</Label>
                                  <Input
                                    type="text"
                                    name="partnerPANTAXID"
                                    onChange={handleChange}
                                    value={partner.partnerPANTAXID}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner GSTNo</Label>
                                  <Input
                                    name="partnerGSTNo"
                                    type="text"
                                    onChange={handleChange}
                                    value={partner.partnerGSTNo}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Partner Company Identification Number(CIN)</Label>
                                  <Input
                                    name="partnerCIN"
                                    type="number"
                                    onChange={handleChange}
                                    value={partner.partnerCIN}
                                  />
                                </div>
                                {/* {selectedCountry.label !== 'India' &&
                    <div className="mb-3">
                        <div className='mb-3'>
                                      <h6 >For Non Indian Companies</h6>
                                  </div>
                      <div className="mb-3">
                        <Label className="form-label"> Browse Form 10F <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={form10F}  className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !form10F? 'form-control is-invalid' : '')} readOnly  />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={form10FFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !form10F &&
                                          <div className="invalid-feedback">Partner FORM10F is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Browse No Permenant Establishment <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={NPE} className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !NPE? 'form-control is-invalid' : '')} readOnly />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={estFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !NPE &&
                                          <div className="invalid-feedback">Partner NoPermanentEstablishment is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Browse Tax Residency Certificate <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={TRC} className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !TRC? 'form-control is-invalid' : '')} readOnly  />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={taxFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !TRC &&
                                          <div className="invalid-feedback">Partner TaxResidencyCertificate is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                    </div>
                  } */}
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div>
                                <div className="container-fluid">
                                  {createContentUI()}
                                  <div className='col-6'>
                                    <div className='d-flex'>
                                      {videosList.length <= 5 &&
                                        //  <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} disabled={videosList.length === 5}>Add User &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} >Add User &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>

                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 4 ? "next disabled" : "next"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">


                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>

              </div>
            </Col>
          </Row>
        </Form>
        {
          errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div >
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
      <Modal size="md" isOpen={showAlertFile} backdrop={'static'} centered={false}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">{partner.IsTCDisabled ? "Could you please confirm that you would like to Disable Terms and Conditions!" : "Could you please confirm that you would like to Enable Terms and Conditions!"}</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => setShowAlertFile(false)}>No</button>
              <button type="button" className="btn btn-primary" onClick={TCEnabledStateChange(partner.IsTCDisabled ? true : false)}>Proceed</button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment >
  )
}

Create_Partner.propTypes = {}

export default Create_Partner