import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Modal,
  Input,
  Spinner,
  UncontrolledPopover,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label,
} from "reactstrap"
import queryString from 'query-string';
import moment from "moment";

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  getProxyAdUnits as onGetProxyAdUnits,
  statusProxyAdUnit as onStatusProxyAdUnit,
  archiveProxyAdUnit as onArchiveProxyAdUnit,
  statusProxyAdUnitSuccess,
  archiveProxyAdUnitSuccess,
  addProxyAdUnitFail,
  updateProxyAdUnitFail,
} from "store/Proxy/ProxyAdUnits/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Create_ProxyAdUnits from "./Create_ProxyAdUnits";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
var loader = true


const ProxyAdUnits = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])

  const [jsTagModalState, setJsTagModalState] = useState(false);
  const [popoverdismiss, setpopoverdismiss] = useState(false);
  const [popoverdismissUrl, setpopoverdismissUrl] = useState(false);
  const [readUnitData, setReadUnitData] = useState('')
  const [requestTotal, setRequestTotal] = useState(0)
  const inputRef = useRef();
  const [statusSearch, setStatusSearch] = useState('Active')

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [switchLoader, setSwitchLoader] = useState(true)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
  });

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
      setJsTagModalState(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const { start, end } = state;

  const handleEvent = (startDate, endDate, label) => {
    console.log(startDate, endDate)
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    dispatch(onGetProxyAdUnits({
      filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
      "endDate": moment(toDate).format("YYYY-MM-DD")
    }));
  };

  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const onToggleTooltip = (e) => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  const [isTooltipOpen1, setIsTooltipOpen1] = useState(false);

  const onToggleTooltip1 = (e) => {
    setIsTooltipOpen1(!isTooltipOpen1)
  }

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetProxyAdUnits({
      filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
      "endDate": moment(toDate).format("YYYY-MM-DD")
    }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetProxyAdUnits({
      filter: 'Active', "startDate": moment(fromDate).format("YYYY-MM-DD"),
      "endDate": moment(toDate).format("YYYY-MM-DD")
    }));
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    fromDate = event.startDate._d.toISOString();
    toDate = event.endDate._d.toISOString();
    dispatch(onGetProxyAdUnits({
      filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
      "endDate": moment(toDate).format("YYYY-MM-DD")
    }));
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.proxyAdUnitID)
        selectedItem.push(row.proxyAdUnitID)
        console.log('1', selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.proxyAdUnitID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.proxyAdUnitID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.proxyAdUnitID)
          selectedItem.push(item.proxyAdUnitID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { proxyAdUnits } = useSelector(state => ({
    proxyAdUnits: state.proxyAdUnit.proxyAdU,
  }));

  const { loading } = useSelector(state => ({
    loading: state.proxyAdUnit.loading,
  }));

  const { proxyAdUnitsResponse } = useSelector(state => ({
    proxyAdUnitsResponse: state.proxyAdUnit.response,
  }));

  const { archiveProxyAdUnitsResponse } = useSelector(state => ({
    archiveProxyAdUnitsResponse: state.proxyAdUnit.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.proxyAdUnits.errorMsg,
  //   successMsg: state.proxyAdUnits.successMsg,
  // }));

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);

  console.log('JB2', proxyAdUnits)

  // useEffect(() => {
  //   if(loader){
  //     const interval = setInterval(() => {
  //       if(proxyAdUnits !== null){
  //         console.log(proxyAdUnits )
  //         if(proxyAdUnits.length > 0){
  //           loader = false
  //         }
  //        else{
  //           loader = false
  //         console.log('No Data')
  //        }
  //       }
  //     }, 2000);

  //     return () => clearInterval(interval);
  //   }
  // }, []);


  console.log('JB', proxyAdUnitsResponse)
  useEffect(() => {
    if (proxyAdUnitsResponse !== null) {
      if (proxyAdUnitsResponse.success) {
        toastr.success('ProxyAdUnit Status Changed Successfully')
        dispatch(onGetProxyAdUnits({
          filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
          "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        dispatch(statusProxyAdUnitSuccess(null));
      }
    }
  }, [dispatch, proxyAdUnitsResponse]);

  useEffect(() => {
    if (archiveProxyAdUnitsResponse !== null) {
      if (archiveProxyAdUnitsResponse.success) {
        toastr.success('ProxyAdUnit Archived Successfully')
        dispatch(onGetProxyAdUnits({
          filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
          "endDate": moment(toDate).format("YYYY-MM-DD")
        }));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        dispatch(archiveProxyAdUnitSuccess(null));
      }
    }
  }, [dispatch, archiveProxyAdUnitsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    // dispatch(onGetProxyAdUnits({
    //   filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
    //   "endDate": moment(toDate).format("YYYY-MM-DD")
    // }));
    dispatch(addProxyAdUnitFail(""));
    dispatch(updateProxyAdUnitFail(""));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, proxyAdUnit) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('ProxyAdUnit Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('ProxyAdUnit Updated Successfully!...')
    }
    selectedData = proxyAdUnit
    setIsArchived(proxyAdUnit.isProxyAdUnitArchived)
    dispatch(onGetProxyAdUnits({
      filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
      "endDate": moment(toDate).format("YYYY-MM-DD")
    }));
    dispatch(addProxyAdUnitFail(""));
    dispatch(updateProxyAdUnitFail(""));
    setIsRight(close);
  };

  useEffect(() => {
    if (proxyAdUnits !== null) {
      console.log('listCalled')
      dispatch(onGetProxyAdUnits({
        filter: statusSearch, "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD")
      }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const proxyAdUnitListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      dataField: "proxyAdUnitID",
      sort: true,
      footer: 'Total Requests',
      // style: { backgroundColor: 'green' },
      //  headerStyle: { backgroundColor: 'green' }
    },
    {
      dataField: "proxyAdUnitCreateTime",
      text: "Creation Date",
      sort: true,
      footer: ''
    },
    {
      text: "Ad Unit Name",
      dataField: "proxyAdUnitName",
      sort: true,
      style: { wordBreak: 'break-word' },
      footer: '',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, proxyAdUnit) => (
        <>
          <h5 className="font-size-12 mb-1">
            <span className="text-dark">
              {proxyAdUnit.proxyAdUnitName}
            </span>
          </h5>
        </>
      ),
    },
    {
      dataField: "proxyAdUnitSize",
      text: "Size",
      sort: true,
      footer: ''
    },
    {
      dataField: "sourcePlatformName",
      text: "Source Platform ",
      sort: true,
      footer: '',
      style: { wordBreak: 'break-word' }
    },
    {
      dataField: "proxyPassbackPlatformName",
      text: "Passback Platform ",
      sort: true,
      footer: '',
      style: { wordBreak: 'break-word' }
    },
    {
      dataField: "requests",
      text: "Requests ",
      sort: true,
      footer: `${requestTotal}`
    },
    {
      dataField: "proxyAdUnitStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, proxyAdUnit) => (
        <>
          {proxyAdUnit.proxyAdUnitStatus && !proxyAdUnit.isProxyAdUnitArchived &&
            <span className="active-state">Active</span>
          }
          {!proxyAdUnit.proxyAdUnitStatus && !proxyAdUnit.isProxyAdUnitArchived &&
            <span className="inactive-state">Inactive</span>
          }
          {proxyAdUnit.isProxyAdUnitArchived &&
            <span className="archived-state">Archived</span>
          }
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits) && (!UserRolePrivileges.ProxyAds.ProxyAdUnits.update),

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, proxyAdUnit) => (
        <div className="d-flex gap-3" >
          <Link className="Email-state" to="#" onClick={toggleDrawer(true, proxyAdUnit)}>
            {proxyAdUnit.isProxyAdUnitArchived &&
              // <i
              //   className="mdi mdi-eye font-size-18"
              //   id="edittooltip"
              // ></i>
                 'View'
            }
            {!proxyAdUnit.isProxyAdUnitArchived &&
              // <i
              //   className="mdi mdi-pencil font-size-18"
              //   id="edittooltip"
              // ></i>
           'Edit'
            }

          </Link>
          {!proxyAdUnit.isProxyAdUnitArchived && proxyAdUnit.isLive &&
            <span className="archived-state" onClick={() => { tog_standard(proxyAdUnit); }} style={{ cursor: 'pointer',width:'max-content' }}>
              View Tag
            </span>
          }
                {!proxyAdUnit.isProxyAdUnitArchived && !proxyAdUnit.isLive &&
            <span className="archived-state" style={{width:'max-content' }}>
              Processing...
            </span>
          }

        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log(proxyAdUnits)
    if (proxyAdUnits !== null && proxyAdUnits.length > 0) {
      let totalRequest = 0
      proxyAdUnits.map((item, index) => {
        totalRequest = totalRequest + item.requests
        item.tagLiveState = true
      })
      proxyAdUnits[proxyAdUnits.length - 1].tagLiveState = tagEnablesCheck(proxyAdUnits[proxyAdUnits.length - 1].proxyAdUnitTime)
      setRequestTotal(totalRequest)
      console.log(proxyAdUnits[proxyAdUnits.length - 1])
    }

  }, [proxyAdUnits]);

  function tagEnablesCheck(adUnitTime) {
    console.log("Input Time:", adUnitTime);

    // Parse the given timestamp
    const givenTimestamp = new Date(adUnitTime).getTime();

    // Get the current UTC time
    const currentUtcTime = Date.now();

    // Convert UTC to EST (subtract 5 hours for EST)
    const estOffset = 5 * 60 * 60 * 1000; // EST is UTC-5 hours
    const currentEstTime = currentUtcTime - estOffset;

    // Calculate the difference in milliseconds
    const difference = currentUtcTime - givenTimestamp;

    // 3 minutes in milliseconds
    const threeMinutes = 3 * 60 * 1000;

    // Log values for debugging
    console.log("Given Timestamp (ms):", givenTimestamp);
    console.log("Current UTC Time (ms):", currentUtcTime);
    console.log("Current EST Time (ms):", currentEstTime);
    console.log("Difference (ms):", difference);
    console.log("Threshold (3 minutes in ms):", threeMinutes);

    // Check if the difference is greater than or equal to 3 minutes
    const isEnabled = difference >= threeMinutes;
    console.log("Button Enabled:", isEnabled);

    return isEnabled;
  }


  function globalPriceSumFormatter(items) {
    console.log(items)
    var totalPrice = 0;
    items.forEach(function (item) {
      totalPrice = totalPrice + item;
    });
    console.log(totalPrice)
    return parseInt(totalPrice);
  }

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveProxyAdUnit = async () => {
    dispatch(onArchiveProxyAdUnit({ idList: selectedItem }));
  }

  const statusProxyAdUnit = (state) => (event) => {
    console.log(state)
    dispatch(onStatusProxyAdUnit({ idList: selectedItem, proxyAdUnitStatus: state }));
  }

  console.log(proxyAdUnits)

  if (proxyAdUnits.length > 0) {
    if (UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits) {
      if (!UserRolePrivileges.ProxyAds.ProxyAdUnits.archive && !UserRolePrivileges.ProxyAds.ProxyAdUnits.status) {
        proxyAdUnits.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        proxyAdUnits.map((item, index) => {
          if (item.isProxyAdUnitArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  function tog_standard(adUnit) {
    console.log(adUnit)
    if (adUnit !== '') {
      setJsTagModalState(!jsTagModalState);
      setReadUnitData(adUnit)
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([readUnitData.JSTag], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = (readUnitData.proxyAdUnitName + ".txt");
    document.body.appendChild(element);
    element.click();
  };

  const downloadTxtFileUrl = () => {
    const element2 = document.createElement("b");
    const file2 = new Blob([readUnitData.URL], {
      type: "text/plain"
    });
    element2.href = URL.createObjectURL(file2);
    element2.download = (readUnitData.proxyAdUnitName + ".txt");
    document.body.appendChild(element2);
    element2.click();
  };

  const copyTag = () => {
    setpopoverdismiss(!popoverdismiss)
    navigator.clipboard.writeText(readUnitData.JSTag)
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(readUnitData.URL)
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Ad Units | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
            <SupplyBreadCrumb title="Proxy" breadcrumbItem="Ad Units " />
            {UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits && UserRolePrivileges.ProxyAds.ProxyAdUnits.create &&
              <div className="text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  New Ad Unit
                </Button>
              </div>
            }
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField={keyField}
                      data={proxyAdUnits}
                      columns={proxyAdUnitListColumns}
                      // bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="4"></Col>
                            <Col sm="4" className="date-range ">
                              <div className="text-sm-end">
                                <DateRangePicker
                                  initialSettings={{
                                    opens: 'left',
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                      Today: [moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                      moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                      Yesterday: [
                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                      ],
                                      'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                      'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                                      'Last Month': [
                                        moment().subtract(1, 'month').startOf('month'),
                                        moment().subtract(1, 'month').endOf('month'),
                                      ],
                                    },
                                  }}
                                  onCallback={handleEvent}
                                >
                                  <button className='datePicker' type="button">
                                    <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                  </button>
                                </DateRangePicker>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              {selectedRowsStatus && selectedIndex.length > 0 &&
                                <div className="row m-0 React-Table-Selection">
                                  {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                  <div style={{ padding: '0.5% 2%' }}>

                                    {/* <button color="primary" className="btn btn-primary " onClick={statusProxyAdUnit(true)}>Activate</button> */}
                                    {UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits && UserRolePrivileges.ProxyAds.ProxyAdUnits.status &&

                                      <button color="primary" className="btn btn-primary " onClick={statusProxyAdUnit(true)}>
                                        Activate
                                      </button>
                                    }
                                    &nbsp;  &nbsp;
                                    {UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits && UserRolePrivileges.ProxyAds.ProxyAdUnits.status &&

                                      <button color="primary" className="btn btn-primary " onClick={statusProxyAdUnit(false)}>
                                        DeActivate
                                      </button>
                                    }
                                    &nbsp;  &nbsp;
                                    {UserRolePrivileges.ProxyAds && UserRolePrivileges.ProxyAds.ProxyAdUnits && UserRolePrivileges.ProxyAds.ProxyAdUnits.archive &&

                                      <Button color="primary" className="btn btn-primary " onClick={archiveProxyAdUnit} >
                                        Archive
                                      </Button>
                                    }
                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                      className="mdi mdi-close-thick font-size-18"
                                    ></i></Button>
                                  </div>
                                </div>
                              }
                            </Col>
                            <Col xl="12" className="table-responsive">
                              <div >
                                <BootstrapTable
                                  keyField={keyField}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  pagination={paginationFactory(pageOptions)}
                                  noDataIndication={<h5 className="text-center">No Data</h5>}

                                // ref={node}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Proxy AdUnit' : 'New Proxy Ad Unit'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_ProxyAdUnits closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedProxyAdUnit={selectedData} />
        </OffcanvasBody>
      </Offcanvas>


      {readUnitData &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Ad Unit : {readUnitData.proxyAdUnitName}
            </h5>
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-md-9 col-lg-10">
                <h5>JS Tag</h5>
              </div>
              <div className="col-md-3 col-lg-2">
                <Button
                  color="primary"
                  id="Popoverdismiss2"
                  className="font-16 btn-block btn btn-primary"
                  onClick={copyTag}
                  onMouseLeave={() => {
                    setpopoverdismiss(!popoverdismiss)
                  }}

                >
                  <i className="bx bx-copy me-1" />
                  Copy Tag
                </Button>
                <ReactTooltip
                  anchorSelect="#Popoverdismiss2"
                  place="top"
                  variant="dark"
                  openOnClick="true"
                  delayHide={800}
                  content="Copied"
                />
              </div>
            </div>

            <Input
              ref={inputRef}
              type="textarea"
              id="textarea"
              className="mt-3"
              rows="4"
              readOnly
              name="jsTag" value={readUnitData.JSTag}
            />
            <br />
            <div className="row mb-2">
              <div className="col-md-9 col-lg-10">
                <h5>URL</h5>
              </div>
              <div className="col-md-3 col-lg-2">
                <Button
                  color="primary"
                  id="Popoverdismiss1"
                  className="font-16 btn-block btn btn-primary"
                  onClick={copyUrl}
                  onMouseLeave={() => {
                    setpopoverdismiss(!popoverdismiss)
                  }}

                >
                  <i className="bx bx-copy me-1" />
                  Copy URL
                </Button>
                <ReactTooltip
                  anchorSelect="#Popoverdismiss1"
                  place="top"
                  variant="dark"
                  openOnClick="true"
                  delayHide={800}
                  content="Copied"
                />
              </div>
            </div>

            <Input
              type="textarea"
              id="textarea"
              rows="2"
              className="mt-3"
              readOnly
              name="url" value={readUnitData.URL}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            {/* <Button
              color="primary"
              id="popoverdismissurl"
              className="font-16 btn-block btn btn-primary"
              onClick={copyUrl}
              onMouseLeave={() => {
                setpopoverdismissUrl(!popoverdismissUrl)
              }}
            >
              <i className="bx bx-copy me-1" />
              Copy URL
            </Button> */}
            {/* <UncontrolledPopover
              target="popoverdismissurl"
              placement="top"
              isOpen={popoverdismissUrl}
            >
              <PopoverHeader>URL Copied</PopoverHeader>
            </UncontrolledPopover> */}
            {/* <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={downloadTxtFileUrl}
            >
              <i className="bx bx-download me-1" />
              Download URL
            </Button> */}
          </div>
        </Modal>
      }

    </React.Fragment>
  )
}

export default ProxyAdUnits