import {
    LOAD_CONTEXTUAL_DATAS,
    NO_DATA,
    GET_CONTEXTUAL_DATAS,
    GET_CONTEXTUAL_DATAS_FAIL,
    GET_CONTEXTUAL_DATAS_SUCCESS,
    ADD_CONTEXTUAL_DATA,
    ADD_CONTEXTUAL_DATA_SUCCESS,
    ADD_CONTEXTUAL_DATA_ERROR,
    UPDATE_CONTEXTUAL_DATA,
    UPDATE_CONTEXTUAL_DATA_SUCCESS,
    UPDATE_CONTEXTUAL_DATA_ERROR,
    READ_CONTEXTUAL_DATA,
    READ_CONTEXTUAL_DATA_SUCCESS,
    STATUS_CONTEXTUAL_DATA,
    STATUS_CONTEXTUAL_DATA_SUCCESS,
    ARCHIVE_CONTEXTUAL_DATA ,
    ARCHIVE_CONTEXTUAL_DATA_SUCCESS,

    CALL_API,
    CALL_API_SUCCESS

  } from "./actionTypes"

  export const loadNetBlockings = Params => ({
    type: LOAD_CONTEXTUAL_DATAS,
    payload:Params
  })

  export const noDataNetBlockings = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getContextualDatas = Params => ({
    type: GET_CONTEXTUAL_DATAS,
    payload:Params
  })
  
  export const getContextualDatasSuccess = NetBlockings => ({
    type: GET_CONTEXTUAL_DATAS_SUCCESS,
    payload: NetBlockings,
  })

  export const getContextualDatasFail = error => ({
    type: GET_CONTEXTUAL_DATAS_FAIL,
    payload: error,
  })

  export const addNewNetBlocking = NetBlocking => ({
    type: ADD_CONTEXTUAL_DATA,
    payload: NetBlocking,
  })
  
  export const addNetBlockingSuccess = netBlocking => ({
    type: ADD_CONTEXTUAL_DATA_SUCCESS,
    payload: netBlocking,
  })
  
  export const addNetBlockingFail = netBlocking => ({
    type: ADD_CONTEXTUAL_DATA_ERROR,
    payload: netBlocking,
  })

  export const updateNetBlocking = netBlocking => ({
    type: UPDATE_CONTEXTUAL_DATA,
    payload: netBlocking,
  })
  
  export const updateNetBlockingSuccess = netBlocking => ({
    type: UPDATE_CONTEXTUAL_DATA_SUCCESS,
    payload: netBlocking,
  })
  
  export const updateNetBlockingFail = netBlocking => ({
    type: UPDATE_CONTEXTUAL_DATA_ERROR,
    payload: netBlocking,
  })

  export const readNetBlocking = netBlocking => ({
    type: READ_CONTEXTUAL_DATA,
    payload: netBlocking,
  })

  export const readNetBlockingSuccess = netBlocking => ({
    type: READ_CONTEXTUAL_DATA_SUCCESS,
    payload: netBlocking,
  })

  export const statusNetBlocking = netBlocking => ({
    type: STATUS_CONTEXTUAL_DATA,
    payload: netBlocking,
  })

  export const statusNetBlockingSuccess = netBlocking => ({
    type: STATUS_CONTEXTUAL_DATA_SUCCESS,
    payload: netBlocking,
  })

  export const archiveNetBlocking = netBlocking => ({
    type: ARCHIVE_CONTEXTUAL_DATA,
    payload: netBlocking,
  })

  export const archiveNetBlockingSuccess = netBlocking => ({
    type: ARCHIVE_CONTEXTUAL_DATA_SUCCESS,
    payload: netBlocking,
  })

  export const callAPIChange = netBlocking => ({
    type: CALL_API,
    payload: netBlocking,
  })

  export const apiCallResponseSuccess = netBlocking => ({
    type: CALL_API_SUCCESS,
    payload: netBlocking,
  })