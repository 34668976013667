import {
    LOAD_ACTIVITIES,
    NO_DATA_PARTNER,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAIL,
    ADD_PARTNER_SUCCESS,
    ADD_PARTNER_ERROR,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_ERROR,
    READ_PARTNER_SUCCESS,
    STATUS_PARTNER_SUCCESS,
    ARCHIVE_PARTNER_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,

    GET_PARTNERS_SUCCESS_LIST,
    GET_PARTNERS_FAIL_LIST
} from "./actionTypes"

const INIT_STATE = {
    partners: [],
    partnerList:[],
    loading:false,
    noData:false,
    users:[],
    error: {},
    successMsg: null,
    errorMsg: null,
    partner: null,
    response:null,
    archiveMsg:null,
}

const partners = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_PARTNER:
                return {
                    ...state,
                    noData: true,
                }
        case GET_PARTNERS_SUCCESS:
            return {
                ...state,
                loading:false,
                partners: action.payload,
            }

        case GET_PARTNERS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
            case GET_PARTNERS_SUCCESS_LIST:
                return {
                    ...state,
                    loading:false,
                    partnerList: action.payload,
                }
    
            case GET_PARTNERS_FAIL_LIST:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
            case GET_USERS_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    users: action.payload,
                }
            
            case GET_USERS_FAIL:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
        case ADD_PARTNER_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_PARTNER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PARTNERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PARTNER_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PARTNERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PARTNER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PARTNERS_SUCCESS' ? null : action.payload,
            }
            case ADD_USER_SUCCESS:

                return {
                    ...state,
                    successMsg: action.payload,
                }
            case ADD_USER_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.payload,
                }
            case UPDATE_USER_SUCCESS:
                return {
                    ...state,
                    successMsg: action.payload,
                }
            case UPDATE_USER_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.payload,
                }
        case READ_PARTNER_SUCCESS:
            return {
                ...state,
                partner: action.payload,
            }
        case STATUS_PARTNER_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PARTNER_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default partners