import React, { useState,useEffect } from "react";
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Col,
    Collapse,
    Button,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    BreadcrumbItem
} from "reactstrap";

import classnames from "classnames";
import Devices from "./Devices";
import Domians from "./Domians";
import UserAgents from "./UserAgents";
import Apps from "./Apps";
import IPV4 from "./IPV4";
import IPV6 from "./IPV6";
import UploadBlockings from "./UploadBlockings";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";


function NetworkBlocking(props) {
    const dispatch = useDispatch();
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [isRight, setIsRight] = useState(false);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [networkBlockingData, setNetworkBlockingData] = useState({})

    // const { networkBlocks } = useSelector(state => ({
    //   networkBlocks: state.commonEvents.blockingAllData,
    // }));
  
    // useEffect(() => {
    //   if (networkBlocks !== null) {
    //     dispatch(onGetBlockingData());
    //   }
    // }, []);
  
    // useEffect(() => {
    //   if (networkBlocks && JSON.stringify(networkBlocks) !== "{}") {
    //     setNetworkBlockingData(networkBlocks)
    //   }
    // }, [networkBlocks]);

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, role) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('New Blocking Created Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Blocking Updated Successfully!...')
        }
        setIsRight(close);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Network Blocking | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Admin" breadcrumbItem="Network Blocking" />
                        {/* <div className=" text-sm-end">
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleRightCanvas}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add Blocking
                            </Button>
                        </div> */}
                    </div>

                    <Nav tabs className="nav-tabs-custom nav-justified" style={{ padding: '1pc 2pc',borderBottom:'none' }}>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Device ID</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Domain</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>User Agents</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                    toggleCustom("4");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Apps</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "5",
                                })}
                                onClick={() => {
                                    toggleCustom("5");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>IPV4</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "6",
                                })}
                                onClick={() => {
                                    toggleCustom("6");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>IPV6</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                    >
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <Devices activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <Domians activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    <UserAgents activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    <Apps activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <IPV4 activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="6">
                            <Row>
                                <Col sm="12">
                                    <IPV6 activeId={customActiveTab} networkBlockData={networkBlockingData} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    <Offcanvas isOpen={isRight} direction='end'  >
                        <OffcanvasHeader toggle={toggleRightCanvas}>
                            {'New Upload for Blocking'}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <UploadBlockings closeCanvas={toggleDrawer(false)} />
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment >
    )
}

NetworkBlocking.propTypes = {}

export default NetworkBlocking
