import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WEBSITE_CATEGORIZATIONS,ADD_WEBSITE_CATEGORIZATION ,GET_LANGUAGES, UPDATE_WEBSITE_CATEGORIZATION,READ_WEBSITE_CATEGORIZATION,STATUS_WEBSITE_CATEGORIZATION,ARCHIVE_WEBSITE_CATEGORIZATION} from "./actionTypes"

import {
  loadWcgs,
  noWcgs,
  getWebsiteCategorizationsSuccess,
  getWebsiteCategorizationsFail,
  addWebsiteCategorizationSuccess,
  addWebsiteCategorizationFail,
  updateWebsiteCategorizationSuccess,
  updateWebsiteCategorizationFail,
  readWebsiteCategorizationSuccess,
  statusWebsiteCategorizationSuccess,
  archiveWebsiteCategorizationSuccess,
  getLanguageSuccess,
  getLanguageFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getWebsiteCategorization,getLanguages,createWebsiteCategorization,updateWebsiteCategorization,readWebsiteCategorization,statusChangeWebsiteCategorization,archiveWebsiteCategorization } from "../../../helpers/Backend_helper"

function* fetchWebsiteCategorizations() {
  try {
    yield put(loadWcgs(true))
    const response = yield call(getWebsiteCategorization)
    console.log(response)
    response.responseData.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.responseData.response.length === 0){
    yield put(noWcgs(true))
  }
    yield put(getWebsiteCategorizationsSuccess(response.responseData.response))
  } catch (error) {
    yield put(getWebsiteCategorizationsFail(error))
  }
}

function* onReadWebsiteCategorization({ payload: websiteCategorization }) {
  try {
    const response = yield call(readWebsiteCategorization, websiteCategorization)
    yield put(readWebsiteCategorizationSuccess(response))
  } catch (error) {
    yield put(readWebsiteCategorizationSuccess(error))
  }
}

function* onAddWebsiteCategorization({ payload: websiteCategorization }) {
  try {
    const response = yield call(createWebsiteCategorization, websiteCategorization)
    if(response.responseData.success){
      yield put(addWebsiteCategorizationSuccess(response.responseData))
    }else{
      yield put(addWebsiteCategorizationFail(response.responseData))
    }
  } catch (error) {
    yield put(addWebsiteCategorizationFail(error))
  }
}

function* onUpdateWebsiteCategorization({ payload: websiteCategorization }) {
  try {
    const response = yield call(updateWebsiteCategorization, websiteCategorization)
    if(response.responseData.success){
      yield put(updateWebsiteCategorizationSuccess(response.responseData))
    }else{
      yield put(updateWebsiteCategorizationFail(response.responseData))
    }
  } catch (error) {
    yield put(updateWebsiteCategorizationFail(error))
  }
}

function* onStatusWebsiteCategorization({ payload: websiteCategorization }) {
  try {
    const response = yield call(statusChangeWebsiteCategorization, websiteCategorization)
    if(response.success){
      yield put(statusWebsiteCategorizationSuccess(response))
    }else{
      yield put(statusWebsiteCategorizationSuccess(response.message))
    }
  } catch (error) {
    yield put(updateWebsiteCategorizationFail(error))
  }
}

function* onArchiveWebsiteCategorization({ payload: websiteCategorization }) {
  try {
    const response = yield call(archiveWebsiteCategorization, websiteCategorization)
    yield put(archiveWebsiteCategorizationSuccess(response))
  } catch (error) {
    yield put(archiveWebsiteCategorizationSuccess(error))
  }
}

function* fetchLanguages() {
  try {
    const response = yield call(getLanguages)
    console.log(response)
  console.log(response.responseData)
    yield put(getLanguageSuccess(response.responseData.response[1].value))
  } catch (error) {
    yield put(getLanguageFail(error))
  }
}

function* websiteCategorizationSaga() {
  yield takeEvery(GET_WEBSITE_CATEGORIZATIONS, fetchWebsiteCategorizations)
  yield takeEvery(ADD_WEBSITE_CATEGORIZATION, onAddWebsiteCategorization)
  yield takeEvery(UPDATE_WEBSITE_CATEGORIZATION, onUpdateWebsiteCategorization)
  yield takeEvery(READ_WEBSITE_CATEGORIZATION, onReadWebsiteCategorization)
  yield takeEvery(STATUS_WEBSITE_CATEGORIZATION, onStatusWebsiteCategorization)
  yield takeEvery(ARCHIVE_WEBSITE_CATEGORIZATION, onArchiveWebsiteCategorization)
  yield takeEvery(GET_LANGUAGES, fetchLanguages)
}

export default websiteCategorizationSaga;
