import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_IOGROUPS,
    GET_IOGROUPS_FAIL,
    GET_IOGROUPS_SUCCESS,
    ADD_IOGROUP,
    ADD_IOGROUP_SUCCESS,
    ADD_IOGROUP_ERROR,
    UPDATE_IOGROUP,
    UPDATE_IOGROUP_SUCCESS,
    UPDATE_IOGROUP_ERROR,
    READ_IOGROUP,
    READ_IOGROUP_SUCCESS,
    STATUS_IOGROUP,
    STATUS_IOGROUP_SUCCESS,
    ARCHIVE_IOGROUP ,
    ARCHIVE_IOGROUP_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesIOGroups = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataIOGroups = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getIOGroups = Params => ({
    type: GET_IOGROUPS,
    payload:Params
  })
  
  export const getIOGroupsSuccess = IOGroups => ({
    type: GET_IOGROUPS_SUCCESS,
    payload: IOGroups,
  })

  export const getIOGroupsFail = error => ({
    type: GET_IOGROUPS_FAIL,
    payload: error,
  })

  export const addNewIOGroup = IOGroup => ({
    type: ADD_IOGROUP,
    payload: IOGroup,
  })
  
  export const addIOGroupSuccess = iOGroup => ({
    type: ADD_IOGROUP_SUCCESS,
    payload: iOGroup,
  })
  
  export const addIOGroupFail = iOGroup => ({
    type: ADD_IOGROUP_ERROR,
    payload: iOGroup,
  })

  export const updateIOGroup = iOGroup => ({
    type: UPDATE_IOGROUP,
    payload: iOGroup,
  })
  
  export const updateIOGroupSuccess = iOGroup => ({
    type: UPDATE_IOGROUP_SUCCESS,
    payload: iOGroup,
  })
  
  export const updateIOGroupFail = iOGroup => ({
    type: UPDATE_IOGROUP_ERROR,
    payload: iOGroup,
  })

  export const readIOGroup = iOGroup => ({
    type: READ_IOGROUP,
    payload: iOGroup,
  })

  export const readIOGroupSuccess = iOGroup => ({
    type: READ_IOGROUP_SUCCESS,
    payload: iOGroup,
  })

  export const statusIOGroup = iOGroup => ({
    type: STATUS_IOGROUP,
    payload: iOGroup,
  })

  export const statusIOGroupSuccess = iOGroup => ({
    type: STATUS_IOGROUP_SUCCESS,
    payload: iOGroup,
  })

  export const archiveIOGroup = iOGroup => ({
    type: ARCHIVE_IOGROUP,
    payload: iOGroup,
  })

  export const archiveIOGroupSuccess = iOGroup => ({
    type: ARCHIVE_IOGROUP_SUCCESS,
    payload: iOGroup,
  })