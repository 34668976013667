breakingads: 15/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import {
  Row, Col, Input, Label, Form, FormFeedback, InputGroup
} from "reactstrap";

var videoSlotList = [];

const PlayerAdConfig = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [adConfig, setAdConfig] = useState({
    adsState: 'breakingAds',
    dfp1x1: false,
    totalAdsPerPlayerLoad: 10,
    maxAdsPerSlot: 1,
    delayBetweenAds: 5,
    midRollSlots: [...videoSlotList],
    preRollSlots: {},
    waterfallRetry_Slot: 0,
    errorLimit_waterfallRetry: 0,
    vastTag: '',
    vast_mute: '',
    preRollState: false,
    preRollVastTag: '',
    midRollVastTag: '',
    preRollAdBreak: false,
    contentStartDelay: 5,
    desktopSettingsEnabled: true,
    mobileSettingsEnabled: false,
    mobileAdConfig: {
      adsState: 'breakingAds',
      totalAdsPerPlayerLoad: 10,
      maxAdsPerSlot: 1,
      delayBetweenAds: 5,
      midRollSlots: [...videoSlotList],
      preRollSlots: {},
      waterfallRetry_Slot: 0,
      errorLimit_waterfallRetry: 0,
      vastTag: '',
      vast_mute: '',
      preRollState: false,
      preRollVastTag: '',
      midRollVastTag: '',
      preRollAdBreak: false,
      contentStartDelay: 5,
    }
  })

  const [preroll, setPreroll] = useState({
    firstAdTimeout: 15,
    adTimeout: 0,
    startAdAt: 0,
    maxAdsPerSlots: 1,
    nextAdTimeout: 8,
  })

  console.log(adConfig)

  const handleChange = e => {
    let { name, value } = e.target;
    console.log(name, value)
    setAdConfig(adConfig => ({ ...adConfig, [name]: value }));
    props.selectedItems({ ...adConfig, [name]: value })
    if (name === 'adsState') {
      videoSlotList = []
      videoSlotList.push({
        firstAdTimeout: 15,
        adTimeout: 0,
        startAdAt: 0,
        maxAdsPerSlots: 1,
        nextAdTimeout: 8,
      })
      setSlotList([...videoSlotList])
      setAdConfig(adConfig => ({
        ...adConfig, totalAdsPerPlayerLoad: 10,
        maxAdsPerSlot: 1,
        delayBetweenAds: 5,
        midRollSlots: [...videoSlotList],
        preRollSlots: {},
        waterfallRetry_Slot: 0,
        errorLimit_waterfallRetry: 0,
        vastTag: '',
        vast_mute: '',
        preRollState: false,
        preRollVastTag: '',
        midRollVastTag: ''
      }));
      props.selectedItems({
        ...adConfig, totalAdsPerPlayerLoad: 10,
        maxAdsPerSlot: 1,
        delayBetweenAds: 5,
        midRollSlots: [...videoSlotList],
        preRollSlots: {},
        waterfallRetry_Slot: 0,
        errorLimit_waterfallRetry: 0,
        vastTag: '',
        vast_mute: '',
        preRollState: false,
        preRollVastTag: '',
        midRollVastTag: ''
      })
    }
  }

  const handleChangeNumber = e => {
    let { name, value } = e.target;
    setAdConfig(adConfig => ({ ...adConfig, [name]: Number(value) }));
    props.selectedItems({ ...adConfig, [name]: Number(value) })

  }

  const handleChangeMobile = e => {
    let { name, value } = e.target;
    console.log(name, value)
    setAdConfig(adConfig => ({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: value } }));
    props.selectedItems({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: value } })
  }

  const handleChangeNumberMobile = e => {
    let { name, value } = e.target;
    setAdConfig(adConfig => ({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: Number(value) } }));
    props.selectedItems({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: Number(value) } })
  }

  const [slotList, setSlotList] = useState([...videoSlotList]);
  const [addNewSlot, setAddNewSlot] = useState(true);
  const addNewVideoSlot = () => {
    setAddNewSlot(false)
    console.log(adConfig)
    if (videoSlotList.length > 0) {
      videoModal(parseInt(videoSlotList[videoSlotList.length - 1].startAdAt) + 5)
    } else {
      videoModal(2)
    }
  }

  useEffect(async () => {
    if (props.settingsData.PlayerSettingType !== '') {
      setAdConfig(adConfig => ({ ...adConfig, mobileSettingsEnabled: props.settingsData.playerSettingsConfig.mobileSettingsEnabled, desktopSettingsEnabled: props.settingsData.playerSettingsConfig.desktopSettingsEnabled }));
    }
  }, [props.settingsData.PlayerSettingType])

  useEffect(async () => {
    console.log(props.selectedPlayer)
    if (props.selectedPlayer !== '') {
      setAdConfig({ ...adConfig, ...props.selectedPlayer.playerSettingsConfig })
      setPreroll({ ...preroll, ...props.selectedPlayer.playerSettingsConfig.preRollSlots });
      videoSlotList = props.selectedPlayer.playerSettingsConfig.midRollSlots
      setSlotList([...videoSlotList])
      setAdConfig(adConfig => ({ ...adConfig, mobileSettingsEnabled: props.selectedPlayer.playerSettingsConfig.mobileSettingsEnabled, desktopSettingsEnabled: props.selectedPlayer.playerSettingsConfig.desktopSettingsEnabled }));
      props.selectedItems({ ...adConfig, mobileSettingsEnabled: props.selectedPlayer.playerSettingsConfig.mobileSettingsEnabled, desktopSettingsEnabled: props.selectedPlayer.playerSettingsConfig.desktopSettingsEnabled })
      props.selectedItems({ ...adConfig, preRollSlots: props.selectedPlayer.playerSettingsConfig.preRollSlots, midRollSlots: props.selectedPlayer.playerSettingsConfig.midRollSlots })
    } else {
      if (videoSlotList.length < 1) {
        addNewVideoSlot();
      }
    }
  }, [])

  const videoModal = (value) => {
    videoSlotList.push({
      firstAdTimeout: 15,
      adTimeout: 0,
      startAdAt: value,
      maxAdsPerSlots: 1,
      nextAdTimeout: 8,
    })
    setSlotList([...videoSlotList])
    setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList], mobileAdConfig: { ...adConfig.mobileAdConfig, midRollSlots: [...videoSlotList] } }));
    console.log(videoSlotList)
  }

  const slotHandleChange = (index) => e => {
    const { name, value } = e.target;
    videoSlotList[index] = { ...videoSlotList[index], [name]: Number(value) };
    setSlotList([...videoSlotList])
    setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList], mobileAdConfig: { ...adConfig.mobileAdConfig, midRollSlots: [...videoSlotList] } }));
    props.selectedItems({ ...adConfig, midRollSlots: [...videoSlotList], mobileAdConfig: { ...adConfig.mobileAdConfig, midRollSlots: [...videoSlotList] } })
  }

  const handleCheck = (e) => {
    console.log(e.target.checked);
    const { name, value } = e.target;
    if (name === 'preRollState') {
      if (e.target.checked) {
        setAdConfig(adConfig => ({ ...adConfig, preRollState: e.target.checked, preRollSlots: preroll, preRollVastTag: '', mobileAdConfig: { ...adConfig.mobileAdConfig, preRollState: e.target.checked, preRollSlots: preroll, preRollVastTag: '' } }));
        props.selectedItems({ ...adConfig, preRollState: e.target.checked, preRollSlots: preroll, preRollVastTag: '', mobileAdConfig: { ...adConfig.mobileAdConfig, preRollState: e.target.checked, preRollSlots: preroll, preRollVastTag: '' } })
      } else {
        setAdConfig(adConfig => ({ ...adConfig, preRollState: e.target.checked, preRollSlots: {}, preRollVastTag: '', mobileAdConfig: { ...adConfig.mobileAdConfig, preRollState: e.target.checked, preRollSlots: {}, preRollVastTag: '' } }));
        props.selectedItems({ ...adConfig, preRollState: e.target.checked, preRollSlots: {}, preRollVastTag: '', mobileAdConfig: { ...adConfig.mobileAdConfig, preRollState: e.target.checked, preRollSlots: {}, preRollVastTag: '' } })
      }
    } else {
      if (name === 'preRollAdBreak' && !e.target.checked) {
        setAdConfig(adConfig => ({ ...adConfig, contentStartDelay: 5 }));
        props.selectedItems({ ...adConfig, contentStartDelay: 5 })
      }
      setAdConfig(adConfig => ({ ...adConfig, [name]: e.target.checked }));
      props.selectedItems({ ...adConfig, [name]: e.target.checked })
    }
  }

  const handleCheckMobileSettings = (e) => {
    console.log(e.target.checked);
    const { name, value } = e.target;
    if (name === 'preRollAdBreak' && !e.target.checked) {
      setAdConfig(adConfig => ({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, contentStartDelay: 5 } }));
      props.selectedItems({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, contentStartDelay: 5 } })
    }
    setAdConfig(adConfig => ({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: e.target.checked } }));
    props.selectedItems({ ...adConfig, mobileAdConfig: { ...adConfig.mobileAdConfig, [name]: e.target.checked } })
  }

  const handleCheckMobile = (e) => {
    const { name, value } = e.target;
    setAdConfig(adConfig => ({ ...adConfig, [name]: e.target.checked }));
    props.selectedItems({ ...adConfig, [name]: e.target.checked })
  }

  const prerollHandleChange = (e) => {
    const { name, value } = e.target;
    let prerollData = { ...preroll, [name]: Number(value) }
    setPreroll(preroll => ({ ...preroll, [name]: Number(value) }));
    setAdConfig(adConfig => ({ ...adConfig, preRollSlots: prerollData, mobileAdConfig: { ...adConfig.mobileAdConfig, preRollSlots: prerollData } }));
    props.selectedItems({ ...adConfig, preRollSlots: prerollData, mobileAdConfig: { ...adConfig.mobileAdConfig, preRollSlots: prerollData } })
  }

  const deleteHandler = (i) => (e) => {
    if (videoSlotList.length > 0) {
      console.log(i)
      videoSlotList.splice(i, 1)
      console.log(videoSlotList)
      setSlotList([...videoSlotList])
      setAdConfig(adConfig => ({ ...adConfig, midRollSlots: [...videoSlotList], mobileAdConfig: { ...adConfig.mobileAdConfig, midRollSlots: [...videoSlotList] } }));
      props.selectedItems({ ...adConfig, midRollSlots: [...videoSlotList], mobileAdConfig: { ...adConfig.mobileAdConfig, midRollSlots: [...videoSlotList] } })
    }
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const createUI = () => {
    return slotList.map((item, i) =>
      <div key={i}>
        <div>
          {true && <label
            className="form-check-label mt-2"
          >
            {`Midroll slot ${i + 1}`}
          </label>}
          <div className="row mt-1">

            <div className='col-2'>
              <span className=' mt-2'>First Ad Timeout</span>
            </div>
            {/* <div className='col-2'>
              <span className='mt-2'> Ad Timeout</span>
            </div> */}
            <div className='col-2'>
              <span className='mt-2'>Start Ad At</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'> Max Ads Per Slots</span>
            </div>
            <div className='col-2'>
              <span className='mt-2'>Next Ad Timeout</span>
            </div>
          </div>
          <div className="row ">
            <div className='col-2'>
              <Input
                type="text" name="firstAdTimeout" value={item.firstAdTimeout} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            {/* <div className='col-2'>
              <Input
                type="text" name="adTimeout" value={item.adTimeout} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div> */}
            <div className='col-2'>
              <Input
                type="text" name="startAdAt" value={item.startAdAt} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="maxAdsPerSlots" value={item.maxAdsPerSlots} onChange={slotHandleChange(i)} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <Input
                type="text" name="nextAdTimeout" value={item.nextAdTimeout} onChange={slotHandleChange(i)} editable={true} onKeyPress={allowOnlyNumbers}
              />
            </div>
            <div className='col-2'>
              <button type='button' className='btn-danger mt-1' style={{ width: "4vh", cursor: 'pointer' }} onClick={deleteHandler(i)} disabled={videoSlotList.length === 1}>X</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  const createPrerollUI = () => {
    return (
      <div>
        <div className="row mt-3">
          <div className='col-2'>
            <span className=' mt-2'>First Ad Timeout</span>
          </div>
          {/* <div className='col-2'>
            <span className='mt-2'> Ad Timeout</span>
          </div> */}
          <div className='col-2'>
            <span className='mt-2'>Start Ad At</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'> Max Ads Per Slots</span>
          </div>
          <div className='col-2'>
            <span className='mt-2'>Next Ad Timeout</span>
          </div>
        </div>
        <div className="row ">
          <div className='col-2'>
            <Input
              type="number" name="firstAdTimeout" value={preroll.firstAdTimeout} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div>
          {/* <div className='col-2'>
            <Input
              type="number" name="adTimeout" value={preroll.adTimeout} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div> */}
          <div className='col-2'>
            <Input
              type="number" name="startAdAt" disabled value={preroll.startAdAt} onChange={prerollHandleChange} editable={true} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="maxAdsPerSlots" value={preroll.maxAdsPerSlots} onChange={prerollHandleChange} onKeyPress={allowOnlyNumbers}
            />
          </div>
          <div className='col-2'>
            <Input
              type="number" name="nextAdTimeout" value={preroll.nextAdTimeout} onChange={slotHandleChange} editable={true} onKeyPress={allowOnlyNumbers}
            />
          </div>

        </div>
        <hr style={{ height: 5, color: "#000000" }} />
      </div>
    )
  }

  return (
    <div>
      <div className='player-content'>
        <div className="container-fluid">
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="dfp1x1"
                checked={adConfig.dfp1x1} onChange={handleCheck} name="dfp1x1"
              />
              <label className="form-check-label " htmlFor="dfp1x1">
                <h6 className="heading6 mt-1">DFP 1x1</h6>
              </label>
            </div>
          </div>
          {props.settingsData.PlayerSettingType === 'all' &&
            <div className='row mb-4'>
              <div className='col-md-5'>
                <div className="form-check form-switch form-check-success form-switch-md" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemds"
                    checked={adConfig.desktopSettingsEnabled} onChange={handleCheckMobile} name="desktopSettingsEnabled"
                  />
                  <label className="form-check-label " htmlFor="customSwitchsizemds">
                    <span color='text-success' style={{ color: adConfig.desktopSettingsEnabled ? '#34c38f' : '#a3a3a3', marginTop: '' }}>Enable Desktop Ad Setup</span>&nbsp;&nbsp;
                  </label>
                </div>
              </div>
              <div className='col-md-1'></div>
              <div className='col-md-5'>
                <div className="form-check form-switch form-check-success form-switch-md" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemdss"
                    checked={adConfig.mobileSettingsEnabled} onChange={handleCheckMobile} name="mobileSettingsEnabled"
                  />
                  <label className="form-check-label " htmlFor="customSwitchsizemdss">
                    <span color='text-success' style={{ color: adConfig.mobileSettingsEnabled ? '#34c38f' : '#a3a3a3', marginTop: '' }}>Enable Mobile Ad Setup</span>&nbsp;&nbsp;
                  </label>
                </div>
              </div>
            </div>
          }
          <div className='row mb-4'>
            {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
              <div className='col-md-5'>
                <h5 className='heading5 mt-2 mb-2'> Desktop Ad Setup</h5>
                <div className="form-group mt-3">
                  <Label className="form-label"> Ads State</Label>
                  <Input
                    type="select"
                    className="form-select"
                    value={adConfig.adsState}
                    onChange={handleChange}
                    label="AdsMode"
                    name="adsState"
                    disabled={!adConfig.desktopSettingsEnabled}
                  >
                    <option value="" disabled >
                      Select Ads State
                    </option>
                    <option value="breakingAds"  >
                      Ad Breaks
                    </option>
                    <option value="midRollSlots"  >
                      Slot
                    </option>
                  </Input>
                </div>
                <div className="form-group mt-3 mb-3">
                  <Label className="form-label">Waterfall Retry Per Slot</Label>
                  <Input
                    type="text"
                    value={adConfig.waterfallRetry_Slot}
                    onChange={handleChangeNumber}
                    onKeyPress={allowOnlyNumbers}
                    name="waterfallRetry_Slot"
                    readOnly={!adConfig.desktopSettingsEnabled}
                  />
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label">Error Limit Per Waterfall Retry</Label>
                  <Input
                    type="text"
                    value={adConfig.errorLimit_waterfallRetry}
                    onChange={handleChangeNumber}
                    onKeyPress={allowOnlyNumbers}
                    name="errorLimit_waterfallRetry"
                    readOnly={!adConfig.desktopSettingsEnabled}
                  />
                </div>
                {(adConfig.adsState === 'breakingAds') &&
                  <div className=''>
                    <div className="mb-3">
                      <Label className="form-label">Vast Tag</Label>
                      <Input
                        name="vastTag"
                        type="textarea" id="textarea"
                        rows="3"
                        style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                        onChange={handleChange}
                        value={adConfig.vastTag || ""}
                        className={'form-control'}
                        readOnly={!adConfig.desktopSettingsEnabled}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Vast Tag (Muted)</Label>
                      <Input
                        name="vast_mute"
                        type="textarea" id="textarea"
                        rows="3"
                        style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                        onChange={handleChange}
                        value={adConfig.vast_mute || ""}
                        className={'form-control'}
                        readOnly={!adConfig.desktopSettingsEnabled}
                      />
                    </div>
                    <div className="form-group ">
                      <div className="form-check form-switch form-check-success form-switch-md mt-2 mb-3" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="PrerollAdbreak"
                          checked={adConfig.preRollAdBreak} onChange={handleCheck} name="preRollAdBreak"
                          disabled={!adConfig.desktopSettingsEnabled}
                        />
                        <label className="form-check-label " htmlFor="PrerollAdbreak">
                          <h5 className="heading5 mt-1">Preroll </h5>
                        </label>
                      </div>
                    </div>
                    <div className="form-group  mt-3">
                      <Label className="form-label">Content Start Delay</Label>
                      <InputGroup>
                        <Input
                          type="text" name="contentStartDelay" value={adConfig.contentStartDelay} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                          readOnly={!adConfig.desktopSettingsEnabled || !adConfig.preRollAdBreak}
                        />
                        <div className="input-group-text">Seconds</div>
                      </InputGroup>
                    </div>
                    <div className="form-group  mt-3">
                      <Label className="form-label">Total Ads per Player Load</Label>
                      <Input
                        type="text" name="totalAdsPerPlayerLoad" value={adConfig.totalAdsPerPlayerLoad} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                        readOnly={!adConfig.desktopSettingsEnabled}
                      />
                    </div>
                    <div className="form-group  mt-3">
                      <Label className="form-label">Maximum Ads per Slot</Label>
                      <Input
                        type="text" name="maxAdsPerSlot" value={adConfig.maxAdsPerSlot} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                        readOnly
                      />
                    </div>
                    <div className="form-group  mt-3">
                      <Label className="form-label">Delay between Ads</Label>
                      <InputGroup>
                        <Input
                          type="text" name="delayBetweenAds" value={adConfig.delayBetweenAds} onChange={handleChangeNumber} onKeyPress={allowOnlyNumbers}
                          readOnly={!adConfig.desktopSettingsEnabled}
                        />
                        <div className="input-group-text">Seconds</div>
                      </InputGroup>
                    </div>
                  </div>
                }
              </div>
            }
            {(props.settingsData.PlayerSettingType === 'desktop' || props.settingsData.PlayerSettingType === 'all') &&
              <div className='col-md-1'></div>
            }
            {(props.settingsData.PlayerSettingType === 'mobile' || props.settingsData.PlayerSettingType === 'all') &&
              <div className='col-md-5'>
                <h5 className='heading5 mt-2 mb-2'> Mobile Ad Setup</h5>
                <div className="form-group mt-3">
                  <Label className="form-label"> Ads State</Label>
                  <Input
                    type="select"
                    className="form-select"
                    value={adConfig.mobileAdConfig.adsState}
                    onChange={handleChangeMobile}
                    label="AdsMode"
                    name="adsState"
                    readOnly
                  >
                    {/* <option value="" disabled >
                        Select Ads State
                      </option> */}
                    <option value="breakingAds"  >
                      Ad Breaks
                    </option>
                    {/* <option value="midRollSlots"  >
                        Slot
                      </option> */}
                  </Input>
                </div>
                <div className="form-group mt-3 mb-3">
                  <Label className="form-label">Waterfall Retry Per Slot</Label>
                  <Input
                    type="text"
                    value={adConfig.mobileAdConfig.waterfallRetry_Slot}
                    onChange={handleChangeNumberMobile}
                    onKeyPress={allowOnlyNumbers}
                    name="waterfallRetry_Slot"
                    readOnly={!adConfig.mobileSettingsEnabled}
                  />
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label">Error Limit Per Waterfall Retry</Label>
                  <Input
                    type="text"
                    value={adConfig.mobileAdConfig.errorLimit_waterfallRetry}
                    onChange={handleChangeNumberMobile}
                    onKeyPress={allowOnlyNumbers}
                    name="errorLimit_waterfallRetry"
                    readOnly={!adConfig.mobileSettingsEnabled}
                  />
                </div>

                <div className="mb-3">
                  <Label className="form-label">Vast Tag</Label>
                  <Input
                    name="vastTag"
                    type="textarea" id="textarea"
                    rows="3"
                    style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                    onChange={handleChangeMobile}
                    value={adConfig.mobileAdConfig.vastTag || ""}
                    className={'form-control'}
                    readOnly={!adConfig.mobileSettingsEnabled}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Vast Tag (Muted)</Label>
                  <Input
                    name="vast_mute"
                    type="textarea" id="textarea"
                    rows="3"
                    style={{ fieldSizing: 'content', width: '100%', minHeight: '4pc' }}
                    onChange={handleChangeMobile}
                    value={adConfig.mobileAdConfig.vast_mute || ""}
                    className={'form-control'}
                    readOnly={!adConfig.mobileSettingsEnabled}
                  />
                </div>
                <div className="form-group ">
                  <div className="form-check form-switch form-check-success form-switch-md mt-2 mb-3" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="PrerollAdbreak"
                      checked={adConfig.mobileAdConfig.preRollAdBreak} onChange={handleCheckMobileSettings} name="preRollAdBreak"
                      disabled={!adConfig.mobileSettingsEnabled}
                    />
                    <label className="form-check-label " htmlFor="PrerollAdbreak">
                      <h5 className="heading5 mt-1">Preroll </h5>
                    </label>
                  </div>
                </div>
                <div className="form-group  mt-3">
                  <Label className="form-label">Content Start Delay</Label>
                  <InputGroup>
                    <Input
                      type="text" name="contentStartDelay" value={adConfig.mobileAdConfig.contentStartDelay} onChange={handleChangeNumberMobile} onKeyPress={allowOnlyNumbers}
                      readOnly={!adConfig.mobileSettingsEnabled || !adConfig.mobileAdConfig.preRollAdBreak}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>
                </div>
                <div className="form-group  mt-3">
                  <Label className="form-label">Total Ads per Player Load</Label>
                  <Input
                    type="text" name="totalAdsPerPlayerLoad" value={adConfig.mobileAdConfig.totalAdsPerPlayerLoad} onChange={handleChangeNumberMobile} onKeyPress={allowOnlyNumbers}
                    readOnly={!adConfig.mobileSettingsEnabled}
                  />
                </div>
                <div className="form-group  mt-3">
                  <Label className="form-label">Maximum Ads per Slot</Label>
                  <Input
                    type="text" name="maxAdsPerSlot" value={adConfig.mobileAdConfig.maxAdsPerSlot} onChange={handleChangeNumberMobile} onKeyPress={allowOnlyNumbers}
                    readOnly
                  />
                </div>
                <div className="form-group  mt-3">
                  <Label className="form-label">Delay between Ads</Label>
                  <InputGroup>
                    <Input
                      type="text" name="delayBetweenAds" value={adConfig.mobileAdConfig.delayBetweenAds} onChange={handleChangeNumberMobile} onKeyPress={allowOnlyNumbers}
                      readOnly={!adConfig.mobileSettingsEnabled}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>
                </div>
              </div>
            }
          </div>
        </div>
        {adConfig.adsState === 'midRollSlots' &&
          <div className='col-12'>
            <h5 className=' mt-4'>Ad Slots</h5>
            <div className="form-group ">
              <div className="form-check form-switch form-check-success form-switch-md mt-2 mb-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="PrerollSlot"
                  checked={adConfig.preRollState} onChange={handleCheck} name="preRollSlot"
                />
                <label className="form-check-label " htmlFor="PrerollSlot">
                  <h5 className="heading5 mt-1">Preroll Slot</h5>
                </label>
              </div>
              {/* <div className="form-check mt-2 mb-2">
                  <label
                    className="form-check-label"
                  >
                    Preroll Slot
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={adConfig.preRollState}
                    onChange={handleCheck}
                  />

                </div> */}
            </div>

            {adConfig.preRollState && <div>
              <h5 className=' mt-4'>Preroll</h5>
              <div className="mb-3 col-6">
                <Label className="form-label">Preroll Vast Tag</Label>
                <Input
                  name="preRollVastTag"
                  type="textarea" id="textarea"
                  rows="3"
                  style={{ fieldSizing: 'content', minHeight: '4pc' }}
                  onChange={handleChange}
                  value={adConfig.preRollVastTag || ""}
                  className={'form-control col-5'}
                />
              </div>
              {createPrerollUI()}
            </div>
            }
            {<div>
              <div className="d-flex ">
                <h5 className=' mt-1'>Midroll</h5>
                <button type='button' className='btn btn-primary ' onClick={addNewVideoSlot} style={{ marginLeft: 'auto' }}>Add Midroll Slots</button>
              </div>
              <div className="mb-3 col-6">
                <Label className="form-label">Midroll Vast Tag</Label>
                <Input
                  name="midRollVastTag"
                  type="textarea" id="textarea"
                  rows="3"
                  style={{ fieldSizing: 'content', minHeight: '4pc' }}
                  onChange={handleChange}
                  value={adConfig.midRollVastTag || ""}
                  className={'form-control col-5'}
                />
              </div>
              {createUI()}</div>}
          </div>
        }
      </div>
    </div>
  )
}

export default PlayerAdConfig;