/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
// import { Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button,Container } from "reactstrap"
import Breadcrumb from 'components/Common/Breadcrumb';
import Sidebar from 'components/VerticalLayout/Sidebar';
import Header from 'components/VerticalLayout/Header';
import { MetaTags } from 'react-meta-tags';


import {
  Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Container
} from "reactstrap"
import axios from "axios"
// import { FFmpeg } from 'react-ffmpeg'
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var videoThumbnailFileName = ''

const Thumbnail_Creator = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [url, setUrl] = useState("")
  const [loader, setLoader] = useState(false)

  function handleVideoTimeUpdate() {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailUrl = canvas.toDataURL();
    console.log(canvas);
    console.log(thumbnailUrl);
    setThumbnailUrl(thumbnailUrl);
  }

  console.log(thumbnailUrl);


  const [userFile, setUserFile] = useState(Math.random().toString(36))
  const [CdnLink, setCdnLink] = useState('')
  // const [loader, setLoader] = useState(false)
  const [loaderYoutube, setLoaderYoutube] = useState(false)

  const [spinner, setSpinner] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);


  const [inputValue, setInputValue] = useState('');
  const [videoId, setVideoId] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [resolution, setResolution] = useState('640:360')


  const [btnprimary2, setBtnprimary2] = useState(false)
  const [quality, setQuality] = useState('ultra')

  const [startTimeSec, setStartTimeSec] = useState('');
  const [startTimeMin, setStartTimeMin] = useState('');

  const [durationSec, setDurationSec] = useState('');
  const [durationMin, setDurationMin] = useState('');


  const [trimEnabled, setTrimEnabled] = useState(false);


  const handleStartTimeChangeSec = (event) => {
    setStartTimeSec(event.target.value);
  };
  const handleStartTimeChangeMin = (event) => {
    setStartTimeMin(event.target.value);
  };

  const handleDurationChangeSec = (event) => {
    setDurationSec(event.target.value);
  };

  const handleDurationChangeMin = (event) => {
    setDurationMin(event.target.value);
  };

  const handleTrimChange = () => {
    setTrimEnabled(!trimEnabled);
  };



  console.log(props.type)

  const [videoContent, setVideoContent] = useState({
    videoContentPath: '',
    videoContentDuration: '',
  });


  const [customActiveTab, setcustomActiveTab] = useState("1");


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };









  const handleYoutubeAndDirectLinkDownload = async () => {
    setShowAlert(false);
    setSpinner(true);
    var videoBlob;
    var videoName;
    var videoType;
    var videoContentFileName;

    try {
      if (inputValue.includes('youtube')) {
        console.log('youtube video');
        const response = await axios.post("/downloadvideo", {
          url: inputValue,
          resolution: resolution,
          // startTime: `${startTimeMin}:${startTimeSec}`,
          // duration: `${durationMin}:${durationSec}`
          preset: quality
        }, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100;
            // setDownloadProgress(percentCompleted);
          },
        });
        videoBlob = new Blob([response.data]);
        const cHeader = response.headers['content-disposition']
        const fileMatch = cHeader.match(/vInfo="(.+)"/)
        if (fileMatch && fileMatch.length > 1) {
          const videoInfo = fileMatch[1]
          const videoData = JSON.parse(videoInfo)
          videoName = videoData[0].title.replace(/\s+/g, "")
          videoType = 'video/mp4'
          videoContentFileName = `${videoName}.mp4`
        }
        // Trigger the download by simulating a click on a temporary anchor element
        const downloadUrl = URL.createObjectURL(videoBlob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${videoContentFileName}`;
        link.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadUrl);
        setSpinner(false)
      }else{
        toastr.info("Please enter valid Url")
        setLoader(false)
        setSpinner(false)
      }

      // ...
    } catch (err) {
      console.error(err);
      setLoader(false);
      setSpinner(false);
      toastr.error(err);
    }
  };




  return (

    <>
      {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Video Compressor and Downloder | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumb title="Video Compressor and Downloder" breadcrumbItem="Video Compressor and Downloder" />
          <Row >
            <Col md="12">
              {loader &&
                <Spinner className="ms-2 loader" color="primary" />
              }
              {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
              <div style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                <Row>
                  <Col sm="12">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Label className="text-left" htmlFor="youtube-link">Enter link:</Label>

                      <Input className="w-75" type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=..." required value={inputValue} onChange={(e) => setInputValue(e.target.value)} />

                      {/* {inputValue && <iframe src={inputValue} title="Embedded YouTube video" width="560" height="315"  allow="autoplay; encrypted-media"/>} */}
                      {/* {inputValue && <iframe width="560" height="315" src={inputValue} frameborder="0" allow="autoplay"></iframe>} */}

                      <div className="d-flex justify-content-center align-items-center">
                        <div className="btn-group m-1 mt-2">
                          <Dropdown isOpen={btnprimary1} toggle={() => setBtnprimary1(!btnprimary1)}>
                            <DropdownToggle caret>
                              Resolution: {resolution === '640:360' ? '360p' : resolution === '426:240' ? '240p' : '144p'}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => setResolution('640:360')}>360p</DropdownItem>
                              <DropdownItem onClick={() => setResolution('426:240')}>240p</DropdownItem>
                              <DropdownItem onClick={() => setResolution('256:144')}>144p</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>

                        <div className="btn-group m-1 mt-2">
                          <Dropdown isOpen={btnprimary2} toggle={() => setBtnprimary2(!btnprimary2)}>
                            <DropdownToggle caret>
                              Quality : {quality}
                            </DropdownToggle>
                            <DropdownMenu>
                            <DropdownItem onClick={() => setQuality('ultra')}>Ultra</DropdownItem>
                            <DropdownItem onClick={() => setQuality('highest')}>Highest</DropdownItem>
                            <DropdownItem onClick={() => setQuality('high')}>High </DropdownItem>
                            <DropdownItem onClick={() => setQuality('medium')}>Medium</DropdownItem>
                            <DropdownItem onClick={() => setQuality('low')}>Low</DropdownItem>
                            <DropdownItem onClick={() => setQuality('lowest')}>Lowest</DropdownItem>
                        </DropdownMenu>
                          </Dropdown>
                        </div>


                        {/* <div>
                        <Button className=" m-1 mt-2" onClick={() => videoDurationValidator()}>Compress and upload</Button>
                      </div> */}
                        <div>
                          <Button className="mt-1" onClick={() => handleYoutubeAndDirectLinkDownload()}>Download</Button>

                        </div>
                      </div>
                      {/* <div className="form-check d-flex justify-content-start mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="trimCheckbox"
                        checked={trimEnabled}
                        onChange={handleTrimChange}
                      />
                      &nbsp;&nbsp;
                      <label className="form-check-label ml-1 " htmlFor="trimCheckbox">
                        Trim
                      </label>
                    </div>

                    <div className="form-group d-flex align-items-center justify-content-center">
                      <label htmlFor="timeInputs" className="mt-2">Start Time   </label>
                      &nbsp;&nbsp;
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="startTimeSec"
                        placeholder="MM"
                        value={startTimeMin}
                        onChange={handleStartTimeChangeMin}
                        disabled={!trimEnabled}
                      />
                      <span className="m-2">:</span>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="duration"
                        placeholder="SS"
                        value={startTimeSec}
                        onChange={handleStartTimeChangeSec}
                        disabled={!trimEnabled}
                      />

                    </div>
                    <div className="form-group d-flex align-items-center justify-content-center">
                      <label htmlFor="timeInputs" className="mt-2">Duration</label>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="startTimeSec"
                        placeholder="MM"
                        value={durationMin}
                        onChange={handleDurationChangeMin}
                        disabled={!trimEnabled}
                      />
                      <span className="m-2">:</span>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="duration"
                        placeholder="SS"
                        value={durationSec}
                        onChange={handleDurationChangeSec}
                        disabled={!trimEnabled}
                      />

                    </div> */}

                    </div>
                    {loaderYoutube &&
                      <Progress
                        className="mt-2"
                        animated
                        color="success"
                        style={{
                          height: '15px'
                        }}
                        striped
                        value={uploadProgress}
                      >{uploadProgress}%</Progress>
                    }
                    {
                      spinner &&
                      <div className="d-flex justify-content-center align-items-center mt-1">

                        <Button
                          color="primary"
                          disabled
                        >
                          <Spinner size="sm">
                            Loading...
                          </Spinner>
                          <span>
                            {' '}Downloading and Compressing Video Please Wait...
                          </span>
                        </Button>
                      </div>

                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </div> */}
      <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">   video is more than 20 minutes in length would you like to download?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
              <button type="button" className="btn btn-primary" onClick={handleYoutubeAndDirectLinkDownload}>Yes</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    </>
  )
}

Thumbnail_Creator.propTypes = {}

export default Thumbnail_Creator




