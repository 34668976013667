/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";
import { WithContext as ReactTags } from 'react-tag-input';

//redux
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


var list = []
var ipMapsList = []

const ContextualSettings = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const [pageUrlData, setPageUrlData] = useState([])
  const [iPMappingData, setIPMappingData] = useState([])
  const [keywordData, setKeywordData] = useState([])

  const [pageUrlState, setPageUrlState] = useState(true)
  const [iPMappingsState, setIPMappingsState] = useState(true)
  const [keywordsState, setKeywordsState] = useState(true)

  const [networkSetting, setNetworkSetting] = useState({
    "networkSettingIpMappingIn": [],
    "networkSettingIpMappingEx": [],
    "networkSettingKeywordsIn": [],
    "networkSettingKeywordsEx": [],
    "networkSettingUrlIn": [],
    "networkSettingUrlEx": [],
  })

  const [targetStates, setTargetStates] = useState({
    iPMappings: false,
    keywords: false,
    urls: false,
  })

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [iPMappingTreeSelect, setIPMappingTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [keywordTreeSelect, setKeywordTreeSelect] = useState({
    checked: [],
    expanded: [],
  })


  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isIPMappingOptionsOpen, setIsIPMappingOptionsOpen] = useState(false);
  const [isKeywordOptionsOpen, setIsKeywordOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const iPMappingToggleOptions = () => {
    setIsIPMappingOptionsOpen(!isIPMappingOptionsOpen);
  };

  const keywordToggleOptions = () => {
    setIsKeywordOptionsOpen(!isKeywordOptionsOpen);
  };


  useEffect(() => {
    if (props.tabId !== 2) {
      setIsOptionsOpen(false);
      setIsIPMappingOptionsOpen(false);
      setIsKeywordOptionsOpen(false);
    }
  }, [props.tabId])


  useEffect(() => {
    if (props.networkSettingData !== '') {
      setNetworkSetting(props.networkSettingData)
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.networkSettingData.networkSettingUrlIn.length > 0 ? props.networkSettingData.networkSettingUrlIn : props.networkSettingData.networkSettingUrlEx.length > 0 ? props.networkSettingData.networkSettingUrlEx : [] }))
      setIPMappingTreeSelect(iPMappingTreeSelect => ({ ...iPMappingTreeSelect, checked: props.networkSettingData.networkSettingIpMappingIn.length > 0 ? props.networkSettingData.networkSettingIpMappingIn : props.networkSettingData.networkSettingIpMappingEx.length > 0 ? props.networkSettingData.networkSettingIpMappingEx : [] }))
      setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: props.networkSettingData.networkSettingKeywordsIn.length > 0 ? props.networkSettingData.networkSettingKeywordsIn : props.networkSettingData.networkSettingKeywordsEx.length > 0 ? props.networkSettingData.networkSettingKeywordsEx : [] }))

      setPageUrlState(props.networkSettingData.networkSettingUrlIn.length > 0 ? true : props.networkSettingData.networkSettingUrlEx.length > 0 ? false : true)
      setIPMappingsState(props.networkSettingData.networkSettingIpMappingIn.length > 0 ? true : props.networkSettingData.networkSettingIpMappingEx.length > 0 ? false : true)
      setKeywordsState(props.networkSettingData.networkSettingKeywordsIn.length > 0 ? true : props.networkSettingData.networkSettingKeywordsEx.length > 0 ? false : true)


      setTargetStates(targetStates => ({
        ...targetStates,
        pageURLs: props.networkSettingData.networkSettingUrlIn.length > 0 || props.networkSettingData.networkSettingUrlEx.length > 0 ? true : false,
        iPMappings: props.networkSettingData.networkSettingIpMappingIn.length > 0 || props.networkSettingData.networkSettingIpMappingEx.length > 0 ? true : false,
        keywords: props.networkSettingData.networkSettingKeywordsIn.length > 0 || props.networkSettingData.networkSettingKeywordsEx.length > 0 ? true : false,
      }));

      props.targetedSelectedItems({
        pageURLs: props.networkSettingData.networkSettingUrlIn.length > 0 || props.networkSettingData.networkSettingUrlEx.length > 0 ? true : false,
        iPMappings: props.networkSettingData.networkSettingIpMappingIn.length > 0 || props.networkSettingData.networkSettingIpMappingEx.length > 0 ? true : false,
        keywords: props.networkSettingData.networkSettingKeywordsIn.length > 0 || props.networkSettingData.networkSettingKeywordsEx.length > 0 ? true : false,
      })
    }
  }, []);

  useEffect(() => {
    if (props.contextualData.pageUrlGroups && props.contextualData.pageUrlGroups.length > 0) {
      setPageUrlData(objectPageURLModal(props.contextualData.pageUrlGroups))
    }
    if (props.contextualData.IPMapping && props.contextualData.IPMapping.length > 0) {
      setIPMappingData(objectIPMappingModal(props.contextualData.IPMapping))
    }
    if (props.contextualData.Keyword && props.contextualData.Keyword.length > 0) {
      setKeywordData(objectKeywordModal(props.contextualData.Keyword))
    }
  }, [props.contextualData]);

  const objectPageURLModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.pageURLGroupName
      obj.label = item.pageURLGroupName
      obj.children = childModal(item.pageURLGroupName, item.pageURLGroupValues)
      dataList.push(obj)
    })
    return dataList
  }

  const objectIPMappingModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iPMappingGroup
      obj.label = item.iPMappingGroup
      obj.children = childModal(item.iPMappingGroup, item.iPMappingList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectKeywordModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.keywordGroup
      obj.label = item.keywordGroup
      obj.children = childModal(item.keywordGroup, item.keywordList)
      dataList.push(obj)
    })
    return dataList
  }

  const childModal = (groupName, response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = groupName + "--" + item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const pageUrlStateChange = e => {
    setPageUrlState(e.target.checked)
    var pageUrls = []
    if (networkSetting.networkSettingUrlIn.length > 0) {
      pageUrls = networkSetting.networkSettingUrlIn
    } else {
      pageUrls = networkSetting.networkSettingUrlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlIn: pageUrls, networkSettingUrlEx: [] }));
      props.selectedItems({ networkSettingUrlIn: pageUrls, networkSettingUrlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlEx: pageUrls, networkSettingUrlIn: [] }));
      props.selectedItems({ networkSettingUrlEx: pageUrls, networkSettingUrlIn: [] })
    }
  }

  const iPMappingStateChange = e => {
    setIPMappingsState(e.target.checked)
    var iPMappingss = []
    if (networkSetting.networkSettingIpMappingIn.length > 0) {
      iPMappingss = networkSetting.networkSettingIpMappingIn
    } else {
      iPMappingss = networkSetting.networkSettingIpMappingEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingIpMappingIn: iPMappingss, networkSettingIpMappingEx: [] }));
      props.selectedItems({ networkSettingIpMappingIn: iPMappingss, networkSettingIpMappingEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingIpMappingEx: iPMappingss, networkSettingIpMappingIn: [] }));
      props.selectedItems({ networkSettingIpMappingEx: iPMappingss, networkSettingIpMappingIn: [] })
    }
  }

  const keywordStateChange = e => {
    setKeywordsState(e.target.checked)
    var keywordss = []
    if (networkSetting.networkSettingKeywordsIn.length > 0) {
      keywordss = networkSetting.networkSettingKeywordsIn
    } else {
      keywordss = networkSetting.networkSettingKeywordsEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingKeywordsIn: keywordss, networkSettingKeywordsEx: [] }));
      props.selectedItems({ networkSettingKeywordsIn: keywordss, networkSettingKeywordsEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingKeywordsEx: keywordss, networkSettingKeywordsIn: [] }));
      props.selectedItems({ networkSettingKeywordsEx: keywordss, networkSettingKeywordsIn: [] })
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
    setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
    props.targetedSelectedItems({ [name]: e.target.checked })
    if (name === 'pageURLs') {
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlEx: [], networkSettingUrlIn: [] }));
      props.selectedItems({ networkSettingUrlEx: [], networkSettingUrlIn: [] })
    }
    if (name === 'iPMappings') {
      setIPMappingTreeSelect(iPMappingTreeSelect => ({ ...iPMappingTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingIpMappingEx: [], networkSettingIpMappingIn: [] }));
      props.selectedItems({ networkSettingIpMappingEx: [], networkSettingIpMappingIn: [] })
    }
    if (name === 'keywords') {
      setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingKeywordsEx: [], networkSettingKeywordsIn: [] }));
      props.selectedItems({ networkSettingKeywordsEx: [], networkSettingKeywordsIn: [] })
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });

  const myRef = useRef();
  const cityRef = useRef();
  const keyRef = useRef();

  const handleClickCityOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOptionsOpen(false)
    }
    if (!cityRef.current.contains(e.target)) {
      setIsIPMappingOptionsOpen(false)
    }
    if (!keyRef.current.contains(e.target)) {
      setIsKeywordOptionsOpen(false)
    }
  };


  return (
    <React.Fragment>
      <Row>
        <Col lg="11">
        <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="iPMappings" id='iPMappings' checked={targetStates.iPMappings} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='iPMappings'>IPMapping Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={cityRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isIPMappingOptionsOpen}
                  className={'selectionButton ' + (isIPMappingOptionsOpen ? "expanded" : "")}
                  onClick={iPMappingToggleOptions}
                  disabled={props.selectedArchived || !targetStates.iPMappings}
                >
                  {iPMappingTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{iPMappingTreeSelect.checked.length + " IPMappings are Selected"}</span> : "Please Select IPMappings"}
                </button>
                <ul className={`options ${isIPMappingOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={iPMappingData}
                    checked={iPMappingTreeSelect.checked}
                    expanded={iPMappingTreeSelect.expanded}
                    onCheck={checked => {
                      setIPMappingTreeSelect(iPMappingTreeSelect => ({ ...iPMappingTreeSelect, checked: checked }));
                      iPMappingsState ? setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingIpMappingIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingIpMappingEx: checked }))
                      iPMappingsState ? props.selectedItems({ networkSettingIpMappingIn: checked }) : props.selectedItems({ networkSettingIpMappingEx: checked })
                    }}
                    onExpand={expanded => setIPMappingTreeSelect(iPMappingTreeSelect => ({ ...iPMappingTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={iPMappingsState}
                  onChange={iPMappingStateChange}
                  disabled={props.selectedArchived || !targetStates.iPMappings}
                />
                <label className="form-check-label">
                  {iPMappingsState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="pageURLs" id='pageURLs' checked={targetStates.pageURLs} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='pageURLs'>Page URL Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={myRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                  onClick={toggleOptions}
                  disabled={props.selectedArchived || !targetStates.pageURLs}
                >
                  {treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Page URLs are Selected"}</span> : "Please Select Page URLs"}
                </button>
                <ul className={`options ${isOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={pageUrlData}
                    checked={treeSelect.checked}
                    expanded={treeSelect.expanded}
                    onCheck={(checked, targetNode) => {
                      setTreeSelect(treeSelect => ({ ...treeSelect, checked: checked }));
                      pageUrlState ? setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlEx: checked }))
                      pageUrlState ? props.selectedItems({ networkSettingUrlIn: checked }) : props.selectedItems({ networkSettingUrlEx: checked })

                      // if(targetNode.isParent){
                      //   pageUrlState ? setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlIn:[...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] })) : setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlEx: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   pageUrlState ? props.selectedItems({ networkSettingUrlIn: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ networkSettingUrlEx: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] })
                      // }else{
                      //   pageUrlState ? setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlIn:[...networkSetting.networkSettingUrlIn,{group_name:targetNode.parent.value,group_values:checked}] })) : setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingUrlEx: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   pageUrlState ? props.selectedItems({ networkSettingUrlIn: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ networkSettingUrlEx: [...networkSetting.networkSettingUrlIn,{group_name:targetNode.value,group_values:checked}] })
                      // }
                    }}
                    onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={pageUrlState}
                  onChange={pageUrlStateChange}
                  disabled={props.selectedArchived || !targetStates.pageURLs}
                />
                <label className="form-check-label">
                  {pageUrlState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="keywords" id='keywords' checked={targetStates.keywords} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='keywords'>Keyword Targeting</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={keyRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isKeywordOptionsOpen}
                  className={'selectionButton ' + (isKeywordOptionsOpen ? "expanded" : "")}
                  onClick={keywordToggleOptions}
                  disabled={props.selectedArchived || !targetStates.keywords}
                >
                  {keywordTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{keywordTreeSelect.checked.length + " Keywords are Selected"}</span> : "Please Select Keywords"}
                </button>
                <ul className={`options ${isKeywordOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={keywordData}
                    checked={keywordTreeSelect.checked}
                    expanded={keywordTreeSelect.expanded}
                    onCheck={checked => {
                      setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, checked: checked }));
                      keywordsState ? setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingKeywordsIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, networkSettingKeywordsEx: checked }))
                      keywordsState ? props.selectedItems({ networkSettingKeywordsIn: checked }) : props.selectedItems({ networkSettingKeywordsEx: checked })
                    }}
                    onExpand={expanded => setKeywordTreeSelect(keywordTreeSelect => ({ ...keywordTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={keywordsState}
                  onChange={keywordStateChange}
                  disabled={props.selectedArchived || !targetStates.keywords}
                />
                <label className="form-check-label">
                  {keywordsState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};


ContextualSettings.propTypes = {}

export default ContextualSettings