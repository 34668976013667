import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PLAYLIST, CREATE_PLAYLIST,UPDATE_PLAYLIST,STATUS_PLAYLIST,ARCHIVE_PLAYLIST,GET_PLAYLISTBYID } from './actionTypes'

import {
    getPlaylistSuccess,
    getPlaylistFail,
    getPlaylistSuccessById,
    getPlaylistFailById,
    createPlaylistSuccess,
    createPlaylistFail,
    updatePlaylistSuccess,
    updatePlaylistFail,
    statusPlaylistSuccess,
    archivePlaylistSuccess, 
    archivePlaylistFail
} from './actions'

import { getPlaylist,getPlaylistById, createPlaylist, updatePlaylist , statusPlaylist, archivePlaylist} from "helpers/Backend_helper"

function* fetchPlaylists({ payload: ply }) {
    try {
        const response = yield call(getPlaylist, ply)
        console.log(ply)
        console.log('saga response', response)
        response.responseData.response.map((item, index) => {
            item.id = index + 1
            item.checked = false
        })
        yield put(getPlaylistSuccess(response.responseData.response))
    } catch (err) {
        yield put(getPlaylistFail(err))
    }
}

function* fetchPlaylistsById({ payload: ply }) {
    try {
        const response = yield call(getPlaylistById, ply)
        console.log(ply)
        console.log('saga response', response)
        // response.responseData.response.map((item, index) => {
        //     item.id = index + 1
        //     item.checked = false
        // })
        // console.log(response.responseData.response)
        yield put(getPlaylistSuccessById(response.responseData.response))
    } catch (err) {
        yield put(getPlaylistFailById(err))
    }
}

function* onCreatePlaylist({ payload: playlist }) {
    try {
        const response = yield call(createPlaylist, playlist)
        console.log("saga response", response)
        if (response.responseData.success) {
            console.log("called saga success")
            yield put(createPlaylistSuccess(response.responseData))
        }
        else {
            yield put(createPlaylistFail(response.responseData.message))
        }
    } catch(err){
        yield put(createPlaylistFail(err))
    }
}

function* onUpdatePlaylist({payload: playlist}){
    try{
        const response = yield call(updatePlaylist, playlist)
        console.log("saga response", response)
        if(response.responseData.success){
            console.log("success")
            yield put(updatePlaylistSuccess(response.responseData))
        }else{
            yield put(updatePlaylistFail(response.responseData.message))
        }
    } catch(err){
        yield put(updatePlaylistFail(err))
    }
}

function* onArchivePlaylist({payload: playlist}){
    try{
        const response = yield call(archivePlaylist, playlist)
        console.log("saga response ", response)
        if(response.responseData.success){
            yield put(archivePlaylistSuccess(response))
        }else{
            yield put(archivePlaylistFail(response.responseData))
        }
    }catch(err){
        yield put(archivePlaylistFail(err))
    }
}

function* onStatusPlaylist({payload: playlist}){
    try{
        const response = yield call(statusPlaylist,playlist)
        yield put(statusPlaylistSuccess(response))
    }catch(err){
        yield put(statusPlaylistSuccess(err))
    }
}

function* playlistSaga() {
    yield takeEvery(GET_PLAYLIST,fetchPlaylists)
    yield takeEvery(GET_PLAYLISTBYID, fetchPlaylistsById)
    yield takeEvery(CREATE_PLAYLIST,onCreatePlaylist)
    yield takeEvery(UPDATE_PLAYLIST,onUpdatePlaylist)
    yield takeEvery(STATUS_PLAYLIST,onStatusPlaylist)
    yield takeEvery(ARCHIVE_PLAYLIST,onArchivePlaylist)
}

export default playlistSaga;