import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Container, loader } from "reactstrap"
import Breadcrumb from 'components/Common/Breadcrumb';
import Sidebar from 'components/VerticalLayout/Sidebar';
import Header from 'components/VerticalLayout/Header';
import { MetaTags } from 'react-meta-tags';

import axios from 'axios';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";



const Ai_Video_Generator = () => {
    const [summary, setSummary] = useState("")
    const [createdVideoId, setCreatedVideoId] = useState("")
    const [loader, setLoader] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null);
    const [avatar, setAvatar] = useState(null)
    const [voice, setVoice] = useState(null)
    const [style, setStyle] = useState(null)
    const [videoReady, setVideoReady] = useState(false);

    //for clips
    const [clipsSummary, setClipsSummary] = useState("")
    const [createdClipId, setCreatedClipId] = useState("")
    const [clipUrl, setClipUrl] = useState(null)
    const [clipVoice, setClipVoice] = useState(null)
    const [clipStyle, setClipStyle] = useState(null)
    const [bgColor, setBgColor] = useState(null)

    //clip url
    //https://d-id-clips-prod.s3.us-west-2.amazonaws.com/auth0%7C6447bc99c211f87e78acba23/clp_oLgS8L8wyEtnurNW3nhlm/amy-jcwCkr1grs.mp4?AWSAccessKeyId=AKIA5CUMPJBIJ7CPKJNP&Expires=1682591640&Signature=dizc%2FXvgffKADaBAMGiXWyRFRM4%3D

    // useEffect(() => {
    //     const eventSource = new EventSource('https://host.domain.tld/to/webhook');
    //     eventSource.addEventListener('message', handleWebhookRequest);
    //     return () => {
    //       eventSource.removeEventListener('message', handleWebhookRequest);
    //     };
    //   }, []);

    //   function handleWebhookRequest(event) {
    //     const webhookData = JSON.parse(event.data);
    //     setVideoReady(true);
    //     console.log(webhookData);
    //   }


    console.log(summary)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const generateAiVideo = async () => {

        const data =
        {
            "script": {
                "type": "text",
                "input": `${summary}`,
                "provider": {
                    "type": "microsoft",
                    "voice_id": `${voice}`,
                    "voice_config": {
                        "style": `${style}`
                    }
                },
                "config": {
                    "stitch": "true"
                }
            },
            "source_url": `${avatar}`,
            "water_mark": "false"

        }
        await axios.post('https://api.d-id.com/talks', data, {
            headers: {
                'accept': 'application/json',
                'Authorization': 'Basic cHJhc2hhbnRhLmlsYWdlckBzdHJlYW1seW4uY29t:uDr4kXsnO0f4oZENa_gPR'
            }
        })
            .then(response => setCreatedVideoId(response.data.id))
            .catch(err => {
                console.error(err)
                toastr.error(err)
            });

    }

    //1.input text summery
    //2.choose avatar(link or upload)
    //3.background color or upload an image
    //4.caption(true or false)
    //5.voice id list and style
    //https://tinypic.host/images/2023/04/25/avatar.jpeg

    // const downloadAiGeneratedVideo = async () => {
    //     const resposeData = await axios.get(`https://api.d-id.com/talks/${createdVideoId}`,{
    //         headers: {
    //             'accept': 'application/json',
    //             'Authorization': 'Basic Y29tbWFuZGVyaW4wMDdAZ21haWwuY29t:746Jl3GX8I2GYf6iLFRMz'
    //         }
    //     })
    //     const interval = setInterval(() => {
    //         if(resposeData.status == 'done'){
    //             fetch(resposeData.result_url)
    //             clearInterval(interval)
    //         }
    //     },3000)
    // }

    // const downloadAiGeneratedVideo = async () => {
    //     setLoader(true)
    //     const interval = setInterval(async () => {
    //         const response = await axios.get(
    //             `https://api.d-id.com/talks/${createdVideoId}`,
    //             {
    //                 headers: {
    //                     accept: "application/json",
    //                     Authorization: "Basic Y29tbWFuZGVyaW4wMDdAZ21haWwuY29t:7_GK8kr9OM-TkO1ZVC8jy",
    //                 },
    //             }
    //         );

    //         if (response.data.status === "done") {
    //             clearInterval(interval);
    //             setLoader(false)
    //             const url = response.data.result_url;
    //             const link = document.createElement("a");
    //             link.href = url;
    //             link.setAttribute("download", "video.mp4");
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }, 10000);
    // };
    const downloadAiGeneratedVideo = async () => {
        setLoader(true)
        const interval = setInterval(async () => {
            const response = await axios.get(
                `https://api.d-id.com/talks/${createdVideoId}`,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: "Basic cHJhc2hhbnRhLmlsYWdlckBzdHJlYW1seW4uY29t:uDr4kXsnO0f4oZENa_gPR",
                    },
                }
            );

            if (response.data.status === "done") {
                clearInterval(interval);
                setLoader(false)
                const url = response.data.result_url;
                setVideoUrl(url);
            }
        }, 10000);
    };

    const generateAiClips = async () => {
        console.log("called generate ai clips")
        const data =
        {
            "script": {
                "type": "text",
                "input": `${clipsSummary}`,
                "provider": {
                    "type": "microsoft",
                    "voice_id": `${clipVoice}`,
                    "voice_config": {
                        "style": `${clipStyle}`
                    }
                },
            },
            "presenter_id": "amy-jcwCkr1grs",
            "driver_id": "uM00QMwJ9x",
            "background": {
                "color": `${bgColor}`
            }

        }
        await axios.post('https://api.d-id.com/clips', data, {
            headers: {
                'accept': 'application/json',
                'Authorization': 'Basic Y29tbWFuZGVyaW4wMDdAZ21haWwuY29t:7_GK8kr9OM-TkO1ZVC8jy'
            }
        })
            .then(response => setCreatedClipId(response.data.id))
            .catch(err => {
                console.error(err)
                toastr.error(err)
            });
    }

    const downloadAiGeneratedClip = async () => {
        console.log("called download clips")
        setLoader(true)
        const interval = setInterval(async () => {
            const response = await axios.get(
                `https://api.d-id.com/clips/${createdClipId}`,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: "Basic Y29tbWFuZGVyaW4wMDdAZ21haWwuY29t:7_GK8kr9OM-TkO1ZVC8jy",
                    },
                }
            );

            if (response.data.status === "done") {
                clearInterval(interval);
                setLoader(false)
                const url = response.data.result_url;
                setClipUrl(url);
            }
        }, 30000);
    }




    return (
        <>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>AI Video Generator | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="AI Video Generator" breadcrumbItem="AI Video Generator" />
                    <Row >
                        <Col md="6">
                            {loader &&
                                <Spinner className="ms-2 loader" color="primary" />
                            }
                            {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                            <div style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                                <Row >
                                    <Col sm="12">
                                        <h5>Create AI talks</h5>
                                        <Label htmlFor='summary' >Enter Video Summery</Label>
                                        <Input className='mb-2' id='summary' type='textarea' rows="4" onChange={(e) => setSummary(e.target.value)} />
                                        <Label htmlFor='avatar' >Enter Avatar URL</Label>
                                        <Input id='avatar' type='text' onChange={(e) => setAvatar(e.target.value)} />
                                        <p className='mb-2'>*Note : only .jpeg, .jpg and .png links excepted</p>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">voice</Label>
                                                    <Input name='voice'
                                                        placeholder="Language"
                                                        className={`form-control col-8 select2-selection`}
                                                        type="select"
                                                        onChange={(e) => setVoice(e.target.value)}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='en-US-DavisNeural'>Davis(Male)</option>
                                                        <option value='en-US-TonyNeural'>Tony(Male)</option>
                                                        <option value="en-US-JasonNeural">Jason(Male)</option>
                                                        <option value='en-US-SaraNeural'>Sara(Female)</option>
                                                        <option value='en-US-NancyNeural'>Nancy(Female)</option>
                                                        <option value='en-US-JaneNeural'>Jane(Female)</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">style</Label>
                                                    <Input name='voice'
                                                        placeholder="Language"
                                                        className={`form-control col-8 select2-selection`}
                                                        type="select"
                                                        onChange={(e) => setStyle(e.target.value)}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='Default'>default</option>
                                                        <option value='Angry'>angry</option>
                                                        <option value="Cheerful">cheerful</option>
                                                        <option value='Excited'>excited</option>
                                                        <option value='Friendly'>friendly</option>
                                                        <option value='Hopeful'>hopeful</option>
                                                        <option value="Sad">sad</option>
                                                        <option value='Shouting'>shouting</option>
                                                        <option value="Terrified">terrified</option>
                                                        <option value='Unfriendly'>unfriendly</option>
                                                        <option value="whispering">whispering</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button type='button' className='mt-3 m-1' onClick={generateAiVideo}>Generate Ai talk video</Button>
                                        {createdVideoId && <Button type='button' className='mt-3 m-1' onClick={downloadAiGeneratedVideo}>
                                            Download Ai talk video </Button>}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6">
                            {videoUrl && avatar &&
                                <>
                                    <h5>Video Preview</h5>
                                    <video width={400} height={400} controls src={videoUrl} />
                                </>
                            }
                            {avatar && !videoUrl &&

                                <div style={{ height: 400, width: 600 }}>
                                    <h5>Avatar Preview</h5>
                                    <img src={avatar} style={{ maxHeight: "100%" }} />
                                </div>
                            }
                            {!avatar && !videoUrl &&

                                <div className='d-flex justify-content-center align-items-center' style={{ height: "24rem", width: "36rem", backgroundColor: "#bfbfbf" }}>
                                    <h5>Preview Panel</h5>
                                </div>
                            }

                        </Col>

                        <Col md="6">
                            {loader &&
                                <Spinner className="ms-2 loader" color="primary" />
                            }
                            {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                            <div style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                                <Row >
                                    <Col sm="12">
                                        <h5>Create AI Clips</h5>
                                        <Label htmlFor='summary' >Enter Video Summery</Label>
                                        <Input className='mb-3' id='summary' type='textarea' rows="4" onChange={(e) => setClipsSummary(e.target.value)} />
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">voice</Label>
                                                    <Input name='voice'
                                                        placeholder="Language"
                                                        className={`form-control col-8 select2-selection`}
                                                        type="select"
                                                        onChange={(e) => setClipVoice(e.target.value)}
                                                    >
                                                        <option value=''>Select</option>
                                                        {/* <option value='en-US-DavisNeural'>Davis(Male)</option>
                                                            <option value='en-US-TonyNeural'>Tony(Male)</option>
                                                            <option value="en-US-JasonNeural">Jason(Male)</option> */}
                                                        <option value='en-US-SaraNeural'>Sara(Female)</option>
                                                        <option value='en-US-NancyNeural'>Nancy(Female)</option>
                                                        <option value='en-US-JaneNeural'>Jane(Female)</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">style</Label>
                                                    <Input name='voice'
                                                        placeholder="Language"
                                                        className={`form-control col-8 select2-selection`}
                                                        type="select"
                                                        onChange={(e) => setClipStyle(e.target.value)}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='Default'>default</option>
                                                        <option value='Angry'>angry</option>
                                                        <option value="Cheerful">cheerful</option>
                                                        <option value='Excited'>excited</option>
                                                        <option value='Friendly'>friendly</option>
                                                        <option value='Hopeful'>hopeful</option>
                                                        <option value="Sad">sad</option>
                                                        <option value='Shouting'>shouting</option>
                                                        <option value="Terrified">terrified</option>
                                                        <option value='Unfriendly'>unfriendly</option>
                                                        <option value="whispering">whispering</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                        </Row>
                                        <label htmlFor='bg' >Background Color</label>
                                        <Input id='bg' type='text' onChange={(e) => setBgColor(e.target.value)} />
                                        <p className='mb-2'>*Note : only Hex codes</p>

                                        <Button type='button' className='mt-3 m-1' onClick={generateAiClips}>Generate Ai Clips</Button>
                                        {createdClipId && <Button type='button' className='mt-3 m-1' onClick={downloadAiGeneratedClip}>
                                            Download Ai clips </Button>}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6">
                            {clipUrl &&
                                <>
                                    <h5>Video Preview</h5>
                                    <video width={480} height={320} controls src={clipUrl} />
                                </>
                            }
                            {!clipUrl &&
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "18rem", width: "30rem", marginTop: "3rem", backgroundColor: "#bfbfbf" }}>
                                    <h5>Preview Panel</h5>
                                </div>
                            }
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* </div > */}

        </>
    )
}

Ai_Video_Generator.propTypes = {}

export default Ai_Video_Generator
