/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef,useCallback } from "react";
import PropTypes from 'prop-types'
import axios from "axios";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Tooltip,
    Spinner
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var selectedItem = []
var selectedData = []
var selectedIndex = []

const Partner_Ads_Txt_Update = props => {

    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [adsTxtEntries, setAdsTxtEntries] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [isRight, setIsRight] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [editState, setEditState] = useState(false);
    const [editEntriesRow, setEditEntriesRow] = useState([])
    const [tagList, setTagList] = useState([])

    const [loading, setLoading] = useState(false)

    console.log(props)

    const onToggleTooltip = (e) => {
        setIsTooltipOpen(!isTooltipOpen)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          setIsRight(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const copyCrawled = (data) => (e) => {
        const cb = navigator.clipboard;
     const paragraph = document.getElementById(data);
     cb.writeText(paragraph.innerText)
   }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            partnerName: userInfo.name,
            name:userInfo.name
        }
    }

    useEffect(() => {
        setLoading(true)
        // if (props.selectedAdsTxtEntries.publisherAdsTxtEntries.length > 0) {
        //     props.selectedAdsTxtEntries.publisherAdsTxtEntries.map((item, i) => {
        //         item.id = i + 1
        //     })
        //     setAdsTxtEntries([...props.selectedAdsTxtEntries.publisherAdsTxtEntries])
        // }
        getAdsEntries()
    }, []);

    const getAdsEntries = (event) => {
        body.url = "http://127.0.0.1:2002/v1.0/Publishers/read/adstxtPublisherID/"+ props.selectedAdsTxtEntries.publisherID
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.responseData.publisherAdsTxtEntries.length > 0) {
                    res.data.responseData.responseData.publisherAdsTxtEntries.map((item, i) => {
                        item.id = i + 1
                    })
                    setAdsTxtEntries([...res.data.responseData.responseData.publisherAdsTxtEntries])
                    setLoading(false)
                      console.log(selectRow.nonSelectable)
                } else {
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }



    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [deleteState,setDeleteState] = useState(false)

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect) {
            setSelected([...selected, row.id])
            setSelectedRowsStatus(true)
            selectedIndex.push(row.id)
            selectedItem.push(row.id)
          } else {
            setSelected(selected.filter(x => x !== row.id))
            let index = selectedIndex.indexOf(row.id)
            selectedIndex.splice(index, 1)
            let index2 = selectedItem.indexOf(row.id)
            selectedItem.splice(index2, 1)
            if (selectedIndex.length === 0) {
              setSelectedRowsStatus(false)
            }
          }
        },
        onSelectAll: (isSelect, rows, e) => {
          const ids = rows.map(r => r.id);
          if (isSelect) {
            setSelected(ids)
            rows.map((item, index) => {
              selectedIndex.push(item.id)
              selectedItem.push(item.id)
            })
            setSelectedRowsStatus(true)
          } else {
            setSelected([])
            setSelectedRowsStatus(false)
            selectedIndex = []
            selectedItem = []
          }
        }
      };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
      }

    const keyField = "id";

    const adsTxtEntriesListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "SSPEntry",
            text: "Ads.txt Entries",
            sort: true,
            editable: (content, row, rowIndex, columnIndex) => !row.ownedByStreamlyn,
            validator: (newValue, row, column) => {
                console.log(row)
                if (newValue === '') {
                    return {
                        valid: false,
                        message: 'Entry must not be empty'
                    };
                }
                return true;
            }
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: adsTxtEntries.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const [newEntryList, setNewEntryList] = useState([{ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' }])

    const addNewEntry = () => {
        let entryList = [...adsTxtEntries]
        entryList.push({ SSPEntryID: entryList.length + 1, SSPEntry: '', id: entryList.length + 1, })
        setAdsTxtEntries(entryList)
    }

    const handleEntryChange = (index) => (e) => {
        const { name, value } = e.target
        let entryList = [...newEntryList]
        let entryData = { ...entryList[index], [name]: value }
        entryList[index] = entryData
        setNewEntryList(entryList)
    }

    const removeEntry = (index) => (e) => {
        let entryList = [...newEntryList]
        entryList.splice(index, 1)
        setNewEntryList(entryList)
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, ads) => (event) => {
        console.log(event)
        selectedData = ads
        setIsRight(close);
    };

    const changeValue = (e) => {
        let values = [...adsTxtEntries]
    }

    const editEntries = (e) => {
        setEditState(true)
    }

    const updateEntries = (e) => {
        console.log(adsTxtEntries)
        let entries = [...adsTxtEntries]
        entries.map((item, i) => {
            delete item.id
        })
        if (tagList.length > 0) {
            entries = [...entries, ...tagList]
        }

        let bodyEntries = {
            url: "http://127.0.0.1:2002/v1.0/Publishers/update/adstxt",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                publisherAdsTxtEntries: entries,
                "publisherID": props.selectedAdsTxtEntries.publisherID,
                "publisherSite": props.selectedAdsTxtEntries.publisherSite,
                name:userInfo.name
            }
        }
        console.log(bodyEntries)
        axios.post("/v1.0/connectOP", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.success) {
                    toastr.success('ADS.txt Entries are Updated Successfully!...')
                    setEditState(false)
                    setLoading(true)
                    getAdsEntries()
                    setTagList([])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const tagChange = (index) => (e) => {
        let value = e.target.value
        let tags = [...tagList]
        tags[index].SSPEntry = e.target.value
        console.log(tags)
        setTagList(tags)
    }

    const removeTag = (index) => (e) => {
        console.log("adfaf")
        let tags = [...tagList]
        tags.splice(index, 1)
        setTagList(tags)
    }

    const addNewTag = () => {
        console.log("click")
        let entryList = [...adsTxtEntries]
        let tags = [...tagList]
        tags.push({ SSPEntry: '', SSPEntryID: entryList[entryList.length-1].SSPEntryID + 1, ownedByStreamlyn: false })
        console.log(tags)
        setTagList(tags)
    }

    const deleteAdsTxt = (event) => {
        console.log(adsTxtEntries)
        let entries = [...adsTxtEntries]
        console.log(selectedItem)
        let arr = entries.filter(item => !selectedItem.includes(item.id))
        console.log(arr)
        arr.map((item, i) => {
            delete item.id
        })
        if (tagList.length > 0) {
            arr = [...entries, ...tagList]
        }

        let bodyEntries = {
            url: "http://127.0.0.1:2002/v1.0/Publishers/update/adstxt",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                publisherAdsTxtEntries: arr,
                "publisherID": props.selectedAdsTxtEntries.publisherID,
                "publisherSite": props.selectedAdsTxtEntries.publisherSite,
                name:userInfo.name
            }
        }
        console.log(bodyEntries)
        axios.post("/v1.0/connectOP", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.success) {
                    toastr.success('ADS.txt Entries are Deleted Successfully!...')
                    setEditState(false)
                    setLoading(true)
                    getAdsEntries()
                    selectedItem = []
                    setSelected([])
                    setSelectedRowsStatus(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    if(adsTxtEntries.length > 0){
        adsTxtEntries.map((item, index) => {
            if (item.ownedByStreamlyn) {
              selectRow.nonSelectable.push(index + 1)
            }
          })
    }


  return (
    <Row>
    <Col lg="12">
        <Card>
            <CardBody>
                <Row className="mb-2">
                    <Col sm="3">
                    </Col>
                    <Col sm="9">
                        <div className="text-sm-end">
                            {/* {!editState &&
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={editEntries}
                                >
                                    Add/Edit Ads.txt Entries
                                </Button>
                            } */}
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                id="controlledTooltip"
                                onClick={copyCrawled('adsTxtEntries')}
                                onMouseLeave={() => {
                                    setIsTooltipOpen(false)
                                }}
                            >
                                Copy Ads.txt Entries
                            </Button>
                            <Tooltip
                                isOpen={isTooltipOpen}
                                placement="top"
                                target="controlledTooltip"
                                toggle={onToggleTooltip}
                                trigger="click"
                            >
                                Copied
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                {/* <div className="mb-3 mt-2" style={{ border: '1px solid #e9ecef', height: '62vh', overflow: 'auto', padding: '2%', borderRadius: '5px' }}>
                    {
                        adsTxtEntries.length > 0 && adsTxtEntries.map((subItem, i) => {
                            return (
                                <div className="crawledData row mt-2" key={i}>
                                    <span className="col-11">{subItem.id}. &nbsp;&nbsp; {subItem.SSPEntry}</span>
                                </div>
                            )
                        })
                    }
                </div> */}
                <Row>
                <Col xl="12">
                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                            <div className="row m-0 React-Table-Selection">
                                            {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                            <div style={{ padding: '0.5% 2%' }}>

                                                {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>Activate</button> */}
                                                {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>
                                                Activate
                                                </button> */}
                                                &nbsp;  &nbsp;
                                                <button color="primary" className="btn btn-primary " onClick={deleteAdsTxt} >
                                                Delete Entry
                                                </button>
                                                &nbsp;  &nbsp;
                                                {/* <Button color="primary" className="btn btn-primary " onClick={archiveAdvertiser} >
                                                Archive
                                                </Button> */}
                                                <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                className="mdi mdi-close-thick font-size-18"
                                                ></i></Button>
                                            </div>
                                            </div>
                                        }
                                        </Col>
                    <Col lg="12">
                    {/* {!editState &&
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col xl="12">
                                        <div className="table-responsive" style={{ height: '50vh', overflow: 'auto' }}>
                                            <BootstrapTable
                                                keyField={keyField}
                                                columns={adsTxtEntriesListColumns}
                                                data={adsTxtEntries}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    blurToSave: true
                                                })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    } */}
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col xl="12">
                                        <div className="table-responsive" style={{ height: '50vh', overflow: 'auto' }}>
                                            <BootstrapTable
                                                keyField={keyField}
                                                columns={adsTxtEntriesListColumns}
                                                data={adsTxtEntries}
                                                selectRow={selectRow}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    blurToSave: true
                                                })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                    <Col xs={7}>
                            <div className="mb-1">
                                {
                                    tagList.map((subItem, i) => {
                                        return (
                                            <div className="vast-settings" key={i}>
                                                <div className="accordion-body">
                                                    <div >
                                                        <div className="form-group">
                                                            <div className="row ">
                                                                <div className="col-10">
                                                                    <Input
                                                                        name="feeds"
                                                                        type="text"
                                                                        // value={subItem.tag}
                                                                        // onChange={tagChange(i)}
                                                                        placeholder={'Add New Entry'}
                                                                        value={subItem.feeds}
                                                                        onChange={tagChange(i)}
                                                                    >
                                                                    </Input>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className='row form-group '>
                                                                        <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeTag(i)}
                                                                        >X</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className='row'>
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        style={{ margin: '1% 2rem 0% ', width: 'auto' }}
                                        onClick={addNewTag}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Add New Ads.txt Entries
                                    </Button>
                                </div>


                            </div>
                    </Col>
                    <Col sm="12">
                        <div className="text-sm-end">
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={updateEntries}
                                >
                                    Submit
                                </Button>

                        </div>
                    </Col>

            <div className="m-0 p-0" id="adsTxtEntries" style={{ height: '0px', width: '0px', overflow: 'auto', }}>
            {
              adsTxtEntries.length > 0 && adsTxtEntries.map((subItem, i) => {
                return (
                  <div className="crawledData row mt-2" key={i}>
                    <span className="col-11">{i + 1}. &nbsp;&nbsp; {subItem.SSPEntry}</span>
                  </div>
                )
              })
            }
          </div>
                </Row>
            </CardBody>
        </Card>
    </Col>
</Row>
  )
}

Partner_Ads_Txt_Update.propTypes = {}

export default Partner_Ads_Txt_Update