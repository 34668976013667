import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import NewCreatives from "./NewCreatives";

import moment from "moment";

import {
    getHouseAdCreatives as onGetCreative,
    statusHouseAdCreative as onStatusCreative,
    archiveHouseAdCreative as onArchiveCreative,
    statusHouseAdCreativeSuccess,
    archiveHouseAdCreativeSuccess,
    addHouseAdCreativeFail,
    updateHouseAdCreativeFail,
} from "store/customTracking/Creatives/actions";

import {
    getHouseAdUnits as onGetAdUnits,
} from "store/customTracking/AdUnits/actions";

import NativeWidget from "./NativeAd/NativeWidget";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var loader = false
var filterCount = 0

const Creatives = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [isRightWidget, setIsRightWidget] = useState(false);
    const [proxy, setAnalyticalReports] = useState([])

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])
    const [adUnitSearch, setAdUnitSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState('Active')

    const [switchLoader, setSwitchLoader] = useState(true)

    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));


    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const changeDatePick = (event) => {
        // console.log(event)
        // console.log("start: ", event.startDate._d);
        // console.log("end: ", event.endDate._d);
        // setFromDate(event.startDate._d.toISOString());
        // setToDate(event.endDate._d.toISOString());
      }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
            setIsRightWidget(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }
    const adUnitChange = (e) => {
        if (e.target.value === '') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (adUnitSearch === '') {
            filterCount = filterCount + 1
        }
        setAdUnitSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        setAdUnitSearch('')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetCreative({ filter: 'Active', adunit: '' }));
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        loader = true
        // dispatch(onGetCreative(null));
    }, []);



    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.houseAdsCreativeID)
                selectedItem.push(row.houseAdsCreativeID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.houseAdsCreativeID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.houseAdsCreativeID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.houseAdsCreativeID)
                    selectedItem.push(item.houseAdsCreativeID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { creatives } = useSelector(state => ({
        creatives: state.creative.crtvs,
    }));

    const { loading } = useSelector(state => ({
        loading: state.creative.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.creative.noData,
    }));

    const { adUnits } = useSelector(state => ({
        adUnits: state.adUnit.adUs,
    }));

    const { creativesResponse } = useSelector(state => ({
        creativesResponse: state.creative.response,
    }));

    const { archiveCreativeResponse } = useSelector(state => ({
        archiveCreativeResponse: state.creative.archiveMsg,
    }));

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.creatives.errorMsg,
    //   successMsg: state.creatives.successMsg,
    // }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    console.log(creativesResponse)
    useEffect(() => {
        if (creativesResponse !== null) {
            if (creativesResponse.responseData.success) {
                toastr.success('Creative Status Changed Successfully')
                dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
                dispatch(statusHouseAdCreativeSuccess(null));
                selectedItem = []
                setSelected([])
                selectedIndex = []
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, creativesResponse]);

    useEffect(() => {
        if (archiveCreativeResponse !== null) {
            if (archiveCreativeResponse.responseData.success) {
                toastr.success('Creative Archived Successfully')
                dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
                dispatch(archiveHouseAdCreativeSuccess(null));
                selectedItem = []
                setSelected([])
                selectedIndex = []
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, archiveCreativeResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeFail(""));
        dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        setIsRight(!isRight);
    };

    const toggleRightCanvasWidget = () => {
        selectedData = ''
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeFail(""));
        dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        setIsRightWidget(!isRightWidget);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, creative) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Creative Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Creative Updated Successfully!...')
        }
        selectedData = creative
        setIsArchived(creative.IsHouseAdsCreativeArchived)
        dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeFail(""));
        setIsRight(close);
    };

    const toggleDrawerWidget = (close) => (event) => {
        dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeFail(""));
        setIsRightWidget(close);
    };

    useEffect(() => {
        if (creatives !== null) {
            dispatch(onGetCreative({ filter: statusSearch, adunit: adUnitSearch }));
        }
    }, []);

    useEffect(() => {
        if (adUnits !== null) {
            dispatch(onGetAdUnits({
                filter: 'Active', "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD")
            }));
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const creativeListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "houseAdsCreativeID",
            text: "ID",
            sort: true,
        },
        {
            dataField: "houseAdsCreativeCreateTime",
            text: "Creation Date",
            sort: true,
        },
        {
            dataField: "houseAdsCreativeName",
            text: "Name",
            sort: true,
            style: { wordBreak: 'break-word' }
        },
        {
            dataField: "houseAdsCreativeSize",
            text: "Size",
            sort: true,
        },
        {
            dataField: "houseAdsCreativeType",
            text: "Type",
            sort: false,
        },
        {
            dataField: "houseAdsCreativeStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, creative) => (
                <>
                    {creative.houseAdsCreativeStatus && !creative.isHouseAdsCreativeArchived &&
                        <span className="active-state">Active</span>
                    }
                    {!creative.houseAdsCreativeStatus && !creative.isHouseAdsCreativeArchived &&
                        <span className="inactive-state">Inactive</span>
                    }
                    {creative.isHouseAdsCreativeArchived &&
                        <span className="archived-state">Archived</span>
                    }
                </>

            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives) && (!UserRolePrivileges.HouseAds.HouseAdsCreatives.update),


            // eslint-disable-next-line react/display-name
            formatter: (cellContent, creative) => (
                <div className="d-flex gap-3" >
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, creative)}>
                        {creative.isHouseAdsCreativeArchived &&
                           'View'
                        }
                        {!creative.isHouseAdsCreativeArchived &&
                              'Edit'
                        }
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveCreative = async () => {
        dispatch(onArchiveCreative({ idList: selectedItem }));
    }

    const statusCreative = (state) => (event) => {
        console.log(state)
        dispatch(onStatusCreative({ idList: selectedItem, houseAdsCreativeStatus: state }));
    }

    console.log(creatives)

    if (creatives.length > 0) {
        if (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives) {
            if (!UserRolePrivileges.HouseAds.HouseAdsCreatives.archive && !UserRolePrivileges.HouseAds.HouseAdsCreatives.status) {
                creatives.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                creatives.map((item, index) => {
                    if (item.IsHouseAdsCreativeArchived) {
                        selectRow.nonSelectable.push(index + 1)
                    }
                })
            }
        }
    }



    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Creatives | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Custom Tracking" breadcrumbItem="Creatives" />
                        <div className=" text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                        {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label"> Ad Unit</Label> <br />
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={adUnitSearch} onChange={adUnitChange}>
                                                                <option value=''> Select...</option>
                                                                {adUnits.map((elem) => (
                                                                    <option key={elem.houseAdsAdUnitName} value={elem.houseAdsAdUnitName}>{elem.houseAdsAdUnitName}</option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Status</Label>
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                <option value=''> All</option>
                                                                <option value='Active' > Active</option>
                                                                <option value='Inactive'> InActive</option>
                                                                <option value='Archived'> Archived</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={applyFilter}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>
                                    </DropdownMenu>
                                </Dropdown>
                            </div> &nbsp;&nbsp;
                            {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives && UserRolePrivileges.HouseAds.HouseAdsCreatives.create &&
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Add Creatives
                                </Button>
                            }
                        </div>
                    </div>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={creatives}
                                                        columns={creativeListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="4"></Col>
                                                                    <Col sm="4" className="date-range ">
                                                                        <div className="text-sm-end">
                                                                            <DateRangePickers datePick={changeDatePick} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                            <div className="row m-0 React-Table-Selection">
                                                                                {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                                <div style={{ padding: '0.5% 2%' }}>

                                                                                    {/* <button color="primary" className="btn btn-primary " onClick={statusCreative(true)}>Activate</button> */}
                                                                                    {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives && UserRolePrivileges.HouseAds.HouseAdsCreatives.status &&
                                                                                        <button color="primary" className="btn btn-primary " onClick={statusCreative(true)}>
                                                                                            Activate
                                                                                        </button>
                                                                                    }
                                                                                    &nbsp;  &nbsp;
                                                                                    {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives && UserRolePrivileges.HouseAds.HouseAdsCreatives.status &&
                                                                                        <button color="primary" className="btn btn-primary " onClick={statusCreative(false)}>
                                                                                            DeActivate
                                                                                        </button>
                                                                                    }
                                                                                    &nbsp;  &nbsp;
                                                                                    {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCreatives && UserRolePrivileges.HouseAds.HouseAdsCreatives.archive &&
                                                                                        <Button color="primary" className="btn btn-primary " onClick={archiveCreative} >
                                                                                            Archive
                                                                                        </Button>
                                                                                    }
                                                                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                        className="mdi mdi-close-thick font-size-18"
                                                                                    ></i></Button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div >
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                selectRow={selectRow}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Add Creatives'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NewCreatives closeCanvas={toggleDrawer(false, '')} selectedCreative={selectedData} selectedArchived={isArchived} />
                </OffcanvasBody>
            </Offcanvas>
            <Offcanvas isOpen={isRightWidget} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvasWidget}>
                    {'Native Widget Creative'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NativeWidget closeCanvas={toggleDrawerWidget(false)} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment >
    )
}

Creatives.propTypes = {}

export default Creatives