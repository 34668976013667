/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Collapse, Button } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewTc as onADDTc,
  updateTc as onUpdateTc,
  readTc as onReadTc,
  addTcSuccess,
  addTcFail,
  updateTcSuccess,
  updateTcFail,
} from "store/Demand/TagController/actions";

import {
  getAdUnitTc as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import Toaster from "pages/Toaster/Toaster";
import classnames from "classnames";
import ToasterWarning from "pages/Toaster/ToasterWarning";

var total = 0
var totalPercentage = 0

const Create_TagController = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tCName, setTCName] = useState('')
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState(null);
  const [itemOpen, setItemOpen] = useState('')
  const [controller, setController] = useState({
    Tag_Controller_URLsToBlock: ''
  })
  const [blockUrls, setBlockUrls] = useState([])
  const [tagList, setTagList] = useState([
    {
      weightage: "1",
      tag: '',
      tagLabel: 'Tag1',
      percentage: 100,
      passbackTags: [
        {
          passbackTag: '',
          size: '728x90'
        },
        {
          passbackTag: '',
          size: '320x50'
        },
      ]
    }
  ])
  console.log(props)

  const [tCOptions, setTCOptions] = useState({
    blockUrls: [],
    size: "",
    position: "",
    paragraphNo: 1,
    tags: tagList
  })

  const handleChangeOptions = (e) => {
    const { name, value } = e.target;
    setTCOptions(tCOptions => ({ ...tCOptions, [name]: value }));
  }

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitListbyTC,
  }));

  console.log(adUnits)

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
  };

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
      if (props.selectedTc !== '') {
        let adUnitEditedObject = {
          value: props.selectedTc.adUnitID,
          label: props.selectedTc.adUnitName
        }
        let arr = objectAdUnitModal(adUnits)
        arr.push(adUnitEditedObject)
        setAdUnitData(arr)
        setSelectedAdUnit(adUnitEditedObject);
      }
    }else{
      if (props.selectedTc !== '') {
        let adUnitEditedObject = {
          value: props.selectedTc.adUnitID,
          label: props.selectedTc.adUnitName
        }
        let arr = objectAdUnitModal(adUnits)
        arr.push(adUnitEditedObject)
        setAdUnitData(arr)
        setSelectedAdUnit(adUnitEditedObject);
      }
    }
  }, [adUnits]);

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.AdUnitID
      obj.label = item.AdUnitName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (adUnits !== null) {
      dispatch(onGetAdUnits({ filter: '' }));
    }
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setController(controller => ({ ...controller, [name]: value }));
    let urls = value.split(/[\s,]+/);
    console.log(urls)
    setBlockUrls(urls)
  }

  const handleChangeTag = (index) => (e) => {
    const { name, value } = e.target;
    let newArr = [...tagList]
    newArr[index].tag = value
    setTagList(newArr)
  }

  const passbackTagChange = (CtgIndex, index) => e => {
    const { name, value } = e.target;
    let mainArr = [...tagList]
    let newArr = [...tagList[CtgIndex].passbackTags];
    newArr[index].passbackTag = value
    console.log(mainArr)
    mainArr[CtgIndex].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }


  useEffect(() => {
    if (props.selectedTc !== '') {
      console.log()
      setIsEdit(true)
      setTCName(props.selectedTc.tCName)
      if (props.selectedTc.tCOptions.tags.length > 0) {
        props.selectedTc.tCOptions.tags.map((item, index) => {
          item.tagLabel = "Tag" + (index + 1)
        })
        setTagList(tagList => ([...tagList, props.selectedTc.tCOptions.tags]));
      }

      setTagList(props.selectedTc.tCOptions.tags)
      setTCOptions(props.selectedTc.tCOptions)
      setBlockUrls(props.selectedTc.tCOptions.blockUrls)
      setController(controller => ({ ...controller, Tag_Controller_URLsToBlock: props.selectedTc.tCOptions.blockUrls }));
      changePercentage(props.selectedTc.tCOptions.tags)
    } else {
      changePercentage(tagList)
    }
  }, []);


  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.tcs.errorMsg,
    successMsg: state.tcs.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addTcSuccess(""));
        dispatch(addTcFail(""));
        dispatch(updateTcSuccess(""));
        dispatch(updateTcFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click() 
        } else {
          if (props.selectedTc !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
    }
  }, [errorMsg]);

  const handleSubmit = async (e) => {
    console.log(tCOptions)
    e.preventDefault();
    setSubmitted(true);
    if (selectedAdUnit.value && tCName && tCOptions.position && (tCOptions.position === 'InRead' ? tCOptions.paragraphNo : true) && tCOptions.size) {
      setLoader(true)
      tagList.map((item, index) => {
        delete item.tagLabel;
      })
      let finalData = {
        "tcName": tCName,
        "tCOptions": {
          "blockUrls": blockUrls,
          "size": tCOptions.size,
          "position": tCOptions.position,
          "paragraphNo": tCOptions.paragraphNo,
          "tags": tagList
        },
        "adUnitID": selectedAdUnit.value

      }
      if (tCOptions.position === 'InRead') {
        finalData.tCOptions.paragraphNo = tCOptions.paragraphNo
      } else {
        delete finalData.tCOptions.paragraphNo
      }
      console.log(finalData)
      
      if (props.selectedTc !== '') {
        dispatch(onUpdateTc({ ...finalData, tcID: props.selectedTc.tCID }));
      } else {
        dispatch(onADDTc({ ...finalData }));
      }

    }
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };


  const contentChangeTag = index => e => {
    let newArr = [...tagList]
    newArr[index].weightage = parseInt(e.target.value);
    setTagList(newArr)
    changePercentage(newArr)
  }

  const blurChangeTag = index => e => {
    console.log("input blurred")
    let newArr = [...tagList]
    if (parseInt(e.target.value) < 1) {
      newArr[index].weightage = 1;
    } else {
      newArr[index].weightage = parseInt(e.target.value);
    }
    setTagList(newArr);
    changePercentage(newArr)
  }

  const changePercentage = (data) => {
    console.log(data)
    total = 0
    data.map((item, index) => {
      if (Number.isNaN(parseInt(item.weightage)) || parseInt(item.weightage) < 1) {
        total = total + 1
      } else {
        total = total + parseInt(item.weightage)
      }
    })
    totalPercentageChange(data)
  }

  const tagMaxChange = (data) => {
    console.log(data)
    var maxId = data.reduce(function (maxId, item) {
      return Math.max(maxId, item.percentage)
    }, Number.MIN_VALUE);
    data.map((item, index) => {
      let newArr = [...data];
      if (totalPercentage >= 100) {
        if (item.percentage === maxId) {
          console.log(item.percentage)
          newArr[index].percentage = maxId - (totalPercentage - 100);
          console.log(newArr)
          setTagList(newArr);
        }
      }
      else if (item.percentage >= 99) {
        newArr[index].percentage = item.percentage - 2;
      }
      else if (item.percentage === 0) {
        newArr[index].percentage = 1;
        totalPercentage = totalPercentage + 1
      }
    })
  }

  const tagMinChange = (data) => {
    let newArr = [...data];
    if (totalPercentage === 99) {
      newArr[0].percentage = newArr[0].percentage + 1
      console.log(newArr)
      setTagList(newArr);
    }
    tagMaxChange(newArr)
  }

  const totalPercentageChange = (data) => {
    totalPercentage = 0
    data.map((item, index) => {
      if (item.weightage < 1) {
        item.percentage = Math.floor(((1 / total) * 100), 6);
      } else {
        item.percentage = Math.floor(((item.weightage / total) * 100), 6);
      }
      totalPercentage = totalPercentage + item.percentage
    })
    console.log(data)
    let newArr = [...data];
    setTagList(newArr)
    tagMinChange(newArr)
  }

  function handleSelectAdUnit(selectAdUnit) {
    setSelectedAdUnit(selectAdUnit);
  }


  const addNewPasssBackData = (index) => e => {
    const newPassBackData = {
      passbackTag: '',
      size: ''
    }
    let mainArr = [...tagList]
    let newArr = [...tagList[index].passbackTags];
    newArr.push(newPassBackData);
    mainArr[index].creativePresent = false
    mainArr[index].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  const addNewTag = () => {

    const newTag = {
      weightage: 1,
      tag: '',
      tagLabel: "Tag" + (tagList.length  + 1),
      percentage: '',
      passbackTags: [
        {
          passbackTag: '',
          size: '728x90'
        },
        {
          passbackTag: '',
          size: '320x50'
        },
      ]
    }
    setTagList([...tagList, newTag])
    changePercentage([...tagList, newTag])
  }

  const removeTags = (index) => (e) => {
    let newArr = [...tagList];
    newArr.splice(index, 1);
    setTagList(newArr);
    changePercentage(newArr)
  }

  const removePassBackTag = (gindex, i) => (e) => {
    let mainArr = [...tagList]
    let newArr = [...tagList[gindex].passbackTags];
    newArr.splice(i, 1);
    mainArr[gindex].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  useEffect(() => {

    const formdata = new FormData();
    // formdata.append("key", "87e595fe8d98e4957a1c4c8a892a4915");
    formdata.append("txt", "Balagokulam is the biggest children’s movement in Kerala today. Kesari, which started a column for children under the caption “Balagokulam” way back in the 1950s,had played an important role in the formation of Balagokulam. Tapasya, an art and literary forum, also owes its birth to Kesari .In 2005, Kesari instituted two awards for journalists: 1. Rashtra Seva Puraskaram- to a senior journalist for lifetime achievement.2. Raghaveeyam Puraskaram- to a novice in the print/visual media. It was in the 1970s, the Swayamsevaks all over Kerala started enlisting subscribers for Kesari in a systematic way. Ever since, in November every year, Swayamsevaks are engaging themselves in the subscription drive, and during the 60th year of the weekly, they could achieve the target of enlisting 60000 subscribers. During the subscription drive in November 2012, the number of subscribers has risen to almost 70000, the maximum in the history of Kesari.Kesari has digitised all its issues, right from the first issue to the last issue of December 2010, and the CDs are available in the market for RS. 1500/-. Kesari’s new office at Chalappuram was inaugurated by Param Poojaneeya Sarsanghachalak Dr. Mohanji Bhagwat on December 2020. The Kesari Bhavan comprises Mahatma Gandhi College of Mass Communication, a center for media research, with a state – of – the – art digital library ,conference hall and many more facilities.");
    formdata.append("sentences", 8);
    
    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
     fetch("https://api.meaningcloud.com/summarization-1.0", requestOptions)
      .then(response => ({
        status: response.status, 
        body: response.json()
      }))
      .then(({ status, body }) => console.log(status, body))
      .catch(error => console.log('error', error));









//   let bodyMonth = {
//       "url": "http://en.wikipedia.org/wiki/Automatic_summarization",
//       "text": "",
//       "sentnum": 8
// }
// axios.get('https://meaningcloud-summarization-v1.p.rapidapi.com/summarization-1.0', 
//     {
//       params: {sentences: '5', url: 'http://en.wikipedia.org/wiki/Star_Trek'},
//       headers: {
//         Accept: 'application/json',
//         'X-RapidAPI-Key': '83bc690497mshb24779dd48acbb6p16a574jsn8f77f7e59d0d',
//         'X-RapidAPI-Host': 'meaningcloud-summarization-v1.p.rapidapi.com'
//       }
//     })
//     .then((res) => {
//         console.log("result is Invoices ==>", res)
//     })
//     .catch((err) => {
//         console.log("e", err)
//     });
  }, []);

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={handleSubmit}>
          <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto', width: '100%' }}>
            <Col xs={8}>
              <div className="mb-3">
                <Label>Ad Unit</Label>
                <Select
                  value={selectedAdUnit}
                  onChange={s => {
                    handleSelectAdUnit(s)
                  }}
                  options={adUnitData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {submitted && !selectedAdUnit.value ? (
                  <FormFeedback type="invalid">{'Ad Unit is required'}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">Tag Controller Name</Label>
                <Input
                  name="tcname"
                  type="text"
                  maxLength={65}
                  value={tCName}
                  onKeyPress={allowAlphaNumericSpace}
                  onChange={(e) => setTCName(e.target.value)}
                  className={'form-control' + (submitted && !tCName ? ' is-invalid' : '')}
                  readOnly={props.selectedArchived}
                />
                {submitted && !tCName ? (
                  <FormFeedback type="invalid">{'Tag Controller Name is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Position</Label>
                <Input type="select" name="position" onChange={handleChangeOptions} value={tCOptions.position}
                  className={'form-control form-select' + (submitted && !tCOptions.position ? ' is-invalid' : '')} readOnly={props.selectedArchived} >
                  <option value=''> Select...</option>
                  <option value='Floating'> Floating</option>
                  <option value='InRead' >InRead</option>
                </Input>
                {submitted && !tCOptions.position ? (
                  <FormFeedback type="invalid">{'Position is required'}</FormFeedback>
                ) : null}
              </div>
              {tCOptions.position === 'InRead' &&
                <div className="mb-3">
                  <Label className="form-label">Paragraph No</Label>
                  <Input
                    name="paragraphNo"
                    type="number"
                    value={tCOptions.paragraphNo}
                    onChange={handleChangeOptions}
                    className={'form-control' + (submitted && tCOptions.position === 'InRead' && !tCOptions.paragraphNo ? ' is-invalid' : '')}
                    readOnly={props.selectedArchived}
                  />
                  {submitted && tCOptions.position === 'InRead' && !tCOptions.paragraphNo ? (
                    <FormFeedback type="invalid">{'Paragraph No is required'}</FormFeedback>
                  ) : null}
                </div>
              }

              <div className="mb-3">
                <Label className="form-label">Size</Label>
                <Input type="select" name="size" onChange={handleChangeOptions} value={tCOptions.size}
                  className={'form-control form-select' + (submitted && !tCOptions.size ? ' is-invalid' : '')} readOnly={props.selectedArchived}>
                  <option value=''> Select</option>
                  {/* <option value="0x0">0x0 </option> */}
                  <option value="1x1">1x1 </option>
                  {/* <option value="300x250">300x250 </option>
                  <option value="300x600">300x600 </option>
                  <option value="728x90">728x90 </option>
                  <option value="120x600">120x600 </option>
                  <option value="160x600">160x600 </option>
                  <option value="970x250">970x250 </option>
                  <option value="970x90">970x90</option>
                  <option value="320x50">320x50</option>
                  <option value="320x100">320x100 </option>
                  <option value="300x50">300x50 </option>
                  <option value="300x100">300x100 </option>
                  <option value="468x60">468x60 </option>
                  <option value="468x90">468x90</option>
                  <option value="336x280">336x280 </option> */}
                </Input>
                {submitted && !tCOptions.size ? (
                  <FormFeedback type="invalid">{'Size is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Block URLs</Label>
                <Input
                  name="Tag_Controller_URLsToBlock"
                  type="textarea"
                  id="textarea"
                  rows="3"
                  value={controller.Tag_Controller_URLsToBlock}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">Tags</Label>
                {
                            tagList.map((subItem = {}, i) => {
                              return (
                                <div className="vast-settings" key={i}>
                                  <div className="accordion-body">
                                    <div >
                                      <div className="form-group">
                                        <div className="row mb-3">
                                          <div className="col-9">
                                            <Label className="form-label"> {subItem.tagLabel}</Label>
                                            <Input
                                              name="tag"
                                              type="textarea"
                                              id="textarea"
                                              rows="2"
                                              value={subItem.tag}
                                              onChange={handleChangeTag(i)}
                                            >
                                            </Input>
                                          </div>
                                          <div className="col-2">
                                            <Label >  Weightage</Label>
                                            <Input
                                              type="number" name="weightage" value={subItem.weightage} onBlur={blurChangeTag(i)} maxLength='4' placeholder='1' onChange={contentChangeTag(i)}  onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                                            />
                                          </div>
                                          <div className="col-1 text-center">
                                            <Label className="mb-3 no-wrap">  Percentage</Label><br />
                                            <span className='ml-4 mr-4 '>
                                              {subItem.percentage}%
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
              </div> */}


              <div className="mb-3">
                <Label >
                  Tags
                </Label>

                {tagList && tagList.map((item, index) => {
                  console.log("group............Item", item, "tagList==========", tagList);
                  return (
                    <div className="tagList-settings" key={index}>
                      <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                        <h2 className="accordion-header row m-0" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: itemOpen !== ('panel' + (index)) }
                            )}
                            type="button"
                            onClick={openCollapsed('panel' + (index))}
                            style={{ cursor: "pointer" }}
                          >   
                          <div className="row mb-3 mt-2 w-100">
                              <div className="col-7">
                                <Label className="form-label"> {item.tagLabel}</Label>
                                <Input
                                  name="tag"
                                  type="textarea"
                                  id="textarea"
                                  rows="1"
                                  value={item.tag}
                                  onChange={handleChangeTag(index)}
                                >
                                </Input>
                              </div>
                              <div className="col-3">
                                <Label >  Weightage</Label>
                                <Input
                                  type="number" name="weightage" value={item.weightage} onBlur={blurChangeTag(index)} maxLength='4' placeholder='1' onChange={contentChangeTag(index)} onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                                />
                              </div>
                              <div className="col-1 text-center">
                                <Label className="mb-3 no-wrap">  Percentage</Label><br />
                                <span className='ml-4 mr-4 '>
                                  {item.percentage}%
                                </span>
                              </div>
                            </div>

                          </button>
                          {
                            tagList.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Tag</button>
                          }
                          {
                            tagList.length !== 1 && <Link to="#" className='btn btn-danger rem-grp-btn' style={{ margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removeTags(index)}
                            >Remove Tag</Link>
                          }
                        </h2>
                        {
                          item.passbackTags.map((subItem = {}, i) => {
                            return (
                              <div className="vast-settings" key={i}>
                                <div className="accordion-body">
                                  <div >

                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-7">
                                          <Label className="form-label"> PassBack Tag</Label>
                                          <Input
                                            name="passbackTag"
                                            type="textarea"
                                            id="textarea"
                                            rows="1"
                                            value={subItem.passbackTag}
                                            onChange={passbackTagChange(index, i)}
                                          >
                                          </Input>
                                        </div>
                                        <div className="col-3" >
                                          <Label className="form-label">Size</Label>
                                          <Input type="text" name="size"
                                            className='form-control ' value={subItem.size} readOnly>
                                          </Input>
                                        </div>
                                        {/* <div className="col-2" >
                                          <div className='row form-group mt-4'>
                                            {
                                              tagList[index].passbackTags.length === 1 && <button className='btn mt-1 btn-danger ml-auto disabled-rmg ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} disabled>X</button>
                                            }
                                            {
                                              tagList[index].passbackTags.length !== 1 && <Link to="#" className='btn mt-1 btn-danger ml-auto ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removePassBackTag(index, i)}
                                              >X</Link>
                                            }
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        {/* <div className='row'>
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }}
                            onClick={addNewPasssBackData(index)}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add PassBack Tag
                          </Button>

                        </div> */}
                      </div>
                    </div>
                  )
                }
                )}
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                    onClick={addNewTag}
                  // disabled={(categories.length === tagList.length) || categorySelection}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Tag
                  </Button>
                </div>
              </div>




            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' &&
          <Toaster status="error" msg={errorMsg} />
        }
      </div>
    </React.Fragment>
  );
};

export default withRouter(Create_TagController);

Create_TagController.propTypes = {
  history: PropTypes.object,
};