import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_IOS_SUCCESS,
    GET_IOS_FAIL,
    ADD_IOS_SUCCESS,
    ADD_IOS_ERROR,
    UPDATE_IOS_SUCCESS,
    UPDATE_IOS_ERROR,
    READ_IOS_SUCCESS,
    STATUS_IOS_SUCCESS,
    ARCHIVE_IOS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    iOSs: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    iOs: null,
    response:null,
    archiveMsg:null,
}

const iOSs = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_IOS_SUCCESS:
            return {
                ...state,
                loading:false,
                iOSs: action.payload,
            }

        case GET_IOS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_IOS_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_IOS_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IOS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IOS_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_IOS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IOS_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IOS_SUCCESS' ? null : action.payload,
            }
        case READ_IOS_SUCCESS:
            return {
                ...state,
                iOs: action.payload,
            }
        case STATUS_IOS_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_IOS_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default iOSs