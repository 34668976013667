import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_PLAYERSETTINGS_SUCCESS,
    GET_PLAYERSETTINGS_FAIL,
    ADD_PLAYERSETTING_SUCCESS,
    ADD_PLAYERSETTING_ERROR,
    UPDATE_PLAYERSETTING_SUCCESS,
    UPDATE_PLAYERSETTING_ERROR,
    READ_PLAYERSETTING_SUCCESS,
    STATUS_PLAYERSETTING_SUCCESS,
    ARCHIVE_PLAYERSETTING_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    playerSettingList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    playerSetting: null,
    response:null,
    archiveMsg:null,
}

const playerSettings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_PLAYERSETTINGS_SUCCESS:
            return {
                ...state,
                loading:false,
                playerSettingList: action.payload,
            }

        case GET_PLAYERSETTINGS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_PLAYERSETTING_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_PLAYERSETTING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PLAYERSETTINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PLAYERSETTING_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PLAYERSETTINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PLAYERSETTING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PLAYERSETTINGS_SUCCESS' ? null : action.payload,
            }
        case READ_PLAYERSETTING_SUCCESS:
            return {
                ...state,
                playerSetting: action.payload,
            }
        case STATUS_PLAYERSETTING_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PLAYERSETTING_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default playerSettings