
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label,
  UncontrolledDropdown,
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  getIOs as onGetIOs,
  statusIOs as onStatusIOs,
  archiveIOs as onArchiveIOs,
  statusIOsSuccess,
  archiveIOsSuccess,
  addIOsFail,
  updateIOsFail,
} from "store/Demand/IOs/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Insertion_Order from "./Create_Insertion_Order";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var iOType = ''

var filterCount = 0

const Insertion_Order = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [drp_primary1, setDrp_primary1] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [iOTypeSearch, setIOTypeSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [switchLoader, setSwitchLoader] = useState(true)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const iOTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (iOTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setIOTypeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    } else {
      dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    setIOTypeSearch('')
    filterCount = 0
    setFilterState(false)
    if (parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: 'Active', iOType: '' }));
    } else {
      dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: 'Active', iOType: '' }));
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.iOGroupID) {
  //     dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: e.target.value, iOType: iOTypeSearch }));
  //   } else {
  //     dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: e.target.value, iOType: iOTypeSearch }));
  //   }
  // }

  // const iOTypeChange = (e) => {
  //   setIOTypeSearch(e.target.value)
  //   if (parsed.iOGroupID) {
  //     dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: e.target.value }));
  //   } else {
  //     dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: e.target.value }));
  //   }
  // }

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetIOs(null));
    if (parsed.status === 'IOCreateByIOGroup') {
      setIsRight(true);
    }
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.iOID)
        selectedItem.push(row.iOID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.iOID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.iOID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.iOID)
          selectedItem.push(item.iOID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { iOSs } = useSelector(state => ({
    iOSs: state.iOSs.iOSs,
  }));

  const { loading } = useSelector(state => ({
    loading: state.iOSs.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.iOSs.noData,
  }));

  const { iOSsResponse } = useSelector(state => ({
    iOSsResponse: state.iOSs.response,
  }));

  const { archiveIOsResponse } = useSelector(state => ({
    archiveIOsResponse: state.iOSs.archiveMsg,
  }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(iOSsResponse)
  useEffect(() => {
    if (iOSsResponse !== null) {
      console.log(iOSsResponse)
      if (iOSsResponse.responseData.success) {
        toastr.success('IO Status Changed Successfully')
        if (parsed.iOGroupID) {
          dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
        } else {
          dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusIOsSuccess(null));
      }
    }
  }, [dispatch, iOSsResponse]);

  useEffect(() => {
    if (archiveIOsResponse !== null) {
      if (archiveIOsResponse.success) {
        toastr.success('IO Archived Successfully')
        if (parsed.iOGroupID) {
          dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
        } else {
          dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
        }
        dispatch(archiveIOsSuccess(null));
        setSelected([])
        setSelectedRowsStatus(false)
        selectedItem = []
        selectedIndex = []
      }
    }
  }, [dispatch, archiveIOsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    iOType = ''
    if (parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    } else {
      dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    }
    dispatch(addIOsFail(""));
    dispatch(updateIOsFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, iOs, iOTypes) => (event) => {
    console.log(event, iOType)
    if (event === 'Create') {
      toastr.success('IO Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('IO Updated Successfully!...')
    }
    selectedData = iOs
    iOType = iOTypes
    setIsArchived(iOs.isIOArchived)
    if (parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    } else {
      dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    }
    console.log(iOType, iOTypes)
    dispatch(addIOsFail(""));
    dispatch(updateIOsFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    if (parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: parsed.iOGroupID, userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    }
  }, []);

  useEffect(() => {
    if (iOSs !== null && !parsed.iOGroupID) {
      dispatch(onGetIOs({ iOGroupID: '', userRole: userInfo.userRole, userID: userInfo.userID,filter: statusSearch, iOType: iOTypeSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const iOsListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      dataField: "iOID",
      sort: true,
      formatter: (cellContent, iOs) => (
        <>
          <span>{iOs.iOID}</span>
        </>
      ),
    },
    {
      dataField: "iOCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "IO Name",
      dataField: "iOName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, iOs) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/Line_Items?iOID=${iOs.iOID}&iOName=${iOs.iOName}&lineItemType=${iOs.iOType}&status=LineItemCreateByParent`} className="color-dark-blue ">
              {iOs.iOName}
            </Link>
          </h5>
        </>
      ),
    },
    {
      text: "IO Type",
      dataField: "iOType",
      sort: true,
      formatter: (cellContent, iOs) => (
        <div className=' ' >
          {iOs.iOType === 'video' &&
            <span>Video</span>
          }
          {iOs.iOType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {iOs.iOType === 'highImpact' &&
            <span>High Impact</span>
          }
        </div>
      )
    },
    {
      dataField: "iOStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, iOs) => (
        <>
          <span className={iOs.newStatus === 'Active' ? "active-state" : iOs.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{iOs.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs) && (!UserRolePrivileges.Demand.IOs.create),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, iOs) => (
        <div className="d-flex gap-3" >
          <span className="Email-state" onClick={toggleDrawer(true, iOs, iOs.iOType)}>
            {iOs.newStatus === 'Archived' &&
           'View'
            }
            {iOs.newStatus !== 'Archived' &&
              'Edit'
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveIOs = async () => {
    dispatch(onArchiveIOs({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem }));
  }

  const statusIOs = (state) => (event) => {
    console.log(state)
    dispatch(onStatusIOs({ userRole: userInfo.userRole, userID: userInfo.userID,idList: selectedItem, iOStatus: state }));
  }

  console.log(iOSs)

  if (iOSs.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs) {
      if (!UserRolePrivileges.Demand.IOs.archive && !UserRolePrivileges.Demand.IOs.status) {
        iOSs.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        iOSs.map((item, index) => {
          if (item.isIOArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }



  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Insertion Order | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumbs */}

          <div className=" d-sm-flex  justify-content-between">
            {parsed.iOGroupID ? (
              <Breadcrumbs title="Demand" breadcrumbItem="Insertion Order" iOGroupID={parsed.iOGroupID} />
            ) : (
              <Breadcrumbs title="Demand" breadcrumbItem="Insertion Order " breadList="All IOs" />
            )
            }
            {UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs && UserRolePrivileges.Demand.IOs.create &&
              <div className="text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                      {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            {UserInventoryType !== 'video' &&
                              <Col sm="12" className="mt-2">
                                <Label className="form-label">IO Type</Label>
                                <div className=" text-sm-end">
                                  <Input type="select" className="form-select" value={iOTypeSearch} onChange={iOTypeChange}>
                                    <option value=''> All</option>
                                    <option value='stdb' >Standard Banner</option>
                                    <option value='highImpact'> High Impact</option>
                                    {UserInventoryType === 'both' &&
                                      <option value='video'> Video</option>
                                    }
                                  </Input>
                                </div>
                              </Col>
                            }
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                            {/* <Col sm="12" className="date-range mt-2">
                                <Label className="form-label">Date Range</Label> <br />
                             
                              </Col> */}
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                            {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                            {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                {UserInventoryType !== 'video' ?
                  <div className="btn-group">
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        <i className="mdi mdi-plus-circle-outline me-1" />Add Insertion Order&nbsp; <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard</DropdownItem>
                        <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact</DropdownItem>
                        {UserInventoryType === 'both' &&
                          <DropdownItem onClick={toggleDrawer(true, '', 'video')}>Video</DropdownItem>
                        }
                      </DropdownMenu>
                    </Dropdown>
                  </div> : <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleDrawer(true, '', 'video')}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Insertion Order
                  </Button>
                }

              </div>
            }
          </div>
          {/* <Card>
            <CardBody aria-hidden="true">
              <Row >
                {UserInventoryType !== 'video' &&
                  <Col sm="2">
                    <Label className="form-label">IO Type</Label>
                    <div className=" text-sm-end">
                      <Input type="select" className="form-select" value={iOTypeSearch} onChange={iOTypeChange}>
                        <option value=''> All</option>
                        <option value='stdb' >Standard Banner</option>
                        <option value='highImpact'> High Impact</option>
                        {UserInventoryType === 'both' &&
                          <option value='video'> Video</option>
                        }
                      </Input>
                    </div>
                  </Col>
                }
                <Col sm="2">
                  <Label className="form-label">Status</Label>
                  <div className=" text-sm-end">
                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                      <option value=''> All</option>
                      <option value='Active' > Active</option>
                      <option value='Inactive'> InActive</option>
                      <option value='Archived'> Archived</option>
                    </Input>
                  </div>
                </Col>
                <Col sm="4" className="date-range">
                  <Label className="form-label">Date Range</Label> <br />
                  <DateRangePickers datePick={changeDatePick} />
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {/* // <Spinner className="ms-2 loader" color="primary" /> */}


          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
             
                          <ToolkitProvider
                            keyField={keyField}
                            data={iOSs}
                            columns={iOsListColumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                      <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                          {/* <button color="primary" className="btn btn-primary " onClick={statusIOs(true)}>Activate</button> */}
                                          {UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs && UserRolePrivileges.Demand.IOs.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusIOs(true)}>
                                              Activate
                                            </button>
                                          }
                                          &nbsp;  &nbsp;
                                          {UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs && UserRolePrivileges.Demand.IOs.status &&
                                            <button color="primary" className="btn btn-primary " onClick={statusIOs(false)}>
                                              DeActivate
                                            </button>
                                          }
                                          &nbsp;  &nbsp;
                                          {UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOs && UserRolePrivileges.Demand.IOs.archive &&
                                            <Button color="primary" className="btn btn-primary " onClick={archiveIOs} >
                                              Archive
                                            </Button>
                                          }
                                          <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                            className="mdi mdi-close-thick font-size-18"
                                          ></i></Button>
                                        </div>
                                      </div>
                                    }
                                  </Col>
                                  <Col xl="12" className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'   >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Insertion Order ' : 'Add Insertion Order '}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Insertion_Order closeCanvas={toggleDrawer(false, '', '')} selectedArchived={isArchived} selectedIOs={selectedData} selectedIOType={iOType} selectedIOGroup={(parsed.status === 'IOCreateByIOGroup' || parsed.status === 'IOCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

export default Insertion_Order




