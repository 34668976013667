import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_LINEITEMS,
    GET_LINEITEMS_FAIL,
    GET_LINEITEMS_SUCCESS,
    ADD_LINEITEM,
    ADD_LINEITEM_SUCCESS,
    ADD_LINEITEM_ERROR,
    UPDATE_LINEITEM,
    UPDATE_LINEITEM_SUCCESS,
    UPDATE_LINEITEM_ERROR,
    READ_LINEITEM,
    READ_LINEITEM_SUCCESS,
    STATUS_LINEITEM,
    STATUS_LINEITEM_SUCCESS,
    ARCHIVE_LINEITEM ,
    ARCHIVE_LINEITEM_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesLineItem = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataLineItem = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getLineItem = Params => ({
    type: GET_LINEITEMS,
    payload:Params
  })
  
  export const getLineItemSuccess = LineItem => ({
    type: GET_LINEITEMS_SUCCESS,
    payload: LineItem,
  })

  export const getLineItemFail = error => ({
    type: GET_LINEITEMS_FAIL,
    payload: error,
  })

  export const addNewLineItem = LineItem => ({
    type: ADD_LINEITEM,
    payload: LineItem,
  })
  
  export const addLineItemSuccess = lineItem => ({
    type: ADD_LINEITEM_SUCCESS,
    payload: lineItem,
  })
  
  export const addLineItemFail = lineItem => ({
    type: ADD_LINEITEM_ERROR,
    payload: lineItem,
  })

  export const updateLineItem = lineItem => ({
    type: UPDATE_LINEITEM,
    payload: lineItem,
  })
  
  export const updateLineItemSuccess = lineItem => ({
    type: UPDATE_LINEITEM_SUCCESS,
    payload: lineItem,
  })
  
  export const updateLineItemFail = lineItem => ({
    type: UPDATE_LINEITEM_ERROR,
    payload: lineItem,
  })

  export const readLineItem = lineItem => ({
    type: READ_LINEITEM,
    payload: lineItem,
  })

  export const readLineItemSuccess = lineItem => ({
    type: READ_LINEITEM_SUCCESS,
    payload: lineItem,
  })

  export const statusLineItem = lineItem => ({
    type: STATUS_LINEITEM,
    payload: lineItem,
  })

  export const statusLineItemSuccess = lineItem => ({
    type: STATUS_LINEITEM_SUCCESS,
    payload: lineItem,
  })

  export const archiveLineItem = lineItem => ({
    type: ARCHIVE_LINEITEM,
    payload: lineItem,
  })

  export const archiveLineItemSuccess = lineItem => ({
    type: ARCHIVE_LINEITEM_SUCCESS,
    payload: lineItem,
  })