/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import {
    addNewAdPublisher as onADDAdPublisher,
    updateAdPublisher as onUpdateAdPublisher,
    addAdPublisherSuccess,
    addAdPublisherFail,
    updateAdPublisherSuccess,
    updateAdPublisherFail,
} from "store/customTracking/HouseAdPub/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewHouseAdPub = (props) => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [adPublisher, setAdPublisher] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [nextTab, setNextTab] = useState(false);
    const nextRef = useRef();

    console.log(props)

    useEffect(() => {
        if (props.selectedAdPublisher !== '') {
            setIsEdit(true)
            setAdPublisher(props.selectedAdPublisher)
        }
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            houseAdsPublisherName: (adPublisher && adPublisher.houseAdsPublisherName) || '',
            houseAdsPublisherSite: (adPublisher && adPublisher.houseAdsPublisherSite) || '',
        },
        validationSchema: Yup.object({
            houseAdsPublisherName: Yup.string().required("Publisher Name is required"),
            houseAdsPublisherSite: Yup.string().required("Site is required"),
        }),
        onSubmit: (values) => {
            setLoader(true)
            if (props.selectedAdPublisher !== '') {
                dispatch(onUpdateAdPublisher({ ...values, houseAdsPublisherID: props.selectedAdPublisher.houseAdsPublisherID,houseAdsPublisherName:values.houseAdsPublisherName.trim(),houseAdsPublisherSite:values.houseAdsPublisherSite.trim() }));
            } else {
                dispatch(onADDAdPublisher({ ...values,houseAdsPublisherName:values.houseAdsPublisherName.trim(),houseAdsPublisherSite:values.houseAdsPublisherSite.trim() }));
            }
        }
    });

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.adPublishers.errorMsg,
        successMsg: state.adPublishers.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addAdPublisherSuccess(""));
                dispatch(addAdPublisherFail(""));
                dispatch(updateAdPublisherSuccess(""));
                dispatch(updateAdPublisherFail(""));
                setLoader(false)
                if(nextTab){
                    props.closeCanvas('')
                    nextRef.current.click()
                  }
                  else{
                    if (props.selectedAdPublisher !== '') {
                        props.closeCanvas('Update')
                    } else {
                        props.closeCanvas('Create')
                    }
                  }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
          setLoader(false)
          if(errorMsg.status && errorMsg.status === 500){
          }else{
            dispatch(addAdPublisherSuccess(""));
            dispatch(addAdPublisherFail(""));
            dispatch(updateAdPublisherSuccess(""));
            dispatch(updateAdPublisherFail(""));
          }
        }
      }, [errorMsg]);
    
      const toggleToast = (n) => (e) => {
        dispatch(addAdPublisherSuccess(""));
        dispatch(addAdPublisherFail(""));
        dispatch(updateAdPublisherSuccess(""));
        dispatch(updateAdPublisherFail(""));
            props.closeCanvas('')
      };

    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
      };


    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
                    <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Publisher Name</Label>
                                <Input
                                    name="houseAdsPublisherName"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    maxLength={65}
                                    onKeyPress={allowAlphaNumericSpace}
                                    value={validation.values.houseAdsPublisherName || ""}
                                    invalid={
                                        validation.touched.houseAdsPublisherName && validation.errors.houseAdsPublisherName ? true : false
                                    }
                                    readOnly={props.selectedArchived}
                                />
                                {validation.touched.houseAdsPublisherName && validation.errors.houseAdsPublisherName ? (
                                    <FormFeedback type="invalid">{validation.errors.houseAdsPublisherName}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Site</Label>
                                <Input
                                    name="houseAdsPublisherSite"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.houseAdsPublisherSite || ""}
                                    invalid={
                                        validation.touched.houseAdsPublisherSite && validation.errors.houseAdsPublisherSite ? true : false
                                    }
                                    readOnly={props.selectedArchived}
                                />
                                {validation.touched.houseAdsPublisherSite && validation.errors.houseAdsPublisherSite ? (
                                    <FormFeedback type="invalid">{validation.errors.houseAdsPublisherSite}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                        onClick={() => setNextTab(true)}
                                    >
                                           Save & Add Ad Unit
                                    </button>
                                }
                                &nbsp;&nbsp;

                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                                {nextTab && successMsg &&
                                    <Link ref={nextRef} to={`/AdUnits?publisherName=${successMsg.houseAdsPublisherName}&status=AdUnitCreateByPublisher`}  > </Link>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
        </React.Fragment>
    )
}

export default NewHouseAdPub