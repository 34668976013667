import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

const SidebarContent = props => {
  const ref = useRef();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const userRole = localStorage.getItem('UserRole');
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  // console.log(UserRolePrivileges)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    if (pathName !== '/') {
      const initMenu = () => {
        new MetisMenu("#side-menu");
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
        if (matchingMenuItem) {
          activateParentDropdown(matchingMenuItem);
        }
      };
      initMenu();
    }
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      // if (currentPosition > window.innerHeight) {
      ref.current.getScrollElement().scrollTop = currentPosition - 200;
      // }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref} id="">
        <div id="sidebar-menu">
          {(userRole === 'Admin' || userRole === 'User') &&
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Dashboard && (UserRolePrivileges.Dashboard.isRevenueAccesss || UserRolePrivileges.Dashboard.isAnalyticsAccess)) ? 'block' : 'none' }}>{props.t("Dashboards")} </li>
              {/* <li >
                <Link to="/Dashboard" className="">
                  <i className='bx bx-home-alt' ></i>
                  <span>{props.t("Home")}</span>
                </Link>
              </li> */}
              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isRevenueAccess) ? 'block' : 'none' }}>
                <Link to="/Marketplace_Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Marketplace Dashboard")}</span>
                </Link>
              </li>
              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isAnalyticsAccess) ? 'block' : 'none' }}>
                <Link to="/Server_Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Server Dashboard")}</span>
                </Link>
              </li>
              {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isAgencyAccess) ? 'block' : 'none' }}>
                <Link to="/Agency_Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Agency Dashboard")}</span>
                </Link>
              </li> */}

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isAgencyAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Agency Dashboard")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">

                  <li >
                    <Link to="/Agency_Dashboard_USD" className="">
                      <span>{props.t("Agency Dashboard - $")}</span>
                    </Link>
                  </li>
                  <li >
                    <Link to="/Agency_Dashboard_INR" className="">
                      <span>{props.t("Agency Dashboard - ₹")}</span>
                    </Link>
                  </li>
                </ul>
              </li>


              {/* <li style={{ display: ((userInfo.userRole !== 'Root' && userInfo.userRole !== 'SuperAdmin') && (UserRolePrivileges && UserRolePrivileges.Dashboard && (!UserRolePrivileges.Dashboard.isRevenueAccess && !UserRolePrivileges.Dashboard.isAnalyticsAccess))) ? 'block' : 'none' }}>
                <Link to="/Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li> */}
              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && (UserRolePrivileges.isDemandAccess || UserRolePrivileges.isSupplyAccess)) ? 'block' : 'none' }}>{props.t("Bidserv")}</li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isDemandAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow" onClick={(e) => e.preventDefault()}>
                  <i className="bx bxs-folder-open"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Demand")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isAdveriserAccess) ? 'block' : 'none' }}>
                    <Link to="/Advertiser">{props.t("Advertiser")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isIOGroupAccess) ? 'block' : 'none' }}>
                    <Link to="/Insertion_Order_Group">{props.t("Insertion Order Group")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isIOsAccess) ? 'block' : 'none' }}>
                    <Link to="/Insertion_Order">{props.t("Insertion Order")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isLineItemAccess) ? 'block' : 'none' }}>
                    <Link to="/Line_Items">{props.t("Line Items")}</Link>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isCreativeAccess) ? 'block' : 'none' }}>
                    {props.location.pathname === '/Creative_Weightage' ? <Link to="/Creative_Weightage">{props.t("Creative")}</Link>
                      : <Link to="/Creative">{props.t("Creative")}</Link>}
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Header Bidding")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                        <Link to="/HB_Buyer">{props.t("HB Buyer")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                        <Link to="/HB_AdUnits">{props.t("HB AdUnits")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : 'none' }}>
                        <Link to="/Bidder">{props.t("Bidders")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateBuyer">{props.t("HB Buyer")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateAdUnit">{props.t("HB AdUnits")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isSupplyAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Supply")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isPartnerAccess) ? 'block' : 'none' }}>
                    <Link to="/Supply_Partner">{props.t("Supply Partners")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isPublisherAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher">{props.t("Publishers")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isAdUnitGroupAccess) ? 'block' : 'none' }}>
                    <Link to="/AdUnitGroup">{props.t("AdUnit Group")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isAdUnitAccess) ? 'block' : 'none' }}>
                    <Link to="/AdUnit">{props.t("AdUnit")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isChildAdUnitAccess) ? 'block' : 'none' }}>
                    <Link to="/ChildAdUnit">{props.t("Child AdUnits")}</Link>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isPlayerSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/Player_Settings">{props.t("Player Settings")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isHighImpactSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/High_Impact">{props.t("High Impact Settings")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isSTDBSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/Stbr_Settings">{props.t("Standard Banner Settings")}</Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && (UserRolePrivileges.isReportAccess || UserRolePrivileges.isReportUploadingAccess || UserRolePrivileges.isBillingAccess)) ? 'block' : 'none' }}>{props.t("Reports")}</li>
              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isReportAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-report"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Reporting")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && (UserRolePrivileges.Report.isAnalyticReportAccess || UserRolePrivileges.Report.isPageUrlReportAccess || UserRolePrivileges.Report.isRevenueReportAccess || UserRolePrivileges.Report.isAgencyReportAccess)) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Master Reports")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isAnalyticReportAccess) ? 'block' : 'none' }}>
                        <Link to="/Analytics_Report">{props.t("Analytics Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isPageUrlReportAccess) ? 'block' : 'none' }}>
                        <Link to="/Page_Url_Report" style={{ cursor:(userInfo.userID === 900 || userInfo.userID === 920) ? 'pointer' : 'none', pointerEvents:(userInfo.userID === 900 || userInfo.userID === 920) ? 'auto' : 'none' }}>{props.t("Page URL Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isRevenueReportAccess) ? 'block' : 'none' }}>
                        <Link to="/Revenue_Report">{props.t("Revenue Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isAgencyReportAccess) ? 'block' : 'none' }}>
                        <Link to="/AgencyReport">{props.t("Agency Report")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && (UserRolePrivileges.Report.isYMAnalyticReportAccess || UserRolePrivileges.Report.isASAnalyticReportAccess || UserRolePrivileges.Report.isMISAnalyticReportAccess || UserRolePrivileges.Report.isTeamPageUrlReportAccess)) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Team Reports")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isYMAnalyticReportAccess) ? 'block' : 'none' }}>
                        <Link to="/YM_Analytics_Report">{props.t("YM Analytics Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isASAnalyticReportAccess) ? 'block' : 'none' }}>
                        <Link to="/AS_Analytics_Report">{props.t("AS Analytics Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isMISAnalyticReportAccess) ? 'block' : 'none' }}>
                        <Link to="/MIS_Analytics_Report">{props.t("MIS Analytics Report")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Report && UserRolePrivileges.Report.isTeamPageUrlReportAccess) ? 'block' : 'none' }}>
                        <Link to="/Team_Page_Url_Report" style={{ cursor: 'none', pointerEvents: 'none' }}>{props.t("Team Page URL Report")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isReportUploadingAccess) ? 'block' : 'none' }}>
                <Link to="/Report_Upload" className="">
                  <i className="bx bx-upload"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Report Upload")}</span>
                </Link>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isBillingAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-receipt"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Billing")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Billing && UserRolePrivileges.Billing.isInvoiceApproveAccess) ? 'block' : 'none' }}>
                    <Link to="/Invoice_Approval">{props.t("Invoice Approval")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Billing && UserRolePrivileges.Billing.isInvoiceHistoryAccess) ? 'block' : 'none' }}>
                    <Link to="/Invoice_History">{props.t("Invoice History")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Billing && UserRolePrivileges.Billing.isInvoicePaymentAccess) ? 'block' : 'none' }}>
                    <Link to="/Payments">{props.t("Payments")}</Link>
                  </li>
                </ul>
              </li>



              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isHouseAdAccess) ? 'block' : 'none' }}>{props.t("Apps")}</li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isHouseAdAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Bx-Apps")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isHouseAdAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      {/* <i className="bx bx-chalkboard"></i> */}
                      {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                      <span>{props.t("House Ads")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isHouseAdsPublisherAccess) ? 'block' : 'none' }}>
                        <Link to="/AdPublishers">{props.t("Publishers")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isHouseAdsAdUnitAccess) ? 'block' : 'none' }}>
                        <Link to="/AdUnits">{props.t("Ad Units")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isHouseAdCreativeAccess) ? 'block' : 'none' }}>
                        <Link to="/Creatives">{props.t("Creatives")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isNativeAdAccess) ? 'block' : 'none' }}>
                        <Link to="/NativeWidget">{props.t("Native Ad")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isHouseAdsCategoryAccess) ? 'block' : 'none' }}>
                        <Link to="/Categories">{props.t("Categories")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.HouseAds.isFsrvAccess) ? 'block' : 'none' }}>
                        <Link to="/Custom_Report">{props.t("Report")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isProxyAdAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      {/* <i className="bx bx-server"></i> */}
                      {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                      <span>{props.t("Proxy")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ProxyAds.isProxyAdUnitAccess) ? 'block' : 'none' }}>
                        <Link to="/Proxy_AdUnits">{props.t("Ad Units")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ProxyAds.isSsrvAccess) ? 'block' : 'none' }}>
                        <Link to="/Query_Report">{props.t("Report")}</Link>
                      </li>
                    </ul>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isTagControllerAccess) ? 'block' : 'none' }}>
                    <Link to="/Tag_Controller">
                      {/* <i className="bx bxs-folder-open"></i> */}
                      <span>{props.t("Tag Controller")}</span>
                    </Link>
                  </li>
                </ul>
              </li>





              {/* <li>
                <Link to="/Partner_Management" className="">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Partner Management")}</span>
                </Link>
              </li> */}



              {/* Media */}


              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isVCsAccess) ? 'block' : 'none' }}>{props.t("Media")}</li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isVCsAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-video-recording"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Video Content Suite")}</span>
                </Link>

                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.VCs && UserRolePrivileges.VCs.isVideoContentLibrarayAccess) ? 'block' : 'none' }}>
                    <Link to="/Video_Content_Library" className="" >
                      {/* <i className='bx bx-video-recording'></i> */}
                      <span>{props.t("Video Content Library")}</span>
                    </Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.VCs && UserRolePrivileges.VCs.isVideoPlayListAccess) ? 'block' : 'none' }}>
                    <Link to="/Video_Playlists" className="">
                      {/* <i className='bx bx-collection'></i> */}
                      <span>{props.t("Video Playlists")}</span>
                    </Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.VCs && UserRolePrivileges.VCs.isVideoImportAccess) ? 'block' : 'none' }}>
                    <Link to="/Video_Import" className="">
                      {/* <i className='bx bx-video-plus'></i> */}
                      <span>{props.t("Video Import")}</span>
                    </Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.VCs && UserRolePrivileges.VCs.isContentMarketplaceAccess) ? 'block' : 'none' }}>
                    <Link to="/Content_Market_Place" className="">
                      {/* <i className='bx bx-briefcase-alt'></i> */}
                      <span>{props.t("Content Marketplace")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isContextualSettingsAccess) ? 'block' : 'none' }}>{props.t("Preferences")}</li>



              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isNetworkSettingAccess) ? 'block' : 'none' }}>
                <Link to="/Network_Settings" className="">
                  <i className="mdi mdi-signal"></i>
                  <span>{props.t("Network Settings")}</span>
                </Link>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isNetworkBlockingAccess) ? 'block' : 'none' }}>
                <Link to="/Network_Blocking" className="">
                  <i className="bx bx-block"></i>
                  <span>{props.t("Network Blocking")}</span>
                </Link>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isContextualSettingsAccess) ? 'block' : 'none' }}>
                <Link to="/Contextual_Settings" className="">
                  <i className="bx bx-slider-alt"></i>
                  <span>{props.t("Contextual Settings")}</span>
                </Link>
              </li>

              {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isContextualSettingsAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-slider-alt"></i>
                  <span>{props.t("Contextual Settings")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isBrandAffinityAccess) ? 'block' : 'none' }}>
                    <Link to="/Brand_Affinity">{props.t("Brand Affinity")}</Link></li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isInterestAccess) ? 'block' : 'none' }}>
                    <Link to="/Interests">{props.t("Interests")}</Link></li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isKeywordAccess) ? 'block' : 'none' }}>
                    <Link to="/Keywords">{props.t("Keywords")}</Link></li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isKeywordAccess) ? 'block' : 'none' }}>
                    <Link to="/IPMapping">{props.t("IP Mapping")}</Link></li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isPageUrlAccess) ? 'block' : 'none' }}>
                    <Link to="/Page_URL">{props.t("Page URL")}</Link></li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.ContextualSettings && UserRolePrivileges.ContextualSettings.isWebCategorizationAccess) ? 'block' : 'none' }}>
                    <Link to="/Web_Categorization">{props.t("Web Categorization")}</Link></li>

                </ul>
              </li> */}


              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isToolsAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-wrench"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Tools")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">

                  {/* <li  style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isVideoContentLibrarayAccess) ? 'block' : 'none' }}>
                    <Link to="/VideoContent">{props.t("Video Content Library")}</Link>
                  </li> */}
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isScreenShotToolAccess) ? 'block' : 'none' }}>
                    <Link to="/ScreenshotTool">{props.t("Screenshot Tool")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isVideoCompresser) ? 'block' : 'none' }}>
                    <Link to="/VideoCompressor">{props.t("Video Compressor")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isAdTagTesterAccess) ? 'block' : 'none' }}>
                    <Link to="/Ad_Tag_Tester">{props.t("Ad Tag Tester")}</Link>
                  </li>
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isVastTagTesterAccess) ? 'block' : 'none' }}>
                    <Link to="/Vast_Tag_Tester">{props.t("Vast Tag Tester")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isVastTagTesterAccess) ? 'block' : 'none' }}>
                    <Link to="/playerDashboard">{props.t("Player Dashboard")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isVastTagTesterAccess) ? 'block' : 'none' }}>
                    <Link to="/Summerization">{props.t("Text Summerization")}</Link>
                  </li> */}
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isAdsTxtValidatorAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Ads.txt Validator")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li>
                        <Link to="/Ads_Txt_Validator">{props.t("Ads.Txt Validator")}</Link>
                      </li>
                      <li>
                        <Link to="/SSP_Domain_Validator">{props.t("SSP Domain Validator")}</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Tools && UserRolePrivileges.Tools.isScreenShotToolAccess) ? 'block' : 'none' }}>
                    <Link to="/AiVideoGenerator">{props.t("Ai Video Generator")}</Link>
                  </li> */}
                  {/* <li>
                      <Link to="/MyAdtxt">{props.t("MyAdtxt")}</Link>
                    </li> */}

                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block'  : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Header Bidding Test")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block'  : 'none' }}>
                        <Link to="/HB_Buyer_test">{props.t("HB Buyer Test")}</Link>
                      </li>
                      <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : 'none' }}>
                        <Link to="/HB_AdUnits_test">{props.t("HB AdUnits Test")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateBuyer_test">{props.t("HB Buyer")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateAdUnit_test">{props.t("HB AdUnits")}</Link>
                      </li>
                    </ul>
                  </li> */}

                </ul>
              </li>


              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isAdminAccess) ? 'block' : 'none' }}>{props.t("Admin")}</li>


              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isAdminAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-shield"></i>
                  <span>{props.t("Admin")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Network_Settings">{props.t("Network Settings")}</Link>
                  </li> */}
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Access_Management">{props.t("Access Management")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isPartnerManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Partner_Management" className="">
                      <span>{props.t("Partner Management")}</span>
                    </Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Access_Management">{props.t("Supply Partner ACM")}</Link>
                  </li>
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Access_Management">{props.t("Publisher Access Management")}</Link>
                  </li> */}
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Data_Feed">{props.t("Data Feed")}</Link>
                  </li> */}

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAdsTxtManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Ads.txt Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li>
                        <Link to="/Ads_Txt_Queries">{props.t("Ads.txt Queries")}</Link>
                      </li>
                      <li>
                        <Link to="/Ads_Txt_Entries">{props.t("ADS.txt Entries")}</Link>
                      </li>
                      <li>
                        <Link to="/Publisher_Ads_Txt_Entries">{props.t("Publisher ADS.txt Entries")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isMasterLogAccess) ? 'block' : 'none' }}>
                    <Link to="/Master_Log" >{props.t("Master Log")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isPublisherLogAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Log" >{props.t("Publisher Log")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isNotificationAccess) ? 'block' : 'none' }}>
                    <Link to="/Notification">{props.t("Notification")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isPublisherApprovalAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Approval">{props.t("Publisher Approval")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isSupportTicketAccess) ? 'block' : 'none' }}>
                    <Link to="/Support_Tickets">{props.t("Support Tickets")}</Link>
                  </li>


                </ul>
              </li>

            </ul>
          }
          {userRole === 'Publisher' &&
            <ul className="metismenu list-unstyled" id="side-menu">

              <li className="menu-title">{props.t("Dashboard")} </li>
              {/* <li >
                <Link to="/Dashboard" className="">
                  <i className='bx bx-home-alt' ></i>
                  <span>{props.t("Home")}</span>
                </Link>
              </li> */}
              <li>
                <Link to="/Publisher_Marketplace_Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Marketplace Dashboard")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("BSRV")}</li>

              <li style={{ display: (UserRolePrivileges && UserRolePrivileges.isDemandAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow" onClick={(e) => e.preventDefault()}>
                  <i className="bx bxs-folder-open"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Demand")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isAdveriserAccess) ? 'block' : 'none' }}>
                    <Link to="/Advertiser">{props.t("Advertiser")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isIOGroupAccess) ? 'block' : 'none' }}>
                    <Link to="/Insertion_Order_Group">{props.t("Insertion Order Group")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isIOsAccess) ? 'block' : 'none' }}>
                    <Link to="/Insertion_Order">{props.t("Insertion Order")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isLineItemAccess) ? 'block' : 'none' }}>
                    <Link to="/Line_Items">{props.t("Line Items")}</Link>
                  </li>

                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isCreativeAccess) ? 'block' : 'none' }}>
                    {props.location.pathname === '/Creative_Weightage' ? <Link to="/Creative_Weightage">{props.t("Creative")}</Link>
                      : <Link to="/Creative">{props.t("Creative")}</Link>}
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Header Bidding")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                        <Link to="/HB_Buyer">{props.t("HB Buyer")}</Link>
                      </li>
                      <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Demand.isHeaderBiddingAccess) ? 'block' : 'none' }}>
                        <Link to="/HB_AdUnits">{props.t("HB AdUnits")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateBuyer">{props.t("HB Buyer")}</Link>
                      </li>
                      <li style={{ display: 'none' }}>
                        <Link to="/AssociateAdUnit">{props.t("HB AdUnits")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li style={{ display: (UserRolePrivileges && UserRolePrivileges.isSupplyAccess) ? 'block' : 'none' }}>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Supply")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.Supply.isPartnerAccess) ? 'block' : 'none' }}>
                    <Link to="/Supply_Partner">{props.t("Supply Partners")}</Link>
                  </li> */}
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isPublisherAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher">{props.t("Publishers")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isAdUnitGroupAccess) ? 'block' : 'none' }}>
                    <Link to="/AdUnitGroup">{props.t("AdUnit Group")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isAdUnitAccess) ? 'block' : 'none' }}>
                    <Link to="/AdUnit">{props.t("AdUnit")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isChildAdUnitAccess) ? 'block' : 'none' }}>
                    <Link to="/ChildAdUnit">{props.t("Child AdUnits")}</Link>
                  </li>

                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isPlayerSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/Player_Settings">{props.t("Player Settings")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isHighImpactSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/High_Impact">{props.t("High Impact Settings")}</Link>
                  </li>
                  <li style={{ display: (UserRolePrivileges && UserRolePrivileges.Supply.isSTDBSettingAccess) ? 'block' : 'none' }}>
                    <Link to="/Stbr_Settings">{props.t("Standard Banner Settings")}</Link>
                  </li>
                </ul>
              </li>

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  <span>{props.t("Supply")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/PublisherConsole_Publisher">{props.t("Publisher")}</Link>
                  </li>
                  <li>
                    <Link to="/Publisher_AdUnitGroup">{props.t("AdUnit Group")}</Link>
                  </li>
                  <li>
                    <Link to="/Publisher_AdUnit">{props.t("AdUnit")}</Link>
                  </li>
                </ul>
              </li> */}

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reporting")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Revenue_Report">{props.t("Revenue Report")}</Link>
                  </li>
                </ul>
              </li> */}

              <li >
                <Link to="/Publisher_Report" className="">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
              </li>
              {/* <li >
                <Link to="/PublisherSchedule_Report" className="">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reporting")}</span>
                </Link>
              </li> */}


              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-receipt"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Invoices")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Publisher_Invoices">{props.t("Invoice")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/Invoice_Approval">{props.t("Payment Profile")}</Link>
                  </li> */}
                  <li>
                    <Link to="/Publisher_Payments">{props.t("Payments")}</Link>
                  </li>
                </ul>
              </li>



              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dollar-circle" />
                  <span>{props.t("Revenue Opportunities")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Explore_Adformats">{props.t("Explore Adformats")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-shield"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Ads.txt Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/Publisher_Ads_Txt_Queries">{props.t("Ads.txt Monitor")}</Link>
                      </li>
                      <li>
                        <Link to="/Publishers_Ads_Txt_Entries">{props.t("ADS.txt Entries")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/BrandSafety">{props.t("Brand Safety")}</Link>
                  </li>
                  <li>
                    <Link to="/Reporting_API">{props.t("Reporting API")}</Link>
                  </li>
                </ul>
              </li>
              <li >
                <Link to="/Publisher_Support_Tickets" >
                  <i className='bx bxs-discount'></i>
                  <span>{props.t("Support Tickets")}</span>
                </Link>
              </li>

            </ul>
          }
          {userRole === 'AdOps' &&
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Dashboard")} </li>
              <li >
                <Link to="/Dashboard" className="">
                  <i className='bx bx-home-alt' ></i>
                  <span>{props.t("Home")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Bidserv")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  <span>{props.t("Demand")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li >
                    <Link to="/Advertiser">{props.t("Advertiser")}</Link>
                  </li>
                  <li >
                    <Link to="/Insertion_Order_Group">{props.t("Insertion Order Group")}</Link>
                  </li>
                  <li >
                    <Link to="/Insertion_Order">{props.t("Insertion Order")}</Link>
                  </li>
                  <li >
                    <Link to="/Line_Items">{props.t("Line Items")}</Link>
                  </li>
                  <li >
                    <Link to="/Tag_Controller">{props.t("Tag Controller")}</Link>
                  </li>
                  <li >
                    {props.location.pathname === '/Creative_Weightage' ? <Link to="/Creative_Weightage">{props.t("Creative")}</Link>
                      : <Link to="/Creative">{props.t("Creative")}</Link>}
                  </li>
                </ul>
              </li>
              <li >
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-folder-open"></i>
                  <span>{props.t("Supply")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li >
                    <Link to="/Supply_Partner">{props.t("Supply Partners")}</Link>
                  </li>
                  <li >
                    <Link to="/Publisher">{props.t("Publishers")}</Link>
                  </li>
                  <li >
                    <Link to="/AdUnitGroup">{props.t("AdUnit Group")}</Link>
                  </li>
                  <li >
                    <Link to="/AdUnit">{props.t("AdUnit")}</Link>
                  </li>
                  <li >
                    <Link to="/ChildAdUnit">{props.t("Child AdUnits")}</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-title">{props.t("Apps")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-chalkboard"></i>
                  <span>{props.t("House Ads")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/AdPublishers">{props.t("Publishers")}</Link>
                  </li>
                  <li>
                    <Link to="/AdUnits">{props.t("Ad Units")}</Link>
                  </li>
                  <li>
                    <Link to="/Creatives">{props.t("Creatives")}</Link>
                  </li>
                  <li>
                    <Link to="/NativeWidget">{props.t("Native Ad")}</Link>
                  </li>
                  <li>
                    <Link to="/Categories">{props.t("Categories")}</Link>
                  </li>
                  <li>
                    <Link to="/Custom_Report">{props.t("Report")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-server"></i>
                  <span>{props.t("Proxy")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Proxy_AdUnits">{props.t("Ad Units")}</Link>
                  </li>
                  <li>
                    <Link to="/Query_Report">{props.t("Report")}</Link>
                  </li>
                </ul>
              </li>

              <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isPartnerManagementAccess) ? 'block' : 'none' }}>
                <Link to="/Partner_Management" className="">
                  <i className="bx bx-user"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Partner Management")}</span>
                </Link>
              </li>
            </ul>
          }
          {userRole === 'BSRV21' &&
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-chalkboard"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                  <span>{props.t("Reporting")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Display_Analytics">{props.t("Display Analytics Report")}</Link>
                  </li>
                  <li>
                    <Link to="/Page_URL_Reports">{props.t("Page URL Report")}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          }
          {userRole === 'Agency' &&
            <ul className="metismenu list-unstyled" id="side-menu">

              <li className="menu-title">{props.t("Dashboard")} </li>
              <li>
                <Link to="/Agency_Marketplace_Dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Agency Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Bsrv")} </li>
              <li >
                <Link to="/Agency_Report" className="">
                  <i className="bx bxs-report"></i>
                  <span>{props.t(" Reports")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dollar-circle" />
                  <span>{props.t("Advertiser Opportunities")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Agency_ExploreAdFormats">{props.t("Explore Adformats")}</Link>
                  </li>
                </ul>
              </li>

              <li >
                <Link to="/Publisher_Support_Tickets" >
                  <i className='bx bxs-discount'></i>
                  <span>{props.t("Support Tickets")}</span>
                </Link>
              </li>
            </ul>
          }
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
