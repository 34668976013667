/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";
import { WithContext as ReactTags } from 'react-tag-input';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewKeyword as onADDKeyword,
  updateKeyword as onUpdateKeyword,
  readKeyword as onReadKeyword,
  addKeywordSuccess,
  addKeywordFail,
  updateKeywordSuccess,
  updateKeywordFail,
} from "store/Contextual_Settings/Keywords/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

var list = []
var keywordsList = []


const Create_Keywords = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [keyword, setKeyword] = useState({
    keywordGroup: '',
    keywordList: [],
  });
  console.log(props)

  useEffect(() => {
    if (props.selectedKeyword !== '') {
      list = []
      keywordsList = []
      console.log(props.selectedKeyword)
      setIsEdit(true)
      setKeyword(props.selectedKeyword)
      keywordsList = props.selectedKeyword.keywordList.map((item, index) =>
        Object.assign({}, { text: item, id: item })
      )
      console.log(keywordsList)
      props.selectedKeyword.keywordList.map((item, index) => {
        list.push(item)
        return list
      })

      setTags(keywordsList)
    } else {
      list = []
      keywordsList = []
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyword(keyword => ({ ...keyword, [name]: value }));
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.keywords.errorMsg,
    successMsg: state.keywords.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addKeywordSuccess(""));
        dispatch(addKeywordFail(""));
        dispatch(updateKeywordSuccess(""));
        dispatch(updateKeywordFail(""));
        if (props.selectedKeyword !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addKeywordSuccess(""));
        dispatch(addKeywordFail(""));
        dispatch(updateKeywordSuccess(""));
        dispatch(updateKeywordFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addKeywordFail(""));
    dispatch(updateKeywordFail(""));
    props.closeCanvas('')
};

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    list.splice(i, 1)
    setKeyword(keyword => ({ ...keyword, keywordList: list }));
  };

  const handleAddition = tag => {
    list.push(tag.id)
    setTags([...tags, tag]);
    setKeyword(keyword => ({ ...keyword, keywordList: list }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (keyword.keywordGroup) {
      if (props.selectedKeyword !== '') {
        dispatch(onUpdateKeyword({ ...keyword }));
      } else {
        dispatch(onADDKeyword(keyword));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="col-6">
        <Form onSubmit={handleSubmit}>
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Keyword Group Name</Label>
                <Input
                  type="text" name="keywordGroup" value={keyword.keywordGroup} onChange={handleChange} className={'form-control' + (submitted && !keyword.keywordGroup ? ' is-invalid' : '')}
                />
                {submitted && !keyword.keywordGroup &&
                  <div className="invalid-feedback">Keyword Group Name is required</div>
                }
              </div>
              <div className="mb-3">
                <Label className="form-label">Keyword</Label>

                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="inline"
                  style={{ width: '100%' }}
                  autocomplete
                  placeholder="Press enter to add new Keyword"
                />
              </div>
              <h6>or</h6>
              <div className="mt-3">
                <Label htmlFor="formFile" className="form-label">Upload Keyword List</Label>
                <Input className="form-control" type="file" id="formFile" />
                <br></br>
                <span href="#">Download sample file <i className="bx bx-cloud-download"></i></span>
              </div>


            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save Keyword Group
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_Keywords);

Create_Keywords.propTypes = {
  history: PropTypes.object,
};