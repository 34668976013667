/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,Spinner,InputGroup } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewChildAdUnit as onADDChildAdUnit,
  updateChildAdUnit as onUpdateChildAdUnit,
  readChildAdUnit as onReadChildAdUnit,
  addChildAdUnitSuccess,
  addChildAdUnitFail,
  updateChildAdUnitSuccess,
  updateChildAdUnitFail,
} from "store/Supply/ChildAdUnit/actions";

import {
  getPartnersList as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const Create_ChildAdUnit = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [childAdUnit, setChildAdUnit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [adUnitGroupData, setAdUnitGroupData] = useState([])
  const [adUnitData, setAdUnitData] = useState([])
  const [sizeData, setSizeData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(userInfo.userRole === 'Partner' ? { value: userInfo.userID, label: userInfo.name } : null);
  const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
  const [selectedAdUnit, setSelectedAdUnit] = useState(null);
  const [selectedDealType,setSelectedDealType] = useState(null);
  const [selectedSize,setSelectedSize] = useState(null);
  const [loader, setLoader] = useState(false);
  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
}

  var dealTypeOptions = [
    { value:'RevShare',label:'RevShare'},
    { value:'CPM',label:'CPM'},
    { value:'Fixed Spot',label:'Fixed Spot'},
  ]

  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
    dispatch(onGetAdUnitGroups(null));
    dispatch(onGetAdUnits(null));
  },[])

  useEffect(() => {
    if(props.selectedChildAdUnit  !== '' ){
      setIsEdit(true)
      setChildAdUnit(props.selectedChildAdUnit)
      console.log(props.selectedChildAdUnit)
  
      setSelectedDealType({value:props.selectedChildAdUnit.childAdUnitDealType,label:props.selectedChildAdUnit.childAdUnitDealType});
      setSelectedSize({value:props.selectedChildAdUnit.childAdUnitSize,label:props.selectedChildAdUnit.childAdUnitSize});
      getParentChildAdUnit()
    }
    if(props.selectedAdUnit !== '' || props.selectedChildAdUnit  === ''){
      getParentChildAdUnit()
    }
}, []);

const getParentChildAdUnit = (publisherId, adUnitGroupId, adUnitId) => {
  var filterBody = {
    url:(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/supply/getParent' : "http://127.0.0.1:2002/v1.0/supply/getParent",
    requestBody: {
      "partnerID": 0,
      "publisherID": 0,
      "adUnitGroupID": 0,
      "adUnitID": 0,
      "childAdUnitID": 0,
      "playerSettingID": 0,
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name:userInfo.name,
    }
  }
  if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
    filterBody.requestBody = {...filterBody.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
}
  if (props.selectedChildAdUnit !== '') {
    filterBody.requestBody.adUnitID = props.selectedChildAdUnit.adUnitID
  }
  if (props.selectedAdUnit !== '') {
    filterBody.requestBody.adUnitID = props.selectedAdUnit.adUnitId
  }
  if (publisherId) {
    filterBody.requestBody.publisherID = publisherId
  }
  if (adUnitGroupId) {
    filterBody.requestBody.adUnitGroupID = adUnitGroupId
  }
  if (adUnitId) {
    filterBody.requestBody.adUnitID = adUnitId
  }
  axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", filterBody,
    {
      headers: headers
    })
    .then((res) => {
      console.log("cdsresult is ==>", res)
      if (res.data.responseData) {
        setSelectedPublisher({ value: res.data.responseData.publisherID, label: res.data.responseData.publisherName });
        setSelectedPartner({ value: res.data.responseData.partnerID, label: res.data.responseData.username });
        if (!publisherId) {
          setSelectedAdUnitGroup({ value: res.data.responseData.adUnitGroupID, label: res.data.responseData.adUnitGroupName });
          setSelectedAdUnit({ value: res.data.responseData.adUnitID, label: res.data.responseData.adUnitName });
        }
        if (!adUnitGroupId) {
          setSelectedAdUnit({ value: res.data.responseData.adUnitID, label: res.data.responseData.adUnitName });
        }
      }
    })
    .catch((err) => {
      console.log("e", err)
    });
}

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupID:(selectedAdUnitGroup ? selectedAdUnitGroup.value : '') || '',
      adUnitID: (selectedAdUnit ? selectedAdUnit.value : '') || '',
      childAdUnitName: (childAdUnit && childAdUnit.childAdUnitName) || '',
      childAdUnitSize:(selectedSize ? selectedSize.value : '') || '',
      childAdUnitDealType:(selectedDealType ? selectedDealType.value : '') || '',
      childAdUnitPricing: (childAdUnit && childAdUnit.childAdUnitPricing) || 0,
      childAdUnitType: props.selectedChildAdUnitType || props.selectedChildAdUnit.childAdUnitType,
      isHBEnabled:false
    },
    validationSchema: Yup.object({
      childAdUnitName: Yup.string().required("ChildAdUnit Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if(props.selectedChildAdUnit  !== ''){
        dispatch(onUpdateChildAdUnit({...values,childAdUnitID:props.selectedChildAdUnit.childAdUnitID,childAdUnitName:values.childAdUnitName.trim(),userRole:userInfo.userRole,userID:userInfo.userID}));
      }else{
        dispatch(onADDChildAdUnit({...values,childAdUnitName:values.childAdUnitName.trim(),userRole:userInfo.userRole,userID:userInfo.userID}));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.childAdUnits.errorMsg,
    successMsg: state.childAdUnits.successMsg,
  }));

  console.log(errorMsg, successMsg)

    useEffect(() => {
    if(successMsg !== null){
       if(successMsg.success){
        dispatch(addChildAdUnitSuccess(""));
        dispatch(addChildAdUnitFail(""));
        dispatch(updateChildAdUnitSuccess(""));
        dispatch(updateChildAdUnitFail(""));
        setLoader(false)
        if(props.selectedChildAdUnit  !== ''){
          props.closeCanvas('Update')
        }else{
          props.closeCanvas('Create')
        }
       }
   }
   }, [ successMsg]);

   useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addChildAdUnitSuccess(""));
        dispatch(addChildAdUnitFail(""));
        dispatch(updateChildAdUnitSuccess(""));
        dispatch(updateChildAdUnitFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addChildAdUnitSuccess(""));
    dispatch(addChildAdUnitFail(""));
    dispatch(updateChildAdUnitSuccess(""));
    dispatch(updateChildAdUnitFail(""));
        props.closeCanvas('')
  };

   const { partners ,publishers, adUnitGroups,adUnits, sizes} = useSelector(state => ({
    partners: state.partners.partnerList,
    publishers: state.publishers.publisherList,
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    adUnits: state.adUnits.adUnitList,
    sizes : state.commonEvents.sizeList
  }));
  
  console.log(partners,publishers,adUnitGroups,adUnits,sizes)

  useEffect(() => {
    if (partners !== null ) {
      dispatch(onGetPartners({type:(validation.values.childAdUnitType === 'play' || validation.values.childAdUnitType === 'vast') ? 'video' :  validation.values.childAdUnitType}));
      
    }
  }, []);

   useEffect(() => {
    if (publishers !== null && adUnitGroups !== null && adUnits !== null && sizes !== null) {
      dispatch(onGetPublishers({partnerID:'',userRole:userInfo.userRole,userID:userInfo.userID ,filter:'Active',type:(validation.values.childAdUnitType === 'play' || validation.values.childAdUnitType === 'vast') ? 'video' :  validation.values.childAdUnitType}));
      dispatch(onGetAdUnitGroups({publisherID:'',userRole:userInfo.userRole,userID:userInfo.userID ,filter:'Active',adUnitGroupType:(validation.values.childAdUnitType === 'play' || validation.values.childAdUnitType === 'vast') ? 'video' :  validation.values.childAdUnitType}));
      dispatch(onGetAdUnits({ adUnitGroupID: '', userRole:userInfo.userRole,userID:userInfo.userID ,filter: 'Active', adUnitType:props.selectedChildAdUnitType || props.selectedChildAdUnit.childAdUnitType, adUnitSize: '' }));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers && publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  useEffect(() => {
    if (adUnitGroups && adUnitGroups.length > 0) {
      setAdUnitGroupData(objectAdUnitGroupModal(adUnitGroups))
      console.log(adUnitGroups)
    }
  }, [adUnitGroups]);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  useEffect(() => {
      setSizeData([{value:'1x1',label:'1x1'}])
      setSelectedSize({value:'1x1',label:'1x1'})
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitGroupID
      obj.label = item.adUnitGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.adUnitName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setChildAdUnit(validation.values)
    setSelectedPartner(selectPartner);
    console.log(selectPartner)
    dispatch(onGetPublishers({partnerID:selectPartner.value,userRole:userInfo.userRole,userID:userInfo.userID ,filter:'Active',type:(validation.values.childAdUnitType === 'play' || validation.values.childAdUnitType === 'vast') ? 'video' :  validation.values.childAdUnitType}));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    getParentChildAdUnit(selectPublisher.value,'','')
    setChildAdUnit(validation.values)
    setSelectedPublisher(selectPublisher);
    dispatch(onGetAdUnitGroups({publisherID:selectPublisher.value,userRole:userInfo.userRole,userID:userInfo.userID ,filter:'Active',adUnitGroupType:(validation.values.childAdUnitType === 'play' || validation.values.childAdUnitType === 'vast') ? 'video' :  validation.values.childAdUnitType}));
    setSelectedAdUnitGroup(null)
  }

  function handleSelectAdUnitGroup(selectAdUnitGroup) {
    getParentChildAdUnit('',selectAdUnitGroup.value,'')
    setChildAdUnit(validation.values)
    setSelectedAdUnitGroup(selectAdUnitGroup);
    dispatch(onGetAdUnits({ adUnitGroupID: selectAdUnitGroup.value, userRole:userInfo.userRole,userID:userInfo.userID ,filter: 'Active', adUnitType:props.selectedChildAdUnitType || props.selectedChildAdUnit.childAdUnitType, adUnitSize: '' }));
    setSelectedAdUnit(null)
  }

  function handleSelectAdUnit(selectAdUnit) {
    getParentChildAdUnit('','',selectAdUnit.value)
    setChildAdUnit(validation.values)
    setSelectedAdUnit(selectAdUnit);
  }

  function handleSelectDealType(selectDealType) {
    setChildAdUnit(validation.values)
    setSelectedDealType(selectDealType);
  }

  function handleSelectSize(selectSize) {
    setChildAdUnit(validation.values)
    setSelectedSize(selectSize);
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault();}
  };

function allowAlphaNumericSpace(e) {
  var code = ('charCode' in e) ? e.charCode : e.keyCode;
  if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
};

  return (
    <React.Fragment>
      {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row form style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
            {userInfo.userRole !== 'Partner' &&
            <div className="mb-3">
                <Label>Partner</Label>
                <Select
                  value={selectedPartner}
                  onChange={s => {
                    handleSelectPartner(s)
                  }}
                  options={partnerData}
                  invalid={
                    validation.touched.partnerID && validation.errors.partnerID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.partnerID && validation.errors.partnerID ? (
                  <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
                ) : null}

              </div>
}
              <div className="mb-3">
                <Label>Publisher</Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  invalid={
                    validation.touched.publisherID && validation.errors.publisherID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.publisherID && validation.errors.publisherID ? (
                  <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>Ad Unit Group</Label>
                <Select
                  value={selectedAdUnitGroup}
                  onChange={s => {
                    handleSelectAdUnitGroup(s)
                  }}
                  options={adUnitGroupData}
                  invalid={
                    validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitGroupID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>Ad Unit</Label>
                <Select
                  value={selectedAdUnit}
                  onChange={s => {
                    handleSelectAdUnit(s)
                  }}
                  options={adUnitData}
                  invalid={
                    validation.touched.adUnitID && validation.errors.adUnitID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.adUnitID && validation.errors.adUnitID ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">Child Ad Unit Name</Label>
                <Input
                  name="childAdUnitName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={110}
                  value={validation.values.childAdUnitName || ""}
                  invalid={
                    validation.touched.childAdUnitName && validation.errors.childAdUnitName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.childAdUnitName && validation.errors.childAdUnitName ? (
                  <FormFeedback type="invalid">{validation.errors.childAdUnitName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Child Ad Unit Pricing</Label>
                <InputGroup>
                        <div className="input-group-text">$</div>
                <Input
                  name="childAdUnitPricing"
                  type="text"  onKeyPress={allowOnlyNumbers}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.childAdUnitPricing}
                  invalid={
                    validation.touched.childAdUnitPricing && validation.errors.childAdUnitPricing ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                </InputGroup>
                {validation.touched.childAdUnitPricing && validation.errors.childAdUnitPricing ? (
                  <FormFeedback type="invalid">{validation.errors.childAdUnitPricing}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label>Child Ad Unit Deal Type</Label>
                <Select
                  value={selectedDealType}
                  onChange={s => {
                    handleSelectDealType(s)
                  }}
                  options={dealTypeOptions}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                <Label>Child Ad Unit Size</Label>
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  invalid={
                    validation.touched.childAdUnitSize && validation.errors.childAdUnitSize ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.childAdUnitSize && validation.errors.childAdUnitSize ? (
                  <FormFeedback type="invalid">{validation.errors.childAdUnitSize}</FormFeedback>
                ) : null}

              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
              {isEdit && !props.selectedArchived &&
                 <button
                 type="submit"
                 className="btn btn-primary save-user"
               >
                Update
               </button>
              }
              {!isEdit && 
                  <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Save
                </button>
              }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_ChildAdUnit);

Create_ChildAdUnit.propTypes = {
  history: PropTypes.object,
};