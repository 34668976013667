import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SCHEDULE_REPORTS,ADD_SCHEDULE_REPORT , UPDATE_SCHEDULE_REPORT,READ_SCHEDULE_REPORT,STATUS_SCHEDULE_REPORT,ARCHIVE_SCHEDULE_REPORT} from "./actionTypes"

import {
  loadScheduleReports,
  noScheduleReports,
  getScheduleReportsSuccess,
  getScheduleReportsFail,
  addScheduleReportSuccess,
  addScheduleReportFail,
  updateScheduleReportSuccess,
  updateScheduleReportFail,
  readScheduleReportSuccess,
  statusScheduleReportSuccess,
  archiveScheduleReportSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getScheduleReports,postScheduleReport,updateScheduleReport,readScheduleReport,statusChangeScheduleReport,archiveScheduleReport } from "../../../helpers/Backend_helper"

function* fetchScheduleReports({ payload: scr }) {
  try {
    yield put(loadScheduleReports(true))
    var getScrList
      if(scr){
        getScrList = yield call(getScheduleReports,scr)
        getScrList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
      if(getScrList.responseData.response.length === 0){
        yield put(noScheduleReports(true))
      }
        yield put(getScheduleReportsSuccess(getScrList.responseData.response))
      }
      else{
        getScrList = []
        yield put(getScheduleReportsSuccess(getScrList))
      }
  } catch (error) {
    console.log(error)
    yield put(getScheduleReportsFail(error))
  }
}

function* onReadScheduleReport({ payload: scr }) {
  try {
    const response = yield call(readScheduleReport, scr)
    yield put(readScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(readScheduleReportSuccess(error))
  }
}

function* onAddScheduleReport({ payload: scr }) {
  try {
    const response = yield call(postScheduleReport, scr)
    if(response.responseData.success){
      yield put(addScheduleReportSuccess(response.responseData))
    }else{
      yield put(addScheduleReportFail(response.responseData.message))
    }
  } catch (error) {
    console.log(error)
    yield put(addScheduleReportFail(error))
  }
}

function* onUpdateScheduleReport({ payload: scr }) {
  try {
    const response = yield call(updateScheduleReport, scr)
    yield put(updateScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusScheduleReportSuccess(error))
  }
}

function* onStatusScheduleReport({ payload: scr }) {
  try {
    const response = yield call(statusChangeScheduleReport, scr)
    yield put(statusScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(updateScheduleReportFail(error))
  }
}

function* onArchiveScheduleReport({ payload: scr }) {
  try {
    const response = yield call(archiveScheduleReport, scr)
    yield put(archiveScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveScheduleReportSuccess(error))
  }
}

function* scheduleReportsSaga() {
  yield takeEvery(GET_SCHEDULE_REPORTS, fetchScheduleReports)
  yield takeEvery(ADD_SCHEDULE_REPORT, onAddScheduleReport)
  yield takeEvery(UPDATE_SCHEDULE_REPORT, onUpdateScheduleReport)
  yield takeEvery(READ_SCHEDULE_REPORT, onReadScheduleReport)
  yield takeEvery(STATUS_SCHEDULE_REPORT, onStatusScheduleReport)
  yield takeEvery(ARCHIVE_SCHEDULE_REPORT, onArchiveScheduleReport)
}

export default scheduleReportsSaga;
