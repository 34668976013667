import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Input, Label,Spinner,    DropdownItem,
    DropdownToggle,
    DropdownMenu,    Card,
    CardBody,
    Dropdown, Form, Button ,FormFeedback} from "reactstrap"
import axios from "axios";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

const AdsTxtValidator = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [adsTxtData, setAdsTxtData] = useState({
        type: "",
        email:"",
        adsTxtEntries:[],
        domainEntries:[]
      });
      const [adsTxtEntries, setAdsTxtEntries] = useState([])
      const [submitted, setSubmitted] = useState(false);
      const [loader, setLoader] = useState(false);
      const [reportOpen, setReportOpen] = useState(false);
      const [adsTxtQueries,setAdsTxtQueries] = useState([])
      const [btnprimary1, setBtnprimary1] = useState(false)

      const [adsTxtEntriesData, setAdsTxtEntriesData] = useState({
        adsTxtEntriesValue: ''
      })
      const [domainEntries, setDomainEntries] = useState([])

      const [domainEntriesData, setDomainEntriesData] = useState({
        domainEntriesValue: ''
      })

      const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

      const handleChange = (e) => {
        const { name, value } = e.target;
        setAdsTxtData(adsTxtData => ({ ...adsTxtData, [name]: value }));
      }

      const handleChangeAdsTxtEntries = (e) => {
        const { name, value } = e.target;
        setAdsTxtEntriesData(adsTxtEntriesData => ({ ...adsTxtEntriesData, [name]: value }));
        let urls = value.split(/\n/);
        setAdsTxtEntries(urls)
      }

      const handleChangeDomain = (e) => {
        const { name, value } = e.target;
        setDomainEntriesData(domainEntriesData => ({ ...domainEntriesData, [name]: value }));
        let urls = value.split(/[\s,]+/);
        setDomainEntries(urls)
      }


      const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (adsTxtData.email ) {
          setLoader(true)
          let finalData = {
              "type": 'adstxt',
              "email": adsTxtData.email,
              "adsTxtEntries":adsTxtEntries,
              "domainEntries": domainEntries,
          }
          const body = {
            "url": "http://127.0.0.1:2001",
            "requestBody":{
              ...finalData,
              userID: userInfo.userID,
              userRole: userInfo.userRole,
              companyID: userInfo.companyID,
            }
          }
          axios.post("/v1.0/connectOP", body,{
                headers: headers
          })
          .then((res) => {
            setLoader(false)
            console.log("Data Created", res)
            if(res.data.responseData.success){
                console.log(res.data.responseData)
                setReportOpen(true)
                res.data.responseData.data.map((item, index) => {
                    item.id = index + 1
                    item.original = item.original
                    item.actual = item.actual
                    item.entryString = JSON.stringify(item.entryString.replace(/,/g, ','))
                    item.overAll = item.found
                    item.sspDomainValue = item.entryObject.sspDomain.value,
                    item.sspDomainFound = item.entryObject.sspDomain.found,
                    item.accountIdValue = item.entryObject.accountId.value,
                    item.accountIdFound = item.entryObject.accountId.found,
                    item.relationshipValue = item.entryObject.relationship.value,
                    item.relationshipFound = item.entryObject.relationship.found,
                    item.certificationIDValue = item.entryObject.certificationID.value,
                    item.certificationIDFound = item.entryObject.certificationID.found
                })
                setAdsTxtQueries(res.data.responseData.data)
            }
          })
          .catch((err) => {
            setLoader(false)
            console.log("e", err)
          });
        }
      }

      const keyField = "id";

      const adsTxtQueriesColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "original",
            text: "Domain URL",
            sort: true,
        },
        {
            dataField: "actual",
            text: "Actual URL",
            sort: true,
            style:{wordBreak: 'break-word'}
        },

        {
            dataField: "entryString",
            text: "Ads.Txt Entry",
            sort: true,
            style:{wordBreak: 'break-word'}
        },
        {
            dataField: "overAll",
            text: "Entry Found",
            sort: true,
        },
        {
            dataField: "sspDomainValue",
            text: "SSP Domain",
            sort: true,
        },
        {
            dataField: "sspDomainFound",
            text: "SSP Domain Found",
            sort: false,
        },
        {
            dataField: "accountIdValue",
            text: "Account Id",
            sort: true,
        },
        {
            dataField: "accountIdFound",
            text: "Account Id Found",
            sort: true,
        },
        {
            dataField: "relationshipValue",
            text: "Relationship",
            sort: false,
        },
        {
            dataField: "relationshipFound",
            text: "Relationship Found",
            sort: true,
        },
        {
            dataField: "certificationIDValue",
            text: "Certification ID",
            sort: true,
        },
        {
            dataField: "certificationIDFound",
            text: "Certification ID Found",
            sort: false,
        },
    ];

    
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        totalSize: adsTxtQueries.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{text: "10",value: 10}, {text: "30", value: 30},{text: "50",value: 50},{text: "100",value: 100}],
    };

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>Ads.Txt Validator | Bidsxchange</title>
                    </MetaTags>
                    {!reportOpen && 
                           <Container fluid={true}>
                           <Breadcrumbs title="Tools" breadcrumbItem="Ads.Txt Validator" />
                           <div className="card card-body " >
                               <div>
                                   <Form onSubmit={handleSubmit}>
                                       <div className="form-group">
                                           <div className="row mt-2 mb-3">
                                               <div className="col-md-6">
                                               <div className="mb-3">
                                                   <Label className="form-label">Email Id</Label>
                                                   <Input
                                                   name="email"
                                                   type="text"
                                                   onChange={handleChange}
                                                   value={adsTxtData.email}
                                                   placeholder="E-mail Address"
                                                   className={'form-control' + (submitted && !adsTxtData.email ? ' is-invalid' : '')}
                                                   />
                                                   {submitted && !adsTxtData.email ? (
                                                   <FormFeedback type="invalid">{'Email Id is required'}</FormFeedback>
                                                   ) : null}
                                               </div>
                                               </div>
                                           </div>
                                       </div>
   
                                       <div className="form-group">
                                           <div className="row mt-2 mb-3">
                                               <div className="col-md-6">
                                                   <Label htmlFor="AdsTxtEntries" style={{ color: '#000' }}>ADS.Txt Entries</Label><br></br>
                                                   <Label htmlFor="AdsTxtEntries">[SSP_Domain(Required), Account_ID(Required), Relationship(Optional), Certification ID(Optional)]</Label>                                         
                                                   {/* <div className="valid-feedback">ADS.Txt Entries field is valid!</div>
                                                   <div className="invalid-feedback">ADS.Txt Entries field cannot be blank!</div> */}
                                               </div>
                                               <div className="col-md-6">
                                                   <Label htmlFor="domainEntries" style={{ color: '#000' }}>Domain Entries </Label><br></br>
                                                   <Label htmlFor="domainEntries">[Example: elitelifedecor.com]</Label>
                                                   {/* <div className="valid-feedback">Domain field is valid!</div>
                                                   <div className="invalid-feedback">Domain field cannot be blank!</div> */}
                                               </div>
                                               <div className="col-md-6">
                                                   <Input
                                                           name="adsTxtEntriesValue"
                                                           type="textarea"
                                                           id="textarea"
                                                           rows="9"
                                                           className="form-control mt-3"
                                                           value={adsTxtEntriesData.adsTxtEntriesValue}
                                                           onChange={handleChangeAdsTxtEntries}
                                                           />
                                                   {/* <div className="valid-feedback">ADS.Txt Entries field is valid!</div>
                                                   <div className="invalid-feedback">ADS.Txt Entries field cannot be blank!</div> */}
                                               </div>
                                               <div className="col-md-6">
                                                   <Input
                                                           name="domainEntriesValue"
                                                           type="textarea"
                                                           id="textarea"
                                                           rows="9"
                                                           className="form-control mt-3"
                                                           value={domainEntriesData.domainEntriesValue}
                                                           onChange={handleChangeDomain}
                                                           />
                                                   {/* <div className="valid-feedback">Domain field is valid!</div>
                                                   <div className="invalid-feedback">Domain field cannot be blank!</div> */}
                                               </div>
                                           </div>
                                       </div>
                                       <div className="form-group">
                                           <div className="row mt-2 mb-3">
                                               <div className="col-md-5"></div>
                                               <div className="col-md-2">
                                                   <div className="row justify-content-center">
                                                       <button id="submit_adsTxt" type="submit" className="subscribe btn btn-primary btn-block rounded-pill shadow-sm">Start Validation</button>
                                                   </div>
                                               </div>
                                               <div className="col-md-5"></div>
                                           </div>
                                       </div>
                                       <div className="form-group" id="download_adsTxt_file" style={{ display: 'none' }}>
                                           <div className="row">
                                               <div className="col-md-4"></div>
                                               <div className="col-md-4">
                                                   <div className="download-wrapper" id="download_adsTxt">
                                                       <p><i className="fa fa-download"></i> Download ADS.Txt Validated File</p>
                                                   </div>
                                               </div>
                                               <div className="col-md-4"></div>
                                           </div>
                                       </div>
                                       <div className="form-group" id="Email_file" style={{ display: 'none' }}>
                                           <div className="row">
                                               <div className="col-md-4"></div>
                                               <div className="col-md-4">
                                                   <div className="download-msg">
                                                       <p><span id="emailMsg"></span></p>
                                                   </div>
                                               </div>
                                               <div className="col-md-4"></div>
                                           </div>
                                       </div>
                                   </Form>
                               </div>
                           </div>
                           </Container>
                    }
                       {reportOpen &&
                        <Container fluid={true}>
                            <div className=" d-sm-flex  justify-content-between">
                                <SupplyBreadCrumb title="House Ad" breadcrumbItem="Ads.txt validated Data" />
                                <div className=" text-sm-end">
                                    <div className="btn-group">
                                        <Dropdown
                                            isOpen={btnprimary1}
                                            toggle={() => setBtnprimary1(!btnprimary1)}
                                        >
                                            <DropdownToggle tag="button" className="btn btn-primary">
                                                Export
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={(e) => setReportOpen(false)}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Modify Ads.Txt Entries
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody aria-hidden="true">
                                            {loader &&
                                                <>
                                                    <Spinner className="ms-2 loader" color="primary" />
                                                    <br />
                                                    <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                    <br />
                                                </>
                                            }
                                            {adsTxtQueries.length === 0 &&
                                                <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                            }
                                            {adsTxtQueries.length > 0 &&
                                                <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    keyField={keyField}
                                                    data={adsTxtQueries}
                                                    columns={adsTxtQueriesColumns}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => {
                                                        return (
                                                            <ToolkitProvider
                                                                keyField={keyField}
                                                                data={adsTxtQueries}
                                                                columns={adsTxtQueriesColumns}
                                                                // bootstrap4
                                                                search
                                                                exportCSV={{
                                                                    ignoreFooter: false
                                                                }}
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col sm="8">
                                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                    <div className="position-relative">
                                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="1">
                                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={keyField}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                    // pagination={paginationFactory(pageOptions)}
                                                                                    // ref={node}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <PaginationTotalStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                                    <span>Show Rows : </span> &nbsp;&nbsp;
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        );
                                                    }}
                                                </PaginationProvider>
                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            {/* </div> */}
        </React.Fragment>
    )
}

AdsTxtValidator.propTypes = {}

export default AdsTxtValidator