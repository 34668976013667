import {
    LOAD_ACTIVITIES,
    NO_DATA_HOUSE_ADUNITS,
    GET_HOUSE_ADUNITS_SUCCESS,
    GET_HOUSE_ADUNITS_FAIL,
    ADD_HOUSE_ADUNIT_SUCCESS,
    ADD_HOUSE_ADUNIT_ERROR,
    UPDATE_HOUSE_ADUNIT_SUCCESS,
    UPDATE_HOUSE_ADUNIT_ERROR,
    READ_HOUSE_ADUNIT_SUCCESS,
    STATUS_HOUSE_ADUNIT_SUCCESS,
    ARCHIVE_HOUSE_ADUNIT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    adUs: [],
    error: {},
    successMsg: null,
    loading:false,
    noData:false,
    errorMsg: null,
    adUnit: null,
    response:null,
    archiveMsg:null,
}

const adUnit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_HOUSE_ADUNITS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_HOUSE_ADUNITS_SUCCESS:
            return {
                ...state,
                loading:false,
                adUs: action.payload,
            }
  
        case GET_HOUSE_ADUNITS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_HOUSE_ADUNIT_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_HOUSE_ADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HOUSE_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HOUSE_ADUNIT_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_HOUSE_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HOUSE_ADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HOUSE_ADUNITS_SUCCESS' ? null : action.payload,
            }
        case READ_HOUSE_ADUNIT_SUCCESS:
            return {
                ...state,
                adUnit: action.payload,
            }
        case STATUS_HOUSE_ADUNIT_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_HOUSE_ADUNIT_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default adUnit